import React, { PropsWithChildren, useEffect, useReducer, useState, createContext } from 'react';
// websocket
import { Socket } from 'socket.io-client';
// redux
import { useDispatch } from '../redux/store';
import { getOrders } from '../redux/slices/order';
// hooks
import useAuth from '../hooks/useAuth';
import { useSocket } from '../hooks/useSocket';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

export interface ISocketContextState {
    socket: Socket | undefined;
    uid: string;
    users: string[];
    venues: string[];
}

export const defaultSocketContextState: ISocketContextState = {
    socket: undefined,
    uid: '',
    users: [],
    venues: [],
};

export type TSocketContextActions = 'update_socket' | 'update_uid' | 'update_users' | 'remove_user' | 'join_rooms';
export type TSocketContextPayload = string | string[] | Socket;
export type TSocketStatus = 'connected' | 'disconnected' | 'reconnecting';

export interface ISocketContextActions {
    type: TSocketContextActions;
    payload: TSocketContextPayload;
}

export const SocketReducer = (state: ISocketContextState, action: ISocketContextActions) => {
    // console.log('Message recieved - Action: ' + action.type + ' - Payload: ', action.payload);

    switch (action.type) {
        case 'update_socket':
            return { ...state, socket: action.payload as Socket };
        case 'update_uid':
            return { ...state, uid: action.payload as string };
        case 'update_users':
            return { ...state, users: action.payload as string[] };
        case 'remove_user':
            return { ...state, users: state.users.filter((uid) => uid !== (action.payload as string)) };
        default:
            return state;
    }
};

export interface ISocketContextProps {
    SocketState: ISocketContextState;
    SocketDispatch: React.Dispatch<ISocketContextActions>;
    connection: TSocketStatus;
}

const SocketContext = createContext<ISocketContextProps>({
    SocketState: defaultSocketContextState,
    SocketDispatch: () => {},
    connection: 'disconnected',
});

export const SocketContextConsumer = SocketContext.Consumer;
export const SocketContextProvider = SocketContext.Provider;

// ----------------------------------------------------------------------

export interface ISocketContextComponentProps extends PropsWithChildren {}

const SocketProvider: React.FunctionComponent<ISocketContextComponentProps> = (props) => {
    const { children } = props;

    const { user } = useAuth();

    const dispatch = useDispatch();

    const socket = useSocket(HOST_API, {
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        // autoConnect: false
    });

    const [SocketState, SocketDispatch] = useReducer(SocketReducer, defaultSocketContextState);
    const [loading, setLoading] = useState(true);
    const [connection, setConnection] = useState<TSocketStatus>('connected');

    useEffect(() => {
        socket.connect();
        SocketDispatch({ type: 'update_socket', payload: socket });
        StartListeners();
        SendHandshake();
        JoinRooms();
        // eslint-disable-next-line
    }, []);

    const StartListeners = () => {
        // Messages
        socket.on('user_connected', (users: string[]) => {
            // console.info('User connected message received');
            SocketDispatch({ type: 'update_users', payload: users });
        });

        // Messages
        socket.on('user_disconnected', (uid: string) => {
            // console.info('User disconnected message received');
            SocketDispatch({ type: 'remove_user', payload: uid });
        });

        // Connection / reconnection listeners
        socket.io.on('reconnect', (attempt) => {
            // console.info('Reconnected on attempt: ' + attempt);
            SendHandshake();
            setConnection('connected')
        });

        socket.io.on('reconnect_attempt', (attempt) => {
            // console.info('Reconnection Attempt: ' + attempt);
            setConnection('reconnecting')
        });

        socket.io.on('reconnect_error', (error) => {
            // console.info('Reconnection error: ' + error);
        });

        socket.io.on('reconnect_failed', () => {
            // console.info('Reconnection failure.');
            setConnection('disconnected')
            // alert('We are unable to connect you to the server.  Please make sure your internet connection is stable or try again later.');
        });

        socket.on('new_order', (message) => {
            // console.info('Message received: ' + message);
            dispatch(getOrders())
        });

        socket.on('update_order', (message) => {
            // console.info('Message received: ' + message);
            dispatch(getOrders())
        });
    };

    const SendHandshake = async () => {
        // console.info('Sending handshake to server ...');

        socket.emit('handshake', async (uid: string, users: string[], venues: string[]) => {
            // console.info('User handshake callback message received');
            SocketDispatch({ type: 'update_users', payload: users });
            SocketDispatch({ type: 'update_uid', payload: uid });
        });

        setLoading(false);
    };

    const JoinRooms = () => {
        // Send Venue Ids to Join Rooms
        socket.emit('join_room', user?.venues_ids)
    }

    if (loading) return <p>... loading Socket IO ....</p>;

    return <SocketContextProvider value={{ SocketState, SocketDispatch, connection }}>{children}</SocketContextProvider>;
};

export { SocketContext, SocketProvider };