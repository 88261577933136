import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { UserManager, UserState } from '../../@types/user';
import { collection, query, where, getDocs } from 'firebase/firestore';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: UserState = {
  isLoading: false,
  error: null,
  users: [],
  user: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    rating: '',
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    //  SORT & FILTER USERS
    sortByUsers(state, action) {
      state.sortBy = action.payload;
    },

    filterUsers(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.rating = action.payload.rating;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByUsers,
  filterUsers,
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Axios query
      const response: { data: { users: UserManager[] } } = await axios.get('/venueusers');
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { user: UserManager } } = await axios.get('/venueusers/profile', {
        params: { id },
      });
      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser(data: UserManager) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venueusers/new', {
        data,
      });
      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function resetUserPassword(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/venueusers/password_reset/${id}`, {
        params: { id },
      });
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(data: UserManager) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venueusers/edit', {
        data,
      });

      const isNewPhoto = typeof data.avatarUrl === 'object'

      if (isNewPhoto && data.avatarUrl) {
        
        const formData = new FormData()

        formData.append('photo_key', data.photo_key)

        formData.append('file', data.avatarUrl)

        const photoResponse = await axios.post(`/venueusers/profile-photo/upload`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUserAuth(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venueusers/auth/edit', {
        data,
      });

      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function verifyUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/venueusers/request_verification/${id}`, {
        params: { id },
      });
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}