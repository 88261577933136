import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { CategoryState, Category, CategoryType, CategorySelect } from '../../@types/category';
import { collection, query, where, getDocs } from 'firebase/firestore';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: CategoryState = {
  isLoading: false,
  error: null,
  categories: [],
  category: null,
  categoriesSelect: [],
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  }
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET SHORT CATEGORIES
    getShortCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categoriesSelect = action.payload;
    },

    // GET CATEGORY
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },

    //  SORT & FILTER CATEGORIES
    sortByCategories(state, action) {
      state.sortBy = action.payload;
    },

    filterCategories(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByCategories,
  filterCategories,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCategories() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Axios query
      const response: { data: { categories: Category[] } } = await axios.get('/product_category');
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getShortCategories() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Axios query
      const response: { data: { categoriesSelect: CategorySelect[] } } = await axios.get('/categories');
      dispatch(slice.actions.getShortCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategory(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { category: Category } } = await axios.get('/product_category/:id', {
        params: { id },
      });
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createCategory(data: Category) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/product_category/new', {
        data,
      });
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategory(data: Category) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/product_category/edit', {
        data,
      });
      dispatch(slice.actions.getCategorySuccess(response.data.category));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}