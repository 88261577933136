export const tokens = {
  alerts: {
    attention: 'alerts.attention',
    error: 'alerts.error',
    success: 'alerts.success',
    warning: 'alerts.warning',
  },
  buttons: {
    accept: 'buttons.accept',
    add: 'buttons.add',
    addTo: 'buttons.addTo',
    addToAlt: 'buttons.addToAlt',
    back: 'buttons.back',
    bulkEdit: 'buttons.bulkEdit',
    bulkStockEntry: 'buttons.bulkStockEntry',
    cancel: 'buttons.cancel',
    change: 'buttons.change',
    clearFilter: 'buttons.clearFilter',
    create: 'buttons.create',
    confirm: 'buttons.confirm',
    continue: 'buttons.continue',
    delete: 'buttons.delete',
    deliver: 'buttons.deliver',
    edit: 'buttons.edit',
    filter: 'buttons.filter',
    finish: 'buttons.finish',
    invite: 'buttons.invite',
    new: 'buttons.new',
    newAlt: 'buttons.newAlt',
    next: 'buttons.next',
    reconnect: 'buttons.reconnect',
    record: 'buttons.record',
    rememberAcc: 'buttons.rememberAcc',
    resendCodeEmail: 'buttons.resendCodeEmail',
    resendVerifEmail: 'buttons.resendVerifEmail',
    prepare: 'buttons.prepare',
    previous: 'buttons.previous',
    save: 'buttons.save',
    select: 'buttons.select',
    setReady: 'buttons.setReady',
    show: 'buttons.show',
    submit: 'buttons.submit',
    sync: 'buttons.sync',
    update: 'buttons.update',
    upload: 'buttons.upload',
    verify: 'buttons.verify',
    view: 'buttons.view',
  },
  calendar: {
    day: 'calendar.day',
    week: 'calendar.week',
    month: 'calendar.month',
    year: 'calendar.year',
  },
  components: {
    filter: 'components.filter',
    photo: 'components.photo',
    search: 'components.search',
    fileUpload: {
      header: 'components.fileUpload.header',
      subStart: 'components.fileUpload.subStart',
      subEnd: 'components.fileUpload.subEnd',
    },
    photoUpload: {
      header: 'components.photoUpload.header',
      subStart: 'components.photoUpload.subStart',
      subEnd: 'components.photoUpload.subEnd',
    },
  },
  charts: {
    activeSale: 'chart.activeSale',
    averagePublic: 'chart.averagePublic',
    averageTicket: 'chart.averageTicket',
    monthlyComparison: 'chart.monthlyComparison',
    publicDistribution: 'chart.publicDistribution',
    publicAgeDistribution: 'chart.publicAgeDistribution',
    saleIncome: 'chart.saleIncome',
    salesOverview: 'chart.salesOverview',
    salesPerVenue: 'chart.salesPerVenue',
    productSalesPerCategory: 'chart.productSalesPerCategory',
    productSalesRanking: 'chart.productSalesRanking',
    weeklyComparison: 'chart.weeklyComparison',
  },
  common: {
    all: 'common.all',
    allowed: 'common.allowed',
    authorized: 'common.authorized',
    browse: 'common.browse',
    bulk: 'common.bulk',
    date: 'common.date',
    entry: 'common.entry',
    exit: 'common.exit',
    history: 'common.history',
    languageChanged: 'common.languageChanged',
    maxSize: 'common.maxSize',
    notAllowed: 'common.notAllowed',
    notes: 'common.notes',
    or: 'common.or',
    question: 'common.question',
    recommended: 'common.recommended',
    selected: 'common.selected',
    source: 'common.source',
    to: 'common.to',
    unauthorized: 'common.unauthorized',
    with: 'common.with',
  },
  controls: {
    createdAt: 'controls.createdAt',
    createdBy: 'controls.createdBy',
    orderHistory: 'controls.orderHistory',
    updatedAt: 'controls.updatedAt',
    updatedBy: 'controls.updatedBy',
  },
  fields: {
    address: 'fields.address',
    addressProof: 'fields.addressProof',
    amount: 'fields.amount',
    area: 'fields.area',
    bankingProof: 'fields.bankingProof',
    categoryCode: 'fields.categoryCode',
    categorySku: 'fields.categorySku',
    city: 'fields.city',
    code: 'fields.code',
    comment: 'fields.comment',
    complement: 'fields.complement',
    companyContract: 'fields.companyContract',
    cost: 'fields.cost',
    country: 'fields.country',
    description: 'fields.description',
    discount: 'fields.discount',
    districtCode: 'fields.districtCode',
    districtId: 'fields.districtId',
    drinks: 'fields.drinks',
    email: 'fields.email',
    entranceFee: 'fields.entranceFee',
    federalId: 'fields.federalId',
    federalIdCard: 'fields.federalIdCard',
    fees: 'fields.fees',
    firstName: 'fields.firstName',
    fiscalRegime: 'fields.fiscalRegime',
    food: 'fields.food',
    from: 'fields.from',
    gender: 'fields.gender',
    lastName: 'fields.lastName',
    legalName: 'fields.legalName',
    manager: 'fields.manager',
    minAmount: 'fields.minAmount',
    name: 'fields.name',
    notVerified: 'fields.notVerified',
    number: 'fields.number',
    openStatus: 'fields.openStatus',
    password: 'fields.password',
    placeholders: {
      description: 'fields.placeholders.description'
    },
    price: 'fields.price',
    productCode: 'fields.productCode',
    productNcm: 'fields.productNcm',
    productSku: 'fields.productSku',
    profileAvatar: 'fields.profileAvatar',
    phone: 'fields.phone',
    quantity: 'fields.quantity',
    salePrice: 'fields.salePrice',
    sale: 'fields.sale',
    sku: 'fields.sku',
    state: 'fields.state',
    stateId: 'fields.stateId',
    status: 'fields.status',
    stock: 'fields.stock',
    stockQuantity: 'fields.stockQuantity',
    stockThreshold: 'fields.stockThreshold',
    street: 'fields.street',
    subtotal: 'fields.subtotal',
    taxes: 'fields.taxes',
    to: 'fields.to',
    total: 'fields.total',
    underVerification: 'fields.underVerification',
    unitPrice: 'fields.unitPrice',
    verified: 'fields.verified',
    venueType: 'fields.venueType',
    weekday: 'fields.weekday',
    zipCode: 'fields.zipCode',
  },
  gender: {
    female: 'gender.female',
    male: 'gender.male',
    unidentified: 'gender.unidentified',
  },
  helper: {
    higherThan: 'helper.higherThan',
    invalid: 'helper.invalid',
    invalidEmail: 'helper.invalidEmail',
    isRequired: 'helper.isRequired',
    lowerThan: 'helper.lowerThan',
    minChar: 'helper.minChar',
    minLower: 'helper.minLower',
    minNumber: 'helper.minNumber',
    minSymbol: 'helper.minSymbol',
    minUpper: 'helper.minUpper',
    noMatch: 'helper.noMatch',
    noName: 'helper.noName',
    noSeqNumber: 'helper.noSeqNumber',
    noSpace: 'helper.noSpace',
    notNegative: 'helpet.notNegative',
  },
  joyride: {
    tour: 'joyride.tour',
    dashboard: {
      openVenuesCard: {
        title: 'joyride.dashboard.openVenuesCard.title',
        content: 'joyride.dashboard.openVenuesCard.content',
      },
      salesOverviewCard: {
        title: 'joyride.salesOverviewCard.title',
        content: 'joyride.salesOverviewCard.content',
      },
    },
    inventoryPage: {
      title: 'joyride.inventoryPage.title',
      content: 'joyride.inventoryPage.content',
    },
    invoicesPage: {
      title: 'joyride.invoicesPage.title',
      content: 'joyride.invoicesPage.content',
    },
    menuPage: {
      title: 'joyride.menuPage.title',
      content: 'joyride.menuPage.content',
    },
    ordersPage: {
      title: 'joyride.ordersPage.title',
      content: 'joyride.ordersPage.content',
    },
    usersPage: {
      title: 'joyride.usersPage.title',
      content: 'joyride.usersPage.content',
    },
    venuesPage: {
      title: 'joyride.venuesPage.title',
      content: 'joyride.venuesPage.content',
    },
  },
  kpi: {
    ranking: 'kpi.ranking',
    totalIncome: 'kpi.totalIncome',
  },
  nav: {
    about: 'nav.about',
    academy: 'nav.academy',
    account: 'nav.account',
    activeOrders: 'nav.activeOrders',
    activeOrdersList: 'nav.activeOrdersList',
    app: 'nav.app',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    banking: 'nav.banking',
    blog: 'nav.blog',
    booking: 'nav.booking',
    brokers: 'nav.brokers',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    canceledOrders: 'nav.canceledOrders',
    canceledOrdersList: 'nav.canceledOrdersList',
    cards: 'nav.cards',
    category: 'nav.category',
    categories: 'nav.categories',
    categoryList: 'nav.categoryList',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    customer: 'nav.customer',
    customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    docs: 'nav.docs',
    ecommerce: 'nav.ecommerce',
    economicGroup: 'nav.economicGroup',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fiscal: 'nav.fiscal',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    general: 'nav.general',
    globalMenu: 'nav.globalMenu',
    globalProduct: 'nav.globalProduct',
    inactiveOrders: 'nav.inactiveOrders',
    inactiveOrdersList: 'nav.inactiveOrdersList',
    inventory: 'nav.inventory',
    inventoryControl: 'nav.inventoryControl',
    inventoryStatement: 'nav.inventoryStatement',
    invoice: 'nav.invoice',
    invoiceDetails: 'nav.invoiceDetails',
    invoices: 'nav.invoices',
    invoiceList: 'nav.invoiceList',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    logout: 'nav.logout',
    mail: 'nav.mail',
    management: 'nav.management',
    menu: 'nav.menu',
    menus: 'nav.menus',
    notifications: 'nav.notifications',
    order: 'nav.order',
    orders: 'nav.orders',
    orderDetails: 'nav.orderDetails',
    orderList: 'nav.orderList',
    overview: 'nav.overview',
    pages: 'nav.pages',
    partners: 'nav.partners',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    privacyPolicy: 'nav.privacyPolicy',
    product: 'nav.product',
    products: 'nav.products',
    productList: 'nav.productList',
    profile: 'nav.profile',
    recoverPassword: 'nav.recover',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    reports: 'nav.reports',
    role: 'nav.role',
    roles: 'nav.roles',
    roleList: 'nav.roleList',
    schedule: 'nav.schedule',
    security: 'nav.security',
    settings: 'nav.settings',
    signUp: 'nav.signUp',
    socialMedia: 'nav.socialMedia',
    statement: 'nav.statement',
    stockEntry: 'nav.stockEntry',
    supplier: 'nav.supplier',
    terms: 'nav.terms',
    venue: 'nav.venue',
    venues: 'nav.venues',
    venueList: 'nav.venueList',
    venueScheduleTable: 'nav.venueScheduleTable',
    verifyCode: 'nav.verifyCode',
    verifyEmail: 'nav.verifyEmail',
    user: 'nav.user',
    users: 'nav.users',
    userList: 'nav.userList',
  },
  pages: {
    app: {
      bannerHeader: 'pages.app.bannerHeader',
      bannerSubtitle: 'pages.app.bannerSubtitle',
      bannerCTA: 'pages.app.bannerCTA',
    },
    globalMenu: {
      dialogSubtitle: 'pages.globalMenu.dialogSubtitle',
    },
    invoice: {
      codeDialogHeader: 'pages.invoice.codeDialogHeader',
      codeDialogText: 'pages.invoice.codeDialogText',
      leftFootnote: 'pages.invoice.leftFootnote',
      reminderDialogAlert: 'pages.invoice.reminderDialogAlert',
      reminderDialogHeader: 'pages.invoice.reminderDialogHeader',
      reminderDialogSubtitle: 'pages.invoice.reminderDialogSubtitle',
      reminderDialogText: 'pages.invoice.reminderDialogText',
      rightFootnote: 'pages.invoice.rightFootnote',
      updateDialogAlert: 'pages.invoice.updateDialogAlert',
      updateDialogAlertStrong: 'pages.invoice.updateDialogAlertStrong',
      updateDialogHeader: 'pages.invoice.updateDialogHeader',
      updateDialogText: 'pages.invoice.updateDialogText',
    },
    login: {
      formHeader: 'pages.login.formHeader',
      formSubtitle: 'pages.login.formSubtitle',
      noAccountText: 'pages.login.noAccountText',
      sideBanner: 'pages.login.sideBanner',
    },
    product: {
      inventoryDescription: 'pages.product.inventoryDescription',
      switchDescription: 'pages.product.switchDescription',
    },
    register: {
      formHeader: 'pages.register.formHeader',
      formSubtitle: 'pages.register.formSubtitle',
      formFooterStart: 'pages.register.formFooterStart',
      formFooterEnd: 'pages.register.formFooterEnd',
      existingAccountText: 'pages.register.existingAccountText',
      sideBanner: 'pages.register.sideBanner',
    },
    signUp: {
      formHeader: 'pages.signUp.formHeader',
      formSubtitle: 'pages.signUp.formSubtitle',
      formFooterStart: 'pages.signUp.formFooterStart',
      formFooterEnd: 'pages.signUp.formFooterEnd',
      existingAccountText: 'pages.signUp.existingAccountText',
      sideBanner: 'pages.signUp.sideBanner',
    },
    resetPwrd: {
      formHeader: 'pages.resetPwrd.formHeader',
      formSubtitle: 'pages.resetPwrd.formSubtitle',
    },
    schedule: {
      formAlert: 'pages.schedule.formAlert',
      formHeader: 'pages.schedule.formHeader',
      formSubtitle: 'pages.schedule.formSubtitle',
      closesAt: 'pages.schedule.closesAt',
      openUntil: 'pages.schedule.openUntil',
      opensAt: 'pages.schedule.opensAt',
    },
    userProfile: {
      general: {
        scheduleSwitch: 'pages.userProfile.general.scheduleSwitch',
        scheduleSwitchTooltip: 'pages.userProfile.general.scheduleSwitchTooltip',
        scheduleSwitchDisabledTooltip: 'pages.userProfile.general.scheduleSwitchDisabledTooltip',
        statusSwitch: 'pages.userProfile.general.statusSwitch',
        statusSwitchDisabledTooltip: 'pages.userProfile.general.statusSwitchDisabledTooltip',
        roleDialogHeader: 'pages.userProfile.general.roleDialogHeader',
        roleDialogSubtitle: 'pages.userProfile.general.roleDialogSubtitle',
      },
      security: {
        altHeader: 'pages.userProfile.security.altHeader',
        header: 'pages.userProfile.security.header',
        listHeader: 'pages.userProfile.security.listHeader',
        listAltHeader: 'pages.userProfile.security.listAltHeader', 
      },
    },
    venues: {
      noData: 'pages.venues.noData',
      noDataTitle: 'pages.venues.noDataTitle',
      bulkEditDialogHeader: 'pages.venues.bulkEditDialogHeader',
      bulkEditDialogSubtitle: 'pages.venues.bulkEditDialogSubtitle',
    },
    venueProfile: {
      docs: {
        header: 'pages.venueProfile.docs.header',
        subtitle: 'pages.venueProfile.docs.subtitle',
      },
      general: {
        header: 'pages.venueProfile.general.header',
        openstatusSwitch: 'pages.venueProfile.general.openstatusSwitch',
        openstatusSwitchTooltip: 'pages.venueProfile.general.openstatusSwitchTooltip',
        statusSwitch: 'pages.venueProfile.general.statusSwitch',
        statusSwitchTooltip: 'pages.venueProfile.general.statusSwitchTooltip',
        statusSwitchAltTooltip: 'pages.venueProfile.general.statusSwitchAltTooltip',
        settingsHeader: 'pages.venueProfile.general.settingsHeader',
        entranceFeeSub: 'pages.venueProfile.general.entranceFeeSub',
        minAmountSub: 'pages.venueProfile.general.minAmountSub',
      },
      fiscal: {
        header: 'pages.venueProfile.fiscal.header',
        addressCheck: 'pages.venueProfile.fiscal.addressCheck',
        addressCheckSub: 'pages.venueProfile.fiscal.addressCheckSub',
      },
      menu: {
        productDialog: {
          subtitle: 'pages.venueProfile.menu.productDialog.subtitle',
        },
      },
      inventory: {
        entryDialog: {
          subtitle: 'pages.venueProfile.inventory.entryDialog.subtitle',
        },
        historyDialog: {
          subtitle: 'pages.venueProfile.inventory.historyDialog.subtitle',
        },
      },
      security: {
        altHeader: 'pages.venueProfile.security.altHeader',
        header: 'pages.venueProfile.security.header',
        listHeader: 'pages.venueProfile.security.listHeader',
        listAltHeader: 'pages.venueProfile.security.listAltHeader', 
      },
    },
    verifyCode: {
      formHeader: 'pages.verifyCode.formHeader',
      formSubtitleStart: 'pages.verifyCode.formSubtitleStart',
      formSubtitleEnd: 'pages.verifyCode.formSubtitleEnd',
      formFooter: 'pages.verifyCode.formFooter',
    },
    verifiedEmail: {
      header: 'pages.verifiedEmail.header',
    },
  },
  popover: {
    tenant: {
      header: 'popover.tenant.header',
      subtitle: 'popover.tenant.subtitle',
      generalTitle: 'popover.tenant.generalTitle',
    }
  },
  roles: {
    admin: 'roles.admin',
    bartender: 'roles.bartender',
    cook: 'roles.cook',
    investor: 'roles.investor',
    manager: 'roles.manager',
  },
  snackbar: {
    codeSent: {
      error: 'snackbar.codeSent.error',
      success: 'snackbar.codeSent.success',
    },
    codeValidation: {
      error: 'snackbar.codeValidation.error',
      success: 'snackbar.codeValidation.success',
    },
    create: {
      error: 'snackbar.create.error',
      success: 'snackbar.create.success',
    },
    delete: {
      error: 'snackbar.delete.error',
      success: 'snackbar.delete.success',
    },
    notification: {
      error: 'snackbar.notification.error',
      success: 'snackbar.notification.success',
    },
    passwordChange: {
      error: 'snackbar.passwordChange.error',
      success: 'snackbar.passwordChange.success',
    },
    resetPassword: {
      error: 'snackbar.resetPassword.error',
      success: 'snackbar.resetPassword.success',
    },
    resetPasswordEmail: {
      error: 'snackbar.resetPasswordEmail.error',
      success: 'snackbar.resetPasswordEmail.success',
    },
    signUp: {
      error: 'snackbar.signUp.error',
      success: 'snackbar.signUp.success',
    },
    update: {
      error: 'snackbar.update.error',
      success: 'snackbar.update.success',
    },
    verifyEmail: {
      error: 'snackbar.verifyEmail.error',
      success: 'snackbar.verifyEmail.success',
    },
  },
  status: {
    label: {
      active: 'status.label.active',
      analyzing: 'status.label.analyzing',
      approved: 'status.label.approved',
      canceled: 'status.label.canceled',
      closed: 'status.label.closed',
      completed: 'status.label.completed',
      connected: 'status.label.connected',
      declined: 'status.label.declined',
      disconnected: 'status.label.disconnected',
      inactive: 'status.label.inactive',
      inStock: 'status.label.inStock',
      lowStock: 'status.label.lowStock',
      onSale: 'status.label.onSale',
      outOfStock: 'status.label.outOfStock',
      open: 'status.label.open',
      pending: 'status.label.pending',
      preparing: 'status.label.preparing',
      ready: 'status.label.ready',
      received: 'status.label.received',
      reconnecting: 'status.label.reconnecting',
      sent: 'status.label.sent',
    },
    step: {
      cancel: 'status.step.cancel',
      complete: 'status.step.complete',
      finishPrep: 'status.step.finishPrep',
      receive: 'status.step.receive',
      startPrep: 'status.step.startPrep',
    },
    stepAction: {
      orderCanceled: 'status.stepAction.orderCanceled',
      orderReceived: 'status.stepAction.orderReceived',
      startedPreparation: 'status.stepAction.startedPreparation',
      finishedPreparation: 'status.stepAction.finishedPreparation',
      delivered: 'status.stepAction.delivered',
    },
  },
  tables: {
    openVenues: 'tables.openVenues',
    noOpenVenues: 'tables.noOpenVenues',
    productRanking: 'tables.productRanking',
  },
  tags: {
    containsAlcohol: 'tags.containsAlcohol',
    nonAlcoholic: 'tags.nonAlcoholic',
    type: 'tags.type',
  },
  venueTypes: {
      bar: 'venueTypes.bar',
      nightClub: 'venueTypes.nightClub',
      restaurant: 'venueTypes.restaurant',
      events: 'venueTypes.events',
  },
  weekdays: {
    monday: 'weekdays.monday',
    tuesday: 'weekdays.tuesday',
    wednesday: 'weekdays.wednesday',
    thursday: 'weekdays.thursday',
    friday: 'weekdays.friday',
    saturday: 'weekdays.saturday',
    sunday: 'weekdays.sunday',
  }
};
