import { m, AnimatePresence } from 'framer-motion';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
// @mui
import { alpha } from '@mui/material/styles';
import { List, IconButton, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';
// type
import { CustomFile, UploadMultiFileProps } from './type';
//
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

export default function DndMultiFilePreview({
  showPreview = false,
  files,
  onRemove,
  onReorder,
}: UploadMultiFileProps) {
  const hasFile = files.length > 0;

  // Handle reordering
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !onReorder) return;

    const reorderedFiles = Array.from(files);
    const [removed] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, removed);

    // Notify parent component of the reordered photos
    onReorder(reorderedFiles);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="photos">
        {(provided) => (
          <List disablePadding sx={{ ...(hasFile && { my: 3 }) }} {...provided.droppableProps} ref={provided.innerRef}>
            <AnimatePresence>
              {files.map((file, index) => {
                const { key, name, size, preview } = getFileData(file, index);

                if (showPreview) {
                  return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {(provided) => (
                        <ListItem
                          key={key}
                          // component={m.div}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          {...varFade().inRight}
                          sx={{
                            p: 0,
                            m: 0.5,
                            width: 80,
                            height: 80,
                            borderRadius: 1.25,
                            overflow: 'hidden',
                            position: 'relative',
                            display: 'inline-flex',
                            border: (theme) => `solid 1px ${theme.palette.divider}`,
                          }}
                        >
                          <Image alt="preview" src={preview} ratio="1/1" />

                          {onRemove && (
                            <IconButton
                              size="small"
                              onClick={() => onRemove(file)}
                              sx={{
                                top: 6,
                                p: '2px',
                                right: 6,
                                position: 'absolute',
                                color: 'common.white',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                '&:hover': {
                                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                },
                              }}
                            >
                              <Iconify icon={'eva:close-fill'} />
                            </IconButton>
                          )}
                        </ListItem>
                      )}
                    </Draggable>
                  );
                }

                return (
                  <ListItem
                    key={key}
                    component={m.div}
                    {...varFade().inRight}
                    sx={{
                      my: 1,
                      px: 2,
                      py: 0.75,
                      borderRadius: 0.75,
                      border: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <Iconify
                      icon={'eva:file-fill'}
                      sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
                    />

                    <ListItemText
                      primary={typeof file === 'string' ? file : name}
                      secondary={typeof file === 'string' ? '' : fData(size || 0)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      secondaryTypographyProps={{ variant: 'caption' }}
                    />

                    {onRemove && (
                      <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                        <Iconify icon={'eva:close-fill'} />
                      </IconButton>
                    )}
                  </ListItem>
                );
              })}
              {provided.placeholder}
            </AnimatePresence>
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
