import forge from 'node-forge';

// Load the AES key and IV from environment variables or configuration
const AES_KEY = forge.util.decode64(process.env.REACT_APP_AES_KEY as string); // Base64-encoded key
const AES_IV = forge.util.decode64(process.env.REACT_APP_AES_IV as string);   // Base64-encoded IV

// Function to encrypt data
export const encryptData = (data: string): string => {
    const cipher = forge.cipher.createCipher('AES-CBC', AES_KEY);
    cipher.start({ iv: AES_IV });
    cipher.update(forge.util.createBuffer(data, 'utf8'));
    cipher.finish();
    return forge.util.encode64(cipher.output.getBytes());
};

// Function to decrypt data asynchronously
export const decryptData = async (encryptedData: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            const decipher = forge.cipher.createDecipher('AES-CBC', AES_KEY);
            decipher.start({ iv: AES_IV });
            decipher.update(forge.util.createBuffer(forge.util.decode64(encryptedData)));
            decipher.finish();
            resolve(decipher.output.toString());
        } catch (err) {
            reject(`Decryption failed: ${err}`);
        }
    });
};