// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
// number format
import { PatternFormat, PatternFormatProps, OnValueChange } from 'react-number-format';
import { patternFormats } from 'src/_mock';
import { FiscalTypes } from 'src/@types/venue';
import { useState } from 'react';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
  type: FiscalTypes | 'phone';
  onValueChange?: OnValueChange;
};

type Props = IProps;

export default function RHFPatternField({ name, label, type, onValueChange, ...other }: Props) {
  const { control } = useFormContext();

  const format = patternFormats.filter((id) => id.code === type)[0].format

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PatternFormat
          {...field}
          fullWidth
          label={label}
          format={format}
          mask='_'
          value={field.value}
          patternChar='#'
          error={!!error}
          helperText={error?.message}
          onValueChange={onValueChange}
          customInput={TextField}
          {...other}
        />
      )}
    />
  );
}
