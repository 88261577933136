import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ProductState, CartItem, Product } from '../../@types/product';
import { collection, query, where, getDocs } from 'firebase/firestore';
//
import { dispatch } from '../store';
import { InventoryItems, InventoryState } from 'src/@types/inventory';
import { Order, OrderState } from 'src/@types/order';

// ----------------------------------------------------------------------

const initialState: InventoryState = {
    isLoading: false,
    error: null,
    inventory: [],
    statement: [],
    isOpenModal: false,
    sortBy: null,
    filters: {
      type: ['All'],
      category: 'All',
      priceRange: [0, 200],
    }
  };

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCT HISTORY
    getHistorySuccess(state, action) {
      state.isLoading = false;
      // state.inventory = action.payload;
      console.log(action.payload)
    },

    // GET SUMMARY
    getSummarySuccess(state, action) {
      state.isLoading = false;
      state.inventory = action.payload;
    },

    // GET STATEMENT
    getStatementSuccess(state, action) {
      state.isLoading = false;
      state.statement = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
    //   state.filters.gender = action.payload.gender;
    //   state.filters.category = action.payload.category;
    //   state.filters.colors = action.payload.colors;
    //   state.filters.priceRange = action.payload.priceRange;
    //   state.filters.rating = action.payload.rating;
    },

     // OPEN MODAL
     openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // // MANAGE INVENTORY
    // increaseQuantity(state, action) {
    //   const productId = action.payload;
    //   const updateCart = state.checkout.cart.map((product) => {
    //     if (product.id === productId) {
    //       return {
    //         ...product,
    //         quantity: product.quantity + 1,
    //       };
    //     }
    //     return product;
    //   });

    //   state.checkout.cart = updateCart;
    // },

    // decreaseQuantity(state, action) {
    //   const productId = action.payload;
    //   const updateCart = state.checkout.cart.map((product) => {
    //     if (product.id === productId) {
    //       return {
    //         ...product,
    //         quantity: product.quantity - 1,
    //       };
    //     }
    //     return product;
    //   });

    //   state.checkout.cart = updateCart;
    // },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
//   increaseQuantity,
//   decreaseQuantity,
  sortByProducts,
  filterProducts,
  openModal,
  closeModal,
} = slice.actions;

// ----------------------------------------------------------------------

export function getInventoryStatement() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { orders: Order } } = await axios.get(`/venue/inventory/statement`);
      dispatch(slice.actions.getStatementSuccess(response.data.orders));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductInventoryHistory(productId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/venue/inventory/product-history/${productId}`);
      dispatch(slice.actions.getHistorySuccess(response.data));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInventorySummary() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { inventory: InventoryItems } } = await axios.get(`/venue/inventory/summary`);
      dispatch(slice.actions.getSummarySuccess(response.data.inventory));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createInventoryEntry(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/inventory/entry', {
        data,
      });
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function bulkCreateInventoryEntry(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/inventory/bulk-entry', {
        data,
      });
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function exportInventoryStatement(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/inventory/statement/export', {
        data,
      },{
        responseType: 'blob'
      });

      // Start downloading the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'exported_data.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(slice.actions.stopLoading());
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
