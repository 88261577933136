import axios from 'axios';
import { PATH_AUTH } from '../routes/paths';
// config
import { HOST_API, API_KEY } from '../config';
// utils
import { decryptData, encryptData } from './encryptData';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'x-api-key': API_KEY
  }
});

axiosInstance.interceptors.request.use(
  async (config) => {

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      if (config.headers) {
        // Set the Authorization header with the access token
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    // Skip encryption for multipart/form-data (typically used for file uploads)
    if (config.headers && config.headers['Content-Type'] === 'multipart/form-data') {
      return config;
    }

    // Encrypt the request data if it's not a file upload
    if (config.data && typeof config.data === 'object') {
      try {

        let sensitiveData

        if (config.data.accessToken || config.data.refreshToken) {
          sensitiveData = {
            ...config.data,
            accessToken: config.data.accessToken, // Unencrypted token
            refreshToken: config.data.refreshToken, // Unencrypted token
          }
        } else {
          sensitiveData = config.data
        }
        
        // Encrypt the request data
        const encryptedData = encryptData(JSON.stringify(sensitiveData));

        // Replace the original data with the encrypted data
        config.data = { encryptedData };
      } catch (error) {
          console.error('Error encrypting data:', error);
          return Promise.reject(error); // Handle or reject the request if encryption fails
      }
    }

    return config;
  },
  async (error) => {
    return error;
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    // console.log('Raw Encrypted Response:', response.data);

    // Skip decryption for Excel Spreadsheet download
    if (response.headers && response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8') {
      return response;

    } else {
      try {
        let sensitiveData

        if (response.data.accessToken || response.data.refreshToken) {
          sensitiveData = {
            ...response.data,
            accessToken: response.data.accessToken, // Unencrypted token
            refreshToken: response.data.refreshToken, // Unencrypted token
          }
        } else {
          sensitiveData = response.data
        }

        // Decrypt the response data
        const decryptedData = await decryptData(response.data);

        // console.log('Decrypted Data:', decryptedData);
        
        // Replace the original data with the decrypted data
        response.data = JSON.parse(decryptedData);
      } catch (error) {
          console.error('Error decrypting data:', error);
          return Promise.reject(error); // Handle or reject the request if decryption fails
      }

    }
    
      
    return response;
  },
  async (error) => {
    // console.log('error intercepted', error)
    const originalRequest = error.config;

    try {
      // Decrypt the response data
      const decryptedData = await decryptData(error.response.data);
      
      // Replace the original data with the decrypted data
      error.response.data = JSON.parse(decryptedData);
    } catch (error) {
        console.error('Error decrypting data:', error);
        return Promise.reject(error); // Handle or reject the request if decryption fails
    }

    // If the error is due to an invalid access token
    if (error.response.status === 401 && error.response.data.error === 'Invalid access token') {
      try {
        // console.log('401 intercepted', error)
        // Send a request to the server to refresh the access token
        const response = await axiosInstance.post('/venueusers/refresh', {
          refreshToken: localStorage.getItem('refreshToken'),
        });
        const newAccessToken = response.data.accessToken;
        const newRefreshToken = response.data.refreshToken;

        // Store the new access token and refresh token in local storage
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        // Set the Authorization header with the new access token
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new access token
        return await axiosInstance(originalRequest);
      } catch (error) {
        // If there's an error refreshing the access token, redirect the user to the login page
        alert('Token expired');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = PATH_AUTH.login;
      }

    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
