import { useState } from 'react';
// @mui
import { Typography, Box, Tooltip } from '@mui/material';
// _mock_
import { connectionStatus } from '../../../_mock';
// hooks
import useLocales from '../../../hooks/useLocales';
import { useWebSocket } from '../../../hooks/useSocket';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonRippleAnimate } from '../../../components/animate';
// translations
import { tokens } from '../../../locales/tokens';
// types
import { TSocketStatus } from '../../../contexts/SocketContext';

// ----------------------------------------------------------------------

export default function ConnectionIndicator() {

  const webSocket = useWebSocket();

  const { t } = useLocales()

  const handleReconnect = () => {
    if (webSocket.connection === 'disconnected') {
      window.location.reload()
    }
  };

  const status = connectionStatus.filter((status) => status.label === webSocket.connection)[0]

  return (
    <Box display='flex' flexDirection='row' alignItems='center' sx={{ pr: 4 }}>
      <Tooltip title={t(`buttons.${status.action}`)}>
        <IconButtonRippleAnimate
          onClick={handleReconnect}
          rippleColor={status.ripple}
          sx={[{ pr: 1, color: `${status.color}.main` }, status.label !== 'disconnected' && { pointerEvents: 'none' }]}
        > 
          <Iconify icon={status.icon} />
        </IconButtonRippleAnimate>
      </Tooltip>

      <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
        {t(`status.label.${status.label}`)}
      </Typography>
    </Box>
  );
}
