import numeral from 'numeral';
import 'numeral/locales/pt-br';

// ----------------------------------------------------------------------

numeral.locale('pt-br')

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$ 0,0' : '$ 0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fDecimalsNumber(number: string | number) {
  return numeral(number).format('0,0.00');
}

export function fDigitsNumber(number: string | number) {
  return numeral(number).format('0000');
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
