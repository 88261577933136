import { m } from 'framer-motion';
import { forwardRef, ReactNode, useState } from 'react';
// @mui
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { TouchRippleProps } from '@mui/material/ButtonBase/TouchRipple';

// ----------------------------------------------------------------------

interface IconButtonAnimateProps extends IconButtonProps {
  rippleColor?: string;
}

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonAnimateProps>(
  ({ children, size = 'medium', rippleColor, ...other }, ref) => {

    const touchRippleProps: TouchRippleProps = {
      center: true, // Center the ripple effect
      classes: {
          rippleVisible: 'customRippleVisible', // Custom class for visible ripple
          ripplePulsate: 'customRipplePulsate', // Custom class for pulsating ripple
      },
    };

    // CSS styles for the ripple effect
    const rippleStyles: React.CSSProperties = {
      backgroundColor: rippleColor, // Custom ripple color
      opacity: 0.3, // Custom ripple opacity
      borderRadius: '50%',
      width: '52px', // Adjust the size of the ripple
      height: '52px', // Adjust the size of the ripple
      transform: 'scale(0)', // Start with small size
      animation: 'drip 2s ease-out infinite', // CSS animation for drip effect (infinite loop)
      position: 'absolute', // Position the ripple behind the IconButton
      zIndex: -1, // Set z-index to make the ripple appear behind the IconButton
    };

    // CSS styles for the second ripple effect
    const secondRippleStyles: React.CSSProperties = {
      ...rippleStyles, // Copy styles from the first ripple
      animationDelay: '0.5s', // Start the animation after 0.5 second
    };
    
    return (
    <AnimateWrap size={size}>
      <IconButton
        {...other}
        ref={ref}
        size={size}
        TouchRippleProps={touchRippleProps}
      >
        {children}
      </IconButton>
      <span style={rippleStyles} className="ripple-effect"></span>
      <span style={secondRippleStyles} className="ripple-effect"></span>
      <style>{`
          @keyframes drip {
            0% {
              transform: scale(0);
              opacity: 0.5;
            }
            100% {
              transform: scale(1);
              opacity: 0;
            }
          }
        `}</style>
    </AnimateWrap>
  )}
);

export default IconButtonAnimate;

// ----------------------------------------------------------------------

type AnimateWrapProp = {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
};

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

function AnimateWrap({ size, children }: AnimateWrapProp) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      // whileTap="tap"
      // whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex',
        position: 'relative', // Set position relative for containing the ripple
        alignItems: 'center', // Center the ripple vertically
        justifyContent: 'center', // Center the ripple horizontally
      }}
    >
      {children}
    </Box>
  );
}