// form
import { useFormContext, Controller, ControllerRenderProps } from 'react-hook-form';
// @mui
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type IOptions = {
  id: string;
  label: string;
  category: string;
}

type IProps = {
  name: string;
  options: IOptions[];
  disabledOptions?: IOptions[];
};

type Props = IProps & TextFieldProps;

export default function RHFAutocomplete({ name, options, disabledOptions, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          fullWidth
          autoHighlight
          options={options}
          groupBy={(option) => option.category}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionDisabled={(option) => disabledOptions ? disabledOptions.some((selectedOptions) => selectedOptions === option.id) : false}
          renderInput={(params) => (
            <TextField
              {...params}
              value={field.value}
              error={!!error}
              helperText={error?.message}
              {...other}
            />
          )}
          onChange={(_, value) => field.onChange(value ?? null)}
        />
      )}
    />
  );
}
