import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { Order, OrderState } from '../../@types/order';
// import { collection, query, where, getDocs } from 'firebase/firestore';
//
import { dispatch } from '../store';

// import { DB } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

const initialState: OrderState = {
  isLoading: false,
  error: null,
  orders: [],
  order: null,
  openOrders: [],
  isOpenModal: false,
  isOpenCodeModal: false,
  isOpenNotifyModal: false,
  sortBy: null,
  statistics: {
    ordersSummary: {
      label: '',
      value: 0,
      count: 0,
      percent: 0,
      total: 0,
      average: 0,
      data: [],
    },
    publicSummary: {
      label: '',
      value: 0,
      count: 0,
      percent: 0,
      total: 0,
      average: 0,
      data: [],
    },
    orderMonthlyTotals: [],
    publicMonthlyTotals: [],
    totalsByProduct: [],
    totalsByStatus: [],
    totalsByType: [],
    totalsByVenue: [],
    totalsByTypePerWeekday: [],
  },
  filters: {
    client: [],
    category: 'All',
    priceRange: [0, 200],
    rating: '',
    status: [],
  }
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },

     // GET STATISTICS
     getOrdersStatisticsSuccess(state, action) {
      state.statistics = action.payload;
      state.isLoading = false;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.orders = action.payload;
      state.isLoading = false;
    },

    // GET ORDER
    getOrderSuccess(state, action) {
      state.order = action.payload;
      state.isLoading = false;
    },

    // CREATE ORDER
    createOrderSuccess(state, action) {
      state.openOrders = action.payload;
      state.isLoading = false;
    },

    //  SORT & FILTER ORDERS
    sortByOrders(state, action) {
      state.sortBy = action.payload;
    },

    filterOrders(state, action) {
      state.filters.client = action.payload.client;
      state.filters.category = action.payload.category;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // OPEN CODE MODAL
    openCodeModal(state) {
      state.isOpenCodeModal = true;
    },

    // OPEN NOTIFY MODAL
    openNotifyModal(state) {
      state.isOpenNotifyModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.isOpenCodeModal = false;
      state.isOpenNotifyModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByOrders,
  filterOrders,
  openModal,
  openCodeModal,
  openNotifyModal,
  closeModal,
} = slice.actions;

// ----------------------------------------------------------------------

export function createOrder(order: Order) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        // Axios query
        const response = await axios.post('/user/orders',
            order
        );
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.error(error)
      }
    };
  }

  // ----------------------------------------------------------------------

export function getOrders(filters?: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Axios query
      const response: { data: { orders: Order[] } } = await axios.get('/venue/orders', {
        params: filters
      });

      dispatch(slice.actions.getOrdersSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error)
    }
  };
}

// ----------------------------------------------------------------------

export function getOrder(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { order: Order } } = await axios.get(`/venue/order/${id}`, {
        params: { id },
      });
      dispatch(slice.actions.getOrderSuccess(response.data.order));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrder(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/order/edit', {
        data,
      });
      // dispatch(slice.actions.getOrderSuccess(response.data.order));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function notifyClient(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/order/notify', {
        data,
      });
      // dispatch(slice.actions.getOrderSuccess(response.data.order));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function validateOrder(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/order/validate', {
        data,
      });
      // dispatch(slice.actions.getOrderSuccess(response.data.order));
    } catch (error) {
      console.error('validation error', error);
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message)
    }
  };
}

// ----------------------------------------------------------------------

export function bulkUpdateOrders(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/orders/bulk-edit', {
        data,
      });
      // dispatch(slice.actions.getOrdersSuccess(response.data.orders));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

  // ----------------------------------------------------------------------

  export function getOrdersStatistics() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        // Axios query
        const response = await axios.get('/venue/dashboard');
  
        dispatch(slice.actions.getOrdersStatisticsSuccess(response.data));
  
      } catch (error) {
        dispatch(slice.actions.hasError(error));
        console.error(error)
      }
    };
  }