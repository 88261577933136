export const cities = [
    { stateCode: 'AC', label: 'Acrelândia'},
    { stateCode: 'AC', label: 'Assis Brasil'},
    { stateCode: 'AC', label: 'Brasiléia'},
    { stateCode: 'AC', label: 'Bujari'},
    { stateCode: 'AC', label: 'Capixaba'},
    { stateCode: 'AC', label: 'Cruzeiro do Sul'},
    { stateCode: 'AC', label: 'Epitaciolândia'},
    { stateCode: 'AC', label: 'Feijó'},
    { stateCode: 'AC', label: 'Jordão'},
    { stateCode: 'AC', label: 'Mâncio Lima'},
    { stateCode: 'AC', label: 'Manoel Urbano'},
    { stateCode: 'AC', label: 'Marechal Thaumaturgo'},
    { stateCode: 'AC', label: 'Plácido de Castro'},
    { stateCode: 'AC', label: 'Porto Acre'},
    { stateCode: 'AC', label: 'Porto Walter'},
    { stateCode: 'AC', label: 'Rio Branco'},
    { stateCode: 'AC', label: 'Rodrigues Alves'},
    { stateCode: 'AC', label: 'Santa Rosa do Purus'},
    { stateCode: 'AC', label: 'Sena Madureira'},
    { stateCode: 'AC', label: 'Senador Guiomard'},
    { stateCode: 'AC', label: 'Tarauacá'},
    { stateCode: 'AC', label: 'Xapuri'},
    { stateCode: 'AL', label: 'Água Branca'},
    { stateCode: 'AL', label: 'Anadia'},
    { stateCode: 'AL', label: 'Arapiraca'},
    { stateCode: 'AL', label: 'Atalaia'},
    { stateCode: 'AL', label: 'Barra de Santo Antônio'},
    { stateCode: 'AL', label: 'Barra de São Miguel'},
    { stateCode: 'AL', label: 'Batalha'},
    { stateCode: 'AL', label: 'Belém'},
    { stateCode: 'AL', label: 'Belo Monte'},
    { stateCode: 'AL', label: 'Boca da Mata'},
    { stateCode: 'AL', label: 'Branquinha'},
    { stateCode: 'AL', label: 'Cacimbinhas'},
    { stateCode: 'AL', label: 'Cajueiro'},
    { stateCode: 'AL', label: 'Campestre'},
    { stateCode: 'AL', label: 'Campo Alegre'},
    { stateCode: 'AL', label: 'Campo Grande'},
    { stateCode: 'AL', label: 'Canapi'},
    { stateCode: 'AL', label: 'Capela'},
    { stateCode: 'AL', label: 'Carneiros'},
    { stateCode: 'AL', label: 'Chã Preta'},
    { stateCode: 'AL', label: 'Coité do Nóia'},
    { stateCode: 'AL', label: 'Colônia Leopoldina'},
    { stateCode: 'AL', label: 'Coqueiro Seco'},
    { stateCode: 'AL', label: 'Coruripe'},
    { stateCode: 'AL', label: 'Craíbas'},
    { stateCode: 'AL', label: 'Delmiro Gouveia'},
    { stateCode: 'AL', label: 'Dois Riachos'},
    { stateCode: 'AL', label: 'Estrela de Alagoas'},
    { stateCode: 'AL', label: 'Feira Grande'},
    { stateCode: 'AL', label: 'Feliz Deserto'},
    { stateCode: 'AL', label: 'Flexeiras'},
    { stateCode: 'AL', label: 'Girau do Ponciano'},
    { stateCode: 'AL', label: 'Ibateguara'},
    { stateCode: 'AL', label: 'Igaci'},
    { stateCode: 'AL', label: 'Igreja Nova'},
    { stateCode: 'AL', label: 'Inhapi'},
    { stateCode: 'AL', label: 'Jacaré Dos Homens'},
    { stateCode: 'AL', label: 'Jacuípe'},
    { stateCode: 'AL', label: 'Japaratinga'},
    { stateCode: 'AL', label: 'Jaramataia'},
    { stateCode: 'AL', label: 'Jequiá da Praia'},
    { stateCode: 'AL', label: 'Joaquim Gomes'},
    { stateCode: 'AL', label: 'Jundiá'},
    { stateCode: 'AL', label: 'Junqueiro'},
    { stateCode: 'AL', label: 'Lagoa da Canoa'},
    { stateCode: 'AL', label: 'Limoeiro de Anadia'},
    { stateCode: 'AL', label: 'Maceió'},
    { stateCode: 'AL', label: 'Major Isidoro'},
    { stateCode: 'AL', label: 'Mar Vermelho'},
    { stateCode: 'AL', label: 'Maragogi'},
    { stateCode: 'AL', label: 'Maravilha'},
    { stateCode: 'AL', label: 'Marechal Deodoro'},
    { stateCode: 'AL', label: 'Maribondo'},
    { stateCode: 'AL', label: 'Mata Grande'},
    { stateCode: 'AL', label: 'Matriz de Camaragibe'},
    { stateCode: 'AL', label: 'Messias'},
    { stateCode: 'AL', label: 'Minador do Negrão'},
    { stateCode: 'AL', label: 'Monteirópolis'},
    { stateCode: 'AL', label: 'Murici'},
    { stateCode: 'AL', label: 'Novo Lino'},
    { stateCode: 'AL', label: 'Olho D´água Das Flores'},
    { stateCode: 'AL', label: 'Olho D´água do Casado'},
    { stateCode: 'AL', label: 'Olho D´água Grande'},
    { stateCode: 'AL', label: 'Olivença'},
    { stateCode: 'AL', label: 'Ouro Branco'},
    { stateCode: 'AL', label: 'Palestina'},
    { stateCode: 'AL', label: 'Palmeira Dos Índios'},
    { stateCode: 'AL', label: 'Pão de Açúcar'},
    { stateCode: 'AL', label: 'Pariconha'},
    { stateCode: 'AL', label: 'Paripueira'},
    { stateCode: 'AL', label: 'Passo de Camaragibe'},
    { stateCode: 'AL', label: 'Paulo Jacinto'},
    { stateCode: 'AL', label: 'Penedo'},
    { stateCode: 'AL', label: 'Piaçabuçu'},
    { stateCode: 'AL', label: 'Pilar'},
    { stateCode: 'AL', label: 'Pindoba'},
    { stateCode: 'AL', label: 'Piranhas'},
    { stateCode: 'AL', label: 'Poço Das Trincheiras'},
    { stateCode: 'AL', label: 'Porto Calvo'},
    { stateCode: 'AL', label: 'Porto de Pedras'},
    { stateCode: 'AL', label: 'Porto Real do Colégio'},
    { stateCode: 'AL', label: 'Quebrangulo'},
    { stateCode: 'AL', label: 'Rio Largo'},
    { stateCode: 'AL', label: 'Roteiro'},
    { stateCode: 'AL', label: 'Santa Luzia do Norte'},
    { stateCode: 'AL', label: 'Santana do Ipanema'},
    { stateCode: 'AL', label: 'Santana do Mundaú'},
    { stateCode: 'AL', label: 'São Brás'},
    { stateCode: 'AL', label: 'São José da Laje'},
    { stateCode: 'AL', label: 'São José da Tapera'},
    { stateCode: 'AL', label: 'São Luís do Quitunde'},
    { stateCode: 'AL', label: 'São Miguel Dos Campos'},
    { stateCode: 'AL', label: 'São Miguel Dos Milagres'},
    { stateCode: 'AL', label: 'São Sebastião'},
    { stateCode: 'AL', label: 'Satuba'},
    { stateCode: 'AL', label: 'Senador Rui Palmeira'},
    { stateCode: 'AL', label: 'Tanque D´arca'},
    { stateCode: 'AL', label: 'Taquarana'},
    { stateCode: 'AL', label: 'Teotônio Vilela'},
    { stateCode: 'AL', label: 'Traipu'},
    { stateCode: 'AL', label: 'União Dos Palmares'},
    { stateCode: 'AL', label: 'Viçosa'},
    { stateCode: 'AM', label: 'Alvarães'},
    { stateCode: 'AM', label: 'Amaturá'},
    { stateCode: 'AM', label: 'Anamã'},
    { stateCode: 'AM', label: 'Anori'},
    { stateCode: 'AM', label: 'Apuí'},
    { stateCode: 'AM', label: 'Atalaia do Norte'},
    { stateCode: 'AM', label: 'Autazes'},
    { stateCode: 'AM', label: 'Barcelos'},
    { stateCode: 'AM', label: 'Barreirinha'},
    { stateCode: 'AM', label: 'Benjamin Constant'},
    { stateCode: 'AM', label: 'Beruri'},
    { stateCode: 'AM', label: 'Boa Vista do Ramos'},
    { stateCode: 'AM', label: 'Boca do Acre'},
    { stateCode: 'AM', label: 'Borba'},
    { stateCode: 'AM', label: 'Caapiranga'},
    { stateCode: 'AM', label: 'Canutama'},
    { stateCode: 'AM', label: 'Carauari'},
    { stateCode: 'AM', label: 'Careiro'},
    { stateCode: 'AM', label: 'Careiro da Várzea'},
    { stateCode: 'AM', label: 'Coari'},
    { stateCode: 'AM', label: 'Codajás'},
    { stateCode: 'AM', label: 'Eirunepé'},
    { stateCode: 'AM', label: 'Envira'},
    { stateCode: 'AM', label: 'Fonte Boa'},
    { stateCode: 'AM', label: 'Guajará'},
    { stateCode: 'AM', label: 'Humaitá'},
    { stateCode: 'AM', label: 'Ipixuna'},
    { stateCode: 'AM', label: 'Iranduba'},
    { stateCode: 'AM', label: 'Itacoatiara'},
    { stateCode: 'AM', label: 'Itamarati'},
    { stateCode: 'AM', label: 'Itapiranga'},
    { stateCode: 'AM', label: 'Japurá'},
    { stateCode: 'AM', label: 'Juruá'},
    { stateCode: 'AM', label: 'Jutaí'},
    { stateCode: 'AM', label: 'Lábrea'},
    { stateCode: 'AM', label: 'Manacapuru'},
    { stateCode: 'AM', label: 'Manaquiri'},
    { stateCode: 'AM', label: 'Manaus'},
    { stateCode: 'AM', label: 'Manicoré'},
    { stateCode: 'AM', label: 'Maraã'},
    { stateCode: 'AM', label: 'Maués'},
    { stateCode: 'AM', label: 'Nhamundá'},
    { stateCode: 'AM', label: 'Nova Olinda do Norte'},
    { stateCode: 'AM', label: 'Novo Airão'},
    { stateCode: 'AM', label: 'Novo Aripuanã'},
    { stateCode: 'AM', label: 'Parintins'},
    { stateCode: 'AM', label: 'Pauini'},
    { stateCode: 'AM', label: 'Presidente Figueiredo'},
    { stateCode: 'AM', label: 'Rio Preto da Eva'},
    { stateCode: 'AM', label: 'Santa Isabel do Rio Negro'},
    { stateCode: 'AM', label: 'Santo Antônio do Içá'},
    { stateCode: 'AM', label: 'São Gabriel da Cachoeira'},
    { stateCode: 'AM', label: 'São Paulo de Olivença'},
    { stateCode: 'AM', label: 'São Sebastião do Uatumã'},
    { stateCode: 'AM', label: 'Silves'},
    { stateCode: 'AM', label: 'Tabatinga'},
    { stateCode: 'AM', label: 'Tapauá'},
    { stateCode: 'AM', label: 'Tefé'},
    { stateCode: 'AM', label: 'Tonantins'},
    { stateCode: 'AM', label: 'Uarini'},
    { stateCode: 'AM', label: 'Urucará'},
    { stateCode: 'AM', label: 'Urucurituba'},
    { stateCode: 'AP', label: 'Amapá'},
    { stateCode: 'AP', label: 'Calçoene'},
    { stateCode: 'AP', label: 'Cutias'},
    { stateCode: 'AP', label: 'Ferreira Gomes'},
    { stateCode: 'AP', label: 'Itaubal'},
    { stateCode: 'AP', label: 'Laranjal do Jari'},
    { stateCode: 'AP', label: 'Macapá'},
    { stateCode: 'AP', label: 'Mazagão'},
    { stateCode: 'AP', label: 'Oiapoque'},
    { stateCode: 'AP', label: 'Pedra Branca do Amapari'},
    { stateCode: 'AP', label: 'Porto Grande'},
    { stateCode: 'AP', label: 'Pracuúba'},
    { stateCode: 'AP', label: 'Santana'},
    { stateCode: 'AP', label: 'Serra do Navio'},
    { stateCode: 'AP', label: 'Tartarugalzinho'},
    { stateCode: 'AP', label: 'Vitória do Jari'},
    { stateCode: 'BA', label: 'Abaíra'},
    { stateCode: 'BA', label: 'Abaré'},
    { stateCode: 'BA', label: 'Acajutiba'},
    { stateCode: 'BA', label: 'Adustina'},
    { stateCode: 'BA', label: 'Água Fria'},
    { stateCode: 'BA', label: 'Aiquara'},
    { stateCode: 'BA', label: 'Alagoinhas'},
    { stateCode: 'BA', label: 'Alcobaça'},
    { stateCode: 'BA', label: 'Almadina'},
    { stateCode: 'BA', label: 'Amargosa'},
    { stateCode: 'BA', label: 'Amélia Rodrigues'},
    { stateCode: 'BA', label: 'América Dourada'},
    { stateCode: 'BA', label: 'Anagé'},
    { stateCode: 'BA', label: 'Andaraí'},
    { stateCode: 'BA', label: 'Andorinha'},
    { stateCode: 'BA', label: 'Angical'},
    { stateCode: 'BA', label: 'Anguera'},
    { stateCode: 'BA', label: 'Antas'},
    { stateCode: 'BA', label: 'Antônio Cardoso'},
    { stateCode: 'BA', label: 'Antônio Gonçalves'},
    { stateCode: 'BA', label: 'Aporá'},
    { stateCode: 'BA', label: 'Apuarema'},
    { stateCode: 'BA', label: 'Araças'},
    { stateCode: 'BA', label: 'Aracatu'},
    { stateCode: 'BA', label: 'Araci'},
    { stateCode: 'BA', label: 'Aramari'},
    { stateCode: 'BA', label: 'Arataca'},
    { stateCode: 'BA', label: 'Aratuípe'},
    { stateCode: 'BA', label: 'Aurelino Leal'},
    { stateCode: 'BA', label: 'Baianópolis'},
    { stateCode: 'BA', label: 'Baixa Grande'},
    { stateCode: 'BA', label: 'Banzaê'},
    { stateCode: 'BA', label: 'Barra'},
    { stateCode: 'BA', label: 'Barra da Estiva'},
    { stateCode: 'BA', label: 'Barra do Choça'},
    { stateCode: 'BA', label: 'Barra do Mendes'},
    { stateCode: 'BA', label: 'Barra do Rocha'},
    { stateCode: 'BA', label: 'Barreiras'},
    { stateCode: 'BA', label: 'Barro Alto'},
    { stateCode: 'BA', label: 'Barrocas'},
    { stateCode: 'BA', label: 'Belmonte'},
    { stateCode: 'BA', label: 'Belo Campo'},
    { stateCode: 'BA', label: 'Biritinga'},
    { stateCode: 'BA', label: 'Boa Nova'},
    { stateCode: 'BA', label: 'Boa Vista do Tupim'},
    { stateCode: 'BA', label: 'Bom Jesus da Lapa'},
    { stateCode: 'BA', label: 'Bom Jesus da Serra'},
    { stateCode: 'BA', label: 'Boninal'},
    { stateCode: 'BA', label: 'Bonito'},
    { stateCode: 'BA', label: 'Boquira'},
    { stateCode: 'BA', label: 'Botuporã'},
    { stateCode: 'BA', label: 'Brejões'},
    { stateCode: 'BA', label: 'Brejolândia'},
    { stateCode: 'BA', label: 'Brotas de Macaúbas'},
    { stateCode: 'BA', label: 'Brumado'},
    { stateCode: 'BA', label: 'Buerarema'},
    { stateCode: 'BA', label: 'Buritirama'},
    { stateCode: 'BA', label: 'Caatiba'},
    { stateCode: 'BA', label: 'Cabaceiras do Paraguaçu'},
    { stateCode: 'BA', label: 'Cachoeira'},
    { stateCode: 'BA', label: 'Caculé'},
    { stateCode: 'BA', label: 'Caém'},
    { stateCode: 'BA', label: 'Caetanos'},
    { stateCode: 'BA', label: 'Caetité'},
    { stateCode: 'BA', label: 'Cafarnaum'},
    { stateCode: 'BA', label: 'Cairu'},
    { stateCode: 'BA', label: 'Caldeirão Grande'},
    { stateCode: 'BA', label: 'Camacan'},
    { stateCode: 'BA', label: 'Camaçari'},
    { stateCode: 'BA', label: 'Camamu'},
    { stateCode: 'BA', label: 'Campo Alegre de Lourdes'},
    { stateCode: 'BA', label: 'Campo Formoso'},
    { stateCode: 'BA', label: 'Canápolis'},
    { stateCode: 'BA', label: 'Canarana'},
    { stateCode: 'BA', label: 'Canavieiras'},
    { stateCode: 'BA', label: 'Candeal'},
    { stateCode: 'BA', label: 'Candeias'},
    { stateCode: 'BA', label: 'Candiba'},
    { stateCode: 'BA', label: 'Cândido Sales'},
    { stateCode: 'BA', label: 'Cansanção'},
    { stateCode: 'BA', label: 'Canudos'},
    { stateCode: 'BA', label: 'Capela do Alto Alegre'},
    { stateCode: 'BA', label: 'Capim Grosso'},
    { stateCode: 'BA', label: 'Caraíbas'},
    { stateCode: 'BA', label: 'Caravelas'},
    { stateCode: 'BA', label: 'Cardeal da Silva'},
    { stateCode: 'BA', label: 'Carinhanha'},
    { stateCode: 'BA', label: 'Casa Nova'},
    { stateCode: 'BA', label: 'Castro Alves'},
    { stateCode: 'BA', label: 'Catolândia'},
    { stateCode: 'BA', label: 'Catu'},
    { stateCode: 'BA', label: 'Caturama'},
    { stateCode: 'BA', label: 'Central'},
    { stateCode: 'BA', label: 'Chorrochó'},
    { stateCode: 'BA', label: 'Cícero Dantas'},
    { stateCode: 'BA', label: 'Cipó'},
    { stateCode: 'BA', label: 'Coaraci'},
    { stateCode: 'BA', label: 'Cocos'},
    { stateCode: 'BA', label: 'Conceição da Feira'},
    { stateCode: 'BA', label: 'Conceição do Almeida'},
    { stateCode: 'BA', label: 'Conceição do Coité'},
    { stateCode: 'BA', label: 'Conceição do Jacuípe'},
    { stateCode: 'BA', label: 'Conde'},
    { stateCode: 'BA', label: 'Condeúba'},
    { stateCode: 'BA', label: 'Contendas do Sincorá'},
    { stateCode: 'BA', label: 'Coração de Maria'},
    { stateCode: 'BA', label: 'Cordeiros'},
    { stateCode: 'BA', label: 'Coribe'},
    { stateCode: 'BA', label: 'Coronel João sá'},
    { stateCode: 'BA', label: 'Correntina'},
    { stateCode: 'BA', label: 'Cotegipe'},
    { stateCode: 'BA', label: 'Cravolândia'},
    { stateCode: 'BA', label: 'Crisópolis'},
    { stateCode: 'BA', label: 'Cristópolis'},
    { stateCode: 'BA', label: 'Cruz Das Almas'},
    { stateCode: 'BA', label: 'Curaçá'},
    { stateCode: 'BA', label: 'Dário Meira'},
    { stateCode: 'BA', label: 'Dias D´ávila'},
    { stateCode: 'BA', label: 'Dom Basílio'},
    { stateCode: 'BA', label: 'Dom Macedo Costa'},
    { stateCode: 'BA', label: 'Elísio Medrado'},
    { stateCode: 'BA', label: 'Encruzilhada'},
    { stateCode: 'BA', label: 'Entre Rios'},
    { stateCode: 'BA', label: 'Érico Cardoso'},
    { stateCode: 'BA', label: 'Esplanada'},
    { stateCode: 'BA', label: 'Euclides da Cunha'},
    { stateCode: 'BA', label: 'Eunápolis'},
    { stateCode: 'BA', label: 'Fátima'},
    { stateCode: 'BA', label: 'Feira da Mata'},
    { stateCode: 'BA', label: 'Feira de Santana'},
    { stateCode: 'BA', label: 'Filadélfia'},
    { stateCode: 'BA', label: 'Firmino Alves'},
    { stateCode: 'BA', label: 'Floresta Azul'},
    { stateCode: 'BA', label: 'Formosa do Rio Preto'},
    { stateCode: 'BA', label: 'Gandu'},
    { stateCode: 'BA', label: 'Gavião'},
    { stateCode: 'BA', label: 'Gentio do Ouro'},
    { stateCode: 'BA', label: 'Glória'},
    { stateCode: 'BA', label: 'Gongogi'},
    { stateCode: 'BA', label: 'Governador Lomanto Júnior'},
    { stateCode: 'BA', label: 'Governador Mangabeira'},
    { stateCode: 'BA', label: 'Guajeru'},
    { stateCode: 'BA', label: 'Guanambi'},
    { stateCode: 'BA', label: 'Guaratinga'},
    { stateCode: 'BA', label: 'Heliópolis'},
    { stateCode: 'BA', label: 'Iaçu'},
    { stateCode: 'BA', label: 'Ibiassucê'},
    { stateCode: 'BA', label: 'Ibicaraí'},
    { stateCode: 'BA', label: 'Ibicoara'},
    { stateCode: 'BA', label: 'Ibicuí'},
    { stateCode: 'BA', label: 'Ibipeba'},
    { stateCode: 'BA', label: 'Ibipitanga'},
    { stateCode: 'BA', label: 'Ibiquera'},
    { stateCode: 'BA', label: 'Ibirapitanga'},
    { stateCode: 'BA', label: 'Ibirapuã'},
    { stateCode: 'BA', label: 'Ibirataia'},
    { stateCode: 'BA', label: 'Ibitiara'},
    { stateCode: 'BA', label: 'Ibititá'},
    { stateCode: 'BA', label: 'Ibotirama'},
    { stateCode: 'BA', label: 'Ichu'},
    { stateCode: 'BA', label: 'Igaporã'},
    { stateCode: 'BA', label: 'Igrapiúna'},
    { stateCode: 'BA', label: 'Iguaí'},
    { stateCode: 'BA', label: 'Ilhéus'},
    { stateCode: 'BA', label: 'Inhambupe'},
    { stateCode: 'BA', label: 'Ipecaetá'},
    { stateCode: 'BA', label: 'Ipiaú'},
    { stateCode: 'BA', label: 'Ipirá'},
    { stateCode: 'BA', label: 'Ipupiara'},
    { stateCode: 'BA', label: 'Irajuba'},
    { stateCode: 'BA', label: 'Iramaia'},
    { stateCode: 'BA', label: 'Iraquara'},
    { stateCode: 'BA', label: 'Irará'},
    { stateCode: 'BA', label: 'Irecê'},
    { stateCode: 'BA', label: 'Itabela'},
    { stateCode: 'BA', label: 'Itaberaba'},
    { stateCode: 'BA', label: 'Itabuna'},
    { stateCode: 'BA', label: 'Itacaré'},
    { stateCode: 'BA', label: 'Itaeté'},
    { stateCode: 'BA', label: 'Itagi'},
    { stateCode: 'BA', label: 'Itagibá'},
    { stateCode: 'BA', label: 'Itagimirim'},
    { stateCode: 'BA', label: 'Itaguaçu da Bahia'},
    { stateCode: 'BA', label: 'Itaju do Colônia'},
    { stateCode: 'BA', label: 'Itajuípe'},
    { stateCode: 'BA', label: 'Itamaraju'},
    { stateCode: 'BA', label: 'Itamari'},
    { stateCode: 'BA', label: 'Itambé'},
    { stateCode: 'BA', label: 'Itanagra'},
    { stateCode: 'BA', label: 'Itanhém'},
    { stateCode: 'BA', label: 'Itaparica'},
    { stateCode: 'BA', label: 'Itapé'},
    { stateCode: 'BA', label: 'Itapebi'},
    { stateCode: 'BA', label: 'Itapetinga'},
    { stateCode: 'BA', label: 'Itapicuru'},
    { stateCode: 'BA', label: 'Itapitanga'},
    { stateCode: 'BA', label: 'Itaquara'},
    { stateCode: 'BA', label: 'Itarantim'},
    { stateCode: 'BA', label: 'Itatim'},
    { stateCode: 'BA', label: 'Itiruçu'},
    { stateCode: 'BA', label: 'Itiúba'},
    { stateCode: 'BA', label: 'Itororó'},
    { stateCode: 'BA', label: 'Ituaçu'},
    { stateCode: 'BA', label: 'Ituberá'},
    { stateCode: 'BA', label: 'Iuiú'},
    { stateCode: 'BA', label: 'Jaborandi'},
    { stateCode: 'BA', label: 'Jacaraci'},
    { stateCode: 'BA', label: 'Jacobina'},
    { stateCode: 'BA', label: 'Jaguaquara'},
    { stateCode: 'BA', label: 'Jaguarari'},
    { stateCode: 'BA', label: 'Jaguaripe'},
    { stateCode: 'BA', label: 'Jandaíra'},
    { stateCode: 'BA', label: 'Jequié'},
    { stateCode: 'BA', label: 'Jeremoabo'},
    { stateCode: 'BA', label: 'Jiquiriçá'},
    { stateCode: 'BA', label: 'Jitaúna'},
    { stateCode: 'BA', label: 'João Dourado'},
    { stateCode: 'BA', label: 'Juazeiro'},
    { stateCode: 'BA', label: 'Jucuruçu'},
    { stateCode: 'BA', label: 'Jussara'},
    { stateCode: 'BA', label: 'Jussari'},
    { stateCode: 'BA', label: 'Jussiape'},
    { stateCode: 'BA', label: 'Lafaiete Coutinho'},
    { stateCode: 'BA', label: 'Lagoa Real'},
    { stateCode: 'BA', label: 'Laje'},
    { stateCode: 'BA', label: 'Lajedão'},
    { stateCode: 'BA', label: 'Lajedinho'},
    { stateCode: 'BA', label: 'Lajedo do Tabocal'},
    { stateCode: 'BA', label: 'Lamarão'},
    { stateCode: 'BA', label: 'Lapão'},
    { stateCode: 'BA', label: 'Lauro de Freitas'},
    { stateCode: 'BA', label: 'Lençóis'},
    { stateCode: 'BA', label: 'Licínio de Almeida'},
    { stateCode: 'BA', label: 'Livramento de Nossa Senhora'},
    { stateCode: 'BA', label: 'Luís Eduardo Magalhães'},
    { stateCode: 'BA', label: 'Macajuba'},
    { stateCode: 'BA', label: 'Macarani'},
    { stateCode: 'BA', label: 'Macaúbas'},
    { stateCode: 'BA', label: 'Macururé'},
    { stateCode: 'BA', label: 'Madre de Deus'},
    { stateCode: 'BA', label: 'Maetinga'},
    { stateCode: 'BA', label: 'Maiquinique'},
    { stateCode: 'BA', label: 'Mairi'},
    { stateCode: 'BA', label: 'Malhada'},
    { stateCode: 'BA', label: 'Malhada de Pedras'},
    { stateCode: 'BA', label: 'Manoel Vitorino'},
    { stateCode: 'BA', label: 'Mansidão'},
    { stateCode: 'BA', label: 'Maracás'},
    { stateCode: 'BA', label: 'Maragogipe'},
    { stateCode: 'BA', label: 'Maraú'},
    { stateCode: 'BA', label: 'Marcionílio Souza'},
    { stateCode: 'BA', label: 'Mascote'},
    { stateCode: 'BA', label: 'Mata de São João'},
    { stateCode: 'BA', label: 'Matina'},
    { stateCode: 'BA', label: 'Medeiros Neto'},
    { stateCode: 'BA', label: 'Miguel Calmon'},
    { stateCode: 'BA', label: 'Milagres'},
    { stateCode: 'BA', label: 'Mirangaba'},
    { stateCode: 'BA', label: 'Mirante'},
    { stateCode: 'BA', label: 'Monte Santo'},
    { stateCode: 'BA', label: 'Morpará'},
    { stateCode: 'BA', label: 'Morro do Chapéu'},
    { stateCode: 'BA', label: 'Mortugaba'},
    { stateCode: 'BA', label: 'Mucugê'},
    { stateCode: 'BA', label: 'Mucuri'},
    { stateCode: 'BA', label: 'Mulungu do Morro'},
    { stateCode: 'BA', label: 'Mundo Novo'},
    { stateCode: 'BA', label: 'Muniz Ferreira'},
    { stateCode: 'BA', label: 'Muquém de São Francisco'},
    { stateCode: 'BA', label: 'Muritiba'},
    { stateCode: 'BA', label: 'Mutuípe'},
    { stateCode: 'BA', label: 'Nazaré'},
    { stateCode: 'BA', label: 'Nilo Peçanha'},
    { stateCode: 'BA', label: 'Nordestina'},
    { stateCode: 'BA', label: 'Nova Canaã'},
    { stateCode: 'BA', label: 'Nova Fátima'},
    { stateCode: 'BA', label: 'Nova Ibiá'},
    { stateCode: 'BA', label: 'Nova Itarana'},
    { stateCode: 'BA', label: 'Nova Redenção'},
    { stateCode: 'BA', label: 'Nova Soure'},
    { stateCode: 'BA', label: 'Nova Viçosa'},
    { stateCode: 'BA', label: 'Novo Horizonte'},
    { stateCode: 'BA', label: 'Novo Triunfo'},
    { stateCode: 'BA', label: 'Olindina'},
    { stateCode: 'BA', label: 'Oliveira Dos Brejinhos'},
    { stateCode: 'BA', label: 'Ouriçangas'},
    { stateCode: 'BA', label: 'Ourolândia'},
    { stateCode: 'BA', label: 'Palmas de Monte Alto'},
    { stateCode: 'BA', label: 'Palmeiras'},
    { stateCode: 'BA', label: 'Paramirim'},
    { stateCode: 'BA', label: 'Paratinga'},
    { stateCode: 'BA', label: 'Paripiranga'},
    { stateCode: 'BA', label: 'Pau Brasil'},
    { stateCode: 'BA', label: 'Paulo Afonso'},
    { stateCode: 'BA', label: 'pé de Serra'},
    { stateCode: 'BA', label: 'Pedrão'},
    { stateCode: 'BA', label: 'Pedro Alexandre'},
    { stateCode: 'BA', label: 'Piatã'},
    { stateCode: 'BA', label: 'Pilão Arcado'},
    { stateCode: 'BA', label: 'Pindaí'},
    { stateCode: 'BA', label: 'Pindobaçu'},
    { stateCode: 'BA', label: 'Pintadas'},
    { stateCode: 'BA', label: 'Piraí do Norte'},
    { stateCode: 'BA', label: 'Piripá'},
    { stateCode: 'BA', label: 'Piritiba'},
    { stateCode: 'BA', label: 'Planaltino'},
    { stateCode: 'BA', label: 'Planalto'},
    { stateCode: 'BA', label: 'Poções'},
    { stateCode: 'BA', label: 'Pojuca'},
    { stateCode: 'BA', label: 'Ponto Novo'},
    { stateCode: 'BA', label: 'Porto Seguro'},
    { stateCode: 'BA', label: 'Potiraguá'},
    { stateCode: 'BA', label: 'Prado'},
    { stateCode: 'BA', label: 'Presidente Dutra'},
    { stateCode: 'BA', label: 'Presidente Jânio Quadros'},
    { stateCode: 'BA', label: 'Presidente Tancredo Neves'},
    { stateCode: 'BA', label: 'Queimadas'},
    { stateCode: 'BA', label: 'Quijingue'},
    { stateCode: 'BA', label: 'Quixabeira'},
    { stateCode: 'BA', label: 'Rafael Jambeiro'},
    { stateCode: 'BA', label: 'Remanso'},
    { stateCode: 'BA', label: 'Retirolândia'},
    { stateCode: 'BA', label: 'Riachão Das Neves'},
    { stateCode: 'BA', label: 'Riachão do Jacuípe'},
    { stateCode: 'BA', label: 'Riacho de Santana'},
    { stateCode: 'BA', label: 'Ribeira do Amparo'},
    { stateCode: 'BA', label: 'Ribeira do Pombal'},
    { stateCode: 'BA', label: 'Ribeirão do Largo'},
    { stateCode: 'BA', label: 'Rio de Contas'},
    { stateCode: 'BA', label: 'Rio do Antônio'},
    { stateCode: 'BA', label: 'Rio do Pires'},
    { stateCode: 'BA', label: 'Rio Real'},
    { stateCode: 'BA', label: 'Rodelas'},
    { stateCode: 'BA', label: 'Ruy Barbosa'},
    { stateCode: 'BA', label: 'Salinas da Margarida'},
    { stateCode: 'BA', label: 'Salvador'},
    { stateCode: 'BA', label: 'Santa Bárbara'},
    { stateCode: 'BA', label: 'Santa Brígida'},
    { stateCode: 'BA', label: 'Santa Cruz Cabrália'},
    { stateCode: 'BA', label: 'Santa Cruz da Vitória'},
    { stateCode: 'BA', label: 'Santa Inês'},
    { stateCode: 'BA', label: 'Santa Luzia'},
    { stateCode: 'BA', label: 'Santa Maria da Vitória'},
    { stateCode: 'BA', label: 'Santa Rita de Cássia'},
    { stateCode: 'BA', label: 'Santa Teresinha'},
    { stateCode: 'BA', label: 'Santaluz'},
    { stateCode: 'BA', label: 'Santana'},
    { stateCode: 'BA', label: 'Santanópolis'},
    { stateCode: 'BA', label: 'Santo Amaro'},
    { stateCode: 'BA', label: 'Santo Antônio de Jesus'},
    { stateCode: 'BA', label: 'Santo Estêvão'},
    { stateCode: 'BA', label: 'São Desidério'},
    { stateCode: 'BA', label: 'São Domingos'},
    { stateCode: 'BA', label: 'São Felipe'},
    { stateCode: 'BA', label: 'São Félix'},
    { stateCode: 'BA', label: 'São Félix do Coribe'},
    { stateCode: 'BA', label: 'São Francisco do Conde'},
    { stateCode: 'BA', label: 'São Gabriel'},
    { stateCode: 'BA', label: 'São Gonçalo Dos Campos'},
    { stateCode: 'BA', label: 'São José da Vitória'},
    { stateCode: 'BA', label: 'São José do Jacuípe'},
    { stateCode: 'BA', label: 'São Miguel Das Matas'},
    { stateCode: 'BA', label: 'São Sebastião do Passé'},
    { stateCode: 'BA', label: 'Sapeaçu'},
    { stateCode: 'BA', label: 'Sátiro Dias'},
    { stateCode: 'BA', label: 'Saubara'},
    { stateCode: 'BA', label: 'Saúde'},
    { stateCode: 'BA', label: 'Seabra'},
    { stateCode: 'BA', label: 'Sebastião Laranjeiras'},
    { stateCode: 'BA', label: 'Senhor do Bonfim'},
    { stateCode: 'BA', label: 'Sento sé'},
    { stateCode: 'BA', label: 'Serra do Ramalho'},
    { stateCode: 'BA', label: 'Serra Dourada'},
    { stateCode: 'BA', label: 'Serra Preta'},
    { stateCode: 'BA', label: 'Serrinha'},
    { stateCode: 'BA', label: 'Serrolândia'},
    { stateCode: 'BA', label: 'Simões Filho'},
    { stateCode: 'BA', label: 'Sítio do Mato'},
    { stateCode: 'BA', label: 'Sítio do Quinto'},
    { stateCode: 'BA', label: 'Sobradinho'},
    { stateCode: 'BA', label: 'Souto Soares'},
    { stateCode: 'BA', label: 'Tabocas do Brejo Velho'},
    { stateCode: 'BA', label: 'Tanhaçu'},
    { stateCode: 'BA', label: 'Tanque Novo'},
    { stateCode: 'BA', label: 'Tanquinho'},
    { stateCode: 'BA', label: 'Taperoá'},
    { stateCode: 'BA', label: 'Tapiramutá'},
    { stateCode: 'BA', label: 'Teixeira de Freitas'},
    { stateCode: 'BA', label: 'Teodoro Sampaio'},
    { stateCode: 'BA', label: 'Teofilândia'},
    { stateCode: 'BA', label: 'Teolândia'},
    { stateCode: 'BA', label: 'Terra Nova'},
    { stateCode: 'BA', label: 'Tremedal'},
    { stateCode: 'BA', label: 'Tucano'},
    { stateCode: 'BA', label: 'Uauá'},
    { stateCode: 'BA', label: 'Ubaíra'},
    { stateCode: 'BA', label: 'Ubaitaba'},
    { stateCode: 'BA', label: 'Ubatã'},
    { stateCode: 'BA', label: 'Uibaí'},
    { stateCode: 'BA', label: 'Umburanas'},
    { stateCode: 'BA', label: 'Una'},
    { stateCode: 'BA', label: 'Urandi'},
    { stateCode: 'BA', label: 'Uruçuca'},
    { stateCode: 'BA', label: 'Utinga'},
    { stateCode: 'BA', label: 'Valença'},
    { stateCode: 'BA', label: 'Valente'},
    { stateCode: 'BA', label: 'Várzea da Roça'},
    { stateCode: 'BA', label: 'Várzea do Poço'},
    { stateCode: 'BA', label: 'Várzea Nova'},
    { stateCode: 'BA', label: 'Varzedo'},
    { stateCode: 'BA', label: 'Vera Cruz'},
    { stateCode: 'BA', label: 'Vereda'},
    { stateCode: 'BA', label: 'Vitória da Conquista'},
    { stateCode: 'BA', label: 'Wagner'},
    { stateCode: 'BA', label: 'Wanderley'},
    { stateCode: 'BA', label: 'Wenceslau Guimarães'},
    { stateCode: 'BA', label: 'Xique-xique'},
    { stateCode: 'CE', label: 'Abaiara'},
    { stateCode: 'CE', label: 'Acarapé'},
    { stateCode: 'CE', label: 'Acaraú'},
    { stateCode: 'CE', label: 'Acopiara'},
    { stateCode: 'CE', label: 'Aiuaba'},
    { stateCode: 'CE', label: 'Alcântaras'},
    { stateCode: 'CE', label: 'Altaneira'},
    { stateCode: 'CE', label: 'Alto Santo'},
    { stateCode: 'CE', label: 'Amontada'},
    { stateCode: 'CE', label: 'Antonina do Norte'},
    { stateCode: 'CE', label: 'Apuiarés'},
    { stateCode: 'CE', label: 'Aquiraz'},
    { stateCode: 'CE', label: 'Aracati'},
    { stateCode: 'CE', label: 'Aracoiaba'},
    { stateCode: 'CE', label: 'Ararendá'},
    { stateCode: 'CE', label: 'Araripe'},
    { stateCode: 'CE', label: 'Aratuba'},
    { stateCode: 'CE', label: 'Arneiroz'},
    { stateCode: 'CE', label: 'Assaré'},
    { stateCode: 'CE', label: 'Aurora'},
    { stateCode: 'CE', label: 'Baixio'},
    { stateCode: 'CE', label: 'Banabuiú'},
    { stateCode: 'CE', label: 'Barbalha'},
    { stateCode: 'CE', label: 'Barreira'},
    { stateCode: 'CE', label: 'Barro'},
    { stateCode: 'CE', label: 'Barroquinha'},
    { stateCode: 'CE', label: 'Baturité'},
    { stateCode: 'CE', label: 'Beberibe'},
    { stateCode: 'CE', label: 'Bela Cruz'},
    { stateCode: 'CE', label: 'Boa Viagem'},
    { stateCode: 'CE', label: 'Brejo Santo'},
    { stateCode: 'CE', label: 'Camocim'},
    { stateCode: 'CE', label: 'Campos Sales'},
    { stateCode: 'CE', label: 'Canindé'},
    { stateCode: 'CE', label: 'Capistrano'},
    { stateCode: 'CE', label: 'Caridade'},
    { stateCode: 'CE', label: 'Cariré'},
    { stateCode: 'CE', label: 'Caririaçu'},
    { stateCode: 'CE', label: 'Cariús'},
    { stateCode: 'CE', label: 'Carnaubal'},
    { stateCode: 'CE', label: 'Cascavel'},
    { stateCode: 'CE', label: 'Catarina'},
    { stateCode: 'CE', label: 'Catunda'},
    { stateCode: 'CE', label: 'Caucaia'},
    { stateCode: 'CE', label: 'Cedro'},
    { stateCode: 'CE', label: 'Chaval'},
    { stateCode: 'CE', label: 'Choró'},
    { stateCode: 'CE', label: 'Chorozinho'},
    { stateCode: 'CE', label: 'Coreaú'},
    { stateCode: 'CE', label: 'Crateús'},
    { stateCode: 'CE', label: 'Crato'},
    { stateCode: 'CE', label: 'Croatá'},
    { stateCode: 'CE', label: 'Cruz'},
    { stateCode: 'CE', label: 'Deputado Irapuan Pinheiro'},
    { stateCode: 'CE', label: 'Ererê'},
    { stateCode: 'CE', label: 'Eusébio'},
    { stateCode: 'CE', label: 'Farias Brito'},
    { stateCode: 'CE', label: 'Forquilha'},
    { stateCode: 'CE', label: 'Fortaleza'},
    { stateCode: 'CE', label: 'Fortim'},
    { stateCode: 'CE', label: 'Frecheirinha'},
    { stateCode: 'CE', label: 'General Sampaio'},
    { stateCode: 'CE', label: 'Graça'},
    { stateCode: 'CE', label: 'Granja'},
    { stateCode: 'CE', label: 'Granjeiro'},
    { stateCode: 'CE', label: 'Groaíras'},
    { stateCode: 'CE', label: 'Guaiúba'},
    { stateCode: 'CE', label: 'Guaraciaba do Norte'},
    { stateCode: 'CE', label: 'Guaramiranga'},
    { stateCode: 'CE', label: 'Hidrolândia'},
    { stateCode: 'CE', label: 'Horizonte'},
    { stateCode: 'CE', label: 'Ibaretama'},
    { stateCode: 'CE', label: 'Ibiapina'},
    { stateCode: 'CE', label: 'Ibicuitinga'},
    { stateCode: 'CE', label: 'Icapuí'},
    { stateCode: 'CE', label: 'Icó'},
    { stateCode: 'CE', label: 'Iguatu'},
    { stateCode: 'CE', label: 'Independência'},
    { stateCode: 'CE', label: 'Ipaporanga'},
    { stateCode: 'CE', label: 'Ipaumirim'},
    { stateCode: 'CE', label: 'Ipu'},
    { stateCode: 'CE', label: 'Ipueiras'},
    { stateCode: 'CE', label: 'Iracema'},
    { stateCode: 'CE', label: 'Irauçuba'},
    { stateCode: 'CE', label: 'Itaiçaba'},
    { stateCode: 'CE', label: 'Itaitinga'},
    { stateCode: 'CE', label: 'Itapagé'},
    { stateCode: 'CE', label: 'Itapipoca'},
    { stateCode: 'CE', label: 'Itapiúna'},
    { stateCode: 'CE', label: 'Itarema'},
    { stateCode: 'CE', label: 'Itatira'},
    { stateCode: 'CE', label: 'Jaguaretama'},
    { stateCode: 'CE', label: 'Jaguaribara'},
    { stateCode: 'CE', label: 'Jaguaribe'},
    { stateCode: 'CE', label: 'Jaguaruana'},
    { stateCode: 'CE', label: 'Jardim'},
    { stateCode: 'CE', label: 'Jati'},
    { stateCode: 'CE', label: 'Jijoca de Jericoacoara'},
    { stateCode: 'CE', label: 'Juazeiro do Norte'},
    { stateCode: 'CE', label: 'Jucás'},
    { stateCode: 'CE', label: 'Lavras da Mangabeira'},
    { stateCode: 'CE', label: 'Limoeiro do Norte'},
    { stateCode: 'CE', label: 'Madalena'},
    { stateCode: 'CE', label: 'Maracanaú'},
    { stateCode: 'CE', label: 'Maranguape'},
    { stateCode: 'CE', label: 'Marco'},
    { stateCode: 'CE', label: 'Martinópole'},
    { stateCode: 'CE', label: 'Massapê'},
    { stateCode: 'CE', label: 'Mauriti'},
    { stateCode: 'CE', label: 'Meruoca'},
    { stateCode: 'CE', label: 'Milagres'},
    { stateCode: 'CE', label: 'Milhã'},
    { stateCode: 'CE', label: 'Miraíma'},
    { stateCode: 'CE', label: 'Missão Velha'},
    { stateCode: 'CE', label: 'Mombaça'},
    { stateCode: 'CE', label: 'Monsenhor Tabosa'},
    { stateCode: 'CE', label: 'Morada Nova'},
    { stateCode: 'CE', label: 'Moraújo'},
    { stateCode: 'CE', label: 'Morrinhos'},
    { stateCode: 'CE', label: 'Mucambo'},
    { stateCode: 'CE', label: 'Mulungu'},
    { stateCode: 'CE', label: 'Nova Olinda'},
    { stateCode: 'CE', label: 'Nova Russas'},
    { stateCode: 'CE', label: 'Novo Oriente'},
    { stateCode: 'CE', label: 'Ocara'},
    { stateCode: 'CE', label: 'Orós'},
    { stateCode: 'CE', label: 'Pacajus'},
    { stateCode: 'CE', label: 'Pacatuba'},
    { stateCode: 'CE', label: 'Pacoti'},
    { stateCode: 'CE', label: 'Pacujá'},
    { stateCode: 'CE', label: 'Palhano'},
    { stateCode: 'CE', label: 'Palmácia'},
    { stateCode: 'CE', label: 'Paracuru'},
    { stateCode: 'CE', label: 'Paraipaba'},
    { stateCode: 'CE', label: 'Parambu'},
    { stateCode: 'CE', label: 'Paramoti'},
    { stateCode: 'CE', label: 'Pedra Branca'},
    { stateCode: 'CE', label: 'Penaforte'},
    { stateCode: 'CE', label: 'Pentecoste'},
    { stateCode: 'CE', label: 'Pereiro'},
    { stateCode: 'CE', label: 'Pindoretama'},
    { stateCode: 'CE', label: 'Piquet Carneiro'},
    { stateCode: 'CE', label: 'Pires Ferreira'},
    { stateCode: 'CE', label: 'Poranga'},
    { stateCode: 'CE', label: 'Porteiras'},
    { stateCode: 'CE', label: 'Potengi'},
    { stateCode: 'CE', label: 'Potiretama'},
    { stateCode: 'CE', label: 'Quiterianópolis'},
    { stateCode: 'CE', label: 'Quixadá'},
    { stateCode: 'CE', label: 'Quixelô'},
    { stateCode: 'CE', label: 'Quixeramobim'},
    { stateCode: 'CE', label: 'Quixeré'},
    { stateCode: 'CE', label: 'Redenção'},
    { stateCode: 'CE', label: 'Reriutaba'},
    { stateCode: 'CE', label: 'Russas'},
    { stateCode: 'CE', label: 'Saboeiro'},
    { stateCode: 'CE', label: 'Salitre'},
    { stateCode: 'CE', label: 'Santa Quitéria'},
    { stateCode: 'CE', label: 'Santana do Acaraú'},
    { stateCode: 'CE', label: 'Santana do Cariri'},
    { stateCode: 'CE', label: 'São Benedito'},
    { stateCode: 'CE', label: 'São Gonçalo do Amarante'},
    { stateCode: 'CE', label: 'São João do Jaguaribe'},
    { stateCode: 'CE', label: 'São Luís do Curu'},
    { stateCode: 'CE', label: 'Senador Pompeu'},
    { stateCode: 'CE', label: 'Senador sá'},
    { stateCode: 'CE', label: 'Sobral'},
    { stateCode: 'CE', label: 'Solonópole'},
    { stateCode: 'CE', label: 'Tabuleiro do Norte'},
    { stateCode: 'CE', label: 'Tamboril'},
    { stateCode: 'CE', label: 'Tarrafas'},
    { stateCode: 'CE', label: 'Tauá'},
    { stateCode: 'CE', label: 'Tejuçuoca'},
    { stateCode: 'CE', label: 'Tianguá'},
    { stateCode: 'CE', label: 'Trairi'},
    { stateCode: 'CE', label: 'Tururu'},
    { stateCode: 'CE', label: 'Ubajara'},
    { stateCode: 'CE', label: 'Umari'},
    { stateCode: 'CE', label: 'Umirim'},
    { stateCode: 'CE', label: 'Uruburetama'},
    { stateCode: 'CE', label: 'Uruoca'},
    { stateCode: 'CE', label: 'Varjota'},
    { stateCode: 'CE', label: 'Várzea Alegre'},
    { stateCode: 'CE', label: 'Viçosa do Ceará'},
    { stateCode: 'DF', label: 'Brasília'},
    { stateCode: 'ES', label: 'Afonso Cláudio'},
    { stateCode: 'ES', label: 'Água Doce do Norte'},
    { stateCode: 'ES', label: 'Águia Branca'},
    { stateCode: 'ES', label: 'Alegre'},
    { stateCode: 'ES', label: 'Alfredo Chaves'},
    { stateCode: 'ES', label: 'Alto Rio Novo'},
    { stateCode: 'ES', label: 'Anchieta'},
    { stateCode: 'ES', label: 'Apiacá'},
    { stateCode: 'ES', label: 'Aracruz'},
    { stateCode: 'ES', label: 'Atilio Vivacqua'},
    { stateCode: 'ES', label: 'Baixo Guandu'},
    { stateCode: 'ES', label: 'Barra de São Francisco'},
    { stateCode: 'ES', label: 'Boa Esperança'},
    { stateCode: 'ES', label: 'Bom Jesus do Norte'},
    { stateCode: 'ES', label: 'Brejetuba'},
    { stateCode: 'ES', label: 'Cachoeiro de Itapemirim'},
    { stateCode: 'ES', label: 'Cariacica'},
    { stateCode: 'ES', label: 'Castelo'},
    { stateCode: 'ES', label: 'Colatina'},
    { stateCode: 'ES', label: 'Conceição da Barra'},
    { stateCode: 'ES', label: 'Conceição do Castelo'},
    { stateCode: 'ES', label: 'Divino de São Lourenço'},
    { stateCode: 'ES', label: 'Domingos Martins'},
    { stateCode: 'ES', label: 'Dores do Rio Preto'},
    { stateCode: 'ES', label: 'Ecoporanga'},
    { stateCode: 'ES', label: 'Fundão'},
    { stateCode: 'ES', label: 'Governador Lindenberg'},
    { stateCode: 'ES', label: 'Guaçuí'},
    { stateCode: 'ES', label: 'Guarapari'},
    { stateCode: 'ES', label: 'Ibatiba'},
    { stateCode: 'ES', label: 'Ibiraçu'},
    { stateCode: 'ES', label: 'Ibitirama'},
    { stateCode: 'ES', label: 'Iconha'},
    { stateCode: 'ES', label: 'Irupi'},
    { stateCode: 'ES', label: 'Itaguaçu'},
    { stateCode: 'ES', label: 'Itapemirim'},
    { stateCode: 'ES', label: 'Itarana'},
    { stateCode: 'ES', label: 'Iúna'},
    { stateCode: 'ES', label: 'Jaguaré'},
    { stateCode: 'ES', label: 'Jerônimo Monteiro'},
    { stateCode: 'ES', label: 'João Neiva'},
    { stateCode: 'ES', label: 'Laranja da Terra'},
    { stateCode: 'ES', label: 'Linhares'},
    { stateCode: 'ES', label: 'Mantenópolis'},
    { stateCode: 'ES', label: 'Marataízes'},
    { stateCode: 'ES', label: 'Marechal Floriano'},
    { stateCode: 'ES', label: 'Marilândia'},
    { stateCode: 'ES', label: 'Mimoso do Sul'},
    { stateCode: 'ES', label: 'Montanha'},
    { stateCode: 'ES', label: 'Mucurici'},
    { stateCode: 'ES', label: 'Muniz Freire'},
    { stateCode: 'ES', label: 'Muqui'},
    { stateCode: 'ES', label: 'Nova Venécia'},
    { stateCode: 'ES', label: 'Pancas'},
    { stateCode: 'ES', label: 'Pedro Canário'},
    { stateCode: 'ES', label: 'Pinheiros'},
    { stateCode: 'ES', label: 'Piúma'},
    { stateCode: 'ES', label: 'Ponto Belo'},
    { stateCode: 'ES', label: 'Presidente Kennedy'},
    { stateCode: 'ES', label: 'Rio Bananal'},
    { stateCode: 'ES', label: 'Rio Novo do Sul'},
    { stateCode: 'ES', label: 'Santa Leopoldina'},
    { stateCode: 'ES', label: 'Santa Maria de Jetibá'},
    { stateCode: 'ES', label: 'Santa Teresa'},
    { stateCode: 'ES', label: 'São Domingos do Norte'},
    { stateCode: 'ES', label: 'São Gabriel da Palha'},
    { stateCode: 'ES', label: 'São José do Calçado'},
    { stateCode: 'ES', label: 'São Mateus'},
    { stateCode: 'ES', label: 'São Roque do Canaã'},
    { stateCode: 'ES', label: 'Serra'},
    { stateCode: 'ES', label: 'Sooretama'},
    { stateCode: 'ES', label: 'Vargem Alta'},
    { stateCode: 'ES', label: 'Venda Nova do Imigrante'},
    { stateCode: 'ES', label: 'Viana'},
    { stateCode: 'ES', label: 'Vila Pavão'},
    { stateCode: 'ES', label: 'Vila Valério'},
    { stateCode: 'ES', label: 'Vila Velha'},
    { stateCode: 'ES', label: 'Vitória'},
    { stateCode: 'GO', label: 'Abadia de Goiás'},
    { stateCode: 'GO', label: 'Abadiânia'},
    { stateCode: 'GO', label: 'Acreúna'},
    { stateCode: 'GO', label: 'Adelândia'},
    { stateCode: 'GO', label: 'Água Fria de Goiás'},
    { stateCode: 'GO', label: 'Água Limpa'},
    { stateCode: 'GO', label: 'Águas Lindas de Goiás'},
    { stateCode: 'GO', label: 'Alexânia'},
    { stateCode: 'GO', label: 'Aloândia'},
    { stateCode: 'GO', label: 'Alto Horizonte'},
    { stateCode: 'GO', label: 'Alto Paraíso de Goiás'},
    { stateCode: 'GO', label: 'Alvorada do Norte'},
    { stateCode: 'GO', label: 'Amaralina'},
    { stateCode: 'GO', label: 'Americano do Brasil'},
    { stateCode: 'GO', label: 'Amorinópolis'},
    { stateCode: 'GO', label: 'Anápolis'},
    { stateCode: 'GO', label: 'Anhanguera'},
    { stateCode: 'GO', label: 'Anicuns'},
    { stateCode: 'GO', label: 'Aparecida de Goiânia'},
    { stateCode: 'GO', label: 'Aparecida do Rio Doce'},
    { stateCode: 'GO', label: 'Aporé'},
    { stateCode: 'GO', label: 'Araçu'},
    { stateCode: 'GO', label: 'Aragarças'},
    { stateCode: 'GO', label: 'Aragoiânia'},
    { stateCode: 'GO', label: 'Araguapaz'},
    { stateCode: 'GO', label: 'Arenópolis'},
    { stateCode: 'GO', label: 'Aruanã'},
    { stateCode: 'GO', label: 'Aurilândia'},
    { stateCode: 'GO', label: 'Avelinópolis'},
    { stateCode: 'GO', label: 'Baliza'},
    { stateCode: 'GO', label: 'Barro Alto'},
    { stateCode: 'GO', label: 'Bela Vista de Goiás'},
    { stateCode: 'GO', label: 'Bom Jardim de Goiás'},
    { stateCode: 'GO', label: 'Bom Jesus de Goiás'},
    { stateCode: 'GO', label: 'Bonfinópolis'},
    { stateCode: 'GO', label: 'Bonópolis'},
    { stateCode: 'GO', label: 'Brazabrantes'},
    { stateCode: 'GO', label: 'Britânia'},
    { stateCode: 'GO', label: 'Buriti Alegre'},
    { stateCode: 'GO', label: 'Buriti de Goiás'},
    { stateCode: 'GO', label: 'Buritinópolis'},
    { stateCode: 'GO', label: 'Cabeceiras'},
    { stateCode: 'GO', label: 'Cachoeira Alta'},
    { stateCode: 'GO', label: 'Cachoeira de Goiás'},
    { stateCode: 'GO', label: 'Cachoeira Dourada'},
    { stateCode: 'GO', label: 'Caçu'},
    { stateCode: 'GO', label: 'Caiapônia'},
    { stateCode: 'GO', label: 'Caldas Novas'},
    { stateCode: 'GO', label: 'Caldazinha'},
    { stateCode: 'GO', label: 'Campestre de Goiás'},
    { stateCode: 'GO', label: 'Campinaçu'},
    { stateCode: 'GO', label: 'Campinorte'},
    { stateCode: 'GO', label: 'Campo Alegre de Goiás'},
    { stateCode: 'GO', label: 'Campo Limpo de Goiás'},
    { stateCode: 'GO', label: 'Campos Belos'},
    { stateCode: 'GO', label: 'Campos Verdes'},
    { stateCode: 'GO', label: 'Carmo do Rio Verde'},
    { stateCode: 'GO', label: 'Castelândia'},
    { stateCode: 'GO', label: 'Catalão'},
    { stateCode: 'GO', label: 'Caturaí'},
    { stateCode: 'GO', label: 'Cavalcante'},
    { stateCode: 'GO', label: 'Ceres'},
    { stateCode: 'GO', label: 'Cezarina'},
    { stateCode: 'GO', label: 'Chapadão do Céu'},
    { stateCode: 'GO', label: 'Cidade Ocidental'},
    { stateCode: 'GO', label: 'Cocalzinho de Goiás'},
    { stateCode: 'GO', label: 'Colinas do Sul'},
    { stateCode: 'GO', label: 'Córrego do Ouro'},
    { stateCode: 'GO', label: 'Corumbá de Goiás'},
    { stateCode: 'GO', label: 'Corumbaíba'},
    { stateCode: 'GO', label: 'Cristalina'},
    { stateCode: 'GO', label: 'Cristianópolis'},
    { stateCode: 'GO', label: 'Crixás'},
    { stateCode: 'GO', label: 'Cromínia'},
    { stateCode: 'GO', label: 'Cumari'},
    { stateCode: 'GO', label: 'Damianópolis'},
    { stateCode: 'GO', label: 'Damolândia'},
    { stateCode: 'GO', label: 'Davinópolis'},
    { stateCode: 'GO', label: 'Diorama'},
    { stateCode: 'GO', label: 'Divinópolis de Goiás'},
    { stateCode: 'GO', label: 'Doverlândia'},
    { stateCode: 'GO', label: 'Edealina'},
    { stateCode: 'GO', label: 'Edéia'},
    { stateCode: 'GO', label: 'Estrela do Norte'},
    { stateCode: 'GO', label: 'Faina'},
    { stateCode: 'GO', label: 'Fazenda Nova'},
    { stateCode: 'GO', label: 'Firminópolis'},
    { stateCode: 'GO', label: 'Flores de Goiás'},
    { stateCode: 'GO', label: 'Formosa'},
    { stateCode: 'GO', label: 'Formoso'},
    { stateCode: 'GO', label: 'Gameleira de Goiás'},
    { stateCode: 'GO', label: 'Goianápolis'},
    { stateCode: 'GO', label: 'Goiandira'},
    { stateCode: 'GO', label: 'Goianésia'},
    { stateCode: 'GO', label: 'Goiânia'},
    { stateCode: 'GO', label: 'Goianira'},
    { stateCode: 'GO', label: 'Goiás'},
    { stateCode: 'GO', label: 'Goiatuba'},
    { stateCode: 'GO', label: 'Gouvelândia'},
    { stateCode: 'GO', label: 'Guapó'},
    { stateCode: 'GO', label: 'Guaraíta'},
    { stateCode: 'GO', label: 'Guarani de Goiás'},
    { stateCode: 'GO', label: 'Guarinos'},
    { stateCode: 'GO', label: 'Heitoraí'},
    { stateCode: 'GO', label: 'Hidrolândia'},
    { stateCode: 'GO', label: 'Hidrolina'},
    { stateCode: 'GO', label: 'Iaciara'},
    { stateCode: 'GO', label: 'Inaciolândia'},
    { stateCode: 'GO', label: 'Indiara'},
    { stateCode: 'GO', label: 'Inhumas'},
    { stateCode: 'GO', label: 'Ipameri'},
    { stateCode: 'GO', label: 'Ipiranga de Goiás'},
    { stateCode: 'GO', label: 'Iporá'},
    { stateCode: 'GO', label: 'Israelândia'},
    { stateCode: 'GO', label: 'Itaberaí'},
    { stateCode: 'GO', label: 'Itaguari'},
    { stateCode: 'GO', label: 'Itaguaru'},
    { stateCode: 'GO', label: 'Itajá'},
    { stateCode: 'GO', label: 'Itapaci'},
    { stateCode: 'GO', label: 'Itapirapuã'},
    { stateCode: 'GO', label: 'Itapuranga'},
    { stateCode: 'GO', label: 'Itarumã'},
    { stateCode: 'GO', label: 'Itauçu'},
    { stateCode: 'GO', label: 'Itumbiara'},
    { stateCode: 'GO', label: 'Ivolândia'},
    { stateCode: 'GO', label: 'Jandaia'},
    { stateCode: 'GO', label: 'Jaraguá'},
    { stateCode: 'GO', label: 'Jataí'},
    { stateCode: 'GO', label: 'Jaupaci'},
    { stateCode: 'GO', label: 'Jesúpolis'},
    { stateCode: 'GO', label: 'Joviânia'},
    { stateCode: 'GO', label: 'Jussara'},
    { stateCode: 'GO', label: 'Lagoa Santa'},
    { stateCode: 'GO', label: 'Leopoldo de Bulhões'},
    { stateCode: 'GO', label: 'Luziânia'},
    { stateCode: 'GO', label: 'Mairipotaba'},
    { stateCode: 'GO', label: 'Mambaí'},
    { stateCode: 'GO', label: 'Mara Rosa'},
    { stateCode: 'GO', label: 'Marzagão'},
    { stateCode: 'GO', label: 'Matrinchã'},
    { stateCode: 'GO', label: 'Maurilândia'},
    { stateCode: 'GO', label: 'Mimoso de Goiás'},
    { stateCode: 'GO', label: 'Minaçu'},
    { stateCode: 'GO', label: 'Mineiros'},
    { stateCode: 'GO', label: 'Moiporá'},
    { stateCode: 'GO', label: 'Monte Alegre de Goiás'},
    { stateCode: 'GO', label: 'Montes Claros de Goiás'},
    { stateCode: 'GO', label: 'Montividiu'},
    { stateCode: 'GO', label: 'Montividiu do Norte'},
    { stateCode: 'GO', label: 'Morrinhos'},
    { stateCode: 'GO', label: 'Morro Agudo de Goiás'},
    { stateCode: 'GO', label: 'Mossâmedes'},
    { stateCode: 'GO', label: 'Mozarlândia'},
    { stateCode: 'GO', label: 'Mundo Novo'},
    { stateCode: 'GO', label: 'Mutunópolis'},
    { stateCode: 'GO', label: 'Nazário'},
    { stateCode: 'GO', label: 'Nerópolis'},
    { stateCode: 'GO', label: 'Niquelândia'},
    { stateCode: 'GO', label: 'Nova América'},
    { stateCode: 'GO', label: 'Nova Aurora'},
    { stateCode: 'GO', label: 'Nova Crixás'},
    { stateCode: 'GO', label: 'Nova Glória'},
    { stateCode: 'GO', label: 'Nova Iguaçu de Goiás'},
    { stateCode: 'GO', label: 'Nova Roma'},
    { stateCode: 'GO', label: 'Nova Veneza'},
    { stateCode: 'GO', label: 'Novo Brasil'},
    { stateCode: 'GO', label: 'Novo Gama'},
    { stateCode: 'GO', label: 'Novo Planalto'},
    { stateCode: 'GO', label: 'Orizona'},
    { stateCode: 'GO', label: 'Ouro Verde de Goiás'},
    { stateCode: 'GO', label: 'Ouvidor'},
    { stateCode: 'GO', label: 'Padre Bernardo'},
    { stateCode: 'GO', label: 'Palestina de Goiás'},
    { stateCode: 'GO', label: 'Palmeiras de Goiás'},
    { stateCode: 'GO', label: 'Palmelo'},
    { stateCode: 'GO', label: 'Palminópolis'},
    { stateCode: 'GO', label: 'Panamá'},
    { stateCode: 'GO', label: 'Paranaiguara'},
    { stateCode: 'GO', label: 'Paraúna'},
    { stateCode: 'GO', label: 'Perolândia'},
    { stateCode: 'GO', label: 'Petrolina de Goiás'},
    { stateCode: 'GO', label: 'Pilar de Goiás'},
    { stateCode: 'GO', label: 'Piracanjuba'},
    { stateCode: 'GO', label: 'Piranhas'},
    { stateCode: 'GO', label: 'Pirenópolis'},
    { stateCode: 'GO', label: 'Pires do Rio'},
    { stateCode: 'GO', label: 'Planaltina'},
    { stateCode: 'GO', label: 'Pontalina'},
    { stateCode: 'GO', label: 'Porangatu'},
    { stateCode: 'GO', label: 'Porteirão'},
    { stateCode: 'GO', label: 'Portelândia'},
    { stateCode: 'GO', label: 'Posse'},
    { stateCode: 'GO', label: 'Professor Jamil'},
    { stateCode: 'GO', label: 'Quirinópolis'},
    { stateCode: 'GO', label: 'Rialma'},
    { stateCode: 'GO', label: 'Rianápolis'},
    { stateCode: 'GO', label: 'Rio Quente'},
    { stateCode: 'GO', label: 'Rio Verde'},
    { stateCode: 'GO', label: 'Rubiataba'},
    { stateCode: 'GO', label: 'Sanclerlândia'},
    { stateCode: 'GO', label: 'Santa Bárbara de Goiás'},
    { stateCode: 'GO', label: 'Santa Cruz de Goiás'},
    { stateCode: 'GO', label: 'Santa fé de Goiás'},
    { stateCode: 'GO', label: 'Santa Helena de Goiás'},
    { stateCode: 'GO', label: 'Santa Isabel'},
    { stateCode: 'GO', label: 'Santa Rita do Araguaia'},
    { stateCode: 'GO', label: 'Santa Rita do Novo Destino'},
    { stateCode: 'GO', label: 'Santa Rosa de Goiás'},
    { stateCode: 'GO', label: 'Santa Tereza de Goiás'},
    { stateCode: 'GO', label: 'Santa Terezinha de Goiás'},
    { stateCode: 'GO', label: 'Santo Antônio da Barra'},
    { stateCode: 'GO', label: 'Santo Antônio de Goiás'},
    { stateCode: 'GO', label: 'Santo Antônio do Descoberto'},
    { stateCode: 'GO', label: 'São Domingos'},
    { stateCode: 'GO', label: 'São Francisco de Goiás'},
    { stateCode: 'GO', label: 'São João D´aliança'},
    { stateCode: 'GO', label: 'São João da Paraúna'},
    { stateCode: 'GO', label: 'São Luís de Montes Belos'},
    { stateCode: 'GO', label: 'São Luíz do Norte'},
    { stateCode: 'GO', label: 'São Miguel do Araguaia'},
    { stateCode: 'GO', label: 'São Miguel do Passa Quatro'},
    { stateCode: 'GO', label: 'São Patrício'},
    { stateCode: 'GO', label: 'São Simão'},
    { stateCode: 'GO', label: 'Senador Canedo'},
    { stateCode: 'GO', label: 'Serranópolis'},
    { stateCode: 'GO', label: 'Silvânia'},
    { stateCode: 'GO', label: 'Simolândia'},
    { stateCode: 'GO', label: 'Sítio D´abadia'},
    { stateCode: 'GO', label: 'Taquaral de Goiás'},
    { stateCode: 'GO', label: 'Teresina de Goiás'},
    { stateCode: 'GO', label: 'Terezópolis de Goiás'},
    { stateCode: 'GO', label: 'Três Ranchos'},
    { stateCode: 'GO', label: 'Trindade'},
    { stateCode: 'GO', label: 'Trombas'},
    { stateCode: 'GO', label: 'Turvânia'},
    { stateCode: 'GO', label: 'Turvelândia'},
    { stateCode: 'GO', label: 'Uirapuru'},
    { stateCode: 'GO', label: 'Uruaçu'},
    { stateCode: 'GO', label: 'Uruana'},
    { stateCode: 'GO', label: 'Urutaí'},
    { stateCode: 'GO', label: 'Valparaíso de Goiás'},
    { stateCode: 'GO', label: 'Varjão'},
    { stateCode: 'GO', label: 'Vianópolis'},
    { stateCode: 'GO', label: 'Vicentinópolis'},
    { stateCode: 'GO', label: 'Vila Boa'},
    { stateCode: 'GO', label: 'Vila Propício'},
    { stateCode: 'MA', label: 'Açailândia'},
    { stateCode: 'MA', label: 'Afonso Cunha'},
    { stateCode: 'MA', label: 'Água Doce do Maranhão'},
    { stateCode: 'MA', label: 'Alcântara'},
    { stateCode: 'MA', label: 'Aldeias Altas'},
    { stateCode: 'MA', label: 'Altamira do Maranhão'},
    { stateCode: 'MA', label: 'Alto Alegre do Maranhão'},
    { stateCode: 'MA', label: 'Alto Alegre do Pindaré'},
    { stateCode: 'MA', label: 'Alto Parnaíba'},
    { stateCode: 'MA', label: 'Amapá do Maranhão'},
    { stateCode: 'MA', label: 'Amarante do Maranhão'},
    { stateCode: 'MA', label: 'Anajatuba'},
    { stateCode: 'MA', label: 'Anapurus'},
    { stateCode: 'MA', label: 'Apicum-açu'},
    { stateCode: 'MA', label: 'Araguanã'},
    { stateCode: 'MA', label: 'Araioses'},
    { stateCode: 'MA', label: 'Arame'},
    { stateCode: 'MA', label: 'Arari'},
    { stateCode: 'MA', label: 'Axixá'},
    { stateCode: 'MA', label: 'Bacabal'},
    { stateCode: 'MA', label: 'Bacabeira'},
    { stateCode: 'MA', label: 'Bacuri'},
    { stateCode: 'MA', label: 'Bacurituba'},
    { stateCode: 'MA', label: 'Balsas'},
    { stateCode: 'MA', label: 'Barão de Grajaú'},
    { stateCode: 'MA', label: 'Barra do Corda'},
    { stateCode: 'MA', label: 'Barreirinhas'},
    { stateCode: 'MA', label: 'Bela Vista do Maranhão'},
    { stateCode: 'MA', label: 'Belágua'},
    { stateCode: 'MA', label: 'Benedito Leite'},
    { stateCode: 'MA', label: 'Bequimão'},
    { stateCode: 'MA', label: 'Bernardo do Mearim'},
    { stateCode: 'MA', label: 'Boa Vista do Gurupi'},
    { stateCode: 'MA', label: 'Bom Jardim'},
    { stateCode: 'MA', label: 'Bom Jesus Das Selvas'},
    { stateCode: 'MA', label: 'Bom Lugar'},
    { stateCode: 'MA', label: 'Brejo'},
    { stateCode: 'MA', label: 'Brejo de Areia'},
    { stateCode: 'MA', label: 'Buriti'},
    { stateCode: 'MA', label: 'Buriti Bravo'},
    { stateCode: 'MA', label: 'Buriticupu'},
    { stateCode: 'MA', label: 'Buritirana'},
    { stateCode: 'MA', label: 'Cachoeira Grande'},
    { stateCode: 'MA', label: 'Cajapió'},
    { stateCode: 'MA', label: 'Cajari'},
    { stateCode: 'MA', label: 'Campestre do Maranhão'},
    { stateCode: 'MA', label: 'Cândido Mendes'},
    { stateCode: 'MA', label: 'Cantanhede'},
    { stateCode: 'MA', label: 'Capinzal do Norte'},
    { stateCode: 'MA', label: 'Carolina'},
    { stateCode: 'MA', label: 'Carutapera'},
    { stateCode: 'MA', label: 'Caxias'},
    { stateCode: 'MA', label: 'Cedral'},
    { stateCode: 'MA', label: 'Central do Maranhão'},
    { stateCode: 'MA', label: 'Centro do Guilherme'},
    { stateCode: 'MA', label: 'Centro Novo do Maranhão'},
    { stateCode: 'MA', label: 'Chapadinha'},
    { stateCode: 'MA', label: 'Cidelândia'},
    { stateCode: 'MA', label: 'Codó'},
    { stateCode: 'MA', label: 'Coelho Neto'},
    { stateCode: 'MA', label: 'Colinas'},
    { stateCode: 'MA', label: 'Conceição do Lago-açu'},
    { stateCode: 'MA', label: 'Coroatá'},
    { stateCode: 'MA', label: 'Cururupu'},
    { stateCode: 'MA', label: 'Davinópolis'},
    { stateCode: 'MA', label: 'Dom Pedro'},
    { stateCode: 'MA', label: 'Duque Bacelar'},
    { stateCode: 'MA', label: 'Esperantinópolis'},
    { stateCode: 'MA', label: 'Estreito'},
    { stateCode: 'MA', label: 'Feira Nova do Maranhão'},
    { stateCode: 'MA', label: 'Fernando Falcão'},
    { stateCode: 'MA', label: 'Formosa da Serra Negra'},
    { stateCode: 'MA', label: 'Fortaleza Dos Nogueiras'},
    { stateCode: 'MA', label: 'Fortuna'},
    { stateCode: 'MA', label: 'Godofredo Viana'},
    { stateCode: 'MA', label: 'Gonçalves Dias'},
    { stateCode: 'MA', label: 'Governador Archer'},
    { stateCode: 'MA', label: 'Governador Edison Lobão'},
    { stateCode: 'MA', label: 'Governador Eugênio Barros'},
    { stateCode: 'MA', label: 'Governador Luiz Rocha'},
    { stateCode: 'MA', label: 'Governador Newton Bello'},
    { stateCode: 'MA', label: 'Governador Nunes Freire'},
    { stateCode: 'MA', label: 'Graça Aranha'},
    { stateCode: 'MA', label: 'Grajaú'},
    { stateCode: 'MA', label: 'Guimarães'},
    { stateCode: 'MA', label: 'Humberto de Campos'},
    { stateCode: 'MA', label: 'Icatu'},
    { stateCode: 'MA', label: 'Igarapé do Meio'},
    { stateCode: 'MA', label: 'Igarapé Grande'},
    { stateCode: 'MA', label: 'Imperatriz'},
    { stateCode: 'MA', label: 'Itaipava do Grajaú'},
    { stateCode: 'MA', label: 'Itapecuru Mirim'},
    { stateCode: 'MA', label: 'Itinga do Maranhão'},
    { stateCode: 'MA', label: 'Jatobá'},
    { stateCode: 'MA', label: 'Jenipapo Dos Vieiras'},
    { stateCode: 'MA', label: 'João Lisboa'},
    { stateCode: 'MA', label: 'Joselândia'},
    { stateCode: 'MA', label: 'Junco do Maranhão'},
    { stateCode: 'MA', label: 'Lago da Pedra'},
    { stateCode: 'MA', label: 'Lago do Junco'},
    { stateCode: 'MA', label: 'Lago Dos Rodrigues'},
    { stateCode: 'MA', label: 'Lago Verde'},
    { stateCode: 'MA', label: 'Lagoa do Mato'},
    { stateCode: 'MA', label: 'Lagoa Grande do Maranhão'},
    { stateCode: 'MA', label: 'Lajeado Novo'},
    { stateCode: 'MA', label: 'Lima Campos'},
    { stateCode: 'MA', label: 'Loreto'},
    { stateCode: 'MA', label: 'Luís Domingues'},
    { stateCode: 'MA', label: 'Magalhães de Almeida'},
    { stateCode: 'MA', label: 'Maracaçumé'},
    { stateCode: 'MA', label: 'Marajá do Sena'},
    { stateCode: 'MA', label: 'Maranhãozinho'},
    { stateCode: 'MA', label: 'Mata Roma'},
    { stateCode: 'MA', label: 'Matinha'},
    { stateCode: 'MA', label: 'Matões'},
    { stateCode: 'MA', label: 'Matões do Norte'},
    { stateCode: 'MA', label: 'Milagres do Maranhão'},
    { stateCode: 'MA', label: 'Mirador'},
    { stateCode: 'MA', label: 'Miranda do Norte'},
    { stateCode: 'MA', label: 'Mirinzal'},
    { stateCode: 'MA', label: 'Monção'},
    { stateCode: 'MA', label: 'Montes Altos'},
    { stateCode: 'MA', label: 'Morros'},
    { stateCode: 'MA', label: 'Nina Rodrigues'},
    { stateCode: 'MA', label: 'Nova Colinas'},
    { stateCode: 'MA', label: 'Nova Iorque'},
    { stateCode: 'MA', label: 'Nova Olinda do Maranhão'},
    { stateCode: 'MA', label: 'Olho D´água Das Cunhãs'},
    { stateCode: 'MA', label: 'Olinda Nova do Maranhão'},
    { stateCode: 'MA', label: 'Paço do Lumiar'},
    { stateCode: 'MA', label: 'Palmeirândia'},
    { stateCode: 'MA', label: 'Paraibano'},
    { stateCode: 'MA', label: 'Parnarama'},
    { stateCode: 'MA', label: 'Passagem Franca'},
    { stateCode: 'MA', label: 'Pastos Bons'},
    { stateCode: 'MA', label: 'Paulino Neves'},
    { stateCode: 'MA', label: 'Paulo Ramos'},
    { stateCode: 'MA', label: 'Pedreiras'},
    { stateCode: 'MA', label: 'Pedro do Rosário'},
    { stateCode: 'MA', label: 'Penalva'},
    { stateCode: 'MA', label: 'Peri Mirim'},
    { stateCode: 'MA', label: 'Peritoró'},
    { stateCode: 'MA', label: 'Pindaré-mirim'},
    { stateCode: 'MA', label: 'Pinheiro'},
    { stateCode: 'MA', label: 'Pio Xii'},
    { stateCode: 'MA', label: 'Pirapemas'},
    { stateCode: 'MA', label: 'Poção de Pedras'},
    { stateCode: 'MA', label: 'Porto Franco'},
    { stateCode: 'MA', label: 'Porto Rico do Maranhão'},
    { stateCode: 'MA', label: 'Presidente Dutra'},
    { stateCode: 'MA', label: 'Presidente Juscelino'},
    { stateCode: 'MA', label: 'Presidente Médici'},
    { stateCode: 'MA', label: 'Presidente Sarney'},
    { stateCode: 'MA', label: 'Presidente Vargas'},
    { stateCode: 'MA', label: 'Primeira Cruz'},
    { stateCode: 'MA', label: 'Raposa'},
    { stateCode: 'MA', label: 'Riachão'},
    { stateCode: 'MA', label: 'Ribamar Fiquene'},
    { stateCode: 'MA', label: 'Rosário'},
    { stateCode: 'MA', label: 'Sambaíba'},
    { stateCode: 'MA', label: 'Santa Filomena do Maranhão'},
    { stateCode: 'MA', label: 'Santa Helena'},
    { stateCode: 'MA', label: 'Santa Inês'},
    { stateCode: 'MA', label: 'Santa Luzia'},
    { stateCode: 'MA', label: 'Santa Luzia do Paruá'},
    { stateCode: 'MA', label: 'Santa Quitéria do Maranhão'},
    { stateCode: 'MA', label: 'Santa Rita'},
    { stateCode: 'MA', label: 'Santana do Maranhão'},
    { stateCode: 'MA', label: 'Santo Amaro do Maranhão'},
    { stateCode: 'MA', label: 'Santo Antônio Dos Lopes'},
    { stateCode: 'MA', label: 'São Benedito do Rio Preto'},
    { stateCode: 'MA', label: 'São Bento'},
    { stateCode: 'MA', label: 'São Bernardo'},
    { stateCode: 'MA', label: 'São Domingos do Azeitão'},
    { stateCode: 'MA', label: 'São Domingos do Maranhão'},
    { stateCode: 'MA', label: 'São Félix de Balsas'},
    { stateCode: 'MA', label: 'São Francisco do Brejão'},
    { stateCode: 'MA', label: 'São Francisco do Maranhão'},
    { stateCode: 'MA', label: 'São João Batista'},
    { stateCode: 'MA', label: 'São João do Carú'},
    { stateCode: 'MA', label: 'São João do Paraíso'},
    { stateCode: 'MA', label: 'São João do Soter'},
    { stateCode: 'MA', label: 'São João Dos Patos'},
    { stateCode: 'MA', label: 'São José de Ribamar'},
    { stateCode: 'MA', label: 'São José Dos Basílios'},
    { stateCode: 'MA', label: 'São Luís'},
    { stateCode: 'MA', label: 'São Luís Gonzaga do Maranhão'},
    { stateCode: 'MA', label: 'São Mateus do Maranhão'},
    { stateCode: 'MA', label: 'São Pedro da Água Branca'},
    { stateCode: 'MA', label: 'São Pedro Dos Crentes'},
    { stateCode: 'MA', label: 'São Raimundo Das Mangabeiras'},
    { stateCode: 'MA', label: 'São Raimundo do Doca Bezerra'},
    { stateCode: 'MA', label: 'São Roberto'},
    { stateCode: 'MA', label: 'São Vicente Ferrer'},
    { stateCode: 'MA', label: 'Satubinha'},
    { stateCode: 'MA', label: 'Senador Alexandre Costa'},
    { stateCode: 'MA', label: 'Senador la Rocque'},
    { stateCode: 'MA', label: 'Serrano do Maranhão'},
    { stateCode: 'MA', label: 'Sítio Novo'},
    { stateCode: 'MA', label: 'Sucupira do Norte'},
    { stateCode: 'MA', label: 'Sucupira do Riachão'},
    { stateCode: 'MA', label: 'Tasso Fragoso'},
    { stateCode: 'MA', label: 'Timbiras'},
    { stateCode: 'MA', label: 'Timon'},
    { stateCode: 'MA', label: 'Trizidela do Vale'},
    { stateCode: 'MA', label: 'Tufilândia'},
    { stateCode: 'MA', label: 'Tuntum'},
    { stateCode: 'MA', label: 'Turiaçu'},
    { stateCode: 'MA', label: 'Turilândia'},
    { stateCode: 'MA', label: 'Tutóia'},
    { stateCode: 'MA', label: 'Urbano Santos'},
    { stateCode: 'MA', label: 'Vargem Grande'},
    { stateCode: 'MA', label: 'Viana'},
    { stateCode: 'MA', label: 'Vila Nova Dos Martírios'},
    { stateCode: 'MA', label: 'Vitória do Mearim'},
    { stateCode: 'MA', label: 'Vitorino Freire'},
    { stateCode: 'MA', label: 'zé Doca'},
    { stateCode: 'MG', label: 'Abadia Dos Dourados'},
    { stateCode: 'MG', label: 'Abaeté'},
    { stateCode: 'MG', label: 'Abre Campo'},
    { stateCode: 'MG', label: 'Acaiaca'},
    { stateCode: 'MG', label: 'Açucena'},
    { stateCode: 'MG', label: 'Água Boa'},
    { stateCode: 'MG', label: 'Água Comprida'},
    { stateCode: 'MG', label: 'Aguanil'},
    { stateCode: 'MG', label: 'Águas Formosas'},
    { stateCode: 'MG', label: 'Águas Vermelhas'},
    { stateCode: 'MG', label: 'Aimorés'},
    { stateCode: 'MG', label: 'Aiuruoca'},
    { stateCode: 'MG', label: 'Alagoa'},
    { stateCode: 'MG', label: 'Albertina'},
    { stateCode: 'MG', label: 'Além Paraíba'},
    { stateCode: 'MG', label: 'Alfenas'},
    { stateCode: 'MG', label: 'Alfredo Vasconcelos'},
    { stateCode: 'MG', label: 'Almenara'},
    { stateCode: 'MG', label: 'Alpercata'},
    { stateCode: 'MG', label: 'Alpinópolis'},
    { stateCode: 'MG', label: 'Alterosa'},
    { stateCode: 'MG', label: 'Alto Caparaó'},
    { stateCode: 'MG', label: 'Alto Jequitibá'},
    { stateCode: 'MG', label: 'Alto Rio Doce'},
    { stateCode: 'MG', label: 'Alvarenga'},
    { stateCode: 'MG', label: 'Alvinópolis'},
    { stateCode: 'MG', label: 'Alvorada de Minas'},
    { stateCode: 'MG', label: 'Amparo do Serra'},
    { stateCode: 'MG', label: 'Andradas'},
    { stateCode: 'MG', label: 'Andrelândia'},
    { stateCode: 'MG', label: 'Angelândia'},
    { stateCode: 'MG', label: 'Antônio Carlos'},
    { stateCode: 'MG', label: 'Antônio Dias'},
    { stateCode: 'MG', label: 'Antônio Prado de Minas'},
    { stateCode: 'MG', label: 'Araçaí'},
    { stateCode: 'MG', label: 'Aracitaba'},
    { stateCode: 'MG', label: 'Araçuaí'},
    { stateCode: 'MG', label: 'Araguari'},
    { stateCode: 'MG', label: 'Arantina'},
    { stateCode: 'MG', label: 'Araponga'},
    { stateCode: 'MG', label: 'Araporã'},
    { stateCode: 'MG', label: 'Arapuá'},
    { stateCode: 'MG', label: 'Araújos'},
    { stateCode: 'MG', label: 'Araxá'},
    { stateCode: 'MG', label: 'Arceburgo'},
    { stateCode: 'MG', label: 'Arcos'},
    { stateCode: 'MG', label: 'Areado'},
    { stateCode: 'MG', label: 'Argirita'},
    { stateCode: 'MG', label: 'Aricanduva'},
    { stateCode: 'MG', label: 'Arinos'},
    { stateCode: 'MG', label: 'Astolfo Dutra'},
    { stateCode: 'MG', label: 'Ataléia'},
    { stateCode: 'MG', label: 'Augusto de Lima'},
    { stateCode: 'MG', label: 'Baependi'},
    { stateCode: 'MG', label: 'Baldim'},
    { stateCode: 'MG', label: 'Bambuí'},
    { stateCode: 'MG', label: 'Bandeira'},
    { stateCode: 'MG', label: 'Bandeira do Sul'},
    { stateCode: 'MG', label: 'Barão de Cocais'},
    { stateCode: 'MG', label: 'Barão de Monte Alto'},
    { stateCode: 'MG', label: 'Barbacena'},
    { stateCode: 'MG', label: 'Barra Longa'},
    { stateCode: 'MG', label: 'Barroso'},
    { stateCode: 'MG', label: 'Bela Vista de Minas'},
    { stateCode: 'MG', label: 'Belmiro Braga'},
    { stateCode: 'MG', label: 'Belo Horizonte'},
    { stateCode: 'MG', label: 'Belo Oriente'},
    { stateCode: 'MG', label: 'Belo Vale'},
    { stateCode: 'MG', label: 'Berilo'},
    { stateCode: 'MG', label: 'Berizal'},
    { stateCode: 'MG', label: 'Bertópolis'},
    { stateCode: 'MG', label: 'Betim'},
    { stateCode: 'MG', label: 'Bias Fortes'},
    { stateCode: 'MG', label: 'Bicas'},
    { stateCode: 'MG', label: 'Biquinhas'},
    { stateCode: 'MG', label: 'Boa Esperança'},
    { stateCode: 'MG', label: 'Bocaina de Minas'},
    { stateCode: 'MG', label: 'Bocaiúva'},
    { stateCode: 'MG', label: 'Bom Despacho'},
    { stateCode: 'MG', label: 'Bom Jardim de Minas'},
    { stateCode: 'MG', label: 'Bom Jesus da Penha'},
    { stateCode: 'MG', label: 'Bom Jesus do Amparo'},
    { stateCode: 'MG', label: 'Bom Jesus do Galho'},
    { stateCode: 'MG', label: 'Bom Repouso'},
    { stateCode: 'MG', label: 'Bom Sucesso'},
    { stateCode: 'MG', label: 'Bonfim'},
    { stateCode: 'MG', label: 'Bonfinópolis de Minas'},
    { stateCode: 'MG', label: 'Bonito de Minas'},
    { stateCode: 'MG', label: 'Borda da Mata'},
    { stateCode: 'MG', label: 'Botelhos'},
    { stateCode: 'MG', label: 'Botumirim'},
    { stateCode: 'MG', label: 'Brás Pires'},
    { stateCode: 'MG', label: 'Brasilândia de Minas'},
    { stateCode: 'MG', label: 'Brasília de Minas'},
    { stateCode: 'MG', label: 'Brasópolis'},
    { stateCode: 'MG', label: 'Braúnas'},
    { stateCode: 'MG', label: 'Brumadinho'},
    { stateCode: 'MG', label: 'Bueno Brandão'},
    { stateCode: 'MG', label: 'Buenópolis'},
    { stateCode: 'MG', label: 'Bugre'},
    { stateCode: 'MG', label: 'Buritis'},
    { stateCode: 'MG', label: 'Buritizeiro'},
    { stateCode: 'MG', label: 'Cabeceira Grande'},
    { stateCode: 'MG', label: 'Cabo Verde'},
    { stateCode: 'MG', label: 'Cachoeira da Prata'},
    { stateCode: 'MG', label: 'Cachoeira de Minas'},
    { stateCode: 'MG', label: 'Cachoeira de Pajeú'},
    { stateCode: 'MG', label: 'Cachoeira Dourada'},
    { stateCode: 'MG', label: 'Caetanópolis'},
    { stateCode: 'MG', label: 'Caeté'},
    { stateCode: 'MG', label: 'Caiana'},
    { stateCode: 'MG', label: 'Cajuri'},
    { stateCode: 'MG', label: 'Caldas'},
    { stateCode: 'MG', label: 'Camacho'},
    { stateCode: 'MG', label: 'Camanducaia'},
    { stateCode: 'MG', label: 'Cambuí'},
    { stateCode: 'MG', label: 'Cambuquira'},
    { stateCode: 'MG', label: 'Campanário'},
    { stateCode: 'MG', label: 'Campanha'},
    { stateCode: 'MG', label: 'Campestre'},
    { stateCode: 'MG', label: 'Campina Verde'},
    { stateCode: 'MG', label: 'Campo Azul'},
    { stateCode: 'MG', label: 'Campo Belo'},
    { stateCode: 'MG', label: 'Campo do Meio'},
    { stateCode: 'MG', label: 'Campo Florido'},
    { stateCode: 'MG', label: 'Campos Altos'},
    { stateCode: 'MG', label: 'Campos Gerais'},
    { stateCode: 'MG', label: 'Cana Verde'},
    { stateCode: 'MG', label: 'Canaã'},
    { stateCode: 'MG', label: 'Canápolis'},
    { stateCode: 'MG', label: 'Candeias'},
    { stateCode: 'MG', label: 'Cantagalo'},
    { stateCode: 'MG', label: 'Caparaó'},
    { stateCode: 'MG', label: 'Capela Nova'},
    { stateCode: 'MG', label: 'Capelinha'},
    { stateCode: 'MG', label: 'Capetinga'},
    { stateCode: 'MG', label: 'Capim Branco'},
    { stateCode: 'MG', label: 'Capinópolis'},
    { stateCode: 'MG', label: 'Capitão Andrade'},
    { stateCode: 'MG', label: 'Capitão Enéas'},
    { stateCode: 'MG', label: 'Capitólio'},
    { stateCode: 'MG', label: 'Caputira'},
    { stateCode: 'MG', label: 'Caraí'},
    { stateCode: 'MG', label: 'Caranaíba'},
    { stateCode: 'MG', label: 'Carandaí'},
    { stateCode: 'MG', label: 'Carangola'},
    { stateCode: 'MG', label: 'Caratinga'},
    { stateCode: 'MG', label: 'Carbonita'},
    { stateCode: 'MG', label: 'Careaçu'},
    { stateCode: 'MG', label: 'Carlos Chagas'},
    { stateCode: 'MG', label: 'Carmésia'},
    { stateCode: 'MG', label: 'Carmo da Cachoeira'},
    { stateCode: 'MG', label: 'Carmo da Mata'},
    { stateCode: 'MG', label: 'Carmo de Minas'},
    { stateCode: 'MG', label: 'Carmo do Cajuru'},
    { stateCode: 'MG', label: 'Carmo do Paranaíba'},
    { stateCode: 'MG', label: 'Carmo do Rio Claro'},
    { stateCode: 'MG', label: 'Carmópolis de Minas'},
    { stateCode: 'MG', label: 'Carneirinho'},
    { stateCode: 'MG', label: 'Carrancas'},
    { stateCode: 'MG', label: 'Carvalhópolis'},
    { stateCode: 'MG', label: 'Carvalhos'},
    { stateCode: 'MG', label: 'Casa Grande'},
    { stateCode: 'MG', label: 'Cascalho Rico'},
    { stateCode: 'MG', label: 'Cássia'},
    { stateCode: 'MG', label: 'Cataguases'},
    { stateCode: 'MG', label: 'Catas Altas'},
    { stateCode: 'MG', label: 'Catas Altas da Noruega'},
    { stateCode: 'MG', label: 'Catuji'},
    { stateCode: 'MG', label: 'Catuti'},
    { stateCode: 'MG', label: 'Caxambu'},
    { stateCode: 'MG', label: 'Cedro do Abaeté'},
    { stateCode: 'MG', label: 'Central de Minas'},
    { stateCode: 'MG', label: 'Centralina'},
    { stateCode: 'MG', label: 'Chácara'},
    { stateCode: 'MG', label: 'Chalé'},
    { stateCode: 'MG', label: 'Chapada do Norte'},
    { stateCode: 'MG', label: 'Chapada Gaúcha'},
    { stateCode: 'MG', label: 'Chiador'},
    { stateCode: 'MG', label: 'Cipotânea'},
    { stateCode: 'MG', label: 'Claraval'},
    { stateCode: 'MG', label: 'Claro Dos Poções'},
    { stateCode: 'MG', label: 'Cláudio'},
    { stateCode: 'MG', label: 'Coimbra'},
    { stateCode: 'MG', label: 'Coluna'},
    { stateCode: 'MG', label: 'Comendador Gomes'},
    { stateCode: 'MG', label: 'Comercinho'},
    { stateCode: 'MG', label: 'Conceição da Aparecida'},
    { stateCode: 'MG', label: 'Conceição da Barra de Minas'},
    { stateCode: 'MG', label: 'Conceição Das Alagoas'},
    { stateCode: 'MG', label: 'Conceição Das Pedras'},
    { stateCode: 'MG', label: 'Conceição de Ipanema'},
    { stateCode: 'MG', label: 'Conceição do Mato Dentro'},
    { stateCode: 'MG', label: 'Conceição do Pará'},
    { stateCode: 'MG', label: 'Conceição do Rio Verde'},
    { stateCode: 'MG', label: 'Conceição Dos Ouros'},
    { stateCode: 'MG', label: 'Cônego Marinho'},
    { stateCode: 'MG', label: 'Confins'},
    { stateCode: 'MG', label: 'Congonhal'},
    { stateCode: 'MG', label: 'Congonhas'},
    { stateCode: 'MG', label: 'Congonhas do Norte'},
    { stateCode: 'MG', label: 'Conquista'},
    { stateCode: 'MG', label: 'Conselheiro Lafaiete'},
    { stateCode: 'MG', label: 'Conselheiro Pena'},
    { stateCode: 'MG', label: 'Consolação'},
    { stateCode: 'MG', label: 'Contagem'},
    { stateCode: 'MG', label: 'Coqueiral'},
    { stateCode: 'MG', label: 'Coração de Jesus'},
    { stateCode: 'MG', label: 'Cordisburgo'},
    { stateCode: 'MG', label: 'Cordislândia'},
    { stateCode: 'MG', label: 'Corinto'},
    { stateCode: 'MG', label: 'Coroaci'},
    { stateCode: 'MG', label: 'Coromandel'},
    { stateCode: 'MG', label: 'Coronel Fabriciano'},
    { stateCode: 'MG', label: 'Coronel Murta'},
    { stateCode: 'MG', label: 'Coronel Pacheco'},
    { stateCode: 'MG', label: 'Coronel Xavier Chaves'},
    { stateCode: 'MG', label: 'Córrego Danta'},
    { stateCode: 'MG', label: 'Córrego do Bom Jesus'},
    { stateCode: 'MG', label: 'Córrego Fundo'},
    { stateCode: 'MG', label: 'Córrego Novo'},
    { stateCode: 'MG', label: 'Couto de Magalhães de Minas'},
    { stateCode: 'MG', label: 'Crisólita'},
    { stateCode: 'MG', label: 'Cristais'},
    { stateCode: 'MG', label: 'Cristália'},
    { stateCode: 'MG', label: 'Cristiano Otoni'},
    { stateCode: 'MG', label: 'Cristina'},
    { stateCode: 'MG', label: 'Crucilândia'},
    { stateCode: 'MG', label: 'Cruzeiro da Fortaleza'},
    { stateCode: 'MG', label: 'Cruzília'},
    { stateCode: 'MG', label: 'Cuparaque'},
    { stateCode: 'MG', label: 'Curral de Dentro'},
    { stateCode: 'MG', label: 'Curvelo'},
    { stateCode: 'MG', label: 'Datas'},
    { stateCode: 'MG', label: 'Delfim Moreira'},
    { stateCode: 'MG', label: 'Delfinópolis'},
    { stateCode: 'MG', label: 'Delta'},
    { stateCode: 'MG', label: 'Descoberto'},
    { stateCode: 'MG', label: 'Desterro de Entre Rios'},
    { stateCode: 'MG', label: 'Desterro do Melo'},
    { stateCode: 'MG', label: 'Diamantina'},
    { stateCode: 'MG', label: 'Diogo de Vasconcelos'},
    { stateCode: 'MG', label: 'Dionísio'},
    { stateCode: 'MG', label: 'Divinésia'},
    { stateCode: 'MG', label: 'Divino'},
    { stateCode: 'MG', label: 'Divino Das Laranjeiras'},
    { stateCode: 'MG', label: 'Divinolândia de Minas'},
    { stateCode: 'MG', label: 'Divinópolis'},
    { stateCode: 'MG', label: 'Divisa Alegre'},
    { stateCode: 'MG', label: 'Divisa Nova'},
    { stateCode: 'MG', label: 'Divisópolis'},
    { stateCode: 'MG', label: 'Dom Bosco'},
    { stateCode: 'MG', label: 'Dom Cavati'},
    { stateCode: 'MG', label: 'Dom Joaquim'},
    { stateCode: 'MG', label: 'Dom Silvério'},
    { stateCode: 'MG', label: 'Dom Viçoso'},
    { stateCode: 'MG', label: 'Dona Eusébia'},
    { stateCode: 'MG', label: 'Dores de Campos'},
    { stateCode: 'MG', label: 'Dores de Guanhães'},
    { stateCode: 'MG', label: 'Dores do Indaiá'},
    { stateCode: 'MG', label: 'Dores do Turvo'},
    { stateCode: 'MG', label: 'Doresópolis'},
    { stateCode: 'MG', label: 'Douradoquara'},
    { stateCode: 'MG', label: 'Durandé'},
    { stateCode: 'MG', label: 'Elói Mendes'},
    { stateCode: 'MG', label: 'Engenheiro Caldas'},
    { stateCode: 'MG', label: 'Engenheiro Navarro'},
    { stateCode: 'MG', label: 'Entre Folhas'},
    { stateCode: 'MG', label: 'Entre Rios de Minas'},
    { stateCode: 'MG', label: 'Ervália'},
    { stateCode: 'MG', label: 'Esmeraldas'},
    { stateCode: 'MG', label: 'Espera Feliz'},
    { stateCode: 'MG', label: 'Espinosa'},
    { stateCode: 'MG', label: 'Espírito Santo do Dourado'},
    { stateCode: 'MG', label: 'Estiva'},
    { stateCode: 'MG', label: 'Estrela Dalva'},
    { stateCode: 'MG', label: 'Estrela do Indaiá'},
    { stateCode: 'MG', label: 'Estrela do Sul'},
    { stateCode: 'MG', label: 'Eugenópolis'},
    { stateCode: 'MG', label: 'Ewbank da Câmara'},
    { stateCode: 'MG', label: 'Extrema'},
    { stateCode: 'MG', label: 'Fama'},
    { stateCode: 'MG', label: 'Faria Lemos'},
    { stateCode: 'MG', label: 'Felício Dos Santos'},
    { stateCode: 'MG', label: 'Felisburgo'},
    { stateCode: 'MG', label: 'Felixlândia'},
    { stateCode: 'MG', label: 'Fernandes Tourinho'},
    { stateCode: 'MG', label: 'Ferros'},
    { stateCode: 'MG', label: 'Fervedouro'},
    { stateCode: 'MG', label: 'Florestal'},
    { stateCode: 'MG', label: 'Formiga'},
    { stateCode: 'MG', label: 'Formoso'},
    { stateCode: 'MG', label: 'Fortaleza de Minas'},
    { stateCode: 'MG', label: 'Fortuna de Minas'},
    { stateCode: 'MG', label: 'Francisco Badaró'},
    { stateCode: 'MG', label: 'Francisco Dumont'},
    { stateCode: 'MG', label: 'Francisco sá'},
    { stateCode: 'MG', label: 'Franciscópolis'},
    { stateCode: 'MG', label: 'Frei Gaspar'},
    { stateCode: 'MG', label: 'Frei Inocêncio'},
    { stateCode: 'MG', label: 'Frei Lagonegro'},
    { stateCode: 'MG', label: 'Fronteira'},
    { stateCode: 'MG', label: 'Fronteira Dos Vales'},
    { stateCode: 'MG', label: 'Fruta de Leite'},
    { stateCode: 'MG', label: 'Frutal'},
    { stateCode: 'MG', label: 'Funilândia'},
    { stateCode: 'MG', label: 'Galiléia'},
    { stateCode: 'MG', label: 'Gameleiras'},
    { stateCode: 'MG', label: 'Glaucilândia'},
    { stateCode: 'MG', label: 'Goiabeira'},
    { stateCode: 'MG', label: 'Goianá'},
    { stateCode: 'MG', label: 'Gonçalves'},
    { stateCode: 'MG', label: 'Gonzaga'},
    { stateCode: 'MG', label: 'Gouveia'},
    { stateCode: 'MG', label: 'Governador Valadares'},
    { stateCode: 'MG', label: 'Grão Mogol'},
    { stateCode: 'MG', label: 'Grupiara'},
    { stateCode: 'MG', label: 'Guanhães'},
    { stateCode: 'MG', label: 'Guapé'},
    { stateCode: 'MG', label: 'Guaraciaba'},
    { stateCode: 'MG', label: 'Guaraciama'},
    { stateCode: 'MG', label: 'Guaranésia'},
    { stateCode: 'MG', label: 'Guarani'},
    { stateCode: 'MG', label: 'Guarará'},
    { stateCode: 'MG', label: 'Guarda-mor'},
    { stateCode: 'MG', label: 'Guaxupé'},
    { stateCode: 'MG', label: 'Guidoval'},
    { stateCode: 'MG', label: 'Guimarânia'},
    { stateCode: 'MG', label: 'Guiricema'},
    { stateCode: 'MG', label: 'Gurinhatã'},
    { stateCode: 'MG', label: 'Heliodora'},
    { stateCode: 'MG', label: 'Iapu'},
    { stateCode: 'MG', label: 'Ibertioga'},
    { stateCode: 'MG', label: 'Ibiá'},
    { stateCode: 'MG', label: 'Ibiaí'},
    { stateCode: 'MG', label: 'Ibiracatu'},
    { stateCode: 'MG', label: 'Ibiraci'},
    { stateCode: 'MG', label: 'Ibirité'},
    { stateCode: 'MG', label: 'Ibitiúra de Minas'},
    { stateCode: 'MG', label: 'Ibituruna'},
    { stateCode: 'MG', label: 'Icaraí de Minas'},
    { stateCode: 'MG', label: 'Igarapé'},
    { stateCode: 'MG', label: 'Igaratinga'},
    { stateCode: 'MG', label: 'Iguatama'},
    { stateCode: 'MG', label: 'Ijaci'},
    { stateCode: 'MG', label: 'Ilicínea'},
    { stateCode: 'MG', label: 'Imbé de Minas'},
    { stateCode: 'MG', label: 'Inconfidentes'},
    { stateCode: 'MG', label: 'Indaiabira'},
    { stateCode: 'MG', label: 'Indianópolis'},
    { stateCode: 'MG', label: 'Ingaí'},
    { stateCode: 'MG', label: 'Inhapim'},
    { stateCode: 'MG', label: 'Inhaúma'},
    { stateCode: 'MG', label: 'Inimutaba'},
    { stateCode: 'MG', label: 'Ipaba'},
    { stateCode: 'MG', label: 'Ipanema'},
    { stateCode: 'MG', label: 'Ipatinga'},
    { stateCode: 'MG', label: 'Ipiaçu'},
    { stateCode: 'MG', label: 'Ipuiúna'},
    { stateCode: 'MG', label: 'Iraí de Minas'},
    { stateCode: 'MG', label: 'Itabira'},
    { stateCode: 'MG', label: 'Itabirinha'},
    { stateCode: 'MG', label: 'Itabirito'},
    { stateCode: 'MG', label: 'Itacambira'},
    { stateCode: 'MG', label: 'Itacarambi'},
    { stateCode: 'MG', label: 'Itaguara'},
    { stateCode: 'MG', label: 'Itaipé'},
    { stateCode: 'MG', label: 'Itajubá'},
    { stateCode: 'MG', label: 'Itamarandiba'},
    { stateCode: 'MG', label: 'Itamarati de Minas'},
    { stateCode: 'MG', label: 'Itambacuri'},
    { stateCode: 'MG', label: 'Itambé do Mato Dentro'},
    { stateCode: 'MG', label: 'Itamogi'},
    { stateCode: 'MG', label: 'Itamonte'},
    { stateCode: 'MG', label: 'Itanhandu'},
    { stateCode: 'MG', label: 'Itanhomi'},
    { stateCode: 'MG', label: 'Itaobim'},
    { stateCode: 'MG', label: 'Itapagipe'},
    { stateCode: 'MG', label: 'Itapecerica'},
    { stateCode: 'MG', label: 'Itapeva'},
    { stateCode: 'MG', label: 'Itatiaiuçu'},
    { stateCode: 'MG', label: 'Itaú de Minas'},
    { stateCode: 'MG', label: 'Itaúna'},
    { stateCode: 'MG', label: 'Itaverava'},
    { stateCode: 'MG', label: 'Itinga'},
    { stateCode: 'MG', label: 'Itueta'},
    { stateCode: 'MG', label: 'Ituiutaba'},
    { stateCode: 'MG', label: 'Itumirim'},
    { stateCode: 'MG', label: 'Iturama'},
    { stateCode: 'MG', label: 'Itutinga'},
    { stateCode: 'MG', label: 'Jaboticatubas'},
    { stateCode: 'MG', label: 'Jacinto'},
    { stateCode: 'MG', label: 'Jacuí'},
    { stateCode: 'MG', label: 'Jacutinga'},
    { stateCode: 'MG', label: 'Jaguaraçu'},
    { stateCode: 'MG', label: 'Jaíba'},
    { stateCode: 'MG', label: 'Jampruca'},
    { stateCode: 'MG', label: 'Janaúba'},
    { stateCode: 'MG', label: 'Januária'},
    { stateCode: 'MG', label: 'Japaraíba'},
    { stateCode: 'MG', label: 'Japonvar'},
    { stateCode: 'MG', label: 'Jeceaba'},
    { stateCode: 'MG', label: 'Jenipapo de Minas'},
    { stateCode: 'MG', label: 'Jequeri'},
    { stateCode: 'MG', label: 'Jequitaí'},
    { stateCode: 'MG', label: 'Jequitibá'},
    { stateCode: 'MG', label: 'Jequitinhonha'},
    { stateCode: 'MG', label: 'Jesuânia'},
    { stateCode: 'MG', label: 'Joaíma'},
    { stateCode: 'MG', label: 'Joanésia'},
    { stateCode: 'MG', label: 'João Monlevade'},
    { stateCode: 'MG', label: 'João Pinheiro'},
    { stateCode: 'MG', label: 'Joaquim Felício'},
    { stateCode: 'MG', label: 'Jordânia'},
    { stateCode: 'MG', label: 'José Gonçalves de Minas'},
    { stateCode: 'MG', label: 'José Raydan'},
    { stateCode: 'MG', label: 'Josenópolis'},
    { stateCode: 'MG', label: 'Juatuba'},
    { stateCode: 'MG', label: 'Juiz de Fora'},
    { stateCode: 'MG', label: 'Juramento'},
    { stateCode: 'MG', label: 'Juruaia'},
    { stateCode: 'MG', label: 'Juvenília'},
    { stateCode: 'MG', label: 'Ladainha'},
    { stateCode: 'MG', label: 'Lagamar'},
    { stateCode: 'MG', label: 'Lagoa da Prata'},
    { stateCode: 'MG', label: 'Lagoa Dos Patos'},
    { stateCode: 'MG', label: 'Lagoa Dourada'},
    { stateCode: 'MG', label: 'Lagoa Formosa'},
    { stateCode: 'MG', label: 'Lagoa Grande'},
    { stateCode: 'MG', label: 'Lagoa Santa'},
    { stateCode: 'MG', label: 'Lajinha'},
    { stateCode: 'MG', label: 'Lambari'},
    { stateCode: 'MG', label: 'Lamim'},
    { stateCode: 'MG', label: 'Laranjal'},
    { stateCode: 'MG', label: 'Lassance'},
    { stateCode: 'MG', label: 'Lavras'},
    { stateCode: 'MG', label: 'Leandro Ferreira'},
    { stateCode: 'MG', label: 'Leme do Prado'},
    { stateCode: 'MG', label: 'Leopoldina'},
    { stateCode: 'MG', label: 'Liberdade'},
    { stateCode: 'MG', label: 'Lima Duarte'},
    { stateCode: 'MG', label: 'Limeira do Oeste'},
    { stateCode: 'MG', label: 'Lontra'},
    { stateCode: 'MG', label: 'Luisburgo'},
    { stateCode: 'MG', label: 'Luislândia'},
    { stateCode: 'MG', label: 'Luminárias'},
    { stateCode: 'MG', label: 'Luz'},
    { stateCode: 'MG', label: 'Machacalis'},
    { stateCode: 'MG', label: 'Machado'},
    { stateCode: 'MG', label: 'Madre de Deus de Minas'},
    { stateCode: 'MG', label: 'Malacacheta'},
    { stateCode: 'MG', label: 'Mamonas'},
    { stateCode: 'MG', label: 'Manga'},
    { stateCode: 'MG', label: 'Manhuaçu'},
    { stateCode: 'MG', label: 'Manhumirim'},
    { stateCode: 'MG', label: 'Mantena'},
    { stateCode: 'MG', label: 'Mar de Espanha'},
    { stateCode: 'MG', label: 'Maravilhas'},
    { stateCode: 'MG', label: 'Maria da fé'},
    { stateCode: 'MG', label: 'Mariana'},
    { stateCode: 'MG', label: 'Marilac'},
    { stateCode: 'MG', label: 'Mário Campos'},
    { stateCode: 'MG', label: 'Maripá de Minas'},
    { stateCode: 'MG', label: 'Marliéria'},
    { stateCode: 'MG', label: 'Marmelópolis'},
    { stateCode: 'MG', label: 'Martinho Campos'},
    { stateCode: 'MG', label: 'Martins Soares'},
    { stateCode: 'MG', label: 'Mata Verde'},
    { stateCode: 'MG', label: 'Materlândia'},
    { stateCode: 'MG', label: 'Mateus Leme'},
    { stateCode: 'MG', label: 'Mathias Lobato'},
    { stateCode: 'MG', label: 'Matias Barbosa'},
    { stateCode: 'MG', label: 'Matias Cardoso'},
    { stateCode: 'MG', label: 'Matipó'},
    { stateCode: 'MG', label: 'Mato Verde'},
    { stateCode: 'MG', label: 'Matozinhos'},
    { stateCode: 'MG', label: 'Matutina'},
    { stateCode: 'MG', label: 'Medeiros'},
    { stateCode: 'MG', label: 'Medina'},
    { stateCode: 'MG', label: 'Mendes Pimentel'},
    { stateCode: 'MG', label: 'Mercês'},
    { stateCode: 'MG', label: 'Mesquita'},
    { stateCode: 'MG', label: 'Minas Novas'},
    { stateCode: 'MG', label: 'Minduri'},
    { stateCode: 'MG', label: 'Mirabela'},
    { stateCode: 'MG', label: 'Miradouro'},
    { stateCode: 'MG', label: 'Miraí'},
    { stateCode: 'MG', label: 'Miravânia'},
    { stateCode: 'MG', label: 'Moeda'},
    { stateCode: 'MG', label: 'Moema'},
    { stateCode: 'MG', label: 'Monjolos'},
    { stateCode: 'MG', label: 'Monsenhor Paulo'},
    { stateCode: 'MG', label: 'Montalvânia'},
    { stateCode: 'MG', label: 'Monte Alegre de Minas'},
    { stateCode: 'MG', label: 'Monte Azul'},
    { stateCode: 'MG', label: 'Monte Belo'},
    { stateCode: 'MG', label: 'Monte Carmelo'},
    { stateCode: 'MG', label: 'Monte Formoso'},
    { stateCode: 'MG', label: 'Monte Santo de Minas'},
    { stateCode: 'MG', label: 'Monte Sião'},
    { stateCode: 'MG', label: 'Montes Claros'},
    { stateCode: 'MG', label: 'Montezuma'},
    { stateCode: 'MG', label: 'Morada Nova de Minas'},
    { stateCode: 'MG', label: 'Morro da Garça'},
    { stateCode: 'MG', label: 'Morro do Pilar'},
    { stateCode: 'MG', label: 'Munhoz'},
    { stateCode: 'MG', label: 'Muriaé'},
    { stateCode: 'MG', label: 'Mutum'},
    { stateCode: 'MG', label: 'Muzambinho'},
    { stateCode: 'MG', label: 'Nacip Raydan'},
    { stateCode: 'MG', label: 'Nanuque'},
    { stateCode: 'MG', label: 'Naque'},
    { stateCode: 'MG', label: 'Natalândia'},
    { stateCode: 'MG', label: 'Natércia'},
    { stateCode: 'MG', label: 'Nazareno'},
    { stateCode: 'MG', label: 'Nepomuceno'},
    { stateCode: 'MG', label: 'Ninheira'},
    { stateCode: 'MG', label: 'Nova Belém'},
    { stateCode: 'MG', label: 'Nova Era'},
    { stateCode: 'MG', label: 'Nova Lima'},
    { stateCode: 'MG', label: 'Nova Módica'},
    { stateCode: 'MG', label: 'Nova Ponte'},
    { stateCode: 'MG', label: 'Nova Porteirinha'},
    { stateCode: 'MG', label: 'Nova Resende'},
    { stateCode: 'MG', label: 'Nova Serrana'},
    { stateCode: 'MG', label: 'Nova União'},
    { stateCode: 'MG', label: 'Novo Cruzeiro'},
    { stateCode: 'MG', label: 'Novo Oriente de Minas'},
    { stateCode: 'MG', label: 'Novorizonte'},
    { stateCode: 'MG', label: 'Olaria'},
    { stateCode: 'MG', label: 'Olhos-d´água'},
    { stateCode: 'MG', label: 'Olímpio Noronha'},
    { stateCode: 'MG', label: 'Oliveira'},
    { stateCode: 'MG', label: 'Oliveira Fortes'},
    { stateCode: 'MG', label: 'Onça de Pitangui'},
    { stateCode: 'MG', label: 'Oratórios'},
    { stateCode: 'MG', label: 'Orizânia'},
    { stateCode: 'MG', label: 'Ouro Branco'},
    { stateCode: 'MG', label: 'Ouro Fino'},
    { stateCode: 'MG', label: 'Ouro Preto'},
    { stateCode: 'MG', label: 'Ouro Verde de Minas'},
    { stateCode: 'MG', label: 'Padre Carvalho'},
    { stateCode: 'MG', label: 'Padre Paraíso'},
    { stateCode: 'MG', label: 'Pai Pedro'},
    { stateCode: 'MG', label: 'Paineiras'},
    { stateCode: 'MG', label: 'Pains'},
    { stateCode: 'MG', label: 'Paiva'},
    { stateCode: 'MG', label: 'Palma'},
    { stateCode: 'MG', label: 'Palmópolis'},
    { stateCode: 'MG', label: 'Papagaios'},
    { stateCode: 'MG', label: 'Pará de Minas'},
    { stateCode: 'MG', label: 'Paracatu'},
    { stateCode: 'MG', label: 'Paraguaçu'},
    { stateCode: 'MG', label: 'Paraisópolis'},
    { stateCode: 'MG', label: 'Paraopeba'},
    { stateCode: 'MG', label: 'Passa Quatro'},
    { stateCode: 'MG', label: 'Passa Tempo'},
    { stateCode: 'MG', label: 'Passa-vinte'},
    { stateCode: 'MG', label: 'Passabém'},
    { stateCode: 'MG', label: 'Passos'},
    { stateCode: 'MG', label: 'Patis'},
    { stateCode: 'MG', label: 'Patos de Minas'},
    { stateCode: 'MG', label: 'Patrocínio'},
    { stateCode: 'MG', label: 'Patrocínio do Muriaé'},
    { stateCode: 'MG', label: 'Paula Cândido'},
    { stateCode: 'MG', label: 'Paulistas'},
    { stateCode: 'MG', label: 'Pavão'},
    { stateCode: 'MG', label: 'Peçanha'},
    { stateCode: 'MG', label: 'Pedra Azul'},
    { stateCode: 'MG', label: 'Pedra Bonita'},
    { stateCode: 'MG', label: 'Pedra do Anta'},
    { stateCode: 'MG', label: 'Pedra do Indaiá'},
    { stateCode: 'MG', label: 'Pedra Dourada'},
    { stateCode: 'MG', label: 'Pedralva'},
    { stateCode: 'MG', label: 'Pedras de Maria da Cruz'},
    { stateCode: 'MG', label: 'Pedrinópolis'},
    { stateCode: 'MG', label: 'Pedro Leopoldo'},
    { stateCode: 'MG', label: 'Pedro Teixeira'},
    { stateCode: 'MG', label: 'Pequeri'},
    { stateCode: 'MG', label: 'Pequi'},
    { stateCode: 'MG', label: 'Perdigão'},
    { stateCode: 'MG', label: 'Perdizes'},
    { stateCode: 'MG', label: 'Perdões'},
    { stateCode: 'MG', label: 'Periquito'},
    { stateCode: 'MG', label: 'Pescador'},
    { stateCode: 'MG', label: 'Piau'},
    { stateCode: 'MG', label: 'Piedade de Caratinga'},
    { stateCode: 'MG', label: 'Piedade de Ponte Nova'},
    { stateCode: 'MG', label: 'Piedade do Rio Grande'},
    { stateCode: 'MG', label: 'Piedade Dos Gerais'},
    { stateCode: 'MG', label: 'Pimenta'},
    { stateCode: 'MG', label: 'Pingo-d´água'},
    { stateCode: 'MG', label: 'Pintópolis'},
    { stateCode: 'MG', label: 'Piracema'},
    { stateCode: 'MG', label: 'Pirajuba'},
    { stateCode: 'MG', label: 'Piranga'},
    { stateCode: 'MG', label: 'Piranguçu'},
    { stateCode: 'MG', label: 'Piranguinho'},
    { stateCode: 'MG', label: 'Pirapetinga'},
    { stateCode: 'MG', label: 'Pirapora'},
    { stateCode: 'MG', label: 'Piraúba'},
    { stateCode: 'MG', label: 'Pitangui'},
    { stateCode: 'MG', label: 'Piumhi'},
    { stateCode: 'MG', label: 'Planura'},
    { stateCode: 'MG', label: 'Poço Fundo'},
    { stateCode: 'MG', label: 'Poços de Caldas'},
    { stateCode: 'MG', label: 'Pocrane'},
    { stateCode: 'MG', label: 'Pompéu'},
    { stateCode: 'MG', label: 'Ponte Nova'},
    { stateCode: 'MG', label: 'Ponto Chique'},
    { stateCode: 'MG', label: 'Ponto Dos Volantes'},
    { stateCode: 'MG', label: 'Porteirinha'},
    { stateCode: 'MG', label: 'Porto Firme'},
    { stateCode: 'MG', label: 'Poté'},
    { stateCode: 'MG', label: 'Pouso Alegre'},
    { stateCode: 'MG', label: 'Pouso Alto'},
    { stateCode: 'MG', label: 'Prados'},
    { stateCode: 'MG', label: 'Prata'},
    { stateCode: 'MG', label: 'Pratápolis'},
    { stateCode: 'MG', label: 'Pratinha'},
    { stateCode: 'MG', label: 'Presidente Bernardes'},
    { stateCode: 'MG', label: 'Presidente Juscelino'},
    { stateCode: 'MG', label: 'Presidente Kubitschek'},
    { stateCode: 'MG', label: 'Presidente Olegário'},
    { stateCode: 'MG', label: 'Prudente de Morais'},
    { stateCode: 'MG', label: 'Quartel Geral'},
    { stateCode: 'MG', label: 'Queluzito'},
    { stateCode: 'MG', label: 'Raposos'},
    { stateCode: 'MG', label: 'Raul Soares'},
    { stateCode: 'MG', label: 'Recreio'},
    { stateCode: 'MG', label: 'Reduto'},
    { stateCode: 'MG', label: 'Resende Costa'},
    { stateCode: 'MG', label: 'Resplendor'},
    { stateCode: 'MG', label: 'Ressaquinha'},
    { stateCode: 'MG', label: 'Riachinho'},
    { stateCode: 'MG', label: 'Riacho Dos Machados'},
    { stateCode: 'MG', label: 'Ribeirão Das Neves'},
    { stateCode: 'MG', label: 'Ribeirão Vermelho'},
    { stateCode: 'MG', label: 'Rio Acima'},
    { stateCode: 'MG', label: 'Rio Casca'},
    { stateCode: 'MG', label: 'Rio do Prado'},
    { stateCode: 'MG', label: 'Rio Doce'},
    { stateCode: 'MG', label: 'Rio Espera'},
    { stateCode: 'MG', label: 'Rio Manso'},
    { stateCode: 'MG', label: 'Rio Novo'},
    { stateCode: 'MG', label: 'Rio Paranaíba'},
    { stateCode: 'MG', label: 'Rio Pardo de Minas'},
    { stateCode: 'MG', label: 'Rio Piracicaba'},
    { stateCode: 'MG', label: 'Rio Pomba'},
    { stateCode: 'MG', label: 'Rio Preto'},
    { stateCode: 'MG', label: 'Rio Vermelho'},
    { stateCode: 'MG', label: 'Ritápolis'},
    { stateCode: 'MG', label: 'Rochedo de Minas'},
    { stateCode: 'MG', label: 'Rodeiro'},
    { stateCode: 'MG', label: 'Romaria'},
    { stateCode: 'MG', label: 'Rosário da Limeira'},
    { stateCode: 'MG', label: 'Rubelita'},
    { stateCode: 'MG', label: 'Rubim'},
    { stateCode: 'MG', label: 'Sabará'},
    { stateCode: 'MG', label: 'Sabinópolis'},
    { stateCode: 'MG', label: 'Sacramento'},
    { stateCode: 'MG', label: 'Salinas'},
    { stateCode: 'MG', label: 'Salto da Divisa'},
    { stateCode: 'MG', label: 'Santa Bárbara'},
    { stateCode: 'MG', label: 'Santa Bárbara do Leste'},
    { stateCode: 'MG', label: 'Santa Bárbara do Monte Verde'},
    { stateCode: 'MG', label: 'Santa Bárbara do Tugúrio'},
    { stateCode: 'MG', label: 'Santa Cruz de Minas'},
    { stateCode: 'MG', label: 'Santa Cruz de Salinas'},
    { stateCode: 'MG', label: 'Santa Cruz do Escalvado'},
    { stateCode: 'MG', label: 'Santa Efigênia de Minas'},
    { stateCode: 'MG', label: 'Santa fé de Minas'},
    { stateCode: 'MG', label: 'Santa Helena de Minas'},
    { stateCode: 'MG', label: 'Santa Juliana'},
    { stateCode: 'MG', label: 'Santa Luzia'},
    { stateCode: 'MG', label: 'Santa Margarida'},
    { stateCode: 'MG', label: 'Santa Maria de Itabira'},
    { stateCode: 'MG', label: 'Santa Maria do Salto'},
    { stateCode: 'MG', label: 'Santa Maria do Suaçuí'},
    { stateCode: 'MG', label: 'Santa Rita de Caldas'},
    { stateCode: 'MG', label: 'Santa Rita de Ibitipoca'},
    { stateCode: 'MG', label: 'Santa Rita de Jacutinga'},
    { stateCode: 'MG', label: 'Santa Rita de Minas'},
    { stateCode: 'MG', label: 'Santa Rita do Itueto'},
    { stateCode: 'MG', label: 'Santa Rita do Sapucaí'},
    { stateCode: 'MG', label: 'Santa Rosa da Serra'},
    { stateCode: 'MG', label: 'Santa Vitória'},
    { stateCode: 'MG', label: 'Santana da Vargem'},
    { stateCode: 'MG', label: 'Santana de Cataguases'},
    { stateCode: 'MG', label: 'Santana de Pirapama'},
    { stateCode: 'MG', label: 'Santana do Deserto'},
    { stateCode: 'MG', label: 'Santana do Garambéu'},
    { stateCode: 'MG', label: 'Santana do Jacaré'},
    { stateCode: 'MG', label: 'Santana do Manhuaçu'},
    { stateCode: 'MG', label: 'Santana do Paraíso'},
    { stateCode: 'MG', label: 'Santana do Riacho'},
    { stateCode: 'MG', label: 'Santana Dos Montes'},
    { stateCode: 'MG', label: 'Santo Antônio do Amparo'},
    { stateCode: 'MG', label: 'Santo Antônio do Aventureiro'},
    { stateCode: 'MG', label: 'Santo Antônio do Grama'},
    { stateCode: 'MG', label: 'Santo Antônio do Itambé'},
    { stateCode: 'MG', label: 'Santo Antônio do Jacinto'},
    { stateCode: 'MG', label: 'Santo Antônio do Monte'},
    { stateCode: 'MG', label: 'Santo Antônio do Retiro'},
    { stateCode: 'MG', label: 'Santo Antônio do Rio Abaixo'},
    { stateCode: 'MG', label: 'Santo Hipólito'},
    { stateCode: 'MG', label: 'Santos Dumont'},
    { stateCode: 'MG', label: 'São Bento Abade'},
    { stateCode: 'MG', label: 'São Brás do Suaçuí'},
    { stateCode: 'MG', label: 'São Domingos Das Dores'},
    { stateCode: 'MG', label: 'São Domingos do Prata'},
    { stateCode: 'MG', label: 'São Félix de Minas'},
    { stateCode: 'MG', label: 'São Francisco'},
    { stateCode: 'MG', label: 'São Francisco de Paula'},
    { stateCode: 'MG', label: 'São Francisco de Sales'},
    { stateCode: 'MG', label: 'São Francisco do Glória'},
    { stateCode: 'MG', label: 'São Geraldo'},
    { stateCode: 'MG', label: 'São Geraldo da Piedade'},
    { stateCode: 'MG', label: 'São Geraldo do Baixio'},
    { stateCode: 'MG', label: 'São Gonçalo do Abaeté'},
    { stateCode: 'MG', label: 'São Gonçalo do Pará'},
    { stateCode: 'MG', label: 'São Gonçalo do Rio Abaixo'},
    { stateCode: 'MG', label: 'São Gonçalo do Rio Preto'},
    { stateCode: 'MG', label: 'São Gonçalo do Sapucaí'},
    { stateCode: 'MG', label: 'São Gotardo'},
    { stateCode: 'MG', label: 'São João Batista do Glória'},
    { stateCode: 'MG', label: 'São João da Lagoa'},
    { stateCode: 'MG', label: 'São João da Mata'},
    { stateCode: 'MG', label: 'São João da Ponte'},
    { stateCode: 'MG', label: 'São João Das Missões'},
    { stateCode: 'MG', label: 'São João Del Rei'},
    { stateCode: 'MG', label: 'São João do Manhuaçu'},
    { stateCode: 'MG', label: 'São João do Manteninha'},
    { stateCode: 'MG', label: 'São João do Oriente'},
    { stateCode: 'MG', label: 'São João do Pacuí'},
    { stateCode: 'MG', label: 'São João do Paraíso'},
    { stateCode: 'MG', label: 'São João Evangelista'},
    { stateCode: 'MG', label: 'São João Nepomuceno'},
    { stateCode: 'MG', label: 'São Joaquim de Bicas'},
    { stateCode: 'MG', label: 'São José da Barra'},
    { stateCode: 'MG', label: 'São José da Lapa'},
    { stateCode: 'MG', label: 'São José da Safira'},
    { stateCode: 'MG', label: 'São José da Varginha'},
    { stateCode: 'MG', label: 'São José do Alegre'},
    { stateCode: 'MG', label: 'São José do Divino'},
    { stateCode: 'MG', label: 'São José do Goiabal'},
    { stateCode: 'MG', label: 'São José do Jacuri'},
    { stateCode: 'MG', label: 'São José do Mantimento'},
    { stateCode: 'MG', label: 'São Lourenço'},
    { stateCode: 'MG', label: 'São Miguel do Anta'},
    { stateCode: 'MG', label: 'São Pedro da União'},
    { stateCode: 'MG', label: 'São Pedro do Suaçuí'},
    { stateCode: 'MG', label: 'São Pedro Dos Ferros'},
    { stateCode: 'MG', label: 'São Romão'},
    { stateCode: 'MG', label: 'São Roque de Minas'},
    { stateCode: 'MG', label: 'São Sebastião da Bela Vista'},
    { stateCode: 'MG', label: 'São Sebastião da Vargem Alegre'},
    { stateCode: 'MG', label: 'São Sebastião do Anta'},
    { stateCode: 'MG', label: 'São Sebastião do Maranhão'},
    { stateCode: 'MG', label: 'São Sebastião do Oeste'},
    { stateCode: 'MG', label: 'São Sebastião do Paraíso'},
    { stateCode: 'MG', label: 'São Sebastião do Rio Preto'},
    { stateCode: 'MG', label: 'São Sebastião do Rio Verde'},
    { stateCode: 'MG', label: 'São Thomé Das Letras'},
    { stateCode: 'MG', label: 'São Tiago'},
    { stateCode: 'MG', label: 'São Tomás de Aquino'},
    { stateCode: 'MG', label: 'São Vicente de Minas'},
    { stateCode: 'MG', label: 'Sapucaí-mirim'},
    { stateCode: 'MG', label: 'Sardoá'},
    { stateCode: 'MG', label: 'Sarzedo'},
    { stateCode: 'MG', label: 'Sem-peixe'},
    { stateCode: 'MG', label: 'Senador Amaral'},
    { stateCode: 'MG', label: 'Senador Cortes'},
    { stateCode: 'MG', label: 'Senador Firmino'},
    { stateCode: 'MG', label: 'Senador José Bento'},
    { stateCode: 'MG', label: 'Senador Modestino Gonçalves'},
    { stateCode: 'MG', label: 'Senhora de Oliveira'},
    { stateCode: 'MG', label: 'Senhora do Porto'},
    { stateCode: 'MG', label: 'Senhora Dos Remédios'},
    { stateCode: 'MG', label: 'Sericita'},
    { stateCode: 'MG', label: 'Seritinga'},
    { stateCode: 'MG', label: 'Serra Azul de Minas'},
    { stateCode: 'MG', label: 'Serra da Saudade'},
    { stateCode: 'MG', label: 'Serra do Salitre'},
    { stateCode: 'MG', label: 'Serra Dos Aimorés'},
    { stateCode: 'MG', label: 'Serrania'},
    { stateCode: 'MG', label: 'Serranópolis de Minas'},
    { stateCode: 'MG', label: 'Serranos'},
    { stateCode: 'MG', label: 'Serro'},
    { stateCode: 'MG', label: 'Sete Lagoas'},
    { stateCode: 'MG', label: 'Setubinha'},
    { stateCode: 'MG', label: 'Silveirânia'},
    { stateCode: 'MG', label: 'Silvianópolis'},
    { stateCode: 'MG', label: 'Simão Pereira'},
    { stateCode: 'MG', label: 'Simonésia'},
    { stateCode: 'MG', label: 'Sobrália'},
    { stateCode: 'MG', label: 'Soledade de Minas'},
    { stateCode: 'MG', label: 'Tabuleiro'},
    { stateCode: 'MG', label: 'Taiobeiras'},
    { stateCode: 'MG', label: 'Taparuba'},
    { stateCode: 'MG', label: 'Tapira'},
    { stateCode: 'MG', label: 'Tapiraí'},
    { stateCode: 'MG', label: 'Taquaraçu de Minas'},
    { stateCode: 'MG', label: 'Tarumirim'},
    { stateCode: 'MG', label: 'Teixeiras'},
    { stateCode: 'MG', label: 'Teófilo Otoni'},
    { stateCode: 'MG', label: 'Timóteo'},
    { stateCode: 'MG', label: 'Tiradentes'},
    { stateCode: 'MG', label: 'Tiros'},
    { stateCode: 'MG', label: 'Tocantins'},
    { stateCode: 'MG', label: 'Tocos do Moji'},
    { stateCode: 'MG', label: 'Toledo'},
    { stateCode: 'MG', label: 'Tombos'},
    { stateCode: 'MG', label: 'Três Corações'},
    { stateCode: 'MG', label: 'Três Marias'},
    { stateCode: 'MG', label: 'Três Pontas'},
    { stateCode: 'MG', label: 'Tumiritinga'},
    { stateCode: 'MG', label: 'Tupaciguara'},
    { stateCode: 'MG', label: 'Turmalina'},
    { stateCode: 'MG', label: 'Turvolândia'},
    { stateCode: 'MG', label: 'Ubá'},
    { stateCode: 'MG', label: 'Ubaí'},
    { stateCode: 'MG', label: 'Ubaporanga'},
    { stateCode: 'MG', label: 'Uberaba'},
    { stateCode: 'MG', label: 'Uberlândia'},
    { stateCode: 'MG', label: 'Umburatiba'},
    { stateCode: 'MG', label: 'Unaí'},
    { stateCode: 'MG', label: 'União de Minas'},
    { stateCode: 'MG', label: 'Uruana de Minas'},
    { stateCode: 'MG', label: 'Urucânia'},
    { stateCode: 'MG', label: 'Urucuia'},
    { stateCode: 'MG', label: 'Vargem Alegre'},
    { stateCode: 'MG', label: 'Vargem Bonita'},
    { stateCode: 'MG', label: 'Vargem Grande do Rio Pardo'},
    { stateCode: 'MG', label: 'Varginha'},
    { stateCode: 'MG', label: 'Varjão de Minas'},
    { stateCode: 'MG', label: 'Várzea da Palma'},
    { stateCode: 'MG', label: 'Varzelândia'},
    { stateCode: 'MG', label: 'Vazante'},
    { stateCode: 'MG', label: 'Verdelândia'},
    { stateCode: 'MG', label: 'Veredinha'},
    { stateCode: 'MG', label: 'Veríssimo'},
    { stateCode: 'MG', label: 'Vermelho Novo'},
    { stateCode: 'MG', label: 'Vespasiano'},
    { stateCode: 'MG', label: 'Viçosa'},
    { stateCode: 'MG', label: 'Vieiras'},
    { stateCode: 'MG', label: 'Virgem da Lapa'},
    { stateCode: 'MG', label: 'Virgínia'},
    { stateCode: 'MG', label: 'Virginópolis'},
    { stateCode: 'MG', label: 'Virgolândia'},
    { stateCode: 'MG', label: 'Visconde do Rio Branco'},
    { stateCode: 'MG', label: 'Volta Grande'},
    { stateCode: 'MG', label: 'Wenceslau Braz'},
    { stateCode: 'MS', label: 'Água Clara'},
    { stateCode: 'MS', label: 'Alcinópolis'},
    { stateCode: 'MS', label: 'Amambaí'},
    { stateCode: 'MS', label: 'Anastácio'},
    { stateCode: 'MS', label: 'Anaurilândia'},
    { stateCode: 'MS', label: 'Angélica'},
    { stateCode: 'MS', label: 'Antônio João'},
    { stateCode: 'MS', label: 'Aparecida do Taboado'},
    { stateCode: 'MS', label: 'Aquidauana'},
    { stateCode: 'MS', label: 'Aral Moreira'},
    { stateCode: 'MS', label: 'Bandeirantes'},
    { stateCode: 'MS', label: 'Bataguassu'},
    { stateCode: 'MS', label: 'Batayporã'},
    { stateCode: 'MS', label: 'Bela Vista'},
    { stateCode: 'MS', label: 'Bodoquena'},
    { stateCode: 'MS', label: 'Bonito'},
    { stateCode: 'MS', label: 'Brasilândia'},
    { stateCode: 'MS', label: 'Caarapó'},
    { stateCode: 'MS', label: 'Camapuã'},
    { stateCode: 'MS', label: 'Campo Grande'},
    { stateCode: 'MS', label: 'Caracol'},
    { stateCode: 'MS', label: 'Cassilândia'},
    { stateCode: 'MS', label: 'Chapadão do Sul'},
    { stateCode: 'MS', label: 'Corguinho'},
    { stateCode: 'MS', label: 'Coronel Sapucaia'},
    { stateCode: 'MS', label: 'Corumbá'},
    { stateCode: 'MS', label: 'Costa Rica'},
    { stateCode: 'MS', label: 'Coxim'},
    { stateCode: 'MS', label: 'Deodápolis'},
    { stateCode: 'MS', label: 'Dois Irmãos do Buriti'},
    { stateCode: 'MS', label: 'Douradina'},
    { stateCode: 'MS', label: 'Dourados'},
    { stateCode: 'MS', label: 'Eldorado'},
    { stateCode: 'MS', label: 'Fátima do Sul'},
    { stateCode: 'MS', label: 'Figueirão'},
    { stateCode: 'MS', label: 'Glória de Dourados'},
    { stateCode: 'MS', label: 'Guia Lopes da Laguna'},
    { stateCode: 'MS', label: 'Iguatemi'},
    { stateCode: 'MS', label: 'Inocência'},
    { stateCode: 'MS', label: 'Itaporã'},
    { stateCode: 'MS', label: 'Itaquiraí'},
    { stateCode: 'MS', label: 'Ivinhema'},
    { stateCode: 'MS', label: 'Japorã'},
    { stateCode: 'MS', label: 'Jaraguari'},
    { stateCode: 'MS', label: 'Jardim'},
    { stateCode: 'MS', label: 'Jateí'},
    { stateCode: 'MS', label: 'Juti'},
    { stateCode: 'MS', label: 'Ladário'},
    { stateCode: 'MS', label: 'Laguna Carapã'},
    { stateCode: 'MS', label: 'Maracaju'},
    { stateCode: 'MS', label: 'Miranda'},
    { stateCode: 'MS', label: 'Mundo Novo'},
    { stateCode: 'MS', label: 'Naviraí'},
    { stateCode: 'MS', label: 'Nioaque'},
    { stateCode: 'MS', label: 'Nova Alvorada do Sul'},
    { stateCode: 'MS', label: 'Nova Andradina'},
    { stateCode: 'MS', label: 'Novo Horizonte do Sul'},
    { stateCode: 'MS', label: 'Paraíso das Águas'},
    { stateCode: 'MS', label: 'Paranaíba'},
    { stateCode: 'MS', label: 'Paranhos'},
    { stateCode: 'MS', label: 'Pedro Gomes'},
    { stateCode: 'MS', label: 'Ponta Porã'},
    { stateCode: 'MS', label: 'Porto Murtinho'},
    { stateCode: 'MS', label: 'Ribas do Rio Pardo'},
    { stateCode: 'MS', label: 'Rio Brilhante'},
    { stateCode: 'MS', label: 'Rio Negro'},
    { stateCode: 'MS', label: 'Rio Verde de Mato Grosso'},
    { stateCode: 'MS', label: 'Rochedo'},
    { stateCode: 'MS', label: 'Santa Rita do Pardo'},
    { stateCode: 'MS', label: 'São Gabriel do Oeste'},
    { stateCode: 'MS', label: 'Selvíria'},
    { stateCode: 'MS', label: 'Sete Quedas'},
    { stateCode: 'MS', label: 'Sidrolândia'},
    { stateCode: 'MS', label: 'Sonora'},
    { stateCode: 'MS', label: 'Tacuru'},
    { stateCode: 'MS', label: 'Taquarussu'},
    { stateCode: 'MS', label: 'Terenos'},
    { stateCode: 'MS', label: 'Três Lagoas'},
    { stateCode: 'MS', label: 'Vicentina'},
    { stateCode: 'MT', label: 'Acorizal'},
    { stateCode: 'MT', label: 'Água Boa'},
    { stateCode: 'MT', label: 'Alta Floresta'},
    { stateCode: 'MT', label: 'Alto Araguaia'},
    { stateCode: 'MT', label: 'Alto Boa Vista'},
    { stateCode: 'MT', label: 'Alto Garças'},
    { stateCode: 'MT', label: 'Alto Paraguai'},
    { stateCode: 'MT', label: 'Alto Taquari'},
    { stateCode: 'MT', label: 'Apiacás'},
    { stateCode: 'MT', label: 'Araguaiana'},
    { stateCode: 'MT', label: 'Araguainha'},
    { stateCode: 'MT', label: 'Araputanga'},
    { stateCode: 'MT', label: 'Arenápolis'},
    { stateCode: 'MT', label: 'Aripuanã'},
    { stateCode: 'MT', label: 'Barão de Melgaço'},
    { stateCode: 'MT', label: 'Barra do Bugres'},
    { stateCode: 'MT', label: 'Barra do Garças'},
    { stateCode: 'MT', label: 'Bom Jesus do Araguaia'},
    { stateCode: 'MT', label: 'Brasnorte'},
    { stateCode: 'MT', label: 'Cáceres'},
    { stateCode: 'MT', label: 'Campinápolis'},
    { stateCode: 'MT', label: 'Campo Novo do Parecis'},
    { stateCode: 'MT', label: 'Campo Verde'},
    { stateCode: 'MT', label: 'Campos de Júlio'},
    { stateCode: 'MT', label: 'Canabrava do Norte'},
    { stateCode: 'MT', label: 'Canarana'},
    { stateCode: 'MT', label: 'Carlinda'},
    { stateCode: 'MT', label: 'Castanheira'},
    { stateCode: 'MT', label: 'Chapada Dos Guimarães'},
    { stateCode: 'MT', label: 'Cláudia'},
    { stateCode: 'MT', label: 'Cocalinho'},
    { stateCode: 'MT', label: 'Colíder'},
    { stateCode: 'MT', label: 'Colniza'},
    { stateCode: 'MT', label: 'Comodoro'},
    { stateCode: 'MT', label: 'Confresa'},
    { stateCode: 'MT', label: 'Conquista D´oeste'},
    { stateCode: 'MT', label: 'Cotriguaçu'},
    { stateCode: 'MT', label: 'Cuiabá'},
    { stateCode: 'MT', label: 'Curvelândia'},
    { stateCode: 'MT', label: 'Denise'},
    { stateCode: 'MT', label: 'Diamantino'},
    { stateCode: 'MT', label: 'Dom Aquino'},
    { stateCode: 'MT', label: 'Feliz Natal'},
    { stateCode: 'MT', label: 'Figueirópolis D´oeste'},
    { stateCode: 'MT', label: 'Gaúcha do Norte'},
    { stateCode: 'MT', label: 'General Carneiro'},
    { stateCode: 'MT', label: 'Glória D´oeste'},
    { stateCode: 'MT', label: 'Guarantã do Norte'},
    { stateCode: 'MT', label: 'Guiratinga'},
    { stateCode: 'MT', label: 'Indiavaí'},
    { stateCode: 'MT', label: 'Ipiranga do Norte'},
    { stateCode: 'MT', label: 'Itanhangá'},
    { stateCode: 'MT', label: 'Itaúba'},
    { stateCode: 'MT', label: 'Itiquira'},
    { stateCode: 'MT', label: 'Jaciara'},
    { stateCode: 'MT', label: 'Jangada'},
    { stateCode: 'MT', label: 'Jauru'},
    { stateCode: 'MT', label: 'Juara'},
    { stateCode: 'MT', label: 'Juína'},
    { stateCode: 'MT', label: 'Juruena'},
    { stateCode: 'MT', label: 'Juscimeira'},
    { stateCode: 'MT', label: 'Lambari D´oeste'},
    { stateCode: 'MT', label: 'Lucas do Rio Verde'},
    { stateCode: 'MT', label: 'Luciára'},
    { stateCode: 'MT', label: 'Marcelândia'},
    { stateCode: 'MT', label: 'Matupá'},
    { stateCode: 'MT', label: 'Mirassol D´oeste'},
    { stateCode: 'MT', label: 'Nobres'},
    { stateCode: 'MT', label: 'Nortelândia'},
    { stateCode: 'MT', label: 'Nossa Senhora do Livramento'},
    { stateCode: 'MT', label: 'Nova Bandeirantes'},
    { stateCode: 'MT', label: 'Nova Brasilândia'},
    { stateCode: 'MT', label: 'Nova Canaã do Norte'},
    { stateCode: 'MT', label: 'Nova Guarita'},
    { stateCode: 'MT', label: 'Nova Lacerda'},
    { stateCode: 'MT', label: 'Nova Marilândia'},
    { stateCode: 'MT', label: 'Nova Maringá'},
    { stateCode: 'MT', label: 'Nova Monte Verde'},
    { stateCode: 'MT', label: 'Nova Mutum'},
    { stateCode: 'MT', label: 'Nova Nazaré'},
    { stateCode: 'MT', label: 'Nova Olímpia'},
    { stateCode: 'MT', label: 'Nova Santa Helena'},
    { stateCode: 'MT', label: 'Nova Ubiratã'},
    { stateCode: 'MT', label: 'Nova Xavantina'},
    { stateCode: 'MT', label: 'Novo Horizonte do Norte'},
    { stateCode: 'MT', label: 'Novo Mundo'},
    { stateCode: 'MT', label: 'Novo Santo Antônio'},
    { stateCode: 'MT', label: 'Novo São Joaquim'},
    { stateCode: 'MT', label: 'Paranaíta'},
    { stateCode: 'MT', label: 'Paranatinga'},
    { stateCode: 'MT', label: 'Pedra Preta'},
    { stateCode: 'MT', label: 'Peixoto de Azevedo'},
    { stateCode: 'MT', label: 'Planalto da Serra'},
    { stateCode: 'MT', label: 'Poconé'},
    { stateCode: 'MT', label: 'Pontal do Araguaia'},
    { stateCode: 'MT', label: 'Ponte Branca'},
    { stateCode: 'MT', label: 'Pontes e Lacerda'},
    { stateCode: 'MT', label: 'Porto Alegre do Norte'},
    { stateCode: 'MT', label: 'Porto Dos Gaúchos'},
    { stateCode: 'MT', label: 'Porto Esperidião'},
    { stateCode: 'MT', label: 'Porto Estrela'},
    { stateCode: 'MT', label: 'Poxoréo'},
    { stateCode: 'MT', label: 'Primavera do Leste'},
    { stateCode: 'MT', label: 'Querência'},
    { stateCode: 'MT', label: 'Reserva do Cabaçal'},
    { stateCode: 'MT', label: 'Ribeirão Cascalheira'},
    { stateCode: 'MT', label: 'Ribeirãozinho'},
    { stateCode: 'MT', label: 'Rio Branco'},
    { stateCode: 'MT', label: 'Rondolândia'},
    { stateCode: 'MT', label: 'Rondonópolis'},
    { stateCode: 'MT', label: 'Rosário Oeste'},
    { stateCode: 'MT', label: 'Salto do Céu'},
    { stateCode: 'MT', label: 'Santa Carmem'},
    { stateCode: 'MT', label: 'Santa Cruz do Xingu'},
    { stateCode: 'MT', label: 'Santa Rita do Trivelato'},
    { stateCode: 'MT', label: 'Santa Terezinha'},
    { stateCode: 'MT', label: 'Santo Afonso'},
    { stateCode: 'MT', label: 'Santo Antônio do Leste'},
    { stateCode: 'MT', label: 'Santo Antônio do Leverger'},
    { stateCode: 'MT', label: 'São Félix do Araguaia'},
    { stateCode: 'MT', label: 'São José do Povo'},
    { stateCode: 'MT', label: 'São José do Rio Claro'},
    { stateCode: 'MT', label: 'São José do Xingu'},
    { stateCode: 'MT', label: 'São José Dos Quatro Marcos'},
    { stateCode: 'MT', label: 'São Pedro da Cipa'},
    { stateCode: 'MT', label: 'Sapezal'},
    { stateCode: 'MT', label: 'Serra Nova Dourada'},
    { stateCode: 'MT', label: 'Sinop'},
    { stateCode: 'MT', label: 'Sorriso'},
    { stateCode: 'MT', label: 'Tabaporã'},
    { stateCode: 'MT', label: 'Tangará da Serra'},
    { stateCode: 'MT', label: 'Tapurah'},
    { stateCode: 'MT', label: 'Terra Nova do Norte'},
    { stateCode: 'MT', label: 'Tesouro'},
    { stateCode: 'MT', label: 'Torixoréu'},
    { stateCode: 'MT', label: 'União do Sul'},
    { stateCode: 'MT', label: 'Vale de São Domingos'},
    { stateCode: 'MT', label: 'Várzea Grande'},
    { stateCode: 'MT', label: 'Vera'},
    { stateCode: 'MT', label: 'Vila Bela da Santíssima Trindade'},
    { stateCode: 'MT', label: 'Vila Rica'},
    { stateCode: 'PA', label: 'Abaetetuba'},
    { stateCode: 'PA', label: 'Abel Figueiredo'},
    { stateCode: 'PA', label: 'Acará'},
    { stateCode: 'PA', label: 'Afuá'},
    { stateCode: 'PA', label: 'Água Azul do Norte'},
    { stateCode: 'PA', label: 'Alenquer'},
    { stateCode: 'PA', label: 'Almeirim'},
    { stateCode: 'PA', label: 'Altamira'},
    { stateCode: 'PA', label: 'Anajás'},
    { stateCode: 'PA', label: 'Ananindeua'},
    { stateCode: 'PA', label: 'Anapu'},
    { stateCode: 'PA', label: 'Augusto Corrêa'},
    { stateCode: 'PA', label: 'Aurora do Pará'},
    { stateCode: 'PA', label: 'Aveiro'},
    { stateCode: 'PA', label: 'Bagre'},
    { stateCode: 'PA', label: 'Baião'},
    { stateCode: 'PA', label: 'Bannach'},
    { stateCode: 'PA', label: 'Barcarena'},
    { stateCode: 'PA', label: 'Belém'},
    { stateCode: 'PA', label: 'Belterra'},
    { stateCode: 'PA', label: 'Benevides'},
    { stateCode: 'PA', label: 'Bom Jesus do Tocantins'},
    { stateCode: 'PA', label: 'Bonito'},
    { stateCode: 'PA', label: 'Bragança'},
    { stateCode: 'PA', label: 'Brasil Novo'},
    { stateCode: 'PA', label: 'Brejo Grande do Araguaia'},
    { stateCode: 'PA', label: 'Breu Branco'},
    { stateCode: 'PA', label: 'Breves'},
    { stateCode: 'PA', label: 'Bujaru'},
    { stateCode: 'PA', label: 'Cachoeira do Arari'},
    { stateCode: 'PA', label: 'Cachoeira do Piriá'},
    { stateCode: 'PA', label: 'Cametá'},
    { stateCode: 'PA', label: 'Canaã Dos Carajás'},
    { stateCode: 'PA', label: 'Capanema'},
    { stateCode: 'PA', label: 'Capitão Poço'},
    { stateCode: 'PA', label: 'Castanhal'},
    { stateCode: 'PA', label: 'Chaves'},
    { stateCode: 'PA', label: 'Colares'},
    { stateCode: 'PA', label: 'Conceição do Araguaia'},
    { stateCode: 'PA', label: 'Concórdia do Pará'},
    { stateCode: 'PA', label: 'Cumaru do Norte'},
    { stateCode: 'PA', label: 'Curionópolis'},
    { stateCode: 'PA', label: 'Curralinho'},
    { stateCode: 'PA', label: 'Curuá'},
    { stateCode: 'PA', label: 'Curuçá'},
    { stateCode: 'PA', label: 'Dom Eliseu'},
    { stateCode: 'PA', label: 'Eldorado Dos Carajás'},
    { stateCode: 'PA', label: 'Faro'},
    { stateCode: 'PA', label: 'Floresta do Araguaia'},
    { stateCode: 'PA', label: 'Garrafão do Norte'},
    { stateCode: 'PA', label: 'Goianésia do Pará'},
    { stateCode: 'PA', label: 'Gurupá'},
    { stateCode: 'PA', label: 'Igarapé-açu'},
    { stateCode: 'PA', label: 'Igarapé-miri'},
    { stateCode: 'PA', label: 'Inhangapi'},
    { stateCode: 'PA', label: 'Ipixuna do Pará'},
    { stateCode: 'PA', label: 'Irituia'},
    { stateCode: 'PA', label: 'Itaituba'},
    { stateCode: 'PA', label: 'Itupiranga'},
    { stateCode: 'PA', label: 'Jacareacanga'},
    { stateCode: 'PA', label: 'Jacundá'},
    { stateCode: 'PA', label: 'Juruti'},
    { stateCode: 'PA', label: 'Limoeiro do Ajuru'},
    { stateCode: 'PA', label: 'Mãe do Rio'},
    { stateCode: 'PA', label: 'Magalhães Barata'},
    { stateCode: 'PA', label: 'Marabá'},
    { stateCode: 'PA', label: 'Maracanã'},
    { stateCode: 'PA', label: 'Marapanim'},
    { stateCode: 'PA', label: 'Marituba'},
    { stateCode: 'PA', label: 'Medicilândia'},
    { stateCode: 'PA', label: 'Melgaço'},
    { stateCode: 'PA', label: 'Mocajuba'},
    { stateCode: 'PA', label: 'Moju'},
    { stateCode: 'PA', label: 'Mojuí dos Campos'},
    { stateCode: 'PA', label: 'Monte Alegre'},
    { stateCode: 'PA', label: 'Muaná'},
    { stateCode: 'PA', label: 'Nova Esperança do Piriá'},
    { stateCode: 'PA', label: 'Nova Ipixuna'},
    { stateCode: 'PA', label: 'Nova Timboteua'},
    { stateCode: 'PA', label: 'Novo Progresso'},
    { stateCode: 'PA', label: 'Novo Repartimento'},
    { stateCode: 'PA', label: 'Óbidos'},
    { stateCode: 'PA', label: 'Oeiras do Pará'},
    { stateCode: 'PA', label: 'Oriximiná'},
    { stateCode: 'PA', label: 'Ourém'},
    { stateCode: 'PA', label: 'Ourilândia do Norte'},
    { stateCode: 'PA', label: 'Pacajá'},
    { stateCode: 'PA', label: 'Palestina do Pará'},
    { stateCode: 'PA', label: 'Paragominas'},
    { stateCode: 'PA', label: 'Parauapebas'},
    { stateCode: 'PA', label: 'Pau D´arco'},
    { stateCode: 'PA', label: 'Peixe-boi'},
    { stateCode: 'PA', label: 'Piçarra'},
    { stateCode: 'PA', label: 'Placas'},
    { stateCode: 'PA', label: 'Ponta de Pedras'},
    { stateCode: 'PA', label: 'Portel'},
    { stateCode: 'PA', label: 'Porto de Moz'},
    { stateCode: 'PA', label: 'Prainha'},
    { stateCode: 'PA', label: 'Primavera'},
    { stateCode: 'PA', label: 'Quatipuru'},
    { stateCode: 'PA', label: 'Redenção'},
    { stateCode: 'PA', label: 'Rio Maria'},
    { stateCode: 'PA', label: 'Rondon do Pará'},
    { stateCode: 'PA', label: 'Rurópolis'},
    { stateCode: 'PA', label: 'Salinópolis'},
    { stateCode: 'PA', label: 'Salvaterra'},
    { stateCode: 'PA', label: 'Santa Bárbara do Pará'},
    { stateCode: 'PA', label: 'Santa Cruz do Arari'},
    { stateCode: 'PA', label: 'Santa Isabel do Pará'},
    { stateCode: 'PA', label: 'Santa Luzia do Pará'},
    { stateCode: 'PA', label: 'Santa Maria Das Barreiras'},
    { stateCode: 'PA', label: 'Santa Maria do Pará'},
    { stateCode: 'PA', label: 'Santana do Araguaia'},
    { stateCode: 'PA', label: 'Santarém'},
    { stateCode: 'PA', label: 'Santarém Novo'},
    { stateCode: 'PA', label: 'Santo Antônio do Tauá'},
    { stateCode: 'PA', label: 'São Caetano de Odivelas'},
    { stateCode: 'PA', label: 'São Domingos do Araguaia'},
    { stateCode: 'PA', label: 'São Domingos do Capim'},
    { stateCode: 'PA', label: 'São Félix do Xingu'},
    { stateCode: 'PA', label: 'São Francisco do Pará'},
    { stateCode: 'PA', label: 'São Geraldo do Araguaia'},
    { stateCode: 'PA', label: 'São João da Ponta'},
    { stateCode: 'PA', label: 'São João de Pirabas'},
    { stateCode: 'PA', label: 'São João do Araguaia'},
    { stateCode: 'PA', label: 'São Miguel do Guamá'},
    { stateCode: 'PA', label: 'São Sebastião da Boa Vista'},
    { stateCode: 'PA', label: 'Sapucaia'},
    { stateCode: 'PA', label: 'Senador José Porfírio'},
    { stateCode: 'PA', label: 'Soure'},
    { stateCode: 'PA', label: 'Tailândia'},
    { stateCode: 'PA', label: 'Terra Alta'},
    { stateCode: 'PA', label: 'Terra Santa'},
    { stateCode: 'PA', label: 'Tomé-açu'},
    { stateCode: 'PA', label: 'Tracuateua'},
    { stateCode: 'PA', label: 'Trairão'},
    { stateCode: 'PA', label: 'Tucumã'},
    { stateCode: 'PA', label: 'Tucuruí'},
    { stateCode: 'PA', label: 'Ulianópolis'},
    { stateCode: 'PA', label: 'Uruará'},
    { stateCode: 'PA', label: 'Vigia'},
    { stateCode: 'PA', label: 'Viseu'},
    { stateCode: 'PA', label: 'Vitória do Xingu'},
    { stateCode: 'PA', label: 'Xinguara'},
    { stateCode: 'PB', label: 'Água Branca'},
    { stateCode: 'PB', label: 'Aguiar'},
    { stateCode: 'PB', label: 'Alagoa Grande'},
    { stateCode: 'PB', label: 'Alagoa Nova'},
    { stateCode: 'PB', label: 'Alagoinha'},
    { stateCode: 'PB', label: 'Alcantil'},
    { stateCode: 'PB', label: 'Algodão de Jandaíra'},
    { stateCode: 'PB', label: 'Alhandra'},
    { stateCode: 'PB', label: 'Amparo'},
    { stateCode: 'PB', label: 'Aparecida'},
    { stateCode: 'PB', label: 'Araçagi'},
    { stateCode: 'PB', label: 'Arara'},
    { stateCode: 'PB', label: 'Araruna'},
    { stateCode: 'PB', label: 'Areia'},
    { stateCode: 'PB', label: 'Areia de Baraúnas'},
    { stateCode: 'PB', label: 'Areial'},
    { stateCode: 'PB', label: 'Aroeiras'},
    { stateCode: 'PB', label: 'Assunção'},
    { stateCode: 'PB', label: 'Baía da Traição'},
    { stateCode: 'PB', label: 'Bananeiras'},
    { stateCode: 'PB', label: 'Baraúna'},
    { stateCode: 'PB', label: 'Barra de Santa Rosa'},
    { stateCode: 'PB', label: 'Barra de Santana'},
    { stateCode: 'PB', label: 'Barra de São Miguel'},
    { stateCode: 'PB', label: 'Bayeux'},
    { stateCode: 'PB', label: 'Belém'},
    { stateCode: 'PB', label: 'Belém do Brejo do Cruz'},
    { stateCode: 'PB', label: 'Bernardino Batista'},
    { stateCode: 'PB', label: 'Boa Ventura'},
    { stateCode: 'PB', label: 'Boa Vista'},
    { stateCode: 'PB', label: 'Bom Jesus'},
    { stateCode: 'PB', label: 'Bom Sucesso'},
    { stateCode: 'PB', label: 'Bonito de Santa fé'},
    { stateCode: 'PB', label: 'Boqueirão'},
    { stateCode: 'PB', label: 'Borborema'},
    { stateCode: 'PB', label: 'Brejo do Cruz'},
    { stateCode: 'PB', label: 'Brejo Dos Santos'},
    { stateCode: 'PB', label: 'Caaporã'},
    { stateCode: 'PB', label: 'Cabaceiras'},
    { stateCode: 'PB', label: 'Cabedelo'},
    { stateCode: 'PB', label: 'Cachoeira Dos Índios'},
    { stateCode: 'PB', label: 'Cacimba de Areia'},
    { stateCode: 'PB', label: 'Cacimba de Dentro'},
    { stateCode: 'PB', label: 'Cacimbas'},
    { stateCode: 'PB', label: 'Caiçara'},
    { stateCode: 'PB', label: 'Cajazeiras'},
    { stateCode: 'PB', label: 'Cajazeirinhas'},
    { stateCode: 'PB', label: 'Caldas Brandão'},
    { stateCode: 'PB', label: 'Camalaú'},
    { stateCode: 'PB', label: 'Campina Grande'},
    { stateCode: 'PB', label: 'Campo de Santana'},
    { stateCode: 'PB', label: 'Capim'},
    { stateCode: 'PB', label: 'Caraúbas'},
    { stateCode: 'PB', label: 'Carrapateira'},
    { stateCode: 'PB', label: 'Casserengue'},
    { stateCode: 'PB', label: 'Catingueira'},
    { stateCode: 'PB', label: 'Catolé do Rocha'},
    { stateCode: 'PB', label: 'Caturité'},
    { stateCode: 'PB', label: 'Conceição'},
    { stateCode: 'PB', label: 'Condado'},
    { stateCode: 'PB', label: 'Conde'},
    { stateCode: 'PB', label: 'Congo'},
    { stateCode: 'PB', label: 'Coremas'},
    { stateCode: 'PB', label: 'Coxixola'},
    { stateCode: 'PB', label: 'Cruz do Espírito Santo'},
    { stateCode: 'PB', label: 'Cubati'},
    { stateCode: 'PB', label: 'Cuité'},
    { stateCode: 'PB', label: 'Cuité de Mamanguape'},
    { stateCode: 'PB', label: 'Cuitegi'},
    { stateCode: 'PB', label: 'Curral de Cima'},
    { stateCode: 'PB', label: 'Curral Velho'},
    { stateCode: 'PB', label: 'Damião'},
    { stateCode: 'PB', label: 'Desterro'},
    { stateCode: 'PB', label: 'Diamante'},
    { stateCode: 'PB', label: 'Dona Inês'},
    { stateCode: 'PB', label: 'Duas Estradas'},
    { stateCode: 'PB', label: 'Emas'},
    { stateCode: 'PB', label: 'Esperança'},
    { stateCode: 'PB', label: 'Fagundes'},
    { stateCode: 'PB', label: 'Frei Martinho'},
    { stateCode: 'PB', label: 'Gado Bravo'},
    { stateCode: 'PB', label: 'Guarabira'},
    { stateCode: 'PB', label: 'Gurinhém'},
    { stateCode: 'PB', label: 'Gurjão'},
    { stateCode: 'PB', label: 'Ibiara'},
    { stateCode: 'PB', label: 'Igaracy'},
    { stateCode: 'PB', label: 'Imaculada'},
    { stateCode: 'PB', label: 'Ingá'},
    { stateCode: 'PB', label: 'Itabaiana'},
    { stateCode: 'PB', label: 'Itaporanga'},
    { stateCode: 'PB', label: 'Itapororoca'},
    { stateCode: 'PB', label: 'Itatuba'},
    { stateCode: 'PB', label: 'Jacaraú'},
    { stateCode: 'PB', label: 'Jericó'},
    { stateCode: 'PB', label: 'João Pessoa'},
    { stateCode: 'PB', label: 'Juarez Távora'},
    { stateCode: 'PB', label: 'Juazeirinho'},
    { stateCode: 'PB', label: 'Junco do Seridó'},
    { stateCode: 'PB', label: 'Juripiranga'},
    { stateCode: 'PB', label: 'Juru'},
    { stateCode: 'PB', label: 'Lagoa'},
    { stateCode: 'PB', label: 'Lagoa de Dentro'},
    { stateCode: 'PB', label: 'Lagoa Seca'},
    { stateCode: 'PB', label: 'Lastro'},
    { stateCode: 'PB', label: 'Livramento'},
    { stateCode: 'PB', label: 'Logradouro'},
    { stateCode: 'PB', label: 'Lucena'},
    { stateCode: 'PB', label: 'Mãe D´água'},
    { stateCode: 'PB', label: 'Malta'},
    { stateCode: 'PB', label: 'Mamanguape'},
    { stateCode: 'PB', label: 'Manaíra'},
    { stateCode: 'PB', label: 'Marcação'},
    { stateCode: 'PB', label: 'Mari'},
    { stateCode: 'PB', label: 'Marizópolis'},
    { stateCode: 'PB', label: 'Massaranduba'},
    { stateCode: 'PB', label: 'Mataraca'},
    { stateCode: 'PB', label: 'Matinhas'},
    { stateCode: 'PB', label: 'Mato Grosso'},
    { stateCode: 'PB', label: 'Maturéia'},
    { stateCode: 'PB', label: 'Mogeiro'},
    { stateCode: 'PB', label: 'Montadas'},
    { stateCode: 'PB', label: 'Monte Horebe'},
    { stateCode: 'PB', label: 'Monteiro'},
    { stateCode: 'PB', label: 'Mulungu'},
    { stateCode: 'PB', label: 'Natuba'},
    { stateCode: 'PB', label: 'Nazarezinho'},
    { stateCode: 'PB', label: 'Nova Floresta'},
    { stateCode: 'PB', label: 'Nova Olinda'},
    { stateCode: 'PB', label: 'Nova Palmeira'},
    { stateCode: 'PB', label: 'Olho D´água'},
    { stateCode: 'PB', label: 'Olivedos'},
    { stateCode: 'PB', label: 'Ouro Velho'},
    { stateCode: 'PB', label: 'Parari'},
    { stateCode: 'PB', label: 'Passagem'},
    { stateCode: 'PB', label: 'Patos'},
    { stateCode: 'PB', label: 'Paulista'},
    { stateCode: 'PB', label: 'Pedra Branca'},
    { stateCode: 'PB', label: 'Pedra Lavrada'},
    { stateCode: 'PB', label: 'Pedras de Fogo'},
    { stateCode: 'PB', label: 'Pedro Régis'},
    { stateCode: 'PB', label: 'Piancó'},
    { stateCode: 'PB', label: 'Picuí'},
    { stateCode: 'PB', label: 'Pilar'},
    { stateCode: 'PB', label: 'Pilões'},
    { stateCode: 'PB', label: 'Pilõezinhos'},
    { stateCode: 'PB', label: 'Pirpirituba'},
    { stateCode: 'PB', label: 'Pitimbu'},
    { stateCode: 'PB', label: 'Pocinhos'},
    { stateCode: 'PB', label: 'Poço Dantas'},
    { stateCode: 'PB', label: 'Poço de José de Moura'},
    { stateCode: 'PB', label: 'Pombal'},
    { stateCode: 'PB', label: 'Prata'},
    { stateCode: 'PB', label: 'Princesa Isabel'},
    { stateCode: 'PB', label: 'Puxinanã'},
    { stateCode: 'PB', label: 'Queimadas'},
    { stateCode: 'PB', label: 'Quixabá'},
    { stateCode: 'PB', label: 'Remígio'},
    { stateCode: 'PB', label: 'Riachão'},
    { stateCode: 'PB', label: 'Riachão do Bacamarte'},
    { stateCode: 'PB', label: 'Riachão do Poço'},
    { stateCode: 'PB', label: 'Riacho de Santo Antônio'},
    { stateCode: 'PB', label: 'Riacho Dos Cavalos'},
    { stateCode: 'PB', label: 'Rio Tinto'},
    { stateCode: 'PB', label: 'Salgadinho'},
    { stateCode: 'PB', label: 'Salgado de São Félix'},
    { stateCode: 'PB', label: 'Santa Cecília'},
    { stateCode: 'PB', label: 'Santa Cruz'},
    { stateCode: 'PB', label: 'Santa Helena'},
    { stateCode: 'PB', label: 'Santa Inês'},
    { stateCode: 'PB', label: 'Santa Luzia'},
    { stateCode: 'PB', label: 'Santa Rita'},
    { stateCode: 'PB', label: 'Santa Teresinha'},
    { stateCode: 'PB', label: 'Santana de Mangueira'},
    { stateCode: 'PB', label: 'Santana Dos Garrotes'},
    { stateCode: 'PB', label: 'Santarém'},
    { stateCode: 'PB', label: 'Santo André'},
    { stateCode: 'PB', label: 'São Bentinho'},
    { stateCode: 'PB', label: 'São Bento'},
    { stateCode: 'PB', label: 'São Domingos de Pombal'},
    { stateCode: 'PB', label: 'São Domingos do Cariri'},
    { stateCode: 'PB', label: 'São Francisco'},
    { stateCode: 'PB', label: 'São João do Cariri'},
    { stateCode: 'PB', label: 'São João do Rio do Peixe'},
    { stateCode: 'PB', label: 'São João do Tigre'},
    { stateCode: 'PB', label: 'São José da Lagoa Tapada'},
    { stateCode: 'PB', label: 'São José de Caiana'},
    { stateCode: 'PB', label: 'São José de Espinharas'},
    { stateCode: 'PB', label: 'São José de Piranhas'},
    { stateCode: 'PB', label: 'São José de Princesa'},
    { stateCode: 'PB', label: 'São José do Bonfim'},
    { stateCode: 'PB', label: 'São José do Brejo do Cruz'},
    { stateCode: 'PB', label: 'São José do Sabugi'},
    { stateCode: 'PB', label: 'São José Dos Cordeiros'},
    { stateCode: 'PB', label: 'São José Dos Ramos'},
    { stateCode: 'PB', label: 'São Mamede'},
    { stateCode: 'PB', label: 'São Miguel de Taipu'},
    { stateCode: 'PB', label: 'São Sebastião de Lagoa de Roça'},
    { stateCode: 'PB', label: 'São Sebastião do Umbuzeiro'},
    { stateCode: 'PB', label: 'Sapé'},
    { stateCode: 'PB', label: 'Seridó'},
    { stateCode: 'PB', label: 'Serra Branca'},
    { stateCode: 'PB', label: 'Serra da Raiz'},
    { stateCode: 'PB', label: 'Serra Grande'},
    { stateCode: 'PB', label: 'Serra Redonda'},
    { stateCode: 'PB', label: 'Serraria'},
    { stateCode: 'PB', label: 'Sertãozinho'},
    { stateCode: 'PB', label: 'Sobrado'},
    { stateCode: 'PB', label: 'Solânea'},
    { stateCode: 'PB', label: 'Soledade'},
    { stateCode: 'PB', label: 'Sossêgo'},
    { stateCode: 'PB', label: 'Sousa'},
    { stateCode: 'PB', label: 'Sumé'},
    { stateCode: 'PB', label: 'Taperoá'},
    { stateCode: 'PB', label: 'Tavares'},
    { stateCode: 'PB', label: 'Teixeira'},
    { stateCode: 'PB', label: 'Tenório'},
    { stateCode: 'PB', label: 'Triunfo'},
    { stateCode: 'PB', label: 'Uiraúna'},
    { stateCode: 'PB', label: 'Umbuzeiro'},
    { stateCode: 'PB', label: 'Várzea'},
    { stateCode: 'PB', label: 'Vieirópolis'},
    { stateCode: 'PB', label: 'Vista Serrana'},
    { stateCode: 'PB', label: 'Zabelê'},
    { stateCode: 'PE', label: 'Abreu e Lima'},
    { stateCode: 'PE', label: 'Afogados da Ingazeira'},
    { stateCode: 'PE', label: 'Afrânio'},
    { stateCode: 'PE', label: 'Agrestina'},
    { stateCode: 'PE', label: 'Água Preta'},
    { stateCode: 'PE', label: 'Águas Belas'},
    { stateCode: 'PE', label: 'Alagoinha'},
    { stateCode: 'PE', label: 'Aliança'},
    { stateCode: 'PE', label: 'Altinho'},
    { stateCode: 'PE', label: 'Amaraji'},
    { stateCode: 'PE', label: 'Angelim'},
    { stateCode: 'PE', label: 'Araçoiaba'},
    { stateCode: 'PE', label: 'Araripina'},
    { stateCode: 'PE', label: 'Arcoverde'},
    { stateCode: 'PE', label: 'Barra de Guabiraba'},
    { stateCode: 'PE', label: 'Barreiros'},
    { stateCode: 'PE', label: 'Belém de Maria'},
    { stateCode: 'PE', label: 'Belém de São Francisco'},
    { stateCode: 'PE', label: 'Belo Jardim'},
    { stateCode: 'PE', label: 'Betânia'},
    { stateCode: 'PE', label: 'Bezerros'},
    { stateCode: 'PE', label: 'Bodocó'},
    { stateCode: 'PE', label: 'Bom Conselho'},
    { stateCode: 'PE', label: 'Bom Jardim'},
    { stateCode: 'PE', label: 'Bonito'},
    { stateCode: 'PE', label: 'Brejão'},
    { stateCode: 'PE', label: 'Brejinho'},
    { stateCode: 'PE', label: 'Brejo da Madre de Deus'},
    { stateCode: 'PE', label: 'Buenos Aires'},
    { stateCode: 'PE', label: 'Buíque'},
    { stateCode: 'PE', label: 'Cabo de Santo Agostinho'},
    { stateCode: 'PE', label: 'Cabrobó'},
    { stateCode: 'PE', label: 'Cachoeirinha'},
    { stateCode: 'PE', label: 'Caetés'},
    { stateCode: 'PE', label: 'Calçado'},
    { stateCode: 'PE', label: 'Calumbi'},
    { stateCode: 'PE', label: 'Camaragibe'},
    { stateCode: 'PE', label: 'Camocim de São Félix'},
    { stateCode: 'PE', label: 'Camutanga'},
    { stateCode: 'PE', label: 'Canhotinho'},
    { stateCode: 'PE', label: 'Capoeiras'},
    { stateCode: 'PE', label: 'Carnaíba'},
    { stateCode: 'PE', label: 'Carnaubeira da Penha'},
    { stateCode: 'PE', label: 'Carpina'},
    { stateCode: 'PE', label: 'Caruaru'},
    { stateCode: 'PE', label: 'Casinhas'},
    { stateCode: 'PE', label: 'Catende'},
    { stateCode: 'PE', label: 'Cedro'},
    { stateCode: 'PE', label: 'Chã de Alegria'},
    { stateCode: 'PE', label: 'Chã Grande'},
    { stateCode: 'PE', label: 'Condado'},
    { stateCode: 'PE', label: 'Correntes'},
    { stateCode: 'PE', label: 'Cortês'},
    { stateCode: 'PE', label: 'Cumaru'},
    { stateCode: 'PE', label: 'Cupira'},
    { stateCode: 'PE', label: 'Custódia'},
    { stateCode: 'PE', label: 'Dormentes'},
    { stateCode: 'PE', label: 'Escada'},
    { stateCode: 'PE', label: 'Exu'},
    { stateCode: 'PE', label: 'Feira Nova'},
    { stateCode: 'PE', label: 'Fernando de Noronha'},
    { stateCode: 'PE', label: 'Ferreiros'},
    { stateCode: 'PE', label: 'Flores'},
    { stateCode: 'PE', label: 'Floresta'},
    { stateCode: 'PE', label: 'Frei Miguelinho'},
    { stateCode: 'PE', label: 'Gameleira'},
    { stateCode: 'PE', label: 'Garanhuns'},
    { stateCode: 'PE', label: 'Glória do Goitá'},
    { stateCode: 'PE', label: 'Goiana'},
    { stateCode: 'PE', label: 'Granito'},
    { stateCode: 'PE', label: 'Gravatá'},
    { stateCode: 'PE', label: 'Iati'},
    { stateCode: 'PE', label: 'Ibimirim'},
    { stateCode: 'PE', label: 'Ibirajuba'},
    { stateCode: 'PE', label: 'Igarassu'},
    { stateCode: 'PE', label: 'Iguaraci'},
    { stateCode: 'PE', label: 'Ilha de Itamaracá'},
    { stateCode: 'PE', label: 'Inajá'},
    { stateCode: 'PE', label: 'Ingazeira'},
    { stateCode: 'PE', label: 'Ipojuca'},
    { stateCode: 'PE', label: 'Ipubi'},
    { stateCode: 'PE', label: 'Itacuruba'},
    { stateCode: 'PE', label: 'Itaíba'},
    { stateCode: 'PE', label: 'Itambé'},
    { stateCode: 'PE', label: 'Itapetim'},
    { stateCode: 'PE', label: 'Itapissuma'},
    { stateCode: 'PE', label: 'Itaquitinga'},
    { stateCode: 'PE', label: 'Jaboatão Dos Guararapes'},
    { stateCode: 'PE', label: 'Jaqueira'},
    { stateCode: 'PE', label: 'Jataúba'},
    { stateCode: 'PE', label: 'Jatobá'},
    { stateCode: 'PE', label: 'João Alfredo'},
    { stateCode: 'PE', label: 'Joaquim Nabuco'},
    { stateCode: 'PE', label: 'Jucati'},
    { stateCode: 'PE', label: 'Jupi'},
    { stateCode: 'PE', label: 'Jurema'},
    { stateCode: 'PE', label: 'Lagoa do Carro'},
    { stateCode: 'PE', label: 'Lagoa do Itaenga'},
    { stateCode: 'PE', label: 'Lagoa do Ouro'},
    { stateCode: 'PE', label: 'Lagoa Dos Gatos'},
    { stateCode: 'PE', label: 'Lagoa Grande'},
    { stateCode: 'PE', label: 'Lajedo'},
    { stateCode: 'PE', label: 'Limoeiro'},
    { stateCode: 'PE', label: 'Macaparana'},
    { stateCode: 'PE', label: 'Machados'},
    { stateCode: 'PE', label: 'Manari'},
    { stateCode: 'PE', label: 'Maraial'},
    { stateCode: 'PE', label: 'Mirandiba'},
    { stateCode: 'PE', label: 'Moreilândia'},
    { stateCode: 'PE', label: 'Moreno'},
    { stateCode: 'PE', label: 'Nazaré da Mata'},
    { stateCode: 'PE', label: 'Olinda'},
    { stateCode: 'PE', label: 'Orobó'},
    { stateCode: 'PE', label: 'Orocó'},
    { stateCode: 'PE', label: 'Ouricuri'},
    { stateCode: 'PE', label: 'Palmares'},
    { stateCode: 'PE', label: 'Palmeirina'},
    { stateCode: 'PE', label: 'Panelas'},
    { stateCode: 'PE', label: 'Paranatama'},
    { stateCode: 'PE', label: 'Parnamirim'},
    { stateCode: 'PE', label: 'Passira'},
    { stateCode: 'PE', label: 'Paudalho'},
    { stateCode: 'PE', label: 'Paulista'},
    { stateCode: 'PE', label: 'Pedra'},
    { stateCode: 'PE', label: 'Pesqueira'},
    { stateCode: 'PE', label: 'Petrolândia'},
    { stateCode: 'PE', label: 'Petrolina'},
    { stateCode: 'PE', label: 'Poção'},
    { stateCode: 'PE', label: 'Pombos'},
    { stateCode: 'PE', label: 'Primavera'},
    { stateCode: 'PE', label: 'Quipapá'},
    { stateCode: 'PE', label: 'Quixaba'},
    { stateCode: 'PE', label: 'Recife'},
    { stateCode: 'PE', label: 'Riacho Das Almas'},
    { stateCode: 'PE', label: 'Ribeirão'},
    { stateCode: 'PE', label: 'Rio Formoso'},
    { stateCode: 'PE', label: 'Sairé'},
    { stateCode: 'PE', label: 'Salgadinho'},
    { stateCode: 'PE', label: 'Salgueiro'},
    { stateCode: 'PE', label: 'Saloá'},
    { stateCode: 'PE', label: 'Sanharó'},
    { stateCode: 'PE', label: 'Santa Cruz'},
    { stateCode: 'PE', label: 'Santa Cruz da Baixa Verde'},
    { stateCode: 'PE', label: 'Santa Cruz do Capibaribe'},
    { stateCode: 'PE', label: 'Santa Filomena'},
    { stateCode: 'PE', label: 'Santa Maria da Boa Vista'},
    { stateCode: 'PE', label: 'Santa Maria do Cambucá'},
    { stateCode: 'PE', label: 'Santa Terezinha'},
    { stateCode: 'PE', label: 'São Benedito do Sul'},
    { stateCode: 'PE', label: 'São Bento do Una'},
    { stateCode: 'PE', label: 'São Caitano'},
    { stateCode: 'PE', label: 'São João'},
    { stateCode: 'PE', label: 'São Joaquim do Monte'},
    { stateCode: 'PE', label: 'São José da Coroa Grande'},
    { stateCode: 'PE', label: 'São José do Belmonte'},
    { stateCode: 'PE', label: 'São José do Egito'},
    { stateCode: 'PE', label: 'São Lourenço da Mata'},
    { stateCode: 'PE', label: 'São Vicente Ferrer'},
    { stateCode: 'PE', label: 'Serra Talhada'},
    { stateCode: 'PE', label: 'Serrita'},
    { stateCode: 'PE', label: 'Sertânia'},
    { stateCode: 'PE', label: 'Sirinhaém'},
    { stateCode: 'PE', label: 'Solidão'},
    { stateCode: 'PE', label: 'Surubim'},
    { stateCode: 'PE', label: 'Tabira'},
    { stateCode: 'PE', label: 'Tacaimbó'},
    { stateCode: 'PE', label: 'Tacaratu'},
    { stateCode: 'PE', label: 'Tamandaré'},
    { stateCode: 'PE', label: 'Taquaritinga do Norte'},
    { stateCode: 'PE', label: 'Terezinha'},
    { stateCode: 'PE', label: 'Terra Nova'},
    { stateCode: 'PE', label: 'Timbaúba'},
    { stateCode: 'PE', label: 'Toritama'},
    { stateCode: 'PE', label: 'Tracunhaém'},
    { stateCode: 'PE', label: 'Trindade'},
    { stateCode: 'PE', label: 'Triunfo'},
    { stateCode: 'PE', label: 'Tupanatinga'},
    { stateCode: 'PE', label: 'Tuparetama'},
    { stateCode: 'PE', label: 'Venturosa'},
    { stateCode: 'PE', label: 'Verdejante'},
    { stateCode: 'PE', label: 'Vertente do Lério'},
    { stateCode: 'PE', label: 'Vertentes'},
    { stateCode: 'PE', label: 'Vicência'},
    { stateCode: 'PE', label: 'Vitória de Santo Antão'},
    { stateCode: 'PE', label: 'Xexéu'},
    { stateCode: 'PI', label: 'Acauã'},
    { stateCode: 'PI', label: 'Agricolândia'},
    { stateCode: 'PI', label: 'Água Branca'},
    { stateCode: 'PI', label: 'Alagoinha do Piauí'},
    { stateCode: 'PI', label: 'Alegrete do Piauí'},
    { stateCode: 'PI', label: 'Alto Longá'},
    { stateCode: 'PI', label: 'Altos'},
    { stateCode: 'PI', label: 'Alvorada do Gurguéia'},
    { stateCode: 'PI', label: 'Amarante'},
    { stateCode: 'PI', label: 'Angical do Piauí'},
    { stateCode: 'PI', label: 'Anísio de Abreu'},
    { stateCode: 'PI', label: 'Antônio Almeida'},
    { stateCode: 'PI', label: 'Aroazes'},
    { stateCode: 'PI', label: 'Aroeiras do Itaim'},
    { stateCode: 'PI', label: 'Arraial'},
    { stateCode: 'PI', label: 'Assunção do Piauí'},
    { stateCode: 'PI', label: 'Avelino Lopes'},
    { stateCode: 'PI', label: 'Baixa Grande do Ribeiro'},
    { stateCode: 'PI', label: 'Barra D´alcântara'},
    { stateCode: 'PI', label: 'Barras'},
    { stateCode: 'PI', label: 'Barreiras do Piauí'},
    { stateCode: 'PI', label: 'Barro Duro'},
    { stateCode: 'PI', label: 'Batalha'},
    { stateCode: 'PI', label: 'Bela Vista do Piauí'},
    { stateCode: 'PI', label: 'Belém do Piauí'},
    { stateCode: 'PI', label: 'Beneditinos'},
    { stateCode: 'PI', label: 'Bertolínia'},
    { stateCode: 'PI', label: 'Betânia do Piauí'},
    { stateCode: 'PI', label: 'Boa Hora'},
    { stateCode: 'PI', label: 'Bocaina'},
    { stateCode: 'PI', label: 'Bom Jesus'},
    { stateCode: 'PI', label: 'Bom Princípio do Piauí'},
    { stateCode: 'PI', label: 'Bonfim do Piauí'},
    { stateCode: 'PI', label: 'Boqueirão do Piauí'},
    { stateCode: 'PI', label: 'Brasileira'},
    { stateCode: 'PI', label: 'Brejo do Piauí'},
    { stateCode: 'PI', label: 'Buriti Dos Lopes'},
    { stateCode: 'PI', label: 'Buriti Dos Montes'},
    { stateCode: 'PI', label: 'Cabeceiras do Piauí'},
    { stateCode: 'PI', label: 'Cajazeiras do Piauí'},
    { stateCode: 'PI', label: 'Cajueiro da Praia'},
    { stateCode: 'PI', label: 'Caldeirão Grande do Piauí'},
    { stateCode: 'PI', label: 'Campinas do Piauí'},
    { stateCode: 'PI', label: 'Campo Alegre do Fidalgo'},
    { stateCode: 'PI', label: 'Campo Grande do Piauí'},
    { stateCode: 'PI', label: 'Campo Largo do Piauí'},
    { stateCode: 'PI', label: 'Campo Maior'},
    { stateCode: 'PI', label: 'Canavieira'},
    { stateCode: 'PI', label: 'Canto do Buriti'},
    { stateCode: 'PI', label: 'Capitão de Campos'},
    { stateCode: 'PI', label: 'Capitão Gervásio Oliveira'},
    { stateCode: 'PI', label: 'Caracol'},
    { stateCode: 'PI', label: 'Caraúbas do Piauí'},
    { stateCode: 'PI', label: 'Caridade do Piauí'},
    { stateCode: 'PI', label: 'Castelo do Piauí'},
    { stateCode: 'PI', label: 'Caxingó'},
    { stateCode: 'PI', label: 'Cocal'},
    { stateCode: 'PI', label: 'Cocal de Telha'},
    { stateCode: 'PI', label: 'Cocal Dos Alves'},
    { stateCode: 'PI', label: 'Coivaras'},
    { stateCode: 'PI', label: 'Colônia do Gurguéia'},
    { stateCode: 'PI', label: 'Colônia do Piauí'},
    { stateCode: 'PI', label: 'Conceição do Canindé'},
    { stateCode: 'PI', label: 'Coronel José Dias'},
    { stateCode: 'PI', label: 'Corrente'},
    { stateCode: 'PI', label: 'Cristalândia do Piauí'},
    { stateCode: 'PI', label: 'Cristino Castro'},
    { stateCode: 'PI', label: 'Curimatá'},
    { stateCode: 'PI', label: 'Currais'},
    { stateCode: 'PI', label: 'Curral Novo do Piauí'},
    { stateCode: 'PI', label: 'Curralinhos'},
    { stateCode: 'PI', label: 'Demerval Lobão'},
    { stateCode: 'PI', label: 'Dirceu Arcoverde'},
    { stateCode: 'PI', label: 'Dom Expedito Lopes'},
    { stateCode: 'PI', label: 'Dom Inocêncio'},
    { stateCode: 'PI', label: 'Domingos Mourão'},
    { stateCode: 'PI', label: 'Elesbão Veloso'},
    { stateCode: 'PI', label: 'Eliseu Martins'},
    { stateCode: 'PI', label: 'Esperantina'},
    { stateCode: 'PI', label: 'Fartura do Piauí'},
    { stateCode: 'PI', label: 'Flores do Piauí'},
    { stateCode: 'PI', label: 'Floresta do Piauí'},
    { stateCode: 'PI', label: 'Floriano'},
    { stateCode: 'PI', label: 'Francinópolis'},
    { stateCode: 'PI', label: 'Francisco Ayres'},
    { stateCode: 'PI', label: 'Francisco Macedo'},
    { stateCode: 'PI', label: 'Francisco Santos'},
    { stateCode: 'PI', label: 'Fronteiras'},
    { stateCode: 'PI', label: 'Geminiano'},
    { stateCode: 'PI', label: 'Gilbués'},
    { stateCode: 'PI', label: 'Guadalupe'},
    { stateCode: 'PI', label: 'Guaribas'},
    { stateCode: 'PI', label: 'Hugo Napoleão'},
    { stateCode: 'PI', label: 'Ilha Grande'},
    { stateCode: 'PI', label: 'Inhuma'},
    { stateCode: 'PI', label: 'Ipiranga do Piauí'},
    { stateCode: 'PI', label: 'Isaías Coelho'},
    { stateCode: 'PI', label: 'Itainópolis'},
    { stateCode: 'PI', label: 'Itaueira'},
    { stateCode: 'PI', label: 'Jacobina do Piauí'},
    { stateCode: 'PI', label: 'Jaicós'},
    { stateCode: 'PI', label: 'Jardim do Mulato'},
    { stateCode: 'PI', label: 'Jatobá do Piauí'},
    { stateCode: 'PI', label: 'Jerumenha'},
    { stateCode: 'PI', label: 'João Costa'},
    { stateCode: 'PI', label: 'Joaquim Pires'},
    { stateCode: 'PI', label: 'Joca Marques'},
    { stateCode: 'PI', label: 'José de Freitas'},
    { stateCode: 'PI', label: 'Juazeiro do Piauí'},
    { stateCode: 'PI', label: 'Júlio Borges'},
    { stateCode: 'PI', label: 'Jurema'},
    { stateCode: 'PI', label: 'Lagoa Alegre'},
    { stateCode: 'PI', label: 'Lagoa de São Francisco'},
    { stateCode: 'PI', label: 'Lagoa do Barro do Piauí'},
    { stateCode: 'PI', label: 'Lagoa do Piauí'},
    { stateCode: 'PI', label: 'Lagoa do Sítio'},
    { stateCode: 'PI', label: 'Lagoinha do Piauí'},
    { stateCode: 'PI', label: 'Landri Sales'},
    { stateCode: 'PI', label: 'Luís Correia'},
    { stateCode: 'PI', label: 'Luzilândia'},
    { stateCode: 'PI', label: 'Madeiro'},
    { stateCode: 'PI', label: 'Manoel Emídio'},
    { stateCode: 'PI', label: 'Marcolândia'},
    { stateCode: 'PI', label: 'Marcos Parente'},
    { stateCode: 'PI', label: 'Massapê do Piauí'},
    { stateCode: 'PI', label: 'Matias Olímpio'},
    { stateCode: 'PI', label: 'Miguel Alves'},
    { stateCode: 'PI', label: 'Miguel Leão'},
    { stateCode: 'PI', label: 'Milton Brandão'},
    { stateCode: 'PI', label: 'Monsenhor Gil'},
    { stateCode: 'PI', label: 'Monsenhor Hipólito'},
    { stateCode: 'PI', label: 'Monte Alegre do Piauí'},
    { stateCode: 'PI', label: 'Morro Cabeça no Tempo'},
    { stateCode: 'PI', label: 'Morro do Chapéu do Piauí'},
    { stateCode: 'PI', label: 'Murici Dos Portelas'},
    { stateCode: 'PI', label: 'Nazaré do Piauí'},
    { stateCode: 'PI', label: 'Nazária'},
    { stateCode: 'PI', label: 'Nossa Senhora de Nazaré'},
    { stateCode: 'PI', label: 'Nossa Senhora Dos Remédios'},
    { stateCode: 'PI', label: 'Nova Santa Rita'},
    { stateCode: 'PI', label: 'Novo Oriente do Piauí'},
    { stateCode: 'PI', label: 'Novo Santo Antônio'},
    { stateCode: 'PI', label: 'Oeiras'},
    { stateCode: 'PI', label: 'Olho D´água do Piauí'},
    { stateCode: 'PI', label: 'Padre Marcos'},
    { stateCode: 'PI', label: 'Paes Landim'},
    { stateCode: 'PI', label: 'Pajeú do Piauí'},
    { stateCode: 'PI', label: 'Palmeira do Piauí'},
    { stateCode: 'PI', label: 'Palmeirais'},
    { stateCode: 'PI', label: 'Paquetá'},
    { stateCode: 'PI', label: 'Parnaguá'},
    { stateCode: 'PI', label: 'Parnaíba'},
    { stateCode: 'PI', label: 'Passagem Franca do Piauí'},
    { stateCode: 'PI', label: 'Patos do Piauí'},
    { stateCode: 'PI', label: 'Pau D´arco do Piauí'},
    { stateCode: 'PI', label: 'Paulistana'},
    { stateCode: 'PI', label: 'Pavussu'},
    { stateCode: 'PI', label: 'Pedro ii'},
    { stateCode: 'PI', label: 'Pedro Laurentino'},
    { stateCode: 'PI', label: 'Picos'},
    { stateCode: 'PI', label: 'Pimenteiras'},
    { stateCode: 'PI', label: 'Pio ix'},
    { stateCode: 'PI', label: 'Piracuruca'},
    { stateCode: 'PI', label: 'Piripiri'},
    { stateCode: 'PI', label: 'Porto'},
    { stateCode: 'PI', label: 'Porto Alegre do Piauí'},
    { stateCode: 'PI', label: 'Prata do Piauí'},
    { stateCode: 'PI', label: 'Queimada Nova'},
    { stateCode: 'PI', label: 'Redenção do Gurguéia'},
    { stateCode: 'PI', label: 'Regeneração'},
    { stateCode: 'PI', label: 'Riacho Frio'},
    { stateCode: 'PI', label: 'Ribeira do Piauí'},
    { stateCode: 'PI', label: 'Ribeiro Gonçalves'},
    { stateCode: 'PI', label: 'Rio Grande do Piauí'},
    { stateCode: 'PI', label: 'Santa Cruz do Piauí'},
    { stateCode: 'PI', label: 'Santa Cruz Dos Milagres'},
    { stateCode: 'PI', label: 'Santa Filomena'},
    { stateCode: 'PI', label: 'Santa Luz'},
    { stateCode: 'PI', label: 'Santa Rosa do Piauí'},
    { stateCode: 'PI', label: 'Santana do Piauí'},
    { stateCode: 'PI', label: 'Santo Antônio de Lisboa'},
    { stateCode: 'PI', label: 'Santo Antônio Dos Milagres'},
    { stateCode: 'PI', label: 'Santo Inácio do Piauí'},
    { stateCode: 'PI', label: 'São Braz do Piauí'},
    { stateCode: 'PI', label: 'São Félix do Piauí'},
    { stateCode: 'PI', label: 'São Francisco de Assis do Piauí'},
    { stateCode: 'PI', label: 'São Francisco do Piauí'},
    { stateCode: 'PI', label: 'São Gonçalo do Gurguéia'},
    { stateCode: 'PI', label: 'São Gonçalo do Piauí'},
    { stateCode: 'PI', label: 'São João da Canabrava'},
    { stateCode: 'PI', label: 'São João da Fronteira'},
    { stateCode: 'PI', label: 'São João da Serra'},
    { stateCode: 'PI', label: 'São João da Varjota'},
    { stateCode: 'PI', label: 'São João do Arraial'},
    { stateCode: 'PI', label: 'São João do Piauí'},
    { stateCode: 'PI', label: 'São José do Divino'},
    { stateCode: 'PI', label: 'São José do Peixe'},
    { stateCode: 'PI', label: 'São José do Piauí'},
    { stateCode: 'PI', label: 'São Julião'},
    { stateCode: 'PI', label: 'São Lourenço do Piauí'},
    { stateCode: 'PI', label: 'São Luis do Piauí'},
    { stateCode: 'PI', label: 'São Miguel da Baixa Grande'},
    { stateCode: 'PI', label: 'São Miguel do Fidalgo'},
    { stateCode: 'PI', label: 'São Miguel do Tapuio'},
    { stateCode: 'PI', label: 'São Pedro do Piauí'},
    { stateCode: 'PI', label: 'São Raimundo Nonato'},
    { stateCode: 'PI', label: 'Sebastião Barros'},
    { stateCode: 'PI', label: 'Sebastião Leal'},
    { stateCode: 'PI', label: 'Sigefredo Pacheco'},
    { stateCode: 'PI', label: 'Simões'},
    { stateCode: 'PI', label: 'Simplício Mendes'},
    { stateCode: 'PI', label: 'Socorro do Piauí'},
    { stateCode: 'PI', label: 'Sussuapara'},
    { stateCode: 'PI', label: 'Tamboril do Piauí'},
    { stateCode: 'PI', label: 'Tanque do Piauí'},
    { stateCode: 'PI', label: 'Teresina'},
    { stateCode: 'PI', label: 'União'},
    { stateCode: 'PI', label: 'Uruçuí'},
    { stateCode: 'PI', label: 'Valença do Piauí'},
    { stateCode: 'PI', label: 'Várzea Branca'},
    { stateCode: 'PI', label: 'Várzea Grande'},
    { stateCode: 'PI', label: 'Vera Mendes'},
    { stateCode: 'PI', label: 'Vila Nova do Piauí'},
    { stateCode: 'PI', label: 'Wall Ferraz'},
    { stateCode: 'PR', label: 'Abatiá'},
    { stateCode: 'PR', label: 'Adrianópolis'},
    { stateCode: 'PR', label: 'Agudos do Sul'},
    { stateCode: 'PR', label: 'Almirante Tamandaré'},
    { stateCode: 'PR', label: 'Altamira do Paraná'},
    { stateCode: 'PR', label: 'Alto Paraná'},
    { stateCode: 'PR', label: 'Alto Piquiri'},
    { stateCode: 'PR', label: 'Altônia'},
    { stateCode: 'PR', label: 'Alvorada do Sul'},
    { stateCode: 'PR', label: 'Amaporã'},
    { stateCode: 'PR', label: 'Ampére'},
    { stateCode: 'PR', label: 'Anahy'},
    { stateCode: 'PR', label: 'Andirá'},
    { stateCode: 'PR', label: 'Ângulo'},
    { stateCode: 'PR', label: 'Antonina'},
    { stateCode: 'PR', label: 'Antônio Olinto'},
    { stateCode: 'PR', label: 'Apucarana'},
    { stateCode: 'PR', label: 'Arapongas'},
    { stateCode: 'PR', label: 'Arapoti'},
    { stateCode: 'PR', label: 'Arapuã'},
    { stateCode: 'PR', label: 'Araruna'},
    { stateCode: 'PR', label: 'Araucária'},
    { stateCode: 'PR', label: 'Ariranha do Ivaí'},
    { stateCode: 'PR', label: 'Assaí'},
    { stateCode: 'PR', label: 'Assis Chateaubriand'},
    { stateCode: 'PR', label: 'Astorga'},
    { stateCode: 'PR', label: 'Atalaia'},
    { stateCode: 'PR', label: 'Balsa Nova'},
    { stateCode: 'PR', label: 'Bandeirantes'},
    { stateCode: 'PR', label: 'Barbosa Ferraz'},
    { stateCode: 'PR', label: 'Barra do Jacaré'},
    { stateCode: 'PR', label: 'Barracão'},
    { stateCode: 'PR', label: 'Bela Vista da Caroba'},
    { stateCode: 'PR', label: 'Bela Vista do Paraíso'},
    { stateCode: 'PR', label: 'Bituruna'},
    { stateCode: 'PR', label: 'Boa Esperança'},
    { stateCode: 'PR', label: 'Boa Esperança do Iguaçu'},
    { stateCode: 'PR', label: 'Boa Ventura de São Roque'},
    { stateCode: 'PR', label: 'Boa Vista da Aparecida'},
    { stateCode: 'PR', label: 'Bocaiúva do Sul'},
    { stateCode: 'PR', label: 'Bom Jesus do Sul'},
    { stateCode: 'PR', label: 'Bom Sucesso'},
    { stateCode: 'PR', label: 'Bom Sucesso do Sul'},
    { stateCode: 'PR', label: 'Borrazópolis'},
    { stateCode: 'PR', label: 'Braganey'},
    { stateCode: 'PR', label: 'Brasilândia do Sul'},
    { stateCode: 'PR', label: 'Cafeara'},
    { stateCode: 'PR', label: 'Cafelândia'},
    { stateCode: 'PR', label: 'Cafezal do Sul'},
    { stateCode: 'PR', label: 'Califórnia'},
    { stateCode: 'PR', label: 'Cambará'},
    { stateCode: 'PR', label: 'Cambé'},
    { stateCode: 'PR', label: 'Cambira'},
    { stateCode: 'PR', label: 'Campina da Lagoa'},
    { stateCode: 'PR', label: 'Campina do Simão'},
    { stateCode: 'PR', label: 'Campina Grande do Sul'},
    { stateCode: 'PR', label: 'Campo Bonito'},
    { stateCode: 'PR', label: 'Campo do Tenente'},
    { stateCode: 'PR', label: 'Campo Largo'},
    { stateCode: 'PR', label: 'Campo Magro'},
    { stateCode: 'PR', label: 'Campo Mourão'},
    { stateCode: 'PR', label: 'Cândido de Abreu'},
    { stateCode: 'PR', label: 'Candói'},
    { stateCode: 'PR', label: 'Cantagalo'},
    { stateCode: 'PR', label: 'Capanema'},
    { stateCode: 'PR', label: 'Capitão Leônidas Marques'},
    { stateCode: 'PR', label: 'Carambeí'},
    { stateCode: 'PR', label: 'Carlópolis'},
    { stateCode: 'PR', label: 'Cascavel'},
    { stateCode: 'PR', label: 'Castro'},
    { stateCode: 'PR', label: 'Catanduvas'},
    { stateCode: 'PR', label: 'Centenário do Sul'},
    { stateCode: 'PR', label: 'Cerro Azul'},
    { stateCode: 'PR', label: 'Céu Azul'},
    { stateCode: 'PR', label: 'Chopinzinho'},
    { stateCode: 'PR', label: 'Cianorte'},
    { stateCode: 'PR', label: 'Cidade Gaúcha'},
    { stateCode: 'PR', label: 'Clevelândia'},
    { stateCode: 'PR', label: 'Colombo'},
    { stateCode: 'PR', label: 'Colorado'},
    { stateCode: 'PR', label: 'Congonhinhas'},
    { stateCode: 'PR', label: 'Conselheiro Mairinck'},
    { stateCode: 'PR', label: 'Contenda'},
    { stateCode: 'PR', label: 'Corbélia'},
    { stateCode: 'PR', label: 'Cornélio Procópio'},
    { stateCode: 'PR', label: 'Coronel Domingos Soares'},
    { stateCode: 'PR', label: 'Coronel Vivida'},
    { stateCode: 'PR', label: 'Corumbataí do Sul'},
    { stateCode: 'PR', label: 'Cruz Machado'},
    { stateCode: 'PR', label: 'Cruzeiro do Iguaçu'},
    { stateCode: 'PR', label: 'Cruzeiro do Oeste'},
    { stateCode: 'PR', label: 'Cruzeiro do Sul'},
    { stateCode: 'PR', label: 'Cruzmaltina'},
    { stateCode: 'PR', label: 'Curitiba'},
    { stateCode: 'PR', label: 'Curiúva'},
    { stateCode: 'PR', label: 'Diamante D´oeste'},
    { stateCode: 'PR', label: 'Diamante do Norte'},
    { stateCode: 'PR', label: 'Diamante do Sul'},
    { stateCode: 'PR', label: 'Dois Vizinhos'},
    { stateCode: 'PR', label: 'Douradina'},
    { stateCode: 'PR', label: 'Doutor Camargo'},
    { stateCode: 'PR', label: 'Doutor Ulysses'},
    { stateCode: 'PR', label: 'Enéas Marques'},
    { stateCode: 'PR', label: 'Engenheiro Beltrão'},
    { stateCode: 'PR', label: 'Entre Rios do Oeste'},
    { stateCode: 'PR', label: 'Esperança Nova'},
    { stateCode: 'PR', label: 'Espigão Alto do Iguaçu'},
    { stateCode: 'PR', label: 'Farol'},
    { stateCode: 'PR', label: 'Faxinal'},
    { stateCode: 'PR', label: 'Fazenda Rio Grande'},
    { stateCode: 'PR', label: 'Fênix'},
    { stateCode: 'PR', label: 'Fernandes Pinheiro'},
    { stateCode: 'PR', label: 'Figueira'},
    { stateCode: 'PR', label: 'Flor da Serra do Sul'},
    { stateCode: 'PR', label: 'Floraí'},
    { stateCode: 'PR', label: 'Floresta'},
    { stateCode: 'PR', label: 'Florestópolis'},
    { stateCode: 'PR', label: 'Flórida'},
    { stateCode: 'PR', label: 'Formosa do Oeste'},
    { stateCode: 'PR', label: 'Foz do Iguaçu'},
    { stateCode: 'PR', label: 'Foz do Jordão'},
    { stateCode: 'PR', label: 'Francisco Alves'},
    { stateCode: 'PR', label: 'Francisco Beltrão'},
    { stateCode: 'PR', label: 'General Carneiro'},
    { stateCode: 'PR', label: 'Godoy Moreira'},
    { stateCode: 'PR', label: 'Goioerê'},
    { stateCode: 'PR', label: 'Goioxim'},
    { stateCode: 'PR', label: 'Grandes Rios'},
    { stateCode: 'PR', label: 'Guaíra'},
    { stateCode: 'PR', label: 'Guairaçá'},
    { stateCode: 'PR', label: 'Guamiranga'},
    { stateCode: 'PR', label: 'Guapirama'},
    { stateCode: 'PR', label: 'Guaporema'},
    { stateCode: 'PR', label: 'Guaraci'},
    { stateCode: 'PR', label: 'Guaraniaçu'},
    { stateCode: 'PR', label: 'Guarapuava'},
    { stateCode: 'PR', label: 'Guaraqueçaba'},
    { stateCode: 'PR', label: 'Guaratuba'},
    { stateCode: 'PR', label: 'Honório Serpa'},
    { stateCode: 'PR', label: 'Ibaiti'},
    { stateCode: 'PR', label: 'Ibema'},
    { stateCode: 'PR', label: 'Ibiporã'},
    { stateCode: 'PR', label: 'Icaraíma'},
    { stateCode: 'PR', label: 'Iguaraçu'},
    { stateCode: 'PR', label: 'Iguatu'},
    { stateCode: 'PR', label: 'Imbaú'},
    { stateCode: 'PR', label: 'Imbituva'},
    { stateCode: 'PR', label: 'Inácio Martins'},
    { stateCode: 'PR', label: 'Inajá'},
    { stateCode: 'PR', label: 'Indianópolis'},
    { stateCode: 'PR', label: 'Ipiranga'},
    { stateCode: 'PR', label: 'Iporã'},
    { stateCode: 'PR', label: 'Iracema do Oeste'},
    { stateCode: 'PR', label: 'Irati'},
    { stateCode: 'PR', label: 'Iretama'},
    { stateCode: 'PR', label: 'Itaguajé'},
    { stateCode: 'PR', label: 'Itaipulândia'},
    { stateCode: 'PR', label: 'Itambaracá'},
    { stateCode: 'PR', label: 'Itambé'},
    { stateCode: 'PR', label: 'Itapejara D´oeste'},
    { stateCode: 'PR', label: 'Itaperuçu'},
    { stateCode: 'PR', label: 'Itaúna do Sul'},
    { stateCode: 'PR', label: 'Ivaí'},
    { stateCode: 'PR', label: 'Ivaiporã'},
    { stateCode: 'PR', label: 'Ivaté'},
    { stateCode: 'PR', label: 'Ivatuba'},
    { stateCode: 'PR', label: 'Jaboti'},
    { stateCode: 'PR', label: 'Jacarezinho'},
    { stateCode: 'PR', label: 'Jaguapitã'},
    { stateCode: 'PR', label: 'Jaguariaíva'},
    { stateCode: 'PR', label: 'Jandaia do Sul'},
    { stateCode: 'PR', label: 'Janiópolis'},
    { stateCode: 'PR', label: 'Japira'},
    { stateCode: 'PR', label: 'Japurá'},
    { stateCode: 'PR', label: 'Jardim Alegre'},
    { stateCode: 'PR', label: 'Jardim Olinda'},
    { stateCode: 'PR', label: 'Jataizinho'},
    { stateCode: 'PR', label: 'Jesuítas'},
    { stateCode: 'PR', label: 'Joaquim Távora'},
    { stateCode: 'PR', label: 'Jundiaí do Sul'},
    { stateCode: 'PR', label: 'Juranda'},
    { stateCode: 'PR', label: 'Jussara'},
    { stateCode: 'PR', label: 'Kaloré'},
    { stateCode: 'PR', label: 'Lapa'},
    { stateCode: 'PR', label: 'Laranjal'},
    { stateCode: 'PR', label: 'Laranjeiras do Sul'},
    { stateCode: 'PR', label: 'Leópolis'},
    { stateCode: 'PR', label: 'Lidianópolis'},
    { stateCode: 'PR', label: 'Lindoeste'},
    { stateCode: 'PR', label: 'Loanda'},
    { stateCode: 'PR', label: 'Lobato'},
    { stateCode: 'PR', label: 'Londrina'},
    { stateCode: 'PR', label: 'Luiziana'},
    { stateCode: 'PR', label: 'Lunardelli'},
    { stateCode: 'PR', label: 'Lupionópolis'},
    { stateCode: 'PR', label: 'Mallet'},
    { stateCode: 'PR', label: 'Mamborê'},
    { stateCode: 'PR', label: 'Mandaguaçu'},
    { stateCode: 'PR', label: 'Mandaguari'},
    { stateCode: 'PR', label: 'Mandirituba'},
    { stateCode: 'PR', label: 'Manfrinópolis'},
    { stateCode: 'PR', label: 'Mangueirinha'},
    { stateCode: 'PR', label: 'Manoel Ribas'},
    { stateCode: 'PR', label: 'Marechal Cândido Rondon'},
    { stateCode: 'PR', label: 'Maria Helena'},
    { stateCode: 'PR', label: 'Marialva'},
    { stateCode: 'PR', label: 'Marilândia do Sul'},
    { stateCode: 'PR', label: 'Marilena'},
    { stateCode: 'PR', label: 'Mariluz'},
    { stateCode: 'PR', label: 'Maringá'},
    { stateCode: 'PR', label: 'Mariópolis'},
    { stateCode: 'PR', label: 'Maripá'},
    { stateCode: 'PR', label: 'Marmeleiro'},
    { stateCode: 'PR', label: 'Marquinho'},
    { stateCode: 'PR', label: 'Marumbi'},
    { stateCode: 'PR', label: 'Matelândia'},
    { stateCode: 'PR', label: 'Matinhos'},
    { stateCode: 'PR', label: 'Mato Rico'},
    { stateCode: 'PR', label: 'Mauá da Serra'},
    { stateCode: 'PR', label: 'Medianeira'},
    { stateCode: 'PR', label: 'Mercedes'},
    { stateCode: 'PR', label: 'Mirador'},
    { stateCode: 'PR', label: 'Miraselva'},
    { stateCode: 'PR', label: 'Missal'},
    { stateCode: 'PR', label: 'Moreira Sales'},
    { stateCode: 'PR', label: 'Morretes'},
    { stateCode: 'PR', label: 'Munhoz de Melo'},
    { stateCode: 'PR', label: 'Nossa Senhora Das Graças'},
    { stateCode: 'PR', label: 'Nova Aliança do Ivaí'},
    { stateCode: 'PR', label: 'Nova América da Colina'},
    { stateCode: 'PR', label: 'Nova Aurora'},
    { stateCode: 'PR', label: 'Nova Cantu'},
    { stateCode: 'PR', label: 'Nova Esperança'},
    { stateCode: 'PR', label: 'Nova Esperança do Sudoeste'},
    { stateCode: 'PR', label: 'Nova Fátima'},
    { stateCode: 'PR', label: 'Nova Laranjeiras'},
    { stateCode: 'PR', label: 'Nova Londrina'},
    { stateCode: 'PR', label: 'Nova Olímpia'},
    { stateCode: 'PR', label: 'Nova Prata do Iguaçu'},
    { stateCode: 'PR', label: 'Nova Santa Bárbara'},
    { stateCode: 'PR', label: 'Nova Santa Rosa'},
    { stateCode: 'PR', label: 'Nova Tebas'},
    { stateCode: 'PR', label: 'Novo Itacolomi'},
    { stateCode: 'PR', label: 'Ortigueira'},
    { stateCode: 'PR', label: 'Ourizona'},
    { stateCode: 'PR', label: 'Ouro Verde do Oeste'},
    { stateCode: 'PR', label: 'Paiçandu'},
    { stateCode: 'PR', label: 'Palmas'},
    { stateCode: 'PR', label: 'Palmeira'},
    { stateCode: 'PR', label: 'Palmital'},
    { stateCode: 'PR', label: 'Palotina'},
    { stateCode: 'PR', label: 'Paraíso do Norte'},
    { stateCode: 'PR', label: 'Paranacity'},
    { stateCode: 'PR', label: 'Paranaguá'},
    { stateCode: 'PR', label: 'Paranapoema'},
    { stateCode: 'PR', label: 'Paranavaí'},
    { stateCode: 'PR', label: 'Pato Bragado'},
    { stateCode: 'PR', label: 'Pato Branco'},
    { stateCode: 'PR', label: 'Paula Freitas'},
    { stateCode: 'PR', label: 'Paulo Frontin'},
    { stateCode: 'PR', label: 'Peabiru'},
    { stateCode: 'PR', label: 'Perobal'},
    { stateCode: 'PR', label: 'Pérola'},
    { stateCode: 'PR', label: 'Pérola D´oeste'},
    { stateCode: 'PR', label: 'Piên'},
    { stateCode: 'PR', label: 'Pinhais'},
    { stateCode: 'PR', label: 'Pinhal de São Bento'},
    { stateCode: 'PR', label: 'Pinhalão'},
    { stateCode: 'PR', label: 'Pinhão'},
    { stateCode: 'PR', label: 'Piraí do Sul'},
    { stateCode: 'PR', label: 'Piraquara'},
    { stateCode: 'PR', label: 'Pitanga'},
    { stateCode: 'PR', label: 'Pitangueiras'},
    { stateCode: 'PR', label: 'Planaltina do Paraná'},
    { stateCode: 'PR', label: 'Planalto'},
    { stateCode: 'PR', label: 'Ponta Grossa'},
    { stateCode: 'PR', label: 'Pontal do Paraná'},
    { stateCode: 'PR', label: 'Porecatu'},
    { stateCode: 'PR', label: 'Porto Amazonas'},
    { stateCode: 'PR', label: 'Porto Barreiro'},
    { stateCode: 'PR', label: 'Porto Rico'},
    { stateCode: 'PR', label: 'Porto Vitória'},
    { stateCode: 'PR', label: 'Prado Ferreira'},
    { stateCode: 'PR', label: 'Pranchita'},
    { stateCode: 'PR', label: 'Presidente Castelo Branco'},
    { stateCode: 'PR', label: 'Primeiro de Maio'},
    { stateCode: 'PR', label: 'Prudentópolis'},
    { stateCode: 'PR', label: 'Quarto Centenário'},
    { stateCode: 'PR', label: 'Quatiguá'},
    { stateCode: 'PR', label: 'Quatro Barras'},
    { stateCode: 'PR', label: 'Quatro Pontes'},
    { stateCode: 'PR', label: 'Quedas do Iguaçu'},
    { stateCode: 'PR', label: 'Querência do Norte'},
    { stateCode: 'PR', label: 'Quinta do Sol'},
    { stateCode: 'PR', label: 'Quitandinha'},
    { stateCode: 'PR', label: 'Ramilândia'},
    { stateCode: 'PR', label: 'Rancho Alegre'},
    { stateCode: 'PR', label: 'Rancho Alegre D´oeste'},
    { stateCode: 'PR', label: 'Realeza'},
    { stateCode: 'PR', label: 'Rebouças'},
    { stateCode: 'PR', label: 'Renascença'},
    { stateCode: 'PR', label: 'Reserva'},
    { stateCode: 'PR', label: 'Reserva do Iguaçu'},
    { stateCode: 'PR', label: 'Ribeirão Claro'},
    { stateCode: 'PR', label: 'Ribeirão do Pinhal'},
    { stateCode: 'PR', label: 'Rio Azul'},
    { stateCode: 'PR', label: 'Rio Bom'},
    { stateCode: 'PR', label: 'Rio Bonito do Iguaçu'},
    { stateCode: 'PR', label: 'Rio Branco do Ivaí'},
    { stateCode: 'PR', label: 'Rio Branco do Sul'},
    { stateCode: 'PR', label: 'Rio Negro'},
    { stateCode: 'PR', label: 'Rolândia'},
    { stateCode: 'PR', label: 'Roncador'},
    { stateCode: 'PR', label: 'Rondon'},
    { stateCode: 'PR', label: 'Rosário do Ivaí'},
    { stateCode: 'PR', label: 'Sabáudia'},
    { stateCode: 'PR', label: 'Salgado Filho'},
    { stateCode: 'PR', label: 'Salto do Itararé'},
    { stateCode: 'PR', label: 'Salto do Lontra'},
    { stateCode: 'PR', label: 'Santa Amélia'},
    { stateCode: 'PR', label: 'Santa Cecília do Pavão'},
    { stateCode: 'PR', label: 'Santa Cruz de Monte Castelo'},
    { stateCode: 'PR', label: 'Santa fé'},
    { stateCode: 'PR', label: 'Santa Helena'},
    { stateCode: 'PR', label: 'Santa Inês'},
    { stateCode: 'PR', label: 'Santa Isabel do Ivaí'},
    { stateCode: 'PR', label: 'Santa Izabel do Oeste'},
    { stateCode: 'PR', label: 'Santa Lúcia'},
    { stateCode: 'PR', label: 'Santa Maria do Oeste'},
    { stateCode: 'PR', label: 'Santa Mariana'},
    { stateCode: 'PR', label: 'Santa Mônica'},
    { stateCode: 'PR', label: 'Santa Tereza do Oeste'},
    { stateCode: 'PR', label: 'Santa Terezinha de Itaipu'},
    { stateCode: 'PR', label: 'Santana do Itararé'},
    { stateCode: 'PR', label: 'Santo Antônio da Platina'},
    { stateCode: 'PR', label: 'Santo Antônio do Caiuá'},
    { stateCode: 'PR', label: 'Santo Antônio do Paraíso'},
    { stateCode: 'PR', label: 'Santo Antônio do Sudoeste'},
    { stateCode: 'PR', label: 'Santo Inácio'},
    { stateCode: 'PR', label: 'São Carlos do Ivaí'},
    { stateCode: 'PR', label: 'São Jerônimo da Serra'},
    { stateCode: 'PR', label: 'São João'},
    { stateCode: 'PR', label: 'São João do Caiuá'},
    { stateCode: 'PR', label: 'São João do Ivaí'},
    { stateCode: 'PR', label: 'São João do Triunfo'},
    { stateCode: 'PR', label: 'São Jorge D´oeste'},
    { stateCode: 'PR', label: 'São Jorge do Ivaí'},
    { stateCode: 'PR', label: 'São Jorge do Patrocínio'},
    { stateCode: 'PR', label: 'São José da Boa Vista'},
    { stateCode: 'PR', label: 'São José Das Palmeiras'},
    { stateCode: 'PR', label: 'São José Dos Pinhais'},
    { stateCode: 'PR', label: 'São Manoel do Paraná'},
    { stateCode: 'PR', label: 'São Mateus do Sul'},
    { stateCode: 'PR', label: 'São Miguel do Iguaçu'},
    { stateCode: 'PR', label: 'São Pedro do Iguaçu'},
    { stateCode: 'PR', label: 'São Pedro do Ivaí'},
    { stateCode: 'PR', label: 'São Pedro do Paraná'},
    { stateCode: 'PR', label: 'São Sebastião da Amoreira'},
    { stateCode: 'PR', label: 'São Tomé'},
    { stateCode: 'PR', label: 'Sapopema'},
    { stateCode: 'PR', label: 'Sarandi'},
    { stateCode: 'PR', label: 'Saudade do Iguaçu'},
    { stateCode: 'PR', label: 'Sengés'},
    { stateCode: 'PR', label: 'Serranópolis do Iguaçu'},
    { stateCode: 'PR', label: 'Sertaneja'},
    { stateCode: 'PR', label: 'Sertanópolis'},
    { stateCode: 'PR', label: 'Siqueira Campos'},
    { stateCode: 'PR', label: 'Sulina'},
    { stateCode: 'PR', label: 'Tamarana'},
    { stateCode: 'PR', label: 'Tamboara'},
    { stateCode: 'PR', label: 'Tapejara'},
    { stateCode: 'PR', label: 'Tapira'},
    { stateCode: 'PR', label: 'Teixeira Soares'},
    { stateCode: 'PR', label: 'Telêmaco Borba'},
    { stateCode: 'PR', label: 'Terra Boa'},
    { stateCode: 'PR', label: 'Terra Rica'},
    { stateCode: 'PR', label: 'Terra Roxa'},
    { stateCode: 'PR', label: 'Tibagi'},
    { stateCode: 'PR', label: 'Tijucas do Sul'},
    { stateCode: 'PR', label: 'Toledo'},
    { stateCode: 'PR', label: 'Tomazina'},
    { stateCode: 'PR', label: 'Três Barras do Paraná'},
    { stateCode: 'PR', label: 'Tunas do Paraná'},
    { stateCode: 'PR', label: 'Tuneiras do Oeste'},
    { stateCode: 'PR', label: 'Tupãssi'},
    { stateCode: 'PR', label: 'Turvo'},
    { stateCode: 'PR', label: 'Ubiratã'},
    { stateCode: 'PR', label: 'Umuarama'},
    { stateCode: 'PR', label: 'União da Vitória'},
    { stateCode: 'PR', label: 'Uniflor'},
    { stateCode: 'PR', label: 'Uraí'},
    { stateCode: 'PR', label: 'Ventania'},
    { stateCode: 'PR', label: 'Vera Cruz do Oeste'},
    { stateCode: 'PR', label: 'Verê'},
    { stateCode: 'PR', label: 'Vila Alta'},
    { stateCode: 'PR', label: 'Virmond'},
    { stateCode: 'PR', label: 'Vitorino'},
    { stateCode: 'PR', label: 'Wenceslau Braz'},
    { stateCode: 'PR', label: 'Xambrê'},
    { stateCode: 'RJ', label: 'Angra Dos Reis'},
    { stateCode: 'RJ', label: 'Aperibé'},
    { stateCode: 'RJ', label: 'Araruama'},
    { stateCode: 'RJ', label: 'Areal'},
    { stateCode: 'RJ', label: 'Armação Dos Búzios'},
    { stateCode: 'RJ', label: 'Arraial do Cabo'},
    { stateCode: 'RJ', label: 'Barra do Piraí'},
    { stateCode: 'RJ', label: 'Barra Mansa'},
    { stateCode: 'RJ', label: 'Belford Roxo'},
    { stateCode: 'RJ', label: 'Bom Jardim'},
    { stateCode: 'RJ', label: 'Bom Jesus do Itabapoana'},
    { stateCode: 'RJ', label: 'Cabo Frio'},
    { stateCode: 'RJ', label: 'Cachoeiras de Macacu'},
    { stateCode: 'RJ', label: 'Cambuci'},
    { stateCode: 'RJ', label: 'Campos Dos Goytacazes'},
    { stateCode: 'RJ', label: 'Cantagalo'},
    { stateCode: 'RJ', label: 'Carapebus'},
    { stateCode: 'RJ', label: 'Cardoso Moreira'},
    { stateCode: 'RJ', label: 'Carmo'},
    { stateCode: 'RJ', label: 'Casimiro de Abreu'},
    { stateCode: 'RJ', label: 'Comendador Levy Gasparian'},
    { stateCode: 'RJ', label: 'Conceição de Macabu'},
    { stateCode: 'RJ', label: 'Cordeiro'},
    { stateCode: 'RJ', label: 'Duas Barras'},
    { stateCode: 'RJ', label: 'Duque de Caxias'},
    { stateCode: 'RJ', label: 'Engenheiro Paulo de Frontin'},
    { stateCode: 'RJ', label: 'Guapimirim'},
    { stateCode: 'RJ', label: 'Iguaba Grande'},
    { stateCode: 'RJ', label: 'Itaboraí'},
    { stateCode: 'RJ', label: 'Itaguaí'},
    { stateCode: 'RJ', label: 'Italva'},
    { stateCode: 'RJ', label: 'Itaocara'},
    { stateCode: 'RJ', label: 'Itaperuna'},
    { stateCode: 'RJ', label: 'Itatiaia'},
    { stateCode: 'RJ', label: 'Japeri'},
    { stateCode: 'RJ', label: 'Laje do Muriaé'},
    { stateCode: 'RJ', label: 'Macaé'},
    { stateCode: 'RJ', label: 'Macuco'},
    { stateCode: 'RJ', label: 'Magé'},
    { stateCode: 'RJ', label: 'Mangaratiba'},
    { stateCode: 'RJ', label: 'Maricá'},
    { stateCode: 'RJ', label: 'Mendes'},
    { stateCode: 'RJ', label: 'Mesquita'},
    { stateCode: 'RJ', label: 'Miguel Pereira'},
    { stateCode: 'RJ', label: 'Miracema'},
    { stateCode: 'RJ', label: 'Natividade'},
    { stateCode: 'RJ', label: 'Nilópolis'},
    { stateCode: 'RJ', label: 'Niterói'},
    { stateCode: 'RJ', label: 'Nova Friburgo'},
    { stateCode: 'RJ', label: 'Nova Iguaçu'},
    { stateCode: 'RJ', label: 'Paracambi'},
    { stateCode: 'RJ', label: 'Paraíba do Sul'},
    { stateCode: 'RJ', label: 'Parati'},
    { stateCode: 'RJ', label: 'Paty do Alferes'},
    { stateCode: 'RJ', label: 'Petrópolis'},
    { stateCode: 'RJ', label: 'Pinheiral'},
    { stateCode: 'RJ', label: 'Piraí'},
    { stateCode: 'RJ', label: 'Porciúncula'},
    { stateCode: 'RJ', label: 'Porto Real'},
    { stateCode: 'RJ', label: 'Quatis'},
    { stateCode: 'RJ', label: 'Queimados'},
    { stateCode: 'RJ', label: 'Quissamã'},
    { stateCode: 'RJ', label: 'Resende'},
    { stateCode: 'RJ', label: 'Rio Bonito'},
    { stateCode: 'RJ', label: 'Rio Claro'},
    { stateCode: 'RJ', label: 'Rio Das Flores'},
    { stateCode: 'RJ', label: 'Rio Das Ostras'},
    { stateCode: 'RJ', label: 'Rio de Janeiro'},
    { stateCode: 'RJ', label: 'Santa Maria Madalena'},
    { stateCode: 'RJ', label: 'Santo Antônio de Pádua'},
    { stateCode: 'RJ', label: 'São Fidélis'},
    { stateCode: 'RJ', label: 'São Francisco de Itabapoana'},
    { stateCode: 'RJ', label: 'São Gonçalo'},
    { stateCode: 'RJ', label: 'São João da Barra'},
    { stateCode: 'RJ', label: 'São João de Meriti'},
    { stateCode: 'RJ', label: 'São José de Ubá'},
    { stateCode: 'RJ', label: 'São José do Vale do Rio Preto'},
    { stateCode: 'RJ', label: 'São Pedro da Aldeia'},
    { stateCode: 'RJ', label: 'São Sebastião do Alto'},
    { stateCode: 'RJ', label: 'Sapucaia'},
    { stateCode: 'RJ', label: 'Saquarema'},
    { stateCode: 'RJ', label: 'Seropédica'},
    { stateCode: 'RJ', label: 'Silva Jardim'},
    { stateCode: 'RJ', label: 'Sumidouro'},
    { stateCode: 'RJ', label: 'Tanguá'},
    { stateCode: 'RJ', label: 'Teresópolis'},
    { stateCode: 'RJ', label: 'Trajano de Morais'},
    { stateCode: 'RJ', label: 'Três Rios'},
    { stateCode: 'RJ', label: 'Valença'},
    { stateCode: 'RJ', label: 'Varre-sai'},
    { stateCode: 'RJ', label: 'Vassouras'},
    { stateCode: 'RJ', label: 'Volta Redonda'},
    { stateCode: 'RN', label: 'Acari'},
    { stateCode: 'RN', label: 'Açu'},
    { stateCode: 'RN', label: 'Afonso Bezerra'},
    { stateCode: 'RN', label: 'Água Nova'},
    { stateCode: 'RN', label: 'Alexandria'},
    { stateCode: 'RN', label: 'Almino Afonso'},
    { stateCode: 'RN', label: 'Alto do Rodrigues'},
    { stateCode: 'RN', label: 'Angicos'},
    { stateCode: 'RN', label: 'Antônio Martins'},
    { stateCode: 'RN', label: 'Apodi'},
    { stateCode: 'RN', label: 'Areia Branca'},
    { stateCode: 'RN', label: 'Arês'},
    { stateCode: 'RN', label: 'Augusto Severo'},
    { stateCode: 'RN', label: 'Baía Formosa'},
    { stateCode: 'RN', label: 'Baraúna'},
    { stateCode: 'RN', label: 'Barcelona'},
    { stateCode: 'RN', label: 'Bento Fernandes'},
    { stateCode: 'RN', label: 'Bodó'},
    { stateCode: 'RN', label: 'Bom Jesus'},
    { stateCode: 'RN', label: 'Brejinho'},
    { stateCode: 'RN', label: 'Caiçara do Norte'},
    { stateCode: 'RN', label: 'Caiçara do Rio do Vento'},
    { stateCode: 'RN', label: 'Caicó'},
    { stateCode: 'RN', label: 'Campo Redondo'},
    { stateCode: 'RN', label: 'Canguaretama'},
    { stateCode: 'RN', label: 'Caraúbas'},
    { stateCode: 'RN', label: 'Carnaúba Dos Dantas'},
    { stateCode: 'RN', label: 'Carnaubais'},
    { stateCode: 'RN', label: 'Ceará-mirim'},
    { stateCode: 'RN', label: 'Cerro Corá'},
    { stateCode: 'RN', label: 'Coronel Ezequiel'},
    { stateCode: 'RN', label: 'Coronel João Pessoa'},
    { stateCode: 'RN', label: 'Cruzeta'},
    { stateCode: 'RN', label: 'Currais Novos'},
    { stateCode: 'RN', label: 'Doutor Severiano'},
    { stateCode: 'RN', label: 'Encanto'},
    { stateCode: 'RN', label: 'Equador'},
    { stateCode: 'RN', label: 'Espírito Santo'},
    { stateCode: 'RN', label: 'Extremoz'},
    { stateCode: 'RN', label: 'Felipe Guerra'},
    { stateCode: 'RN', label: 'Fernando Pedroza'},
    { stateCode: 'RN', label: 'Florânia'},
    { stateCode: 'RN', label: 'Francisco Dantas'},
    { stateCode: 'RN', label: 'Frutuoso Gomes'},
    { stateCode: 'RN', label: 'Galinhos'},
    { stateCode: 'RN', label: 'Goianinha'},
    { stateCode: 'RN', label: 'Governador Dix-sept Rosado'},
    { stateCode: 'RN', label: 'Grossos'},
    { stateCode: 'RN', label: 'Guamaré'},
    { stateCode: 'RN', label: 'Ielmo Marinho'},
    { stateCode: 'RN', label: 'Ipanguaçu'},
    { stateCode: 'RN', label: 'Ipueira'},
    { stateCode: 'RN', label: 'Itajá'},
    { stateCode: 'RN', label: 'Itaú'},
    { stateCode: 'RN', label: 'Jaçanã'},
    { stateCode: 'RN', label: 'Jandaíra'},
    { stateCode: 'RN', label: 'Janduís'},
    { stateCode: 'RN', label: 'Januário Cicco'},
    { stateCode: 'RN', label: 'Japi'},
    { stateCode: 'RN', label: 'Jardim de Angicos'},
    { stateCode: 'RN', label: 'Jardim de Piranhas'},
    { stateCode: 'RN', label: 'Jardim do Seridó'},
    { stateCode: 'RN', label: 'João Câmara'},
    { stateCode: 'RN', label: 'João Dias'},
    { stateCode: 'RN', label: 'José da Penha'},
    { stateCode: 'RN', label: 'Jucurutu'},
    { stateCode: 'RN', label: 'Jundiá'},
    { stateCode: 'RN', label: 'Lagoa D´anta'},
    { stateCode: 'RN', label: 'Lagoa de Pedras'},
    { stateCode: 'RN', label: 'Lagoa de Velhos'},
    { stateCode: 'RN', label: 'Lagoa Nova'},
    { stateCode: 'RN', label: 'Lagoa Salgada'},
    { stateCode: 'RN', label: 'Lajes'},
    { stateCode: 'RN', label: 'Lajes Pintadas'},
    { stateCode: 'RN', label: 'Lucrécia'},
    { stateCode: 'RN', label: 'Luís Gomes'},
    { stateCode: 'RN', label: 'Macaíba'},
    { stateCode: 'RN', label: 'Macau'},
    { stateCode: 'RN', label: 'Major Sales'},
    { stateCode: 'RN', label: 'Marcelino Vieira'},
    { stateCode: 'RN', label: 'Martins'},
    { stateCode: 'RN', label: 'Maxaranguape'},
    { stateCode: 'RN', label: 'Messias Targino'},
    { stateCode: 'RN', label: 'Montanhas'},
    { stateCode: 'RN', label: 'Monte Alegre'},
    { stateCode: 'RN', label: 'Monte Das Gameleiras'},
    { stateCode: 'RN', label: 'Mossoró'},
    { stateCode: 'RN', label: 'Natal'},
    { stateCode: 'RN', label: 'Nísia Floresta'},
    { stateCode: 'RN', label: 'Nova Cruz'},
    { stateCode: 'RN', label: 'Olho-d´água do Borges'},
    { stateCode: 'RN', label: 'Ouro Branco'},
    { stateCode: 'RN', label: 'Paraná'},
    { stateCode: 'RN', label: 'Paraú'},
    { stateCode: 'RN', label: 'Parazinho'},
    { stateCode: 'RN', label: 'Parelhas'},
    { stateCode: 'RN', label: 'Parnamirim'},
    { stateCode: 'RN', label: 'Passa e Fica'},
    { stateCode: 'RN', label: 'Passagem'},
    { stateCode: 'RN', label: 'Patu'},
    { stateCode: 'RN', label: 'Pau Dos Ferros'},
    { stateCode: 'RN', label: 'Pedra Grande'},
    { stateCode: 'RN', label: 'Pedra Preta'},
    { stateCode: 'RN', label: 'Pedro Avelino'},
    { stateCode: 'RN', label: 'Pedro Velho'},
    { stateCode: 'RN', label: 'Pendências'},
    { stateCode: 'RN', label: 'Pilões'},
    { stateCode: 'RN', label: 'Poço Branco'},
    { stateCode: 'RN', label: 'Portalegre'},
    { stateCode: 'RN', label: 'Porto do Mangue'},
    { stateCode: 'RN', label: 'Presidente Juscelino'},
    { stateCode: 'RN', label: 'Pureza'},
    { stateCode: 'RN', label: 'Rafael Fernandes'},
    { stateCode: 'RN', label: 'Rafael Godeiro'},
    { stateCode: 'RN', label: 'Riacho da Cruz'},
    { stateCode: 'RN', label: 'Riacho de Santana'},
    { stateCode: 'RN', label: 'Riachuelo'},
    { stateCode: 'RN', label: 'Rio do Fogo'},
    { stateCode: 'RN', label: 'Rodolfo Fernandes'},
    { stateCode: 'RN', label: 'Ruy Barbosa'},
    { stateCode: 'RN', label: 'Santa Cruz'},
    { stateCode: 'RN', label: 'Santa Maria'},
    { stateCode: 'RN', label: 'Santana do Matos'},
    { stateCode: 'RN', label: 'Santana do Seridó'},
    { stateCode: 'RN', label: 'Santo Antônio'},
    { stateCode: 'RN', label: 'São Bento do Norte'},
    { stateCode: 'RN', label: 'São Bento do Trairí'},
    { stateCode: 'RN', label: 'São Fernando'},
    { stateCode: 'RN', label: 'São Francisco do Oeste'},
    { stateCode: 'RN', label: 'São Gonçalo do Amarante'},
    { stateCode: 'RN', label: 'São João do Sabugi'},
    { stateCode: 'RN', label: 'São José de Mipibu'},
    { stateCode: 'RN', label: 'São José do Campestre'},
    { stateCode: 'RN', label: 'São José do Seridó'},
    { stateCode: 'RN', label: 'São Miguel'},
    { stateCode: 'RN', label: 'São Miguel do Gostoso'},
    { stateCode: 'RN', label: 'São Paulo do Potengi'},
    { stateCode: 'RN', label: 'São Pedro'},
    { stateCode: 'RN', label: 'São Rafael'},
    { stateCode: 'RN', label: 'São Tomé'},
    { stateCode: 'RN', label: 'São Vicente'},
    { stateCode: 'RN', label: 'Senador Elói de Souza'},
    { stateCode: 'RN', label: 'Senador Georgino Avelino'},
    { stateCode: 'RN', label: 'Serra de São Bento'},
    { stateCode: 'RN', label: 'Serra do Mel'},
    { stateCode: 'RN', label: 'Serra Negra do Norte'},
    { stateCode: 'RN', label: 'Serrinha'},
    { stateCode: 'RN', label: 'Serrinha Dos Pintos'},
    { stateCode: 'RN', label: 'Severiano Melo'},
    { stateCode: 'RN', label: 'Sítio Novo'},
    { stateCode: 'RN', label: 'Taboleiro Grande'},
    { stateCode: 'RN', label: 'Taipu'},
    { stateCode: 'RN', label: 'Tangará'},
    { stateCode: 'RN', label: 'Tenente Ananias'},
    { stateCode: 'RN', label: 'Tenente Laurentino Cruz'},
    { stateCode: 'RN', label: 'Tibau'},
    { stateCode: 'RN', label: 'Tibau do Sul'},
    { stateCode: 'RN', label: 'Timbaúba Dos Batistas'},
    { stateCode: 'RN', label: 'Touros'},
    { stateCode: 'RN', label: 'Triunfo Potiguar'},
    { stateCode: 'RN', label: 'Umarizal'},
    { stateCode: 'RN', label: 'Upanema'},
    { stateCode: 'RN', label: 'Várzea'},
    { stateCode: 'RN', label: 'Venha-ver'},
    { stateCode: 'RN', label: 'Vera Cruz'},
    { stateCode: 'RN', label: 'Viçosa'},
    { stateCode: 'RN', label: 'Vila Flor'},
    { stateCode: 'RO', label: 'Alta Floresta D´oeste'},
    { stateCode: 'RO', label: 'Alto Alegre Dos Parecis'},
    { stateCode: 'RO', label: 'Alto Paraíso'},
    { stateCode: 'RO', label: 'Alvorada D´oeste'},
    { stateCode: 'RO', label: 'Ariquemes'},
    { stateCode: 'RO', label: 'Buritis'},
    { stateCode: 'RO', label: 'Cabixi'},
    { stateCode: 'RO', label: 'Cacaulândia'},
    { stateCode: 'RO', label: 'Cacoal'},
    { stateCode: 'RO', label: 'Campo Novo de Rondônia'},
    { stateCode: 'RO', label: 'Candeias do Jamari'},
    { stateCode: 'RO', label: 'Castanheiras'},
    { stateCode: 'RO', label: 'Cerejeiras'},
    { stateCode: 'RO', label: 'Chupinguaia'},
    { stateCode: 'RO', label: 'Colorado do Oeste'},
    { stateCode: 'RO', label: 'Corumbiara'},
    { stateCode: 'RO', label: 'Costa Marques'},
    { stateCode: 'RO', label: 'Cujubim'},
    { stateCode: 'RO', label: 'Espigão D´oeste'},
    { stateCode: 'RO', label: 'Governador Jorge Teixeira'},
    { stateCode: 'RO', label: 'Guajará-mirim'},
    { stateCode: 'RO', label: 'Itapuã do Oeste'},
    { stateCode: 'RO', label: 'Jaru'},
    { stateCode: 'RO', label: 'Ji-paraná'},
    { stateCode: 'RO', label: 'Machadinho D´oeste'},
    { stateCode: 'RO', label: 'Ministro Andreazza'},
    { stateCode: 'RO', label: 'Mirante da Serra'},
    { stateCode: 'RO', label: 'Monte Negro'},
    { stateCode: 'RO', label: 'Nova Brasilândia D´oeste'},
    { stateCode: 'RO', label: 'Nova Mamoré'},
    { stateCode: 'RO', label: 'Nova União'},
    { stateCode: 'RO', label: 'Novo Horizonte do Oeste'},
    { stateCode: 'RO', label: 'Ouro Preto do Oeste'},
    { stateCode: 'RO', label: 'Parecis'},
    { stateCode: 'RO', label: 'Pimenta Bueno'},
    { stateCode: 'RO', label: 'Pimenteiras do Oeste'},
    { stateCode: 'RO', label: 'Porto Velho'},
    { stateCode: 'RO', label: 'Presidente Médici'},
    { stateCode: 'RO', label: 'Primavera de Rondônia'},
    { stateCode: 'RO', label: 'Rio Crespo'},
    { stateCode: 'RO', label: 'Rolim de Moura'},
    { stateCode: 'RO', label: 'Santa Luzia D´oeste'},
    { stateCode: 'RO', label: 'São Felipe D´oeste'},
    { stateCode: 'RO', label: 'São Francisco do Guaporé'},
    { stateCode: 'RO', label: 'São Miguel do Guaporé'},
    { stateCode: 'RO', label: 'Seringueiras'},
    { stateCode: 'RO', label: 'Teixeirópolis'},
    { stateCode: 'RO', label: 'Theobroma'},
    { stateCode: 'RO', label: 'Urupá'},
    { stateCode: 'RO', label: 'Vale do Anari'},
    { stateCode: 'RO', label: 'Vale do Paraíso'},
    { stateCode: 'RO', label: 'Vilhena'},
    { stateCode: 'RR', label: 'Alto Alegre'},
    { stateCode: 'RR', label: 'Amajari'},
    { stateCode: 'RR', label: 'Boa Vista'},
    { stateCode: 'RR', label: 'Bonfim'},
    { stateCode: 'RR', label: 'Cantá'},
    { stateCode: 'RR', label: 'Caracaraí'},
    { stateCode: 'RR', label: 'Caroebe'},
    { stateCode: 'RR', label: 'Iracema'},
    { stateCode: 'RR', label: 'Mucajaí'},
    { stateCode: 'RR', label: 'Normandia'},
    { stateCode: 'RR', label: 'Pacaraima'},
    { stateCode: 'RR', label: 'Rorainópolis'},
    { stateCode: 'RR', label: 'São João da Baliza'},
    { stateCode: 'RR', label: 'São Luiz'},
    { stateCode: 'RR', label: 'Uiramutã'},
    { stateCode: 'RS', label: 'Aceguá'},
    { stateCode: 'RS', label: 'Água Santa'},
    { stateCode: 'RS', label: 'Agudo'},
    { stateCode: 'RS', label: 'Ajuricaba'},
    { stateCode: 'RS', label: 'Alecrim'},
    { stateCode: 'RS', label: 'Alegrete'},
    { stateCode: 'RS', label: 'Alegria'},
    { stateCode: 'RS', label: 'Almirante Tamandaré do Sul'},
    { stateCode: 'RS', label: 'Alpestre'},
    { stateCode: 'RS', label: 'Alto Alegre'},
    { stateCode: 'RS', label: 'Alto Feliz'},
    { stateCode: 'RS', label: 'Alvorada'},
    { stateCode: 'RS', label: 'Amaral Ferrador'},
    { stateCode: 'RS', label: 'Ametista do Sul'},
    { stateCode: 'RS', label: 'André da Rocha'},
    { stateCode: 'RS', label: 'Anta Gorda'},
    { stateCode: 'RS', label: 'Antônio Prado'},
    { stateCode: 'RS', label: 'Arambaré'},
    { stateCode: 'RS', label: 'Araricá'},
    { stateCode: 'RS', label: 'Aratiba'},
    { stateCode: 'RS', label: 'Arroio do Meio'},
    { stateCode: 'RS', label: 'Arroio do Padre'},
    { stateCode: 'RS', label: 'Arroio do Sal'},
    { stateCode: 'RS', label: 'Arroio do Tigre'},
    { stateCode: 'RS', label: 'Arroio Dos Ratos'},
    { stateCode: 'RS', label: 'Arroio Grande'},
    { stateCode: 'RS', label: 'Arvorezinha'},
    { stateCode: 'RS', label: 'Augusto Pestana'},
    { stateCode: 'RS', label: 'Áurea'},
    { stateCode: 'RS', label: 'Bagé'},
    { stateCode: 'RS', label: 'Balneário Pinhal'},
    { stateCode: 'RS', label: 'Barão'},
    { stateCode: 'RS', label: 'Barão de Cotegipe'},
    { stateCode: 'RS', label: 'Barão do Triunfo'},
    { stateCode: 'RS', label: 'Barra do Guarita'},
    { stateCode: 'RS', label: 'Barra do Quaraí'},
    { stateCode: 'RS', label: 'Barra do Ribeiro'},
    { stateCode: 'RS', label: 'Barra do Rio Azul'},
    { stateCode: 'RS', label: 'Barra Funda'},
    { stateCode: 'RS', label: 'Barracão'},
    { stateCode: 'RS', label: 'Barros Cassal'},
    { stateCode: 'RS', label: 'Benjamin Constant do Sul'},
    { stateCode: 'RS', label: 'Bento Gonçalves'},
    { stateCode: 'RS', label: 'Boa Vista Das Missões'},
    { stateCode: 'RS', label: 'Boa Vista do Buricá'},
    { stateCode: 'RS', label: 'Boa Vista do Cadeado'},
    { stateCode: 'RS', label: 'Boa Vista do Incra'},
    { stateCode: 'RS', label: 'Boa Vista do Sul'},
    { stateCode: 'RS', label: 'Bom Jesus'},
    { stateCode: 'RS', label: 'Bom Princípio'},
    { stateCode: 'RS', label: 'Bom Progresso'},
    { stateCode: 'RS', label: 'Bom Retiro do Sul'},
    { stateCode: 'RS', label: 'Boqueirão do Leão'},
    { stateCode: 'RS', label: 'Bossoroca'},
    { stateCode: 'RS', label: 'Bozano'},
    { stateCode: 'RS', label: 'Braga'},
    { stateCode: 'RS', label: 'Brochier'},
    { stateCode: 'RS', label: 'Butiá'},
    { stateCode: 'RS', label: 'Caçapava do Sul'},
    { stateCode: 'RS', label: 'Cacequi'},
    { stateCode: 'RS', label: 'Cachoeira do Sul'},
    { stateCode: 'RS', label: 'Cachoeirinha'},
    { stateCode: 'RS', label: 'Cacique Doble'},
    { stateCode: 'RS', label: 'Caibaté'},
    { stateCode: 'RS', label: 'Caiçara'},
    { stateCode: 'RS', label: 'Camaquã'},
    { stateCode: 'RS', label: 'Camargo'},
    { stateCode: 'RS', label: 'Cambará do Sul'},
    { stateCode: 'RS', label: 'Campestre da Serra'},
    { stateCode: 'RS', label: 'Campina Das Missões'},
    { stateCode: 'RS', label: 'Campinas do Sul'},
    { stateCode: 'RS', label: 'Campo Bom'},
    { stateCode: 'RS', label: 'Campo Novo'},
    { stateCode: 'RS', label: 'Campos Borges'},
    { stateCode: 'RS', label: 'Candelária'},
    { stateCode: 'RS', label: 'Cândido Godói'},
    { stateCode: 'RS', label: 'Candiota'},
    { stateCode: 'RS', label: 'Canela'},
    { stateCode: 'RS', label: 'Canguçu'},
    { stateCode: 'RS', label: 'Canoas'},
    { stateCode: 'RS', label: 'Canudos do Vale'},
    { stateCode: 'RS', label: 'Capão Bonito do Sul'},
    { stateCode: 'RS', label: 'Capão da Canoa'},
    { stateCode: 'RS', label: 'Capão do Cipó'},
    { stateCode: 'RS', label: 'Capão do Leão'},
    { stateCode: 'RS', label: 'Capela de Santana'},
    { stateCode: 'RS', label: 'Capitão'},
    { stateCode: 'RS', label: 'Capivari do Sul'},
    { stateCode: 'RS', label: 'Caraá'},
    { stateCode: 'RS', label: 'Carazinho'},
    { stateCode: 'RS', label: 'Carlos Barbosa'},
    { stateCode: 'RS', label: 'Carlos Gomes'},
    { stateCode: 'RS', label: 'Casca'},
    { stateCode: 'RS', label: 'Caseiros'},
    { stateCode: 'RS', label: 'Catuípe'},
    { stateCode: 'RS', label: 'Caxias do Sul'},
    { stateCode: 'RS', label: 'Centenário'},
    { stateCode: 'RS', label: 'Cerrito'},
    { stateCode: 'RS', label: 'Cerro Branco'},
    { stateCode: 'RS', label: 'Cerro Grande'},
    { stateCode: 'RS', label: 'Cerro Grande do Sul'},
    { stateCode: 'RS', label: 'Cerro Largo'},
    { stateCode: 'RS', label: 'Chapada'},
    { stateCode: 'RS', label: 'Charqueadas'},
    { stateCode: 'RS', label: 'Charrua'},
    { stateCode: 'RS', label: 'Chiapetta'},
    { stateCode: 'RS', label: 'Chuí'},
    { stateCode: 'RS', label: 'Chuvisca'},
    { stateCode: 'RS', label: 'Cidreira'},
    { stateCode: 'RS', label: 'Ciríaco'},
    { stateCode: 'RS', label: 'Colinas'},
    { stateCode: 'RS', label: 'Colorado'},
    { stateCode: 'RS', label: 'Condor'},
    { stateCode: 'RS', label: 'Constantina'},
    { stateCode: 'RS', label: 'Coqueiro Baixo'},
    { stateCode: 'RS', label: 'Coqueiros do Sul'},
    { stateCode: 'RS', label: 'Coronel Barros'},
    { stateCode: 'RS', label: 'Coronel Bicaco'},
    { stateCode: 'RS', label: 'Coronel Pilar'},
    { stateCode: 'RS', label: 'Cotiporã'},
    { stateCode: 'RS', label: 'Coxilha'},
    { stateCode: 'RS', label: 'Crissiumal'},
    { stateCode: 'RS', label: 'Cristal'},
    { stateCode: 'RS', label: 'Cristal do Sul'},
    { stateCode: 'RS', label: 'Cruz Alta'},
    { stateCode: 'RS', label: 'Cruzaltense'},
    { stateCode: 'RS', label: 'Cruzeiro do Sul'},
    { stateCode: 'RS', label: 'David Canabarro'},
    { stateCode: 'RS', label: 'Derrubadas'},
    { stateCode: 'RS', label: 'Dezesseis de Novembro'},
    { stateCode: 'RS', label: 'Dilermando de Aguiar'},
    { stateCode: 'RS', label: 'Dois Irmãos'},
    { stateCode: 'RS', label: 'Dois Irmãos Das Missões'},
    { stateCode: 'RS', label: 'Dois Lajeados'},
    { stateCode: 'RS', label: 'Dom Feliciano'},
    { stateCode: 'RS', label: 'Dom Pedrito'},
    { stateCode: 'RS', label: 'Dom Pedro de Alcântara'},
    { stateCode: 'RS', label: 'Dona Francisca'},
    { stateCode: 'RS', label: 'Doutor Maurício Cardoso'},
    { stateCode: 'RS', label: 'Doutor Ricardo'},
    { stateCode: 'RS', label: 'Eldorado do Sul'},
    { stateCode: 'RS', label: 'Encantado'},
    { stateCode: 'RS', label: 'Encruzilhada do Sul'},
    { stateCode: 'RS', label: 'Engenho Velho'},
    { stateCode: 'RS', label: 'Entre Rios do Sul'},
    { stateCode: 'RS', label: 'Entre-ijuís'},
    { stateCode: 'RS', label: 'Erebango'},
    { stateCode: 'RS', label: 'Erechim'},
    { stateCode: 'RS', label: 'Ernestina'},
    { stateCode: 'RS', label: 'Erval Grande'},
    { stateCode: 'RS', label: 'Erval Seco'},
    { stateCode: 'RS', label: 'Esmeralda'},
    { stateCode: 'RS', label: 'Esperança do Sul'},
    { stateCode: 'RS', label: 'Espumoso'},
    { stateCode: 'RS', label: 'Estação'},
    { stateCode: 'RS', label: 'Estância Velha'},
    { stateCode: 'RS', label: 'Esteio'},
    { stateCode: 'RS', label: 'Estrela'},
    { stateCode: 'RS', label: 'Estrela Velha'},
    { stateCode: 'RS', label: 'Eugênio de Castro'},
    { stateCode: 'RS', label: 'Fagundes Varela'},
    { stateCode: 'RS', label: 'Farroupilha'},
    { stateCode: 'RS', label: 'Faxinal do Soturno'},
    { stateCode: 'RS', label: 'Faxinalzinho'},
    { stateCode: 'RS', label: 'Fazenda Vilanova'},
    { stateCode: 'RS', label: 'Feliz'},
    { stateCode: 'RS', label: 'Flores da Cunha'},
    { stateCode: 'RS', label: 'Floriano Peixoto'},
    { stateCode: 'RS', label: 'Fontoura Xavier'},
    { stateCode: 'RS', label: 'Formigueiro'},
    { stateCode: 'RS', label: 'Forquetinha'},
    { stateCode: 'RS', label: 'Fortaleza Dos Valos'},
    { stateCode: 'RS', label: 'Frederico Westphalen'},
    { stateCode: 'RS', label: 'Garibaldi'},
    { stateCode: 'RS', label: 'Garruchos'},
    { stateCode: 'RS', label: 'Gaurama'},
    { stateCode: 'RS', label: 'General Câmara'},
    { stateCode: 'RS', label: 'Gentil'},
    { stateCode: 'RS', label: 'Getúlio Vargas'},
    { stateCode: 'RS', label: 'Giruá'},
    { stateCode: 'RS', label: 'Glorinha'},
    { stateCode: 'RS', label: 'Gramado'},
    { stateCode: 'RS', label: 'Gramado Dos Loureiros'},
    { stateCode: 'RS', label: 'Gramado Xavier'},
    { stateCode: 'RS', label: 'Gravataí'},
    { stateCode: 'RS', label: 'Guabiju'},
    { stateCode: 'RS', label: 'Guaíba'},
    { stateCode: 'RS', label: 'Guaporé'},
    { stateCode: 'RS', label: 'Guarani Das Missões'},
    { stateCode: 'RS', label: 'Harmonia'},
    { stateCode: 'RS', label: 'Herval'},
    { stateCode: 'RS', label: 'Herveiras'},
    { stateCode: 'RS', label: 'Horizontina'},
    { stateCode: 'RS', label: 'Hulha Negra'},
    { stateCode: 'RS', label: 'Humaitá'},
    { stateCode: 'RS', label: 'Ibarama'},
    { stateCode: 'RS', label: 'Ibiaçá'},
    { stateCode: 'RS', label: 'Ibiraiaras'},
    { stateCode: 'RS', label: 'Ibirapuitã'},
    { stateCode: 'RS', label: 'Ibirubá'},
    { stateCode: 'RS', label: 'Igrejinha'},
    { stateCode: 'RS', label: 'Ijuí'},
    { stateCode: 'RS', label: 'Ilópolis'},
    { stateCode: 'RS', label: 'Imbé'},
    { stateCode: 'RS', label: 'Imigrante'},
    { stateCode: 'RS', label: 'Independência'},
    { stateCode: 'RS', label: 'Inhacorá'},
    { stateCode: 'RS', label: 'Ipê'},
    { stateCode: 'RS', label: 'Ipiranga do Sul'},
    { stateCode: 'RS', label: 'Iraí'},
    { stateCode: 'RS', label: 'Itaara'},
    { stateCode: 'RS', label: 'Itacurubi'},
    { stateCode: 'RS', label: 'Itapuca'},
    { stateCode: 'RS', label: 'Itaqui'},
    { stateCode: 'RS', label: 'Itati'},
    { stateCode: 'RS', label: 'Itatiba do Sul'},
    { stateCode: 'RS', label: 'Ivorá'},
    { stateCode: 'RS', label: 'Ivoti'},
    { stateCode: 'RS', label: 'Jaboticaba'},
    { stateCode: 'RS', label: 'Jacuizinho'},
    { stateCode: 'RS', label: 'Jacutinga'},
    { stateCode: 'RS', label: 'Jaguarão'},
    { stateCode: 'RS', label: 'Jaguari'},
    { stateCode: 'RS', label: 'Jaquirana'},
    { stateCode: 'RS', label: 'Jari'},
    { stateCode: 'RS', label: 'Jóia'},
    { stateCode: 'RS', label: 'Júlio de Castilhos'},
    { stateCode: 'RS', label: 'Lagoa Bonita do Sul'},
    { stateCode: 'RS', label: 'Lagoa Dos Três Cantos'},
    { stateCode: 'RS', label: 'Lagoa Vermelha'},
    { stateCode: 'RS', label: 'Lagoão'},
    { stateCode: 'RS', label: 'Lajeado'},
    { stateCode: 'RS', label: 'Lajeado do Bugre'},
    { stateCode: 'RS', label: 'Lavras do Sul'},
    { stateCode: 'RS', label: 'Liberato Salzano'},
    { stateCode: 'RS', label: 'Lindolfo Collor'},
    { stateCode: 'RS', label: 'Linha Nova'},
    { stateCode: 'RS', label: 'Maçambara'},
    { stateCode: 'RS', label: 'Machadinho'},
    { stateCode: 'RS', label: 'Mampituba'},
    { stateCode: 'RS', label: 'Manoel Viana'},
    { stateCode: 'RS', label: 'Maquiné'},
    { stateCode: 'RS', label: 'Maratá'},
    { stateCode: 'RS', label: 'Marau'},
    { stateCode: 'RS', label: 'Marcelino Ramos'},
    { stateCode: 'RS', label: 'Mariana Pimentel'},
    { stateCode: 'RS', label: 'Mariano Moro'},
    { stateCode: 'RS', label: 'Marques de Souza'},
    { stateCode: 'RS', label: 'Mata'},
    { stateCode: 'RS', label: 'Mato Castelhano'},
    { stateCode: 'RS', label: 'Mato Leitão'},
    { stateCode: 'RS', label: 'Mato Queimado'},
    { stateCode: 'RS', label: 'Maximiliano de Almeida'},
    { stateCode: 'RS', label: 'Minas do Leão'},
    { stateCode: 'RS', label: 'Miraguaí'},
    { stateCode: 'RS', label: 'Montauri'},
    { stateCode: 'RS', label: 'Monte Alegre Dos Campos'},
    { stateCode: 'RS', label: 'Monte Belo do Sul'},
    { stateCode: 'RS', label: 'Montenegro'},
    { stateCode: 'RS', label: 'Mormaço'},
    { stateCode: 'RS', label: 'Morrinhos do Sul'},
    { stateCode: 'RS', label: 'Morro Redondo'},
    { stateCode: 'RS', label: 'Morro Reuter'},
    { stateCode: 'RS', label: 'Mostardas'},
    { stateCode: 'RS', label: 'Muçum'},
    { stateCode: 'RS', label: 'Muitos Capões'},
    { stateCode: 'RS', label: 'Muliterno'},
    { stateCode: 'RS', label: 'Não-me-toque'},
    { stateCode: 'RS', label: 'Nicolau Vergueiro'},
    { stateCode: 'RS', label: 'Nonoai'},
    { stateCode: 'RS', label: 'Nova Alvorada'},
    { stateCode: 'RS', label: 'Nova Araçá'},
    { stateCode: 'RS', label: 'Nova Bassano'},
    { stateCode: 'RS', label: 'Nova Boa Vista'},
    { stateCode: 'RS', label: 'Nova Bréscia'},
    { stateCode: 'RS', label: 'Nova Candelária'},
    { stateCode: 'RS', label: 'Nova Esperança do Sul'},
    { stateCode: 'RS', label: 'Nova Hartz'},
    { stateCode: 'RS', label: 'Nova Pádua'},
    { stateCode: 'RS', label: 'Nova Palma'},
    { stateCode: 'RS', label: 'Nova Petrópolis'},
    { stateCode: 'RS', label: 'Nova Prata'},
    { stateCode: 'RS', label: 'Nova Ramada'},
    { stateCode: 'RS', label: 'Nova Roma do Sul'},
    { stateCode: 'RS', label: 'Nova Santa Rita'},
    { stateCode: 'RS', label: 'Novo Barreiro'},
    { stateCode: 'RS', label: 'Novo Cabrais'},
    { stateCode: 'RS', label: 'Novo Hamburgo'},
    { stateCode: 'RS', label: 'Novo Machado'},
    { stateCode: 'RS', label: 'Novo Tiradentes'},
    { stateCode: 'RS', label: 'Novo Xingu'},
    { stateCode: 'RS', label: 'Osório'},
    { stateCode: 'RS', label: 'Paim Filho'},
    { stateCode: 'RS', label: 'Palmares do Sul'},
    { stateCode: 'RS', label: 'Palmeira Das Missões'},
    { stateCode: 'RS', label: 'Palmitinho'},
    { stateCode: 'RS', label: 'Panambi'},
    { stateCode: 'RS', label: 'Pantano Grande'},
    { stateCode: 'RS', label: 'Paraí'},
    { stateCode: 'RS', label: 'Paraíso do Sul'},
    { stateCode: 'RS', label: 'Pareci Novo'},
    { stateCode: 'RS', label: 'Parobé'},
    { stateCode: 'RS', label: 'Passa Sete'},
    { stateCode: 'RS', label: 'Passo do Sobrado'},
    { stateCode: 'RS', label: 'Passo Fundo'},
    { stateCode: 'RS', label: 'Paulo Bento'},
    { stateCode: 'RS', label: 'Paverama'},
    { stateCode: 'RS', label: 'Pedras Altas'},
    { stateCode: 'RS', label: 'Pedro Osório'},
    { stateCode: 'RS', label: 'Pejuçara'},
    { stateCode: 'RS', label: 'Pelotas'},
    { stateCode: 'RS', label: 'Picada Café'},
    { stateCode: 'RS', label: 'Pinhal'},
    { stateCode: 'RS', label: 'Pinhal da Serra'},
    { stateCode: 'RS', label: 'Pinhal Grande'},
    { stateCode: 'RS', label: 'Pinheirinho do Vale'},
    { stateCode: 'RS', label: 'Pinheiro Machado'},
    { stateCode: 'RS', label: 'Pinto Bandeira'},
    { stateCode: 'RS', label: 'Pirapó'},
    { stateCode: 'RS', label: 'Piratini'},
    { stateCode: 'RS', label: 'Planalto'},
    { stateCode: 'RS', label: 'Poço Das Antas'},
    { stateCode: 'RS', label: 'Pontão'},
    { stateCode: 'RS', label: 'Ponte Preta'},
    { stateCode: 'RS', label: 'Portão'},
    { stateCode: 'RS', label: 'Porto Alegre'},
    { stateCode: 'RS', label: 'Porto Lucena'},
    { stateCode: 'RS', label: 'Porto Mauá'},
    { stateCode: 'RS', label: 'Porto Vera Cruz'},
    { stateCode: 'RS', label: 'Porto Xavier'},
    { stateCode: 'RS', label: 'Pouso Novo'},
    { stateCode: 'RS', label: 'Presidente Lucena'},
    { stateCode: 'RS', label: 'Progresso'},
    { stateCode: 'RS', label: 'Protásio Alves'},
    { stateCode: 'RS', label: 'Putinga'},
    { stateCode: 'RS', label: 'Quaraí'},
    { stateCode: 'RS', label: 'Quatro Irmãos'},
    { stateCode: 'RS', label: 'Quevedos'},
    { stateCode: 'RS', label: 'Quinze de Novembro'},
    { stateCode: 'RS', label: 'Redentora'},
    { stateCode: 'RS', label: 'Relvado'},
    { stateCode: 'RS', label: 'Restinga Seca'},
    { stateCode: 'RS', label: 'Rio Dos Índios'},
    { stateCode: 'RS', label: 'Rio Grande'},
    { stateCode: 'RS', label: 'Rio Pardo'},
    { stateCode: 'RS', label: 'Riozinho'},
    { stateCode: 'RS', label: 'Roca Sales'},
    { stateCode: 'RS', label: 'Rodeio Bonito'},
    { stateCode: 'RS', label: 'Rolador'},
    { stateCode: 'RS', label: 'Rolante'},
    { stateCode: 'RS', label: 'Ronda Alta'},
    { stateCode: 'RS', label: 'Rondinha'},
    { stateCode: 'RS', label: 'Roque Gonzales'},
    { stateCode: 'RS', label: 'Rosário do Sul'},
    { stateCode: 'RS', label: 'Sagrada Família'},
    { stateCode: 'RS', label: 'Saldanha Marinho'},
    { stateCode: 'RS', label: 'Salto do Jacuí'},
    { stateCode: 'RS', label: 'Salvador Das Missões'},
    { stateCode: 'RS', label: 'Salvador do Sul'},
    { stateCode: 'RS', label: 'Sananduva'},
    { stateCode: 'RS', label: 'Santa Bárbara do Sul'},
    { stateCode: 'RS', label: 'Santa Cecília do Sul'},
    { stateCode: 'RS', label: 'Santa Clara do Sul'},
    { stateCode: 'RS', label: 'Santa Cruz do Sul'},
    { stateCode: 'RS', label: 'Santa Margarida do Sul'},
    { stateCode: 'RS', label: 'Santa Maria'},
    { stateCode: 'RS', label: 'Santa Maria do Herval'},
    { stateCode: 'RS', label: 'Santa Rosa'},
    { stateCode: 'RS', label: 'Santa Tereza'},
    { stateCode: 'RS', label: 'Santa Vitória do Palmar'},
    { stateCode: 'RS', label: 'Santana da Boa Vista'},
    { stateCode: 'RS', label: 'Santana do Livramento'},
    { stateCode: 'RS', label: 'Santiago'},
    { stateCode: 'RS', label: 'Santo Ângelo'},
    { stateCode: 'RS', label: 'Santo Antônio da Patrulha'},
    { stateCode: 'RS', label: 'Santo Antônio Das Missões'},
    { stateCode: 'RS', label: 'Santo Antônio do Palma'},
    { stateCode: 'RS', label: 'Santo Antônio do Planalto'},
    { stateCode: 'RS', label: 'Santo Augusto'},
    { stateCode: 'RS', label: 'Santo Cristo'},
    { stateCode: 'RS', label: 'Santo Expedito do Sul'},
    { stateCode: 'RS', label: 'São Borja'},
    { stateCode: 'RS', label: 'São Domingos do Sul'},
    { stateCode: 'RS', label: 'São Francisco de Assis'},
    { stateCode: 'RS', label: 'São Francisco de Paula'},
    { stateCode: 'RS', label: 'São Gabriel'},
    { stateCode: 'RS', label: 'São Jerônimo'},
    { stateCode: 'RS', label: 'São João da Urtiga'},
    { stateCode: 'RS', label: 'São João do Polêsine'},
    { stateCode: 'RS', label: 'São Jorge'},
    { stateCode: 'RS', label: 'São José Das Missões'},
    { stateCode: 'RS', label: 'São José do Herval'},
    { stateCode: 'RS', label: 'São José do Hortêncio'},
    { stateCode: 'RS', label: 'São José do Inhacorá'},
    { stateCode: 'RS', label: 'São José do Norte'},
    { stateCode: 'RS', label: 'São José do Ouro'},
    { stateCode: 'RS', label: 'São José do Sul'},
    { stateCode: 'RS', label: 'São José Dos Ausentes'},
    { stateCode: 'RS', label: 'São Leopoldo'},
    { stateCode: 'RS', label: 'São Lourenço do Sul'},
    { stateCode: 'RS', label: 'São Luiz Gonzaga'},
    { stateCode: 'RS', label: 'São Marcos'},
    { stateCode: 'RS', label: 'São Martinho'},
    { stateCode: 'RS', label: 'São Martinho da Serra'},
    { stateCode: 'RS', label: 'São Miguel Das Missões'},
    { stateCode: 'RS', label: 'São Nicolau'},
    { stateCode: 'RS', label: 'São Paulo Das Missões'},
    { stateCode: 'RS', label: 'São Pedro da Serra'},
    { stateCode: 'RS', label: 'São Pedro Das Missões'},
    { stateCode: 'RS', label: 'São Pedro do Butiá'},
    { stateCode: 'RS', label: 'São Pedro do Sul'},
    { stateCode: 'RS', label: 'São Sebastião do Caí'},
    { stateCode: 'RS', label: 'São Sepé'},
    { stateCode: 'RS', label: 'São Valentim'},
    { stateCode: 'RS', label: 'São Valentim do Sul'},
    { stateCode: 'RS', label: 'São Valério do Sul'},
    { stateCode: 'RS', label: 'São Vendelino'},
    { stateCode: 'RS', label: 'São Vicente do Sul'},
    { stateCode: 'RS', label: 'Sapiranga'},
    { stateCode: 'RS', label: 'Sapucaia do Sul'},
    { stateCode: 'RS', label: 'Sarandi'},
    { stateCode: 'RS', label: 'Seberi'},
    { stateCode: 'RS', label: 'Sede Nova'},
    { stateCode: 'RS', label: 'Segredo'},
    { stateCode: 'RS', label: 'Selbach'},
    { stateCode: 'RS', label: 'Senador Salgado Filho'},
    { stateCode: 'RS', label: 'Sentinela do Sul'},
    { stateCode: 'RS', label: 'Serafina Corrêa'},
    { stateCode: 'RS', label: 'Sério'},
    { stateCode: 'RS', label: 'Sertão'},
    { stateCode: 'RS', label: 'Sertão Santana'},
    { stateCode: 'RS', label: 'Sete de Setembro'},
    { stateCode: 'RS', label: 'Severiano de Almeida'},
    { stateCode: 'RS', label: 'Silveira Martins'},
    { stateCode: 'RS', label: 'Sinimbu'},
    { stateCode: 'RS', label: 'Sobradinho'},
    { stateCode: 'RS', label: 'Soledade'},
    { stateCode: 'RS', label: 'Tabaí'},
    { stateCode: 'RS', label: 'Tapejara'},
    { stateCode: 'RS', label: 'Tapera'},
    { stateCode: 'RS', label: 'Tapes'},
    { stateCode: 'RS', label: 'Taquara'},
    { stateCode: 'RS', label: 'Taquari'},
    { stateCode: 'RS', label: 'Taquaruçu do Sul'},
    { stateCode: 'RS', label: 'Tavares'},
    { stateCode: 'RS', label: 'Tenente Portela'},
    { stateCode: 'RS', label: 'Terra de Areia'},
    { stateCode: 'RS', label: 'Teutônia'},
    { stateCode: 'RS', label: 'Tio Hugo'},
    { stateCode: 'RS', label: 'Tiradentes do Sul'},
    { stateCode: 'RS', label: 'Toropi'},
    { stateCode: 'RS', label: 'Torres'},
    { stateCode: 'RS', label: 'Tramandaí'},
    { stateCode: 'RS', label: 'Travesseiro'},
    { stateCode: 'RS', label: 'Três Arroios'},
    { stateCode: 'RS', label: 'Três Cachoeiras'},
    { stateCode: 'RS', label: 'Três Coroas'},
    { stateCode: 'RS', label: 'Três de Maio'},
    { stateCode: 'RS', label: 'Três Forquilhas'},
    { stateCode: 'RS', label: 'Três Palmeiras'},
    { stateCode: 'RS', label: 'Três Passos'},
    { stateCode: 'RS', label: 'Trindade do Sul'},
    { stateCode: 'RS', label: 'Triunfo'},
    { stateCode: 'RS', label: 'Tucunduva'},
    { stateCode: 'RS', label: 'Tunas'},
    { stateCode: 'RS', label: 'Tupanci do Sul'},
    { stateCode: 'RS', label: 'Tupanciretã'},
    { stateCode: 'RS', label: 'Tupandi'},
    { stateCode: 'RS', label: 'Tuparendi'},
    { stateCode: 'RS', label: 'Turuçu'},
    { stateCode: 'RS', label: 'Ubiretama'},
    { stateCode: 'RS', label: 'União da Serra'},
    { stateCode: 'RS', label: 'Unistalda'},
    { stateCode: 'RS', label: 'Uruguaiana'},
    { stateCode: 'RS', label: 'Vacaria'},
    { stateCode: 'RS', label: 'Vale do Sol'},
    { stateCode: 'RS', label: 'Vale Real'},
    { stateCode: 'RS', label: 'Vale Verde'},
    { stateCode: 'RS', label: 'Vanini'},
    { stateCode: 'RS', label: 'Venâncio Aires'},
    { stateCode: 'RS', label: 'Vera Cruz'},
    { stateCode: 'RS', label: 'Veranópolis'},
    { stateCode: 'RS', label: 'Vespasiano Correa'},
    { stateCode: 'RS', label: 'Viadutos'},
    { stateCode: 'RS', label: 'Viamão'},
    { stateCode: 'RS', label: 'Vicente Dutra'},
    { stateCode: 'RS', label: 'Victor Graeff'},
    { stateCode: 'RS', label: 'Vila Flores'},
    { stateCode: 'RS', label: 'Vila Lângaro'},
    { stateCode: 'RS', label: 'Vila Maria'},
    { stateCode: 'RS', label: 'Vila Nova do Sul'},
    { stateCode: 'RS', label: 'Vista Alegre'},
    { stateCode: 'RS', label: 'Vista Alegre do Prata'},
    { stateCode: 'RS', label: 'Vista Gaúcha'},
    { stateCode: 'RS', label: 'Vitória Das Missões'},
    { stateCode: 'RS', label: 'Westfalia'},
    { stateCode: 'RS', label: 'Xangri-lá'},
    { stateCode: 'SC', label: 'Abdon Batista'},
    { stateCode: 'SC', label: 'Abelardo Luz'},
    { stateCode: 'SC', label: 'Agrolândia'},
    { stateCode: 'SC', label: 'Agronômica'},
    { stateCode: 'SC', label: 'Água Doce'},
    { stateCode: 'SC', label: 'Águas de Chapecó'},
    { stateCode: 'SC', label: 'Águas Frias'},
    { stateCode: 'SC', label: 'Águas Mornas'},
    { stateCode: 'SC', label: 'Alfredo Wagner'},
    { stateCode: 'SC', label: 'Alto Bela Vista'},
    { stateCode: 'SC', label: 'Anchieta'},
    { stateCode: 'SC', label: 'Angelina'},
    { stateCode: 'SC', label: 'Anita Garibaldi'},
    { stateCode: 'SC', label: 'Anitápolis'},
    { stateCode: 'SC', label: 'Antônio Carlos'},
    { stateCode: 'SC', label: 'Apiúna'},
    { stateCode: 'SC', label: 'Arabutã'},
    { stateCode: 'SC', label: 'Araquari'},
    { stateCode: 'SC', label: 'Araranguá'},
    { stateCode: 'SC', label: 'Armazém'},
    { stateCode: 'SC', label: 'Arroio Trinta'},
    { stateCode: 'SC', label: 'Arvoredo'},
    { stateCode: 'SC', label: 'Ascurra'},
    { stateCode: 'SC', label: 'Atalanta'},
    { stateCode: 'SC', label: 'Aurora'},
    { stateCode: 'SC', label: 'Balneário Arroio do Silva'},
    { stateCode: 'SC', label: 'Balneário Barra do Sul'},
    { stateCode: 'SC', label: 'Balneário Camboriú'},
    { stateCode: 'SC', label: 'Balneário Gaivota'},
    { stateCode: 'SC', label: 'Balneário Rincão'},
    { stateCode: 'SC', label: 'Bandeirante'},
    { stateCode: 'SC', label: 'Barra Bonita'},
    { stateCode: 'SC', label: 'Barra Velha'},
    { stateCode: 'SC', label: 'Bela Vista do Toldo'},
    { stateCode: 'SC', label: 'Belmonte'},
    { stateCode: 'SC', label: 'Benedito Novo'},
    { stateCode: 'SC', label: 'Biguaçu'},
    { stateCode: 'SC', label: 'Blumenau'},
    { stateCode: 'SC', label: 'Bocaina do Sul'},
    { stateCode: 'SC', label: 'Bom Jardim da Serra'},
    { stateCode: 'SC', label: 'Bom Jesus'},
    { stateCode: 'SC', label: 'Bom Jesus do Oeste'},
    { stateCode: 'SC', label: 'Bom Retiro'},
    { stateCode: 'SC', label: 'Bombinhas'},
    { stateCode: 'SC', label: 'Botuverá'},
    { stateCode: 'SC', label: 'Braço do Norte'},
    { stateCode: 'SC', label: 'Braço do Trombudo'},
    { stateCode: 'SC', label: 'Brunópolis'},
    { stateCode: 'SC', label: 'Brusque'},
    { stateCode: 'SC', label: 'Caçador'},
    { stateCode: 'SC', label: 'Caibi'},
    { stateCode: 'SC', label: 'Calmon'},
    { stateCode: 'SC', label: 'Camboriú'},
    { stateCode: 'SC', label: 'Campo Alegre'},
    { stateCode: 'SC', label: 'Campo Belo do Sul'},
    { stateCode: 'SC', label: 'Campo Erê'},
    { stateCode: 'SC', label: 'Campos Novos'},
    { stateCode: 'SC', label: 'Canelinha'},
    { stateCode: 'SC', label: 'Canoinhas'},
    { stateCode: 'SC', label: 'Capão Alto'},
    { stateCode: 'SC', label: 'Capinzal'},
    { stateCode: 'SC', label: 'Capivari de Baixo'},
    { stateCode: 'SC', label: 'Catanduvas'},
    { stateCode: 'SC', label: 'Caxambu do Sul'},
    { stateCode: 'SC', label: 'Celso Ramos'},
    { stateCode: 'SC', label: 'Cerro Negro'},
    { stateCode: 'SC', label: 'Chapadão do Lageado'},
    { stateCode: 'SC', label: 'Chapecó'},
    { stateCode: 'SC', label: 'Cocal do Sul'},
    { stateCode: 'SC', label: 'Concórdia'},
    { stateCode: 'SC', label: 'Cordilheira Alta'},
    { stateCode: 'SC', label: 'Coronel Freitas'},
    { stateCode: 'SC', label: 'Coronel Martins'},
    { stateCode: 'SC', label: 'Correia Pinto'},
    { stateCode: 'SC', label: 'Corupá'},
    { stateCode: 'SC', label: 'Criciúma'},
    { stateCode: 'SC', label: 'Cunha Porã'},
    { stateCode: 'SC', label: 'Cunhataí'},
    { stateCode: 'SC', label: 'Curitibanos'},
    { stateCode: 'SC', label: 'Descanso'},
    { stateCode: 'SC', label: 'Dionísio Cerqueira'},
    { stateCode: 'SC', label: 'Dona Emma'},
    { stateCode: 'SC', label: 'Doutor Pedrinho'},
    { stateCode: 'SC', label: 'Entre Rios'},
    { stateCode: 'SC', label: 'Ermo'},
    { stateCode: 'SC', label: 'Erval Velho'},
    { stateCode: 'SC', label: 'Faxinal Dos Guedes'},
    { stateCode: 'SC', label: 'Flor do Sertão'},
    { stateCode: 'SC', label: 'Florianópolis'},
    { stateCode: 'SC', label: 'Formosa do Sul'},
    { stateCode: 'SC', label: 'Forquilhinha'},
    { stateCode: 'SC', label: 'Fraiburgo'},
    { stateCode: 'SC', label: 'Frei Rogério'},
    { stateCode: 'SC', label: 'Galvão'},
    { stateCode: 'SC', label: 'Garopaba'},
    { stateCode: 'SC', label: 'Garuva'},
    { stateCode: 'SC', label: 'Gaspar'},
    { stateCode: 'SC', label: 'Governador Celso Ramos'},
    { stateCode: 'SC', label: 'Grão Pará'},
    { stateCode: 'SC', label: 'Gravatal'},
    { stateCode: 'SC', label: 'Guabiruba'},
    { stateCode: 'SC', label: 'Guaraciaba'},
    { stateCode: 'SC', label: 'Guaramirim'},
    { stateCode: 'SC', label: 'Guarujá do Sul'},
    { stateCode: 'SC', label: 'Guatambú'},
    { stateCode: 'SC', label: 'Herval D´oeste'},
    { stateCode: 'SC', label: 'Ibiam'},
    { stateCode: 'SC', label: 'Ibicaré'},
    { stateCode: 'SC', label: 'Ibirama'},
    { stateCode: 'SC', label: 'Içara'},
    { stateCode: 'SC', label: 'Ilhota'},
    { stateCode: 'SC', label: 'Imaruí'},
    { stateCode: 'SC', label: 'Imbituba'},
    { stateCode: 'SC', label: 'Imbuia'},
    { stateCode: 'SC', label: 'Indaial'},
    { stateCode: 'SC', label: 'Iomerê'},
    { stateCode: 'SC', label: 'Ipira'},
    { stateCode: 'SC', label: 'Iporã do Oeste'},
    { stateCode: 'SC', label: 'Ipuaçu'},
    { stateCode: 'SC', label: 'Ipumirim'},
    { stateCode: 'SC', label: 'Iraceminha'},
    { stateCode: 'SC', label: 'Irani'},
    { stateCode: 'SC', label: 'Irati'},
    { stateCode: 'SC', label: 'Irineópolis'},
    { stateCode: 'SC', label: 'Itá'},
    { stateCode: 'SC', label: 'Itaiópolis'},
    { stateCode: 'SC', label: 'Itajaí'},
    { stateCode: 'SC', label: 'Itapema'},
    { stateCode: 'SC', label: 'Itapiranga'},
    { stateCode: 'SC', label: 'Itapoá'},
    { stateCode: 'SC', label: 'Ituporanga'},
    { stateCode: 'SC', label: 'Jaborá'},
    { stateCode: 'SC', label: 'Jacinto Machado'},
    { stateCode: 'SC', label: 'Jaguaruna'},
    { stateCode: 'SC', label: 'Jaraguá do Sul'},
    { stateCode: 'SC', label: 'Jardinópolis'},
    { stateCode: 'SC', label: 'Joaçaba'},
    { stateCode: 'SC', label: 'Joinville'},
    { stateCode: 'SC', label: 'José Boiteux'},
    { stateCode: 'SC', label: 'Jupiá'},
    { stateCode: 'SC', label: 'Lacerdópolis'},
    { stateCode: 'SC', label: 'Lages'},
    { stateCode: 'SC', label: 'Laguna'},
    { stateCode: 'SC', label: 'Lajeado Grande'},
    { stateCode: 'SC', label: 'Laurentino'},
    { stateCode: 'SC', label: 'Lauro Muller'},
    { stateCode: 'SC', label: 'Lebon Régis'},
    { stateCode: 'SC', label: 'Leoberto Leal'},
    { stateCode: 'SC', label: 'Lindóia do Sul'},
    { stateCode: 'SC', label: 'Lontras'},
    { stateCode: 'SC', label: 'Luiz Alves'},
    { stateCode: 'SC', label: 'Luzerna'},
    { stateCode: 'SC', label: 'Macieira'},
    { stateCode: 'SC', label: 'Mafra'},
    { stateCode: 'SC', label: 'Major Gercino'},
    { stateCode: 'SC', label: 'Major Vieira'},
    { stateCode: 'SC', label: 'Maracajá'},
    { stateCode: 'SC', label: 'Maravilha'},
    { stateCode: 'SC', label: 'Marema'},
    { stateCode: 'SC', label: 'Massaranduba'},
    { stateCode: 'SC', label: 'Matos Costa'},
    { stateCode: 'SC', label: 'Meleiro'},
    { stateCode: 'SC', label: 'Mirim Doce'},
    { stateCode: 'SC', label: 'Modelo'},
    { stateCode: 'SC', label: 'Mondaí'},
    { stateCode: 'SC', label: 'Monte Carlo'},
    { stateCode: 'SC', label: 'Monte Castelo'},
    { stateCode: 'SC', label: 'Morro da Fumaça'},
    { stateCode: 'SC', label: 'Morro Grande'},
    { stateCode: 'SC', label: 'Navegantes'},
    { stateCode: 'SC', label: 'Nova Erechim'},
    { stateCode: 'SC', label: 'Nova Itaberaba'},
    { stateCode: 'SC', label: 'Nova Trento'},
    { stateCode: 'SC', label: 'Nova Veneza'},
    { stateCode: 'SC', label: 'Novo Horizonte'},
    { stateCode: 'SC', label: 'Orleans'},
    { stateCode: 'SC', label: 'Otacílio Costa'},
    { stateCode: 'SC', label: 'Ouro'},
    { stateCode: 'SC', label: 'Ouro Verde'},
    { stateCode: 'SC', label: 'Paial'},
    { stateCode: 'SC', label: 'Painel'},
    { stateCode: 'SC', label: 'Palhoça'},
    { stateCode: 'SC', label: 'Palma Sola'},
    { stateCode: 'SC', label: 'Palmeira'},
    { stateCode: 'SC', label: 'Palmitos'},
    { stateCode: 'SC', label: 'Papanduva'},
    { stateCode: 'SC', label: 'Paraíso'},
    { stateCode: 'SC', label: 'Passo de Torres'},
    { stateCode: 'SC', label: 'Passos Maia'},
    { stateCode: 'SC', label: 'Paulo Lopes'},
    { stateCode: 'SC', label: 'Pedras Grandes'},
    { stateCode: 'SC', label: 'Penha'},
    { stateCode: 'SC', label: 'Peritiba'},
    { stateCode: 'SC', label: 'Pescaria Brava'},
    { stateCode: 'SC', label: 'Petrolândia'},
    { stateCode: 'SC', label: 'Piçarras'},
    { stateCode: 'SC', label: 'Pinhalzinho'},
    { stateCode: 'SC', label: 'Pinheiro Preto'},
    { stateCode: 'SC', label: 'Piratuba'},
    { stateCode: 'SC', label: 'Planalto Alegre'},
    { stateCode: 'SC', label: 'Pomerode'},
    { stateCode: 'SC', label: 'Ponte Alta'},
    { stateCode: 'SC', label: 'Ponte Alta do Norte'},
    { stateCode: 'SC', label: 'Ponte Serrada'},
    { stateCode: 'SC', label: 'Porto Belo'},
    { stateCode: 'SC', label: 'Porto União'},
    { stateCode: 'SC', label: 'Pouso Redondo'},
    { stateCode: 'SC', label: 'Praia Grande'},
    { stateCode: 'SC', label: 'Presidente Castelo Branco'},
    { stateCode: 'SC', label: 'Presidente Getúlio'},
    { stateCode: 'SC', label: 'Presidente Nereu'},
    { stateCode: 'SC', label: 'Princesa'},
    { stateCode: 'SC', label: 'Quilombo'},
    { stateCode: 'SC', label: 'Rancho Queimado'},
    { stateCode: 'SC', label: 'Rio Das Antas'},
    { stateCode: 'SC', label: 'Rio do Campo'},
    { stateCode: 'SC', label: 'Rio do Oeste'},
    { stateCode: 'SC', label: 'Rio do Sul'},
    { stateCode: 'SC', label: 'Rio Dos Cedros'},
    { stateCode: 'SC', label: 'Rio Fortuna'},
    { stateCode: 'SC', label: 'Rio Negrinho'},
    { stateCode: 'SC', label: 'Rio Rufino'},
    { stateCode: 'SC', label: 'Riqueza'},
    { stateCode: 'SC', label: 'Rodeio'},
    { stateCode: 'SC', label: 'Romelândia'},
    { stateCode: 'SC', label: 'Salete'},
    { stateCode: 'SC', label: 'Saltinho'},
    { stateCode: 'SC', label: 'Salto Veloso'},
    { stateCode: 'SC', label: 'Sangão'},
    { stateCode: 'SC', label: 'Santa Cecília'},
    { stateCode: 'SC', label: 'Santa Helena'},
    { stateCode: 'SC', label: 'Santa Rosa de Lima'},
    { stateCode: 'SC', label: 'Santa Rosa do Sul'},
    { stateCode: 'SC', label: 'Santa Terezinha'},
    { stateCode: 'SC', label: 'Santa Terezinha do Progresso'},
    { stateCode: 'SC', label: 'Santiago do Sul'},
    { stateCode: 'SC', label: 'Santo Amaro da Imperatriz'},
    { stateCode: 'SC', label: 'São Bento do Sul'},
    { stateCode: 'SC', label: 'São Bernardino'},
    { stateCode: 'SC', label: 'São Bonifácio'},
    { stateCode: 'SC', label: 'São Carlos'},
    { stateCode: 'SC', label: 'São Cristovão do Sul'},
    { stateCode: 'SC', label: 'São Domingos'},
    { stateCode: 'SC', label: 'São Francisco do Sul'},
    { stateCode: 'SC', label: 'São João Batista'},
    { stateCode: 'SC', label: 'São João do Itaperiú'},
    { stateCode: 'SC', label: 'São João do Oeste'},
    { stateCode: 'SC', label: 'São João do Sul'},
    { stateCode: 'SC', label: 'São Joaquim'},
    { stateCode: 'SC', label: 'São José'},
    { stateCode: 'SC', label: 'São José do Cedro'},
    { stateCode: 'SC', label: 'São José do Cerrito'},
    { stateCode: 'SC', label: 'São Lourenço do Oeste'},
    { stateCode: 'SC', label: 'São Ludgero'},
    { stateCode: 'SC', label: 'São Martinho'},
    { stateCode: 'SC', label: 'São Miguel da Boa Vista'},
    { stateCode: 'SC', label: 'São Miguel do Oeste'},
    { stateCode: 'SC', label: 'São Pedro de Alcântara'},
    { stateCode: 'SC', label: 'Saudades'},
    { stateCode: 'SC', label: 'Schroeder'},
    { stateCode: 'SC', label: 'Seara'},
    { stateCode: 'SC', label: 'Serra Alta'},
    { stateCode: 'SC', label: 'Siderópolis'},
    { stateCode: 'SC', label: 'Sombrio'},
    { stateCode: 'SC', label: 'Sul Brasil'},
    { stateCode: 'SC', label: 'Taió'},
    { stateCode: 'SC', label: 'Tangará'},
    { stateCode: 'SC', label: 'Tigrinhos'},
    { stateCode: 'SC', label: 'Tijucas'},
    { stateCode: 'SC', label: 'Timbé do Sul'},
    { stateCode: 'SC', label: 'Timbó'},
    { stateCode: 'SC', label: 'Timbó Grande'},
    { stateCode: 'SC', label: 'Três Barras'},
    { stateCode: 'SC', label: 'Treviso'},
    { stateCode: 'SC', label: 'Treze de Maio'},
    { stateCode: 'SC', label: 'Treze Tílias'},
    { stateCode: 'SC', label: 'Trombudo Central'},
    { stateCode: 'SC', label: 'Tubarão'},
    { stateCode: 'SC', label: 'Tunápolis'},
    { stateCode: 'SC', label: 'Turvo'},
    { stateCode: 'SC', label: 'União do Oeste'},
    { stateCode: 'SC', label: 'Urubici'},
    { stateCode: 'SC', label: 'Urupema'},
    { stateCode: 'SC', label: 'Urussanga'},
    { stateCode: 'SC', label: 'Vargeão'},
    { stateCode: 'SC', label: 'Vargem'},
    { stateCode: 'SC', label: 'Vargem Bonita'},
    { stateCode: 'SC', label: 'Vidal Ramos'},
    { stateCode: 'SC', label: 'Videira'},
    { stateCode: 'SC', label: 'Vitor Meireles'},
    { stateCode: 'SC', label: 'Witmarsum'},
    { stateCode: 'SC', label: 'Xanxerê'},
    { stateCode: 'SC', label: 'Xavantina'},
    { stateCode: 'SC', label: 'Xaxim'},
    { stateCode: 'SC', label: 'Zortéa'},
    { stateCode: 'SE', label: 'Amparo de São Francisco'},
    { stateCode: 'SE', label: 'Aquidabã'},
    { stateCode: 'SE', label: 'Aracaju'},
    { stateCode: 'SE', label: 'Arauá'},
    { stateCode: 'SE', label: 'Areia Branca'},
    { stateCode: 'SE', label: 'Barra Dos Coqueiros'},
    { stateCode: 'SE', label: 'Boquim'},
    { stateCode: 'SE', label: 'Brejo Grande'},
    { stateCode: 'SE', label: 'Campo do Brito'},
    { stateCode: 'SE', label: 'Canhoba'},
    { stateCode: 'SE', label: 'Canindé de São Francisco'},
    { stateCode: 'SE', label: 'Capela'},
    { stateCode: 'SE', label: 'Carira'},
    { stateCode: 'SE', label: 'Carmópolis'},
    { stateCode: 'SE', label: 'Cedro de São João'},
    { stateCode: 'SE', label: 'Cristinápolis'},
    { stateCode: 'SE', label: 'Cumbe'},
    { stateCode: 'SE', label: 'Divina Pastora'},
    { stateCode: 'SE', label: 'Estância'},
    { stateCode: 'SE', label: 'Feira Nova'},
    { stateCode: 'SE', label: 'Frei Paulo'},
    { stateCode: 'SE', label: 'Gararu'},
    { stateCode: 'SE', label: 'General Maynard'},
    { stateCode: 'SE', label: 'Gracho Cardoso'},
    { stateCode: 'SE', label: 'Ilha Das Flores'},
    { stateCode: 'SE', label: 'Indiaroba'},
    { stateCode: 'SE', label: 'Itabaiana'},
    { stateCode: 'SE', label: 'Itabaianinha'},
    { stateCode: 'SE', label: 'Itabi'},
    { stateCode: 'SE', label: 'Itaporanga D´ajuda'},
    { stateCode: 'SE', label: 'Japaratuba'},
    { stateCode: 'SE', label: 'Japoatã'},
    { stateCode: 'SE', label: 'Lagarto'},
    { stateCode: 'SE', label: 'Laranjeiras'},
    { stateCode: 'SE', label: 'Macambira'},
    { stateCode: 'SE', label: 'Malhada Dos Bois'},
    { stateCode: 'SE', label: 'Malhador'},
    { stateCode: 'SE', label: 'Maruim'},
    { stateCode: 'SE', label: 'Moita Bonita'},
    { stateCode: 'SE', label: 'Monte Alegre de Sergipe'},
    { stateCode: 'SE', label: 'Muribeca'},
    { stateCode: 'SE', label: 'Neópolis'},
    { stateCode: 'SE', label: 'Nossa Senhora Aparecida'},
    { stateCode: 'SE', label: 'Nossa Senhora da Glória'},
    { stateCode: 'SE', label: 'Nossa Senhora Das Dores'},
    { stateCode: 'SE', label: 'Nossa Senhora de Lourdes'},
    { stateCode: 'SE', label: 'Nossa Senhora do Socorro'},
    { stateCode: 'SE', label: 'Pacatuba'},
    { stateCode: 'SE', label: 'Pedra Mole'},
    { stateCode: 'SE', label: 'Pedrinhas'},
    { stateCode: 'SE', label: 'Pinhão'},
    { stateCode: 'SE', label: 'Pirambu'},
    { stateCode: 'SE', label: 'Poço Redondo'},
    { stateCode: 'SE', label: 'Poço Verde'},
    { stateCode: 'SE', label: 'Porto da Folha'},
    { stateCode: 'SE', label: 'Propriá'},
    { stateCode: 'SE', label: 'Riachão do Dantas'},
    { stateCode: 'SE', label: 'Riachuelo'},
    { stateCode: 'SE', label: 'Ribeirópolis'},
    { stateCode: 'SE', label: 'Rosário do Catete'},
    { stateCode: 'SE', label: 'Salgado'},
    { stateCode: 'SE', label: 'Santa Luzia do Itanhy'},
    { stateCode: 'SE', label: 'Santa Rosa de Lima'},
    { stateCode: 'SE', label: 'Santana do São Francisco'},
    { stateCode: 'SE', label: 'Santo Amaro Das Brotas'},
    { stateCode: 'SE', label: 'São Cristóvão'},
    { stateCode: 'SE', label: 'São Domingos'},
    { stateCode: 'SE', label: 'São Francisco'},
    { stateCode: 'SE', label: 'São Miguel do Aleixo'},
    { stateCode: 'SE', label: 'Simão Dias'},
    { stateCode: 'SE', label: 'Siriri'},
    { stateCode: 'SE', label: 'Telha'},
    { stateCode: 'SE', label: 'Tobias Barreto'},
    { stateCode: 'SE', label: 'Tomar do Geru'},
    { stateCode: 'SE', label: 'Umbaúba'},
    { stateCode: 'SP', label: 'Adamantina'},
    { stateCode: 'SP', label: 'Adolfo'},
    { stateCode: 'SP', label: 'Aguaí'},
    { stateCode: 'SP', label: 'Águas da Prata'},
    { stateCode: 'SP', label: 'Águas de Lindóia'},
    { stateCode: 'SP', label: 'Águas de Santa Bárbara'},
    { stateCode: 'SP', label: 'Águas de São Pedro'},
    { stateCode: 'SP', label: 'Agudos'},
    { stateCode: 'SP', label: 'Alambari'},
    { stateCode: 'SP', label: 'Alfredo Marcondes'},
    { stateCode: 'SP', label: 'Altair'},
    { stateCode: 'SP', label: 'Altinópolis'},
    { stateCode: 'SP', label: 'Alto Alegre'},
    { stateCode: 'SP', label: 'Alumínio'},
    { stateCode: 'SP', label: 'Álvares Florence'},
    { stateCode: 'SP', label: 'Álvares Machado'},
    { stateCode: 'SP', label: 'Álvaro de Carvalho'},
    { stateCode: 'SP', label: 'Alvinlândia'},
    { stateCode: 'SP', label: 'Americana'},
    { stateCode: 'SP', label: 'Américo Brasiliense'},
    { stateCode: 'SP', label: 'Américo de Campos'},
    { stateCode: 'SP', label: 'Amparo'},
    { stateCode: 'SP', label: 'Analândia'},
    { stateCode: 'SP', label: 'Andradina'},
    { stateCode: 'SP', label: 'Angatuba'},
    { stateCode: 'SP', label: 'Anhembi'},
    { stateCode: 'SP', label: 'Anhumas'},
    { stateCode: 'SP', label: 'Aparecida'},
    { stateCode: 'SP', label: 'Aparecida D´oeste'},
    { stateCode: 'SP', label: 'Apiaí'},
    { stateCode: 'SP', label: 'Araçariguama'},
    { stateCode: 'SP', label: 'Araçatuba'},
    { stateCode: 'SP', label: 'Araçoiaba da Serra'},
    { stateCode: 'SP', label: 'Aramina'},
    { stateCode: 'SP', label: 'Arandu'},
    { stateCode: 'SP', label: 'Arapeí'},
    { stateCode: 'SP', label: 'Araraquara'},
    { stateCode: 'SP', label: 'Araras'},
    { stateCode: 'SP', label: 'Arco-íris'},
    { stateCode: 'SP', label: 'Arealva'},
    { stateCode: 'SP', label: 'Areias'},
    { stateCode: 'SP', label: 'Areiópolis'},
    { stateCode: 'SP', label: 'Ariranha'},
    { stateCode: 'SP', label: 'Artur Nogueira'},
    { stateCode: 'SP', label: 'Arujá'},
    { stateCode: 'SP', label: 'Aspásia'},
    { stateCode: 'SP', label: 'Assis'},
    { stateCode: 'SP', label: 'Atibaia'},
    { stateCode: 'SP', label: 'Auriflama'},
    { stateCode: 'SP', label: 'Avaí'},
    { stateCode: 'SP', label: 'Avanhandava'},
    { stateCode: 'SP', label: 'Avaré'},
    { stateCode: 'SP', label: 'Bady Bassitt'},
    { stateCode: 'SP', label: 'Balbinos'},
    { stateCode: 'SP', label: 'Bálsamo'},
    { stateCode: 'SP', label: 'Bananal'},
    { stateCode: 'SP', label: 'Barão de Antonina'},
    { stateCode: 'SP', label: 'Barbosa'},
    { stateCode: 'SP', label: 'Bariri'},
    { stateCode: 'SP', label: 'Barra Bonita'},
    { stateCode: 'SP', label: 'Barra do Chapéu'},
    { stateCode: 'SP', label: 'Barra do Turvo'},
    { stateCode: 'SP', label: 'Barretos'},
    { stateCode: 'SP', label: 'Barrinha'},
    { stateCode: 'SP', label: 'Barueri'},
    { stateCode: 'SP', label: 'Bastos'},
    { stateCode: 'SP', label: 'Batatais'},
    { stateCode: 'SP', label: 'Bauru'},
    { stateCode: 'SP', label: 'Bebedouro'},
    { stateCode: 'SP', label: 'Bento de Abreu'},
    { stateCode: 'SP', label: 'Bernardino de Campos'},
    { stateCode: 'SP', label: 'Bertioga'},
    { stateCode: 'SP', label: 'Bilac'},
    { stateCode: 'SP', label: 'Birigui'},
    { stateCode: 'SP', label: 'Biritiba-mirim'},
    { stateCode: 'SP', label: 'Boa Esperança do Sul'},
    { stateCode: 'SP', label: 'Bocaina'},
    { stateCode: 'SP', label: 'Bofete'},
    { stateCode: 'SP', label: 'Boituva'},
    { stateCode: 'SP', label: 'Bom Jesus Dos Perdões'},
    { stateCode: 'SP', label: 'Bom Sucesso de Itararé'},
    { stateCode: 'SP', label: 'Borá'},
    { stateCode: 'SP', label: 'Boracéia'},
    { stateCode: 'SP', label: 'Borborema'},
    { stateCode: 'SP', label: 'Borebi'},
    { stateCode: 'SP', label: 'Botucatu'},
    { stateCode: 'SP', label: 'Bragança Paulista'},
    { stateCode: 'SP', label: 'Braúna'},
    { stateCode: 'SP', label: 'Brejo Alegre'},
    { stateCode: 'SP', label: 'Brodowski'},
    { stateCode: 'SP', label: 'Brotas'},
    { stateCode: 'SP', label: 'Buri'},
    { stateCode: 'SP', label: 'Buritama'},
    { stateCode: 'SP', label: 'Buritizal'},
    { stateCode: 'SP', label: 'Cabrália Paulista'},
    { stateCode: 'SP', label: 'Cabreúva'},
    { stateCode: 'SP', label: 'Caçapava'},
    { stateCode: 'SP', label: 'Cachoeira Paulista'},
    { stateCode: 'SP', label: 'Caconde'},
    { stateCode: 'SP', label: 'Cafelândia'},
    { stateCode: 'SP', label: 'Caiabu'},
    { stateCode: 'SP', label: 'Caieiras'},
    { stateCode: 'SP', label: 'Caiuá'},
    { stateCode: 'SP', label: 'Cajamar'},
    { stateCode: 'SP', label: 'Cajati'},
    { stateCode: 'SP', label: 'Cajobi'},
    { stateCode: 'SP', label: 'Cajuru'},
    { stateCode: 'SP', label: 'Campina do Monte Alegre'},
    { stateCode: 'SP', label: 'Campinas'},
    { stateCode: 'SP', label: 'Campo Limpo Paulista'},
    { stateCode: 'SP', label: 'Campos do Jordão'},
    { stateCode: 'SP', label: 'Campos Novos Paulista'},
    { stateCode: 'SP', label: 'Cananéia'},
    { stateCode: 'SP', label: 'Canas'},
    { stateCode: 'SP', label: 'Cândido Mota'},
    { stateCode: 'SP', label: 'Cândido Rodrigues'},
    { stateCode: 'SP', label: 'Canitar'},
    { stateCode: 'SP', label: 'Capão Bonito'},
    { stateCode: 'SP', label: 'Capela do Alto'},
    { stateCode: 'SP', label: 'Capivari'},
    { stateCode: 'SP', label: 'Caraguatatuba'},
    { stateCode: 'SP', label: 'Carapicuíba'},
    { stateCode: 'SP', label: 'Cardoso'},
    { stateCode: 'SP', label: 'Casa Branca'},
    { stateCode: 'SP', label: 'Cássia Dos Coqueiros'},
    { stateCode: 'SP', label: 'Castilho'},
    { stateCode: 'SP', label: 'Catanduva'},
    { stateCode: 'SP', label: 'Catiguá'},
    { stateCode: 'SP', label: 'Cedral'},
    { stateCode: 'SP', label: 'Cerqueira César'},
    { stateCode: 'SP', label: 'Cerquilho'},
    { stateCode: 'SP', label: 'Cesário Lange'},
    { stateCode: 'SP', label: 'Charqueada'},
    { stateCode: 'SP', label: 'Chavantes'},
    { stateCode: 'SP', label: 'Clementina'},
    { stateCode: 'SP', label: 'Colina'},
    { stateCode: 'SP', label: 'Colômbia'},
    { stateCode: 'SP', label: 'Conchal'},
    { stateCode: 'SP', label: 'Conchas'},
    { stateCode: 'SP', label: 'Cordeirópolis'},
    { stateCode: 'SP', label: 'Coroados'},
    { stateCode: 'SP', label: 'Coronel Macedo'},
    { stateCode: 'SP', label: 'Corumbataí'},
    { stateCode: 'SP', label: 'Cosmópolis'},
    { stateCode: 'SP', label: 'Cosmorama'},
    { stateCode: 'SP', label: 'Cotia'},
    { stateCode: 'SP', label: 'Cravinhos'},
    { stateCode: 'SP', label: 'Cristais Paulista'},
    { stateCode: 'SP', label: 'Cruzália'},
    { stateCode: 'SP', label: 'Cruzeiro'},
    { stateCode: 'SP', label: 'Cubatão'},
    { stateCode: 'SP', label: 'Cunha'},
    { stateCode: 'SP', label: 'Descalvado'},
    { stateCode: 'SP', label: 'Diadema'},
    { stateCode: 'SP', label: 'Dirce Reis'},
    { stateCode: 'SP', label: 'Divinolândia'},
    { stateCode: 'SP', label: 'Dobrada'},
    { stateCode: 'SP', label: 'Dois Córregos'},
    { stateCode: 'SP', label: 'Dolcinópolis'},
    { stateCode: 'SP', label: 'Dourado'},
    { stateCode: 'SP', label: 'Dracena'},
    { stateCode: 'SP', label: 'Duartina'},
    { stateCode: 'SP', label: 'Dumont'},
    { stateCode: 'SP', label: 'Echaporã'},
    { stateCode: 'SP', label: 'Eldorado'},
    { stateCode: 'SP', label: 'Elias Fausto'},
    { stateCode: 'SP', label: 'Elisiário'},
    { stateCode: 'SP', label: 'Embaúba'},
    { stateCode: 'SP', label: 'Embu'},
    { stateCode: 'SP', label: 'Embu-guaçu'},
    { stateCode: 'SP', label: 'Emilianópolis'},
    { stateCode: 'SP', label: 'Engenheiro Coelho'},
    { stateCode: 'SP', label: 'Espírito Santo do Pinhal'},
    { stateCode: 'SP', label: 'Espírito Santo do Turvo'},
    { stateCode: 'SP', label: 'Estiva Gerbi'},
    { stateCode: 'SP', label: 'Estrela D´oeste'},
    { stateCode: 'SP', label: 'Estrela do Norte'},
    { stateCode: 'SP', label: 'Euclides da Cunha Paulista'},
    { stateCode: 'SP', label: 'Fartura'},
    { stateCode: 'SP', label: 'Fernando Prestes'},
    { stateCode: 'SP', label: 'Fernandópolis'},
    { stateCode: 'SP', label: 'Fernão'},
    { stateCode: 'SP', label: 'Ferraz de Vasconcelos'},
    { stateCode: 'SP', label: 'Flora Rica'},
    { stateCode: 'SP', label: 'Floreal'},
    { stateCode: 'SP', label: 'Flórida Paulista'},
    { stateCode: 'SP', label: 'Florínia'},
    { stateCode: 'SP', label: 'Franca'},
    { stateCode: 'SP', label: 'Francisco Morato'},
    { stateCode: 'SP', label: 'Franco da Rocha'},
    { stateCode: 'SP', label: 'Gabriel Monteiro'},
    { stateCode: 'SP', label: 'Gália'},
    { stateCode: 'SP', label: 'Garça'},
    { stateCode: 'SP', label: 'Gastão Vidigal'},
    { stateCode: 'SP', label: 'Gavião Peixoto'},
    { stateCode: 'SP', label: 'General Salgado'},
    { stateCode: 'SP', label: 'Getulina'},
    { stateCode: 'SP', label: 'Glicério'},
    { stateCode: 'SP', label: 'Guaiçara'},
    { stateCode: 'SP', label: 'Guaimbê'},
    { stateCode: 'SP', label: 'Guaíra'},
    { stateCode: 'SP', label: 'Guapiaçu'},
    { stateCode: 'SP', label: 'Guapiara'},
    { stateCode: 'SP', label: 'Guará'},
    { stateCode: 'SP', label: 'Guaraçaí'},
    { stateCode: 'SP', label: 'Guaraci'},
    { stateCode: 'SP', label: 'Guarani D´oeste'},
    { stateCode: 'SP', label: 'Guarantã'},
    { stateCode: 'SP', label: 'Guararapes'},
    { stateCode: 'SP', label: 'Guararema'},
    { stateCode: 'SP', label: 'Guaratinguetá'},
    { stateCode: 'SP', label: 'Guareí'},
    { stateCode: 'SP', label: 'Guariba'},
    { stateCode: 'SP', label: 'Guarujá'},
    { stateCode: 'SP', label: 'Guarulhos'},
    { stateCode: 'SP', label: 'Guatapará'},
    { stateCode: 'SP', label: 'Guzolândia'},
    { stateCode: 'SP', label: 'Herculândia'},
    { stateCode: 'SP', label: 'Holambra'},
    { stateCode: 'SP', label: 'Hortolândia'},
    { stateCode: 'SP', label: 'Iacanga'},
    { stateCode: 'SP', label: 'Iacri'},
    { stateCode: 'SP', label: 'Iaras'},
    { stateCode: 'SP', label: 'Ibaté'},
    { stateCode: 'SP', label: 'Ibirá'},
    { stateCode: 'SP', label: 'Ibirarema'},
    { stateCode: 'SP', label: 'Ibitinga'},
    { stateCode: 'SP', label: 'Ibiúna'},
    { stateCode: 'SP', label: 'Icém'},
    { stateCode: 'SP', label: 'Iepê'},
    { stateCode: 'SP', label: 'Igaraçu do Tietê'},
    { stateCode: 'SP', label: 'Igarapava'},
    { stateCode: 'SP', label: 'Igaratá'},
    { stateCode: 'SP', label: 'Iguape'},
    { stateCode: 'SP', label: 'Ilha Comprida'},
    { stateCode: 'SP', label: 'Ilha Solteira'},
    { stateCode: 'SP', label: 'Ilhabela'},
    { stateCode: 'SP', label: 'Indaiatuba'},
    { stateCode: 'SP', label: 'Indiana'},
    { stateCode: 'SP', label: 'Indiaporã'},
    { stateCode: 'SP', label: 'Inúbia Paulista'},
    { stateCode: 'SP', label: 'Ipaussu'},
    { stateCode: 'SP', label: 'Iperó'},
    { stateCode: 'SP', label: 'Ipeúna'},
    { stateCode: 'SP', label: 'Ipiguá'},
    { stateCode: 'SP', label: 'Iporanga'},
    { stateCode: 'SP', label: 'Ipuã'},
    { stateCode: 'SP', label: 'Iracemápolis'},
    { stateCode: 'SP', label: 'Irapuã'},
    { stateCode: 'SP', label: 'Irapuru'},
    { stateCode: 'SP', label: 'Itaberá'},
    { stateCode: 'SP', label: 'Itaí'},
    { stateCode: 'SP', label: 'Itajobi'},
    { stateCode: 'SP', label: 'Itaju'},
    { stateCode: 'SP', label: 'Itanhaém'},
    { stateCode: 'SP', label: 'Itaóca'},
    { stateCode: 'SP', label: 'Itapecerica da Serra'},
    { stateCode: 'SP', label: 'Itapetininga'},
    { stateCode: 'SP', label: 'Itapeva'},
    { stateCode: 'SP', label: 'Itapevi'},
    { stateCode: 'SP', label: 'Itapira'},
    { stateCode: 'SP', label: 'Itapirapuã Paulista'},
    { stateCode: 'SP', label: 'Itápolis'},
    { stateCode: 'SP', label: 'Itaporanga'},
    { stateCode: 'SP', label: 'Itapuí'},
    { stateCode: 'SP', label: 'Itapura'},
    { stateCode: 'SP', label: 'Itaquaquecetuba'},
    { stateCode: 'SP', label: 'Itararé'},
    { stateCode: 'SP', label: 'Itariri'},
    { stateCode: 'SP', label: 'Itatiba'},
    { stateCode: 'SP', label: 'Itatinga'},
    { stateCode: 'SP', label: 'Itirapina'},
    { stateCode: 'SP', label: 'Itirapuã'},
    { stateCode: 'SP', label: 'Itobi'},
    { stateCode: 'SP', label: 'Itu'},
    { stateCode: 'SP', label: 'Itupeva'},
    { stateCode: 'SP', label: 'Ituverava'},
    { stateCode: 'SP', label: 'Jaborandi'},
    { stateCode: 'SP', label: 'Jaboticabal'},
    { stateCode: 'SP', label: 'Jacareí'},
    { stateCode: 'SP', label: 'Jaci'},
    { stateCode: 'SP', label: 'Jacupiranga'},
    { stateCode: 'SP', label: 'Jaguariúna'},
    { stateCode: 'SP', label: 'Jales'},
    { stateCode: 'SP', label: 'Jambeiro'},
    { stateCode: 'SP', label: 'Jandira'},
    { stateCode: 'SP', label: 'Jardinópolis'},
    { stateCode: 'SP', label: 'Jarinu'},
    { stateCode: 'SP', label: 'Jaú'},
    { stateCode: 'SP', label: 'Jeriquara'},
    { stateCode: 'SP', label: 'Joanópolis'},
    { stateCode: 'SP', label: 'João Ramalho'},
    { stateCode: 'SP', label: 'José Bonifácio'},
    { stateCode: 'SP', label: 'Júlio Mesquita'},
    { stateCode: 'SP', label: 'Jumirim'},
    { stateCode: 'SP', label: 'Jundiaí'},
    { stateCode: 'SP', label: 'Junqueirópolis'},
    { stateCode: 'SP', label: 'Juquiá'},
    { stateCode: 'SP', label: 'Juquitiba'},
    { stateCode: 'SP', label: 'Lagoinha'},
    { stateCode: 'SP', label: 'Laranjal Paulista'},
    { stateCode: 'SP', label: 'Lavínia'},
    { stateCode: 'SP', label: 'Lavrinhas'},
    { stateCode: 'SP', label: 'Leme'},
    { stateCode: 'SP', label: 'Lençóis Paulista'},
    { stateCode: 'SP', label: 'Limeira'},
    { stateCode: 'SP', label: 'Lindóia'},
    { stateCode: 'SP', label: 'Lins'},
    { stateCode: 'SP', label: 'Lorena'},
    { stateCode: 'SP', label: 'Lourdes'},
    { stateCode: 'SP', label: 'Louveira'},
    { stateCode: 'SP', label: 'Lucélia'},
    { stateCode: 'SP', label: 'Lucianópolis'},
    { stateCode: 'SP', label: 'Luís Antônio'},
    { stateCode: 'SP', label: 'Luiziânia'},
    { stateCode: 'SP', label: 'Lupércio'},
    { stateCode: 'SP', label: 'Lutécia'},
    { stateCode: 'SP', label: 'Macatuba'},
    { stateCode: 'SP', label: 'Macaubal'},
    { stateCode: 'SP', label: 'Macedônia'},
    { stateCode: 'SP', label: 'Magda'},
    { stateCode: 'SP', label: 'Mairinque'},
    { stateCode: 'SP', label: 'Mairiporã'},
    { stateCode: 'SP', label: 'Manduri'},
    { stateCode: 'SP', label: 'Marabá Paulista'},
    { stateCode: 'SP', label: 'Maracaí'},
    { stateCode: 'SP', label: 'Marapoama'},
    { stateCode: 'SP', label: 'Mariápolis'},
    { stateCode: 'SP', label: 'Marília'},
    { stateCode: 'SP', label: 'Marinópolis'},
    { stateCode: 'SP', label: 'Martinópolis'},
    { stateCode: 'SP', label: 'Matão'},
    { stateCode: 'SP', label: 'Mauá'},
    { stateCode: 'SP', label: 'Mendonça'},
    { stateCode: 'SP', label: 'Meridiano'},
    { stateCode: 'SP', label: 'Mesópolis'},
    { stateCode: 'SP', label: 'Miguelópolis'},
    { stateCode: 'SP', label: 'Mineiros do Tietê'},
    { stateCode: 'SP', label: 'Mira Estrela'},
    { stateCode: 'SP', label: 'Miracatu'},
    { stateCode: 'SP', label: 'Mirandópolis'},
    { stateCode: 'SP', label: 'Mirante do Paranapanema'},
    { stateCode: 'SP', label: 'Mirassol'},
    { stateCode: 'SP', label: 'Mirassolândia'},
    { stateCode: 'SP', label: 'Mococa'},
    { stateCode: 'SP', label: 'Mogi Guaçu'},
    { stateCode: 'SP', label: 'Moji Das Cruzes'},
    { stateCode: 'SP', label: 'Moji-mirim'},
    { stateCode: 'SP', label: 'Mombuca'},
    { stateCode: 'SP', label: 'Monções'},
    { stateCode: 'SP', label: 'Mongaguá'},
    { stateCode: 'SP', label: 'Monte Alegre do Sul'},
    { stateCode: 'SP', label: 'Monte Alto'},
    { stateCode: 'SP', label: 'Monte Aprazível'},
    { stateCode: 'SP', label: 'Monte Azul Paulista'},
    { stateCode: 'SP', label: 'Monte Castelo'},
    { stateCode: 'SP', label: 'Monte Mor'},
    { stateCode: 'SP', label: 'Monteiro Lobato'},
    { stateCode: 'SP', label: 'Morro Agudo'},
    { stateCode: 'SP', label: 'Morungaba'},
    { stateCode: 'SP', label: 'Motuca'},
    { stateCode: 'SP', label: 'Murutinga do Sul'},
    { stateCode: 'SP', label: 'Nantes'},
    { stateCode: 'SP', label: 'Narandiba'},
    { stateCode: 'SP', label: 'Natividade da Serra'},
    { stateCode: 'SP', label: 'Nazaré Paulista'},
    { stateCode: 'SP', label: 'Neves Paulista'},
    { stateCode: 'SP', label: 'Nhandeara'},
    { stateCode: 'SP', label: 'Nipoã'},
    { stateCode: 'SP', label: 'Nova Aliança'},
    { stateCode: 'SP', label: 'Nova Campina'},
    { stateCode: 'SP', label: 'Nova Canaã Paulista'},
    { stateCode: 'SP', label: 'Nova Castilho'},
    { stateCode: 'SP', label: 'Nova Europa'},
    { stateCode: 'SP', label: 'Nova Granada'},
    { stateCode: 'SP', label: 'Nova Guataporanga'},
    { stateCode: 'SP', label: 'Nova Independência'},
    { stateCode: 'SP', label: 'Nova Luzitânia'},
    { stateCode: 'SP', label: 'Nova Odessa'},
    { stateCode: 'SP', label: 'Novais'},
    { stateCode: 'SP', label: 'Novo Horizonte'},
    { stateCode: 'SP', label: 'Nuporanga'},
    { stateCode: 'SP', label: 'Ocauçu'},
    { stateCode: 'SP', label: 'Óleo'},
    { stateCode: 'SP', label: 'Olímpia'},
    { stateCode: 'SP', label: 'Onda Verde'},
    { stateCode: 'SP', label: 'Oriente'},
    { stateCode: 'SP', label: 'Orindiúva'},
    { stateCode: 'SP', label: 'Orlândia'},
    { stateCode: 'SP', label: 'Osasco'},
    { stateCode: 'SP', label: 'Oscar Bressane'},
    { stateCode: 'SP', label: 'Osvaldo Cruz'},
    { stateCode: 'SP', label: 'Ourinhos'},
    { stateCode: 'SP', label: 'Ouro Verde'},
    { stateCode: 'SP', label: 'Ouroeste'},
    { stateCode: 'SP', label: 'Pacaembu'},
    { stateCode: 'SP', label: 'Palestina'},
    { stateCode: 'SP', label: 'Palmares Paulista'},
    { stateCode: 'SP', label: 'Palmeira D´oeste'},
    { stateCode: 'SP', label: 'Palmital'},
    { stateCode: 'SP', label: 'Panorama'},
    { stateCode: 'SP', label: 'Paraguaçu Paulista'},
    { stateCode: 'SP', label: 'Paraibuna'},
    { stateCode: 'SP', label: 'Paraíso'},
    { stateCode: 'SP', label: 'Paranapanema'},
    { stateCode: 'SP', label: 'Paranapuã'},
    { stateCode: 'SP', label: 'Parapuã'},
    { stateCode: 'SP', label: 'Pardinho'},
    { stateCode: 'SP', label: 'Pariquera-açu'},
    { stateCode: 'SP', label: 'Parisi'},
    { stateCode: 'SP', label: 'Patrocínio Paulista'},
    { stateCode: 'SP', label: 'Paulicéia'},
    { stateCode: 'SP', label: 'Paulínia'},
    { stateCode: 'SP', label: 'Paulistânia'},
    { stateCode: 'SP', label: 'Paulo de Faria'},
    { stateCode: 'SP', label: 'Pederneiras'},
    { stateCode: 'SP', label: 'Pedra Bela'},
    { stateCode: 'SP', label: 'Pedranópolis'},
    { stateCode: 'SP', label: 'Pedregulho'},
    { stateCode: 'SP', label: 'Pedreira'},
    { stateCode: 'SP', label: 'Pedrinhas Paulista'},
    { stateCode: 'SP', label: 'Pedro de Toledo'},
    { stateCode: 'SP', label: 'Penápolis'},
    { stateCode: 'SP', label: 'Pereira Barreto'},
    { stateCode: 'SP', label: 'Pereiras'},
    { stateCode: 'SP', label: 'Peruíbe'},
    { stateCode: 'SP', label: 'Piacatu'},
    { stateCode: 'SP', label: 'Piedade'},
    { stateCode: 'SP', label: 'Pilar do Sul'},
    { stateCode: 'SP', label: 'Pindamonhangaba'},
    { stateCode: 'SP', label: 'Pindorama'},
    { stateCode: 'SP', label: 'Pinhalzinho'},
    { stateCode: 'SP', label: 'Piquerobi'},
    { stateCode: 'SP', label: 'Piquete'},
    { stateCode: 'SP', label: 'Piracaia'},
    { stateCode: 'SP', label: 'Piracicaba'},
    { stateCode: 'SP', label: 'Piraju'},
    { stateCode: 'SP', label: 'Pirajuí'},
    { stateCode: 'SP', label: 'Pirangi'},
    { stateCode: 'SP', label: 'Pirapora do Bom Jesus'},
    { stateCode: 'SP', label: 'Pirapozinho'},
    { stateCode: 'SP', label: 'Pirassununga'},
    { stateCode: 'SP', label: 'Piratininga'},
    { stateCode: 'SP', label: 'Pitangueiras'},
    { stateCode: 'SP', label: 'Planalto'},
    { stateCode: 'SP', label: 'Platina'},
    { stateCode: 'SP', label: 'Poá'},
    { stateCode: 'SP', label: 'Poloni'},
    { stateCode: 'SP', label: 'Pompéia'},
    { stateCode: 'SP', label: 'Pongaí'},
    { stateCode: 'SP', label: 'Pontal'},
    { stateCode: 'SP', label: 'Pontalinda'},
    { stateCode: 'SP', label: 'Pontes Gestal'},
    { stateCode: 'SP', label: 'Populina'},
    { stateCode: 'SP', label: 'Porangaba'},
    { stateCode: 'SP', label: 'Porto Feliz'},
    { stateCode: 'SP', label: 'Porto Ferreira'},
    { stateCode: 'SP', label: 'Potim'},
    { stateCode: 'SP', label: 'Potirendaba'},
    { stateCode: 'SP', label: 'Pracinha'},
    { stateCode: 'SP', label: 'Pradópolis'},
    { stateCode: 'SP', label: 'Praia Grande'},
    { stateCode: 'SP', label: 'Pratânia'},
    { stateCode: 'SP', label: 'Presidente Alves'},
    { stateCode: 'SP', label: 'Presidente Bernardes'},
    { stateCode: 'SP', label: 'Presidente Epitácio'},
    { stateCode: 'SP', label: 'Presidente Prudente'},
    { stateCode: 'SP', label: 'Presidente Venceslau'},
    { stateCode: 'SP', label: 'Promissão'},
    { stateCode: 'SP', label: 'Quadra'},
    { stateCode: 'SP', label: 'Quatá'},
    { stateCode: 'SP', label: 'Queiroz'},
    { stateCode: 'SP', label: 'Queluz'},
    { stateCode: 'SP', label: 'Quintana'},
    { stateCode: 'SP', label: 'Rafard'},
    { stateCode: 'SP', label: 'Rancharia'},
    { stateCode: 'SP', label: 'Redenção da Serra'},
    { stateCode: 'SP', label: 'Regente Feijó'},
    { stateCode: 'SP', label: 'Reginópolis'},
    { stateCode: 'SP', label: 'Registro'},
    { stateCode: 'SP', label: 'Restinga'},
    { stateCode: 'SP', label: 'Ribeira'},
    { stateCode: 'SP', label: 'Ribeirão Bonito'},
    { stateCode: 'SP', label: 'Ribeirão Branco'},
    { stateCode: 'SP', label: 'Ribeirão Corrente'},
    { stateCode: 'SP', label: 'Ribeirão do Sul'},
    { stateCode: 'SP', label: 'Ribeirão Dos Índios'},
    { stateCode: 'SP', label: 'Ribeirão Grande'},
    { stateCode: 'SP', label: 'Ribeirão Pires'},
    { stateCode: 'SP', label: 'Ribeirão Preto'},
    { stateCode: 'SP', label: 'Rifaina'},
    { stateCode: 'SP', label: 'Rincão'},
    { stateCode: 'SP', label: 'Rinópolis'},
    { stateCode: 'SP', label: 'Rio Claro'},
    { stateCode: 'SP', label: 'Rio Das Pedras'},
    { stateCode: 'SP', label: 'Rio Grande da Serra'},
    { stateCode: 'SP', label: 'Riolândia'},
    { stateCode: 'SP', label: 'Riversul'},
    { stateCode: 'SP', label: 'Rosana'},
    { stateCode: 'SP', label: 'Roseira'},
    { stateCode: 'SP', label: 'Rubiácea'},
    { stateCode: 'SP', label: 'Rubinéia'},
    { stateCode: 'SP', label: 'Sabino'},
    { stateCode: 'SP', label: 'Sagres'},
    { stateCode: 'SP', label: 'Sales'},
    { stateCode: 'SP', label: 'Sales Oliveira'},
    { stateCode: 'SP', label: 'Salesópolis'},
    { stateCode: 'SP', label: 'Salmourão'},
    { stateCode: 'SP', label: 'Saltinho'},
    { stateCode: 'SP', label: 'Salto'},
    { stateCode: 'SP', label: 'Salto de Pirapora'},
    { stateCode: 'SP', label: 'Salto Grande'},
    { stateCode: 'SP', label: 'Sandovalina'},
    { stateCode: 'SP', label: 'Santa Adélia'},
    { stateCode: 'SP', label: 'Santa Albertina'},
    { stateCode: 'SP', label: 'Santa Bárbara D´oeste'},
    { stateCode: 'SP', label: 'Santa Branca'},
    { stateCode: 'SP', label: 'Santa Clara D´oeste'},
    { stateCode: 'SP', label: 'Santa Cruz da Conceição'},
    { stateCode: 'SP', label: 'Santa Cruz da Esperança'},
    { stateCode: 'SP', label: 'Santa Cruz Das Palmeiras'},
    { stateCode: 'SP', label: 'Santa Cruz do Rio Pardo'},
    { stateCode: 'SP', label: 'Santa Ernestina'},
    { stateCode: 'SP', label: 'Santa fé do Sul'},
    { stateCode: 'SP', label: 'Santa Gertrudes'},
    { stateCode: 'SP', label: 'Santa Isabel'},
    { stateCode: 'SP', label: 'Santa Lúcia'},
    { stateCode: 'SP', label: 'Santa Maria da Serra'},
    { stateCode: 'SP', label: 'Santa Mercedes'},
    { stateCode: 'SP', label: 'Santa Rita D´oeste'},
    { stateCode: 'SP', label: 'Santa Rita do Passa Quatro'},
    { stateCode: 'SP', label: 'Santa Rosa de Viterbo'},
    { stateCode: 'SP', label: 'Santa Salete'},
    { stateCode: 'SP', label: 'Santana da Ponte Pensa'},
    { stateCode: 'SP', label: 'Santana de Parnaíba'},
    { stateCode: 'SP', label: 'Santo Anastácio'},
    { stateCode: 'SP', label: 'Santo André'},
    { stateCode: 'SP', label: 'Santo Antônio da Alegria'},
    { stateCode: 'SP', label: 'Santo Antônio de Posse'},
    { stateCode: 'SP', label: 'Santo Antônio do Aracanguá'},
    { stateCode: 'SP', label: 'Santo Antônio do Jardim'},
    { stateCode: 'SP', label: 'Santo Antônio do Pinhal'},
    { stateCode: 'SP', label: 'Santo Expedito'},
    { stateCode: 'SP', label: 'Santópolis do Aguapeí'},
    { stateCode: 'SP', label: 'Santos'},
    { stateCode: 'SP', label: 'São Bento do Sapucaí'},
    { stateCode: 'SP', label: 'São Bernardo do Campo'},
    { stateCode: 'SP', label: 'São Caetano do Sul'},
    { stateCode: 'SP', label: 'São Carlos'},
    { stateCode: 'SP', label: 'São Francisco'},
    { stateCode: 'SP', label: 'São João da Boa Vista'},
    { stateCode: 'SP', label: 'São João Das Duas Pontes'},
    { stateCode: 'SP', label: 'São João de Iracema'},
    { stateCode: 'SP', label: 'São João do Pau D´alho'},
    { stateCode: 'SP', label: 'São Joaquim da Barra'},
    { stateCode: 'SP', label: 'São José da Bela Vista'},
    { stateCode: 'SP', label: 'São José do Barreiro'},
    { stateCode: 'SP', label: 'São José do Rio Pardo'},
    { stateCode: 'SP', label: 'São José do Rio Preto'},
    { stateCode: 'SP', label: 'São José Dos Campos'},
    { stateCode: 'SP', label: 'São Lourenço da Serra'},
    { stateCode: 'SP', label: 'São Luís do Paraitinga'},
    { stateCode: 'SP', label: 'São Manuel'},
    { stateCode: 'SP', label: 'São Miguel Arcanjo'},
    { stateCode: 'SP', label: 'São Paulo'},
    { stateCode: 'SP', label: 'São Pedro'},
    { stateCode: 'SP', label: 'São Pedro do Turvo'},
    { stateCode: 'SP', label: 'São Roque'},
    { stateCode: 'SP', label: 'São Sebastião'},
    { stateCode: 'SP', label: 'São Sebastião da Grama'},
    { stateCode: 'SP', label: 'São Simão'},
    { stateCode: 'SP', label: 'São Vicente'},
    { stateCode: 'SP', label: 'Sarapuí'},
    { stateCode: 'SP', label: 'Sarutaiá'},
    { stateCode: 'SP', label: 'Sebastianópolis do Sul'},
    { stateCode: 'SP', label: 'Serra Azul'},
    { stateCode: 'SP', label: 'Serra Negra'},
    { stateCode: 'SP', label: 'Serrana'},
    { stateCode: 'SP', label: 'Sertãozinho'},
    { stateCode: 'SP', label: 'Sete Barras'},
    { stateCode: 'SP', label: 'Severínia'},
    { stateCode: 'SP', label: 'Silveiras'},
    { stateCode: 'SP', label: 'Socorro'},
    { stateCode: 'SP', label: 'Sorocaba'},
    { stateCode: 'SP', label: 'Sud Mennucci'},
    { stateCode: 'SP', label: 'Sumaré'},
    { stateCode: 'SP', label: 'Suzanápolis'},
    { stateCode: 'SP', label: 'Suzano'},
    { stateCode: 'SP', label: 'Tabapuã'},
    { stateCode: 'SP', label: 'Tabatinga'},
    { stateCode: 'SP', label: 'Taboão da Serra'},
    { stateCode: 'SP', label: 'Taciba'},
    { stateCode: 'SP', label: 'Taguaí'},
    { stateCode: 'SP', label: 'Taiaçu'},
    { stateCode: 'SP', label: 'Taiúva'},
    { stateCode: 'SP', label: 'Tambaú'},
    { stateCode: 'SP', label: 'Tanabi'},
    { stateCode: 'SP', label: 'Tapiraí'},
    { stateCode: 'SP', label: 'Tapiratiba'},
    { stateCode: 'SP', label: 'Taquaral'},
    { stateCode: 'SP', label: 'Taquaritinga'},
    { stateCode: 'SP', label: 'Taquarituba'},
    { stateCode: 'SP', label: 'Taquarivaí'},
    { stateCode: 'SP', label: 'Tarabai'},
    { stateCode: 'SP', label: 'Tarumã'},
    { stateCode: 'SP', label: 'Tatuí'},
    { stateCode: 'SP', label: 'Taubaté'},
    { stateCode: 'SP', label: 'Tejupá'},
    { stateCode: 'SP', label: 'Teodoro Sampaio'},
    { stateCode: 'SP', label: 'Terra Roxa'},
    { stateCode: 'SP', label: 'Tietê'},
    { stateCode: 'SP', label: 'Timburi'},
    { stateCode: 'SP', label: 'Torre de Pedra'},
    { stateCode: 'SP', label: 'Torrinha'},
    { stateCode: 'SP', label: 'Trabiju'},
    { stateCode: 'SP', label: 'Tremembé'},
    { stateCode: 'SP', label: 'Três Fronteiras'},
    { stateCode: 'SP', label: 'Tuiuti'},
    { stateCode: 'SP', label: 'Tupã'},
    { stateCode: 'SP', label: 'Tupi Paulista'},
    { stateCode: 'SP', label: 'Turiúba'},
    { stateCode: 'SP', label: 'Turmalina'},
    { stateCode: 'SP', label: 'Ubarana'},
    { stateCode: 'SP', label: 'Ubatuba'},
    { stateCode: 'SP', label: 'Ubirajara'},
    { stateCode: 'SP', label: 'Uchoa'},
    { stateCode: 'SP', label: 'União Paulista'},
    { stateCode: 'SP', label: 'Urânia'},
    { stateCode: 'SP', label: 'Uru'},
    { stateCode: 'SP', label: 'Urupês'},
    { stateCode: 'SP', label: 'Valentim Gentil'},
    { stateCode: 'SP', label: 'Valinhos'},
    { stateCode: 'SP', label: 'Valparaíso'},
    { stateCode: 'SP', label: 'Vargem'},
    { stateCode: 'SP', label: 'Vargem Grande do Sul'},
    { stateCode: 'SP', label: 'Vargem Grande Paulista'},
    { stateCode: 'SP', label: 'Várzea Paulista'},
    { stateCode: 'SP', label: 'Vera Cruz'},
    { stateCode: 'SP', label: 'Vinhedo'},
    { stateCode: 'SP', label: 'Viradouro'},
    { stateCode: 'SP', label: 'Vista Alegre do Alto'},
    { stateCode: 'SP', label: 'Vitória Brasil'},
    { stateCode: 'SP', label: 'Votorantim'},
    { stateCode: 'SP', label: 'Votuporanga'},
    { stateCode: 'SP', label: 'Zacarias'},
    { stateCode: 'TO', label: 'Abreulândia'},
    { stateCode: 'TO', label: 'Aguiarnópolis'},
    { stateCode: 'TO', label: 'Aliança do Tocantins'},
    { stateCode: 'TO', label: 'Almas'},
    { stateCode: 'TO', label: 'Alvorada'},
    { stateCode: 'TO', label: 'Ananás'},
    { stateCode: 'TO', label: 'Angico'},
    { stateCode: 'TO', label: 'Aparecida do Rio Negro'},
    { stateCode: 'TO', label: 'Aragominas'},
    { stateCode: 'TO', label: 'Araguacema'},
    { stateCode: 'TO', label: 'Araguaçu'},
    { stateCode: 'TO', label: 'Araguaína'},
    { stateCode: 'TO', label: 'Araguanã'},
    { stateCode: 'TO', label: 'Araguatins'},
    { stateCode: 'TO', label: 'Arapoema'},
    { stateCode: 'TO', label: 'Arraias'},
    { stateCode: 'TO', label: 'Augustinópolis'},
    { stateCode: 'TO', label: 'Aurora do Tocantins'},
    { stateCode: 'TO', label: 'Axixá do Tocantins'},
    { stateCode: 'TO', label: 'Babaçulândia'},
    { stateCode: 'TO', label: 'Bandeirantes do Tocantins'},
    { stateCode: 'TO', label: 'Barra do Ouro'},
    { stateCode: 'TO', label: 'Barrolândia'},
    { stateCode: 'TO', label: 'Bernardo Sayão'},
    { stateCode: 'TO', label: 'Bom Jesus do Tocantins'},
    { stateCode: 'TO', label: 'Brasilândia do Tocantins'},
    { stateCode: 'TO', label: 'Brejinho de Nazaré'},
    { stateCode: 'TO', label: 'Buriti do Tocantins'},
    { stateCode: 'TO', label: 'Cachoeirinha'},
    { stateCode: 'TO', label: 'Campos Lindos'},
    { stateCode: 'TO', label: 'Cariri do Tocantins'},
    { stateCode: 'TO', label: 'Carmolândia'},
    { stateCode: 'TO', label: 'Carrasco Bonito'},
    { stateCode: 'TO', label: 'Caseara'},
    { stateCode: 'TO', label: 'Centenário'},
    { stateCode: 'TO', label: 'Chapada da Natividade'},
    { stateCode: 'TO', label: 'Chapada de Areia'},
    { stateCode: 'TO', label: 'Colinas do Tocantins'},
    { stateCode: 'TO', label: 'Colméia'},
    { stateCode: 'TO', label: 'Combinado'},
    { stateCode: 'TO', label: 'Conceição do Tocantins'},
    { stateCode: 'TO', label: 'Couto de Magalhães'},
    { stateCode: 'TO', label: 'Cristalândia'},
    { stateCode: 'TO', label: 'Crixás do Tocantins'},
    { stateCode: 'TO', label: 'Darcinópolis'},
    { stateCode: 'TO', label: 'Dianópolis'},
    { stateCode: 'TO', label: 'Divinópolis do Tocantins'},
    { stateCode: 'TO', label: 'Dois Irmãos do Tocantins'},
    { stateCode: 'TO', label: 'Dueré'},
    { stateCode: 'TO', label: 'Esperantina'},
    { stateCode: 'TO', label: 'Fátima'},
    { stateCode: 'TO', label: 'Figueirópolis'},
    { stateCode: 'TO', label: 'Filadélfia'},
    { stateCode: 'TO', label: 'Formoso do Araguaia'},
    { stateCode: 'TO', label: 'Fortaleza do Tabocão'},
    { stateCode: 'TO', label: 'Goianorte'},
    { stateCode: 'TO', label: 'Goiatins'},
    { stateCode: 'TO', label: 'Guaraí'},
    { stateCode: 'TO', label: 'Gurupi'},
    { stateCode: 'TO', label: 'Ipueiras'},
    { stateCode: 'TO', label: 'Itacajá'},
    { stateCode: 'TO', label: 'Itaguatins'},
    { stateCode: 'TO', label: 'Itapiratins'},
    { stateCode: 'TO', label: 'Itaporã do Tocantins'},
    { stateCode: 'TO', label: 'Jaú do Tocantins'},
    { stateCode: 'TO', label: 'Juarina'},
    { stateCode: 'TO', label: 'Lagoa da Confusão'},
    { stateCode: 'TO', label: 'Lagoa do Tocantins'},
    { stateCode: 'TO', label: 'Lajeado'},
    { stateCode: 'TO', label: 'Lavandeira'},
    { stateCode: 'TO', label: 'Lizarda'},
    { stateCode: 'TO', label: 'Luzinópolis'},
    { stateCode: 'TO', label: 'Marianópolis do Tocantins'},
    { stateCode: 'TO', label: 'Mateiros'},
    { stateCode: 'TO', label: 'Maurilândia do Tocantins'},
    { stateCode: 'TO', label: 'Miracema do Tocantins'},
    { stateCode: 'TO', label: 'Miranorte'},
    { stateCode: 'TO', label: 'Monte do Carmo'},
    { stateCode: 'TO', label: 'Monte Santo do Tocantins'},
    { stateCode: 'TO', label: 'Muricilândia'},
    { stateCode: 'TO', label: 'Natividade'},
    { stateCode: 'TO', label: 'Nazaré'},
    { stateCode: 'TO', label: 'Nova Olinda'},
    { stateCode: 'TO', label: 'Nova Rosalândia'},
    { stateCode: 'TO', label: 'Novo Acordo'},
    { stateCode: 'TO', label: 'Novo Alegre'},
    { stateCode: 'TO', label: 'Novo Jardim'},
    { stateCode: 'TO', label: 'Oliveira de Fátima'},
    { stateCode: 'TO', label: 'Palmas'},
    { stateCode: 'TO', label: 'Palmeirante'},
    { stateCode: 'TO', label: 'Palmeiras do Tocantins'},
    { stateCode: 'TO', label: 'Palmeirópolis'},
    { stateCode: 'TO', label: 'Paraíso do Tocantins'},
    { stateCode: 'TO', label: 'Paranã'},
    { stateCode: 'TO', label: 'Pau D´arco'},
    { stateCode: 'TO', label: 'Pedro Afonso'},
    { stateCode: 'TO', label: 'Peixe'},
    { stateCode: 'TO', label: 'Pequizeiro'},
    { stateCode: 'TO', label: 'Pindorama do Tocantins'},
    { stateCode: 'TO', label: 'Piraquê'},
    { stateCode: 'TO', label: 'Pium'},
    { stateCode: 'TO', label: 'Ponte Alta do Bom Jesus'},
    { stateCode: 'TO', label: 'Ponte Alta do Tocantins'},
    { stateCode: 'TO', label: 'Porto Alegre do Tocantins'},
    { stateCode: 'TO', label: 'Porto Nacional'},
    { stateCode: 'TO', label: 'Praia Norte'},
    { stateCode: 'TO', label: 'Presidente Kennedy'},
    { stateCode: 'TO', label: 'Pugmil'},
    { stateCode: 'TO', label: 'Recursolândia'},
    { stateCode: 'TO', label: 'Riachinho'},
    { stateCode: 'TO', label: 'Rio da Conceição'},
    { stateCode: 'TO', label: 'Rio Dos Bois'},
    { stateCode: 'TO', label: 'Rio Sono'},
    { stateCode: 'TO', label: 'Sampaio'},
    { stateCode: 'TO', label: 'Sandolândia'},
    { stateCode: 'TO', label: 'Santa fé do Araguaia'},
    { stateCode: 'TO', label: 'Santa Maria do Tocantins'},
    { stateCode: 'TO', label: 'Santa Rita do Tocantins'},
    { stateCode: 'TO', label: 'Santa Rosa do Tocantins'},
    { stateCode: 'TO', label: 'Santa Tereza do Tocantins'},
    { stateCode: 'TO', label: 'Santa Terezinha do Tocantins'},
    { stateCode: 'TO', label: 'São Bento do Tocantins'},
    { stateCode: 'TO', label: 'São Félix do Tocantins'},
    { stateCode: 'TO', label: 'São Miguel do Tocantins'},
    { stateCode: 'TO', label: 'São Salvador do Tocantins'},
    { stateCode: 'TO', label: 'São Sebastião do Tocantins'},
    { stateCode: 'TO', label: 'São Valério da Natividade'},
    { stateCode: 'TO', label: 'Silvanópolis'},
    { stateCode: 'TO', label: 'Sítio Novo do Tocantins'},
    { stateCode: 'TO', label: 'Sucupira'},
    { stateCode: 'TO', label: 'Taguatinga'},
    { stateCode: 'TO', label: 'Taipas do Tocantins'},
    { stateCode: 'TO', label: 'Talismã'},
    { stateCode: 'TO', label: 'Tocantínia'},
    { stateCode: 'TO', label: 'Tocantinópolis'},
    { stateCode: 'TO', label: 'Tupirama'},
    { stateCode: 'TO', label: 'Tupiratins'},
    { stateCode: 'TO', label: 'Wanderlândia'},
    { stateCode: 'TO', label: 'Xambioá'},
    ];