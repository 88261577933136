type IOptions = {
  id: string;
  label: string;
  value: string;
}

export const venueTypes: IOptions[] = [
  { id: '1', label: 'bar', value: 'bar'},
  { id: '2', label: 'nightClub', value: 'nightclub'},
  { id: '3', label: 'restaurant', value: 'restaurant'},
  { id: '4', label: 'events', value: 'events'},
]