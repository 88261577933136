import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { VenueManager, VenueFiscal, VenueSchedule, VenueState, VenueSecurity, VenueSettings, VenueDocs } from '../../@types/venue';
import { collection, query, where, getDocs } from 'firebase/firestore';
//
import { dispatch } from '../store';
import { UserManager } from '../../@types/user';

// ----------------------------------------------------------------------

const initialState: VenueState = {
  isLoading: false,
  error: null,
  venues: [],
  tenantVenue: 'all',
  venue: null,
  fiscal: null,
  schedule: [],
  security: [],
  settings: null,
  docs: null,
  deleteSchedule: [],
  isOpenModal: false,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET TENANT VENUE
    setTenantVenue(state, action) {
      state.isLoading = false
      state.tenantVenue = action.payload
    },

    // GET VENUES
    getVenuesSuccess(state, action) {
      state.isLoading = false;
      state.venues = action.payload;
    },

    // GET VENUE
    getVenueSuccess(state, action) {
      state.isLoading = false;
      state.venue = action.payload;
    },
    
    // GET VENUE AUTH
    getVenueAuthSuccess(state, action) {
      state.isLoading = false;
      state.security = action.payload;
    },

    // GET VENUE DOCS
    getVenueDocsSuccess(state, action) {
      state.isLoading = false;
      state.docs = action.payload;
    },

    // GET VENUE FISCAL
    getVenueFiscalSuccess(state, action) {
      state.isLoading = false;
      state.fiscal = action.payload;
    },

    // GET VENUE SCHEDULE
    getVenueScheduleSuccess(state, action) {
      state.isLoading = false;
      state.schedule = action.payload;
    },

    // GET VENUE SETTINGS
    getVenueSettingsSuccess(state, action) {
      state.isLoading = false;
      state.settings = action.payload;
    },

    deleteVenueSchedule(state, action) {
      const deleteSchedule = state.schedule.filter((item) => item.id !== action.payload.id);
      // state.deleteSchedule.push(action.payload)
      // const deletedArray = state.deleteSchedule.push(deleteSchedule)
      // state.deleteSchedule = deleteSchedule;

      state.isLoading = false;
      state.schedule = deleteSchedule;
    },

    //  SORT & FILTER VENUES
    sortByVenues(state, action) {
      state.sortBy = action.payload;
    },

    filterVenues(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

     // OPEN MODAL
     openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setTenantVenue,
  sortByVenues,
  filterVenues,
  openModal,
  closeModal,
} = slice.actions;

// ----------------------------------------------------------------------

export function getVenues() {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Axios query
      const response: { data: { venues: VenueManager[] } } = await axios.get('/venues');
      dispatch(slice.actions.getVenuesSuccess(response.data));
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenue(venueId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { venue: VenueManager } } = await axios.get('/venue/profile', {
        params: { venueId },
      });
      dispatch(slice.actions.getVenueSuccess(response.data.venue));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenueDocs(venueId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { docs: VenueDocs } } = await axios.get('/venue/docs', {
        params: { venueId },
      });
      dispatch(slice.actions.getVenueDocsSuccess(response.data.docs));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenueFiscal(venueId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { fiscal: VenueFiscal } } = await axios.get('/venue/fiscal', {
        params: { venueId },
      });
      dispatch(slice.actions.getVenueFiscalSuccess(response.data.fiscal));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenueSchedule(venueId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { schedule: VenueSchedule } } = await axios.get('/venue/schedule', {
        params: { venueId },
      });
      dispatch(slice.actions.getVenueScheduleSuccess(response.data.schedule));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVenueSecurity(venueId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { users: UserManager } } = await axios.get('/venue/security', {
        params: { venueId },
      });
      dispatch(slice.actions.getVenueAuthSuccess(response.data.users));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// NOT BEING USED - PLACEHOLDER FOR WHEN THERE'RE MORE SEETINGS
export function getVenueSettings(venueId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { settings: VenueSettings } } = await axios.get('/venue/settings', {
        params: { venueId },
      });
      dispatch(slice.actions.getVenueSettingsSuccess(response.data.settings));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createVenue(data: VenueManager) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/new', {
        data,
      });

      if (data.avatarUrl) {
        
        const formData = new FormData()

        formData.append('photo_key', data.photo_key)
      
        formData.append('file', data.avatarUrl)
      
        const photoResponse = await axios.post(`/venue/profile-photo/upload/${response.data.venue.id}`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
      dispatch(slice.actions.getVenueSuccess(response.data.venue));
      // dispatch(getVenue(response.data.venue.id))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createVenueFiscal(data: VenueFiscal) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/fiscal/new', {
        data,
      });
      dispatch(slice.actions.getVenueScheduleSuccess(response.data.venue));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createVenueSchedule(data: VenueManager) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/schedule/new', {
        data,
      });
      dispatch(slice.actions.getVenueScheduleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVenue(data: VenueManager) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(data)
      const isNewPhoto = typeof data.avatarUrl === 'object'

      if (isNewPhoto && data.avatarUrl) {
        
        const formData = new FormData()

        formData.append('photo_key', data.photo_key)

        formData.append('file', data.avatarUrl)
        
        console.log('formData', formData)

        const photoResponse = await axios.post(`/venue/profile-photo/upload/${data.id}`, formData , {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      const formData2 = new FormData()

      // Add deleted photos
      formData2.append('deletedPhoto_keys', JSON.stringify(data.deletedPhoto_keys));
      formData2.append('photo_urls', JSON.stringify(data.photo_urls))
      
      let newPhotos = false
      console.log(formData2)

      data.avatarUrls?.map((avatarUrl) => {
        const isNewPhoto = typeof avatarUrl === 'object'
        console.log(isNewPhoto, typeof avatarUrl, avatarUrl)

        if (avatarUrl && typeof avatarUrl === 'object') {
          newPhotos = true
          formData2.append('newPhotos', avatarUrl)
        }
      })

      const photoResponse = await axios.post(`/venue/profile-photo/upload-multiple/${data.id}`, formData2 , {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const response = await axios.post('/venue/edit', {
        data,
      });  

      dispatch(slice.actions.getVenueSuccess(response.data.venue));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVenueDocs(data: VenueDocs) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const formData = new FormData()

      if (data.federalIdCard && typeof data.federalIdCard === 'object') {
        formData.append('federalIdCard', data.federalIdCard)
      }

      if (data.companyContract && typeof data.companyContract === 'object') {
        formData.append('companyContract', data.companyContract)
      }

      if (data.addressProof && typeof data.addressProof === 'object') {
        formData.append('addressProof', data.addressProof)
      }

      if (data.bankingProof && typeof data.bankingProof === 'object') {
        formData.append('bankingProof', data.bankingProof)
      }

      // if (data.docs) {

      //   for (let i = 0; i < data.docs.length; i++) {
      //     formData.append('files', data.docs[i]);
      //   }
      // }

      const response = await axios.post(`/venue/docs/upload/${data.venueId}`, formData , {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      
      dispatch(slice.actions.getVenueDocsSuccess(response.data.docs));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVenueFiscal(data: VenueFiscal) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/fiscal/edit', {
        data,
      });
      dispatch(slice.actions.getVenueFiscalSuccess(response.data.fiscal));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVenueSchedule(data: VenueManager) {
  return async () => {
    dispatch(slice.actions.startLoading());
    console.log(data)
    try {
      const response = await axios.post('/venue/schedule/edit', {
        data,
      });
      dispatch(slice.actions.getVenueScheduleSuccess(response.data.venue.schedule));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVenueSecurity(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/security/edit', {
        data,
      });

      dispatch(slice.actions.getVenueAuthSuccess(response.data.users));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteVenueSchedule(item: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/venue/schedule/delete', {
        data: item,
      });
      dispatch(slice.actions.deleteVenueSchedule(item))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

// ----------------------------------------------------------------------

export function bulkUpdateVenues(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/venue/bulk-edit', {
        data,
      });
      dispatch(slice.actions.getVenueSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}