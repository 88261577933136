import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
// @mui
import { useTheme } from '@mui/material/styles';
// hooks
import useLocales from '../hooks/useLocales';
// translations
import { tokens } from '../locales/tokens';

const Tour: React.FC = () => {
    const [run, setRun] = useState(false);
    const location = useLocation();
    
    const theme = useTheme();

    const { t } = useLocales()

    const steps: Step[] = [
      {
        target: '.dashboard-open-venues-step',
        title: t(tokens.joyride.dashboard.openVenuesCard.title),
        content: t(tokens.joyride.dashboard.openVenuesCard.content),
        placement: 'top',
      },
      {
        target: '.dashboard-sales-overview-step',
        title: t(tokens.joyride.dashboard.salesOverviewCard.title),
        content: t(tokens.joyride.dashboard.salesOverviewCard.content),
        placement: 'bottom',
      },
      {
        target: '.navbar-dashboard-step',
        content: 'This is the dashboard page!',
        placement: 'right',
      },
      {
        target: '.navbar-orders-step',
        title: t(tokens.joyride.ordersPage.title),
        content: t(tokens.joyride.ordersPage.content),
        placement: 'right',
      },
      {
        target: '.navbar-users-step',
        title: t(tokens.joyride.usersPage.title),
        content: t(tokens.joyride.usersPage.content),
        placement: 'right',
      },
      {
        target: '.navbar-venues-step',
        title: t(tokens.joyride.venuesPage.title),
        content: t(tokens.joyride.venuesPage.content),
        placement: 'right',
      },
      {
        target: '.navbar-products-step',
        title: t(tokens.joyride.menuPage.title),
        content: t(tokens.joyride.menuPage.content),
        placement: 'right',
      },
      {
        target: '.navbar-inventory-step',
        title: t(tokens.joyride.inventoryPage.title),
        content: t(tokens.joyride.inventoryPage.content),
        placement: 'right',
      },
      {
        target: '.navbar-invoices-step',
        title: t(tokens.joyride.invoicesPage.title),
        content: t(tokens.joyride.invoicesPage.content),
        placement: 'right',
      },
    ];

    const customOptions = {
        arrowColor: theme.palette.background.paper,
        backgroundColor: theme.palette.background.paper,
        beaconSize: 36,
        overlayColor: theme.palette.secondary.darker,
        primaryColor: theme.palette.primary.main,
        spotlightShadow: theme.shadows[24],
        textColor: theme.palette.text.primary,
        width: undefined,
        zIndex: 9999,
    };

    useEffect(() => {
        // Check if user is on dashboard and has not seen the tour before
        if (location.pathname.startsWith('/dashboard') && !localStorage.getItem('joyrideSeen')) {
            setRun(true);
        } else {
            setRun(false);
        }
    }, [location]);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;

        console.log(status)

        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            setRun(false);
            localStorage.setItem('joyrideSeen', 'true'); // Save the tour state to local storage
        }
    };

    return (
        <Joyride
            steps={steps}
            run={run}
            continuous
            scrollToFirstStep
            // showSkipButton
            showProgress
            hideCloseButton
            disableCloseOnEsc
            disableScrolling
            disableOverlayClose
            callback={handleJoyrideCallback}
            styles={{ 
                options: customOptions,
                overlay: { 
                    height: document.body.scrollHeight
                },
                tooltipContent: {
                  maxWidth: '360px', // Set a maximum width for the tooltip
                  whiteSpace: 'normal', // Ensure text wraps normally
                  wordWrap: 'break-word', // Break long words
                },
            }}
            locale={{
                back: t(tokens.buttons.previous),
                last: t(tokens.buttons.finish),
                next: t(tokens.buttons.next),
              }}
        />
    );
};

export default Tour;