export const states = [
    { code: 'AC', label: 'Acre'},
    { code: 'AL', label: 'Alagoas'},
    { code: 'AM', label: 'Amazonas'},
    { code: 'AP', label: 'Amapá'},
    { code: 'BA', label: 'Bahia'},
    { code: 'CE', label: 'Ceará'},
    { code: 'DF', label: 'Distrito Federal'},
    { code: 'ES', label: 'Espírito Santo'},
    { code: 'GO', label: 'Goiás'},
    { code: 'MA', label: 'Maranhão'},
    { code: 'MG', label: 'Minas Gerais'},
    { code: 'MS', label: 'Mato Grosso do Sul'},
    { code: 'MT', label: 'Mato Grosso'},
    { code: 'PA', label: 'Pará'},
    { code: 'PB', label: 'Paraíba'},
    { code: 'PE', label: 'Pernambuco'},
    { code: 'PI', label: 'Piauí'},
    { code: 'PR', label: 'Paraná'},
    { code: 'RJ', label: 'Rio de Janeiro'},
    { code: 'RN', label: 'Rio Grande do Norte'},
    { code: 'RO', label: 'Rondônia'},
    { code: 'RR', label: 'Roraima'},
    { code: 'RS', label: 'Rio Grande do Sul'},
    { code: 'SC', label: 'Santa Catarina'},
    { code: 'SE', label: 'Sergipe'},
    { code: 'SP', label: 'São Paulo'},
    { code: 'TO', label: 'Tocantins'},
  ];