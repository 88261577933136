// @mui
import { ListItemButtonProps, ListSubheaderProps } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// guards
import RoleBasedGuard from '../../../guards/RoleBasedGuard';
//
import { NavSubheaderProps } from '../type';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle, ListSubheaderStyle } from './style';

// ----------------------------------------------------------------------

type Props = NavSubheaderProps & ListSubheaderProps;

export default function NavItem({ subheader, roles, tenant, isCollapse, ...other }: Props) {
  const { t } = useLocales();

  const renderContent = (
    <ListSubheaderStyle
      sx={{
        ...(isCollapse && {
          opacity: 0,
        }),
      }}
    >
      {t(subheader)}
    </ListSubheaderStyle>
  );

  return <RoleBasedGuard roles={roles} tenant={tenant}>{renderContent}</RoleBasedGuard>;
}