import { useContext, useEffect, useRef } from 'react';
import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import { SocketContext } from '../contexts/SocketContext';

export const useSocket = (url: string, options?: Partial<ManagerOptions & SocketOptions> | undefined): Socket => {
    const { current: socket } = useRef(io(url, options));

    useEffect(() => {
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    return socket;
};

export const useWebSocket = () => {
    const context = useContext(SocketContext);
  
    if (!context) throw new Error('Socket context must be use inside SocketProvider');
  
    return context;
  };