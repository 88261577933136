import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const TEXT_PRIMARY = theme.palette.text.primary

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box sx={{ width: 80, height: 80, ...sx }}>

    <svg width="100%" height="100%" viewBox="0 0 192 192" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="50.000088%" x2="104.457578%" y2="50.000088%" id="linearGradient-1">
                <stop stop-color="#0077B3" offset="0%"></stop>
                <stop stop-color="#86BC25" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="iOS-Icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Check-iOS-Icon" transform="translate(36.000000, 25.000000)" fill-rule="nonzero">
                <path d="M31.6845681,112.875522 C30.767088,112.877015 29.8857118,112.517919 29.2302127,111.875546 L18.5221988,101.365397 C17.8782963,100.750353 17.2218503,100.110205 16.5779479,99.4616883 C-5.51134251,76.9046684 -5.52806246,40.8156625 16.5403172,18.2381571 C17.2176691,17.560353 17.8741151,16.9160206 18.5431047,16.2716883 C41.8707493,-5.40940206 77.9581361,-5.42582134 101.305474,16.2340325 C102.012094,16.9118367 102.66854,17.556169 103.312443,18.2005014 C122.623869,37.903498 125.38355,68.4955089 109.910352,91.3405904 C108.829184,92.9133747 106.691981,93.3366451 105.093627,92.294537 C104.327255,91.8063541 103.792283,91.027241 103.61169,90.1362999 C103.431097,89.2453589 103.62042,88.3192359 104.136136,87.5708278 C117.801573,67.3834916 115.371529,40.3587488 98.3242893,22.9367625 C97.751467,22.3593738 97.1326517,21.7526972 96.4929305,21.1376527 C75.8569104,2.0355039 44.0034598,2.0500877 23.3849165,21.1711245 C22.7870071,21.7485132 22.1640106,22.3593738 21.5577388,22.9660503 C2.08373179,42.9146896 2.09115829,74.7733293 21.5744636,94.7128752 C22.1472859,95.28608 22.7702824,95.8969405 23.4058224,96.5078011 L34.1431047,107.055605 C34.7972155,107.686493 35.1667454,108.556482 35.1667454,109.465576 C35.1667454,110.374669 34.7972155,111.244658 34.1431047,111.875546 C33.4860713,112.518171 32.6033203,112.877215 31.6845681,112.875522 Z" id="Path-Copy" fill="url(#linearGradient-1)"></path>
                <path d="M59.9493765,140.611104 C59.0318964,140.612594 58.1505202,140.253498 57.495021,139.611124 L37.1493765,119.64519 C36.4952657,119.014302 36.1257357,118.144313 36.1257357,117.235219 C36.1257357,116.326126 36.4952657,115.456137 37.1493765,114.825249 C38.5172589,113.495079 40.694386,113.495079 42.0622684,114.825249 L59.9493765,132.397949 L97.4211535,95.629166 C98.7890359,94.2989961 100.966163,94.2989961 102.334045,95.629166 C102.988156,96.2600535 103.357686,97.1300426 103.357686,98.0391364 C103.357686,98.9482301 102.988156,99.8182192 102.334045,100.449107 L62.407913,139.611124 C61.75113,140.254131 60.8682158,140.613243 59.9493765,140.611104 Z" id="Path-Copy-2" fill={TEXT_PRIMARY}></path>
                <path d="M28.4775994,58.4126972 C28.4775994,41.2165548 42.9110489,27.5642104 60.9486796,27.5642104 C68.2190359,27.5544506 75.2879973,29.9540291 81.0518155,34.3883055 C82.3396204,35.2753085 82.5988538,37.1706497 81.4364845,38.5722818 C80.3504339,39.7943326 78.5210463,40.0067337 77.1842197,39.065991 C72.550333,35.4394459 66.8314262,33.4793057 60.9486796,33.5013025 C46.2601778,33.5013025 34.6657528,44.7520147 34.6657528,58.4085132 C34.6657528,72.3202343 46.2601778,83.4454272 60.9486796,83.4454272 C66.8080606,83.4500381 72.5087363,81.5402193 77.1842197,78.006258 C78.5241042,77.0685732 80.3538696,77.2864192 81.4364845,78.5125192 C82.5988538,79.9057833 82.3396204,81.8011245 80.792582,82.939166 C75.0649638,87.2249401 68.1005033,89.5318243 60.9486796,89.512317 C42.9110489,89.5163767 28.4775994,75.8849821 28.4775994,58.4126972 Z" id="Path-Copy-3" fill={TEXT_PRIMARY}></path>
            </g>
        </g>
    </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/dashboard">{logo}</RouterLink>;
}
