// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  signUp: path(ROOTS_AUTH, '/sign-up'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifyNewPassword: (verifyToken: string) => path(ROOTS_AUTH, `/new-password/verify/${verifyToken}`),
  newPassword: (resetToken: string) => path(ROOTS_AUTH, `/new-password/${resetToken}`),
  inviteNewPassword: (verifyQuery: string) => path(ROOTS_AUTH, `/invite/set-password${verifyQuery}`),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
  venue: {
    root: path(ROOTS_DASHBOARD, '/venue'),
    new: path(ROOTS_DASHBOARD, '/venue/new'),
    list: path(ROOTS_DASHBOARD, '/venue/list'),
    cards: path(ROOTS_DASHBOARD, '/venue/cards'),
    table: path(ROOTS_DASHBOARD, '/venue/schedule-table'),
    profile: path(ROOTS_DASHBOARD, '/venue/profile'),
    account: path(ROOTS_DASHBOARD, '/venue/account'),
    addInventory: (id: string) => path(ROOTS_DASHBOARD, `/venue/${id}/inventory/entry`),
    view: (name: string) => path(ROOTS_DASHBOARD, `/venue/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/venue/${name}/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/menu'),
    shop: path(ROOTS_DASHBOARD, '/menu/shop'),
    list: path(ROOTS_DASHBOARD, '/menu/list'),
    products: path(ROOTS_DASHBOARD, '/menu/products'),
    categories: path(ROOTS_DASHBOARD, '/menu/categories'),
    checkout: path(ROOTS_DASHBOARD, '/menu/checkout'),
    new: path(ROOTS_DASHBOARD, '/menu/product/new'),
    newCategory: path(ROOTS_DASHBOARD, '/menu/category/new'),
    editCategory: (name: string) => path(ROOTS_DASHBOARD, `/menu/category/${name}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/menu/product/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/menu/product/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/menu/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/menu/product/nike-air-force-1-ndestrukt'),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    new: path(ROOTS_DASHBOARD, '/order/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/order/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/order/${id}/edit`),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    create: path(ROOTS_DASHBOARD, '/inventory/create'),
    control: path(ROOTS_DASHBOARD, '/inventory/control'),
    statement: path(ROOTS_DASHBOARD, '/inventory/statement'),
    venueCreate: (id: string) => path(ROOTS_DASHBOARD, `/inventory/${id}/create`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/inventory/product/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/inventory/product/${id}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  security: {
    root: path(ROOTS_DASHBOARD, '/security'),
    roleList: path(ROOTS_DASHBOARD, '/security/role/list'),
    userList: path(ROOTS_DASHBOARD, '/security/user/list'),
    groupList: path(ROOTS_DASHBOARD, '/security/group/list'),
    venueList: path(ROOTS_DASHBOARD, '/security/venue/list'),
    reports: path(ROOTS_DASHBOARD, '/security/reports'),
    new: path(ROOTS_DASHBOARD, '/security/new'),
    list: path(ROOTS_DASHBOARD, '/security/list'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/security/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/security/${name}/edit`),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = '#';
