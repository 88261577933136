import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { List, IconButton, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';
// type
import { UploadMultiFileProps, UploadProps } from './type';
//
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

export default function SingleFilePreview({
  showPreview = false,
  file,
  onRemove,
}: UploadProps) {
  const hasFile = file !== null;

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      <AnimatePresence>
        <ListItem
          component={m.div}
          {...varFade().inRight}
          sx={{
            my: 1,
            px: 2,
            py: 0.75,
            borderRadius: 0.75,
            border: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          <Iconify
            icon={'eva:file-fill'}
            sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
          />

          <ListItemText
            primary={typeof file === 'string' ? file : file?.name}
            secondary={typeof file === 'string' ? '' : fData(file?.size || 0)}
            primaryTypographyProps={{ variant: 'subtitle2' }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />

          {onRemove && (
            <IconButton edge="end" size="small" onClick={() => onRemove()}>
              <Iconify icon={'eva:close-fill'} />
            </IconButton>
          )}
        </ListItem>
      </AnimatePresence>
    </List>
  );
}
