// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  minTime?: dayjs.Dayjs,
  maxTime?: dayjs.Dayjs,
};

type Props = IProps & TextFieldProps;

export default function RHFTimePicker({ name, minTime, maxTime, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            {...field}
            label={other.label}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            ampm={false}
            minTime={minTime}
            maxTime={maxTime}
            renderInput={(params) => 
              <TextField
                size='small'
                error={!!error}
                helperText={error?.message}
                {...params}
              />
            }
          />
        </LocalizationProvider>
      )}
    />
  );
}
