// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
// translations
import { tokens } from '../../../locales/tokens';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  order: getIcon('ic_invoice'),
  inventory: getIcon('ic_inventory'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  store: getIcon('ic_store'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  security: getIcon('ic_security')
};

const navConfig = [

  // // BARTENDER GENERAL
  // // ----------------------------------------------------------------------
  // {
  //   subheader: (tokens.nav.general),
  //   roles: ['bartender'],
  //   items: [
  //     {
  //       title: (tokens.nav.orders),
  //       path: PATH_DASHBOARD.order.root,
  //       icon: ICONS.order,
  //     },
  //   ],
  // },
  
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: (tokens.nav.general),
    roles: ['admin', 'investor', 'manager'],
    items: [
      { 
        title: (tokens.nav.app),
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
        // tourKey: 'navbar-dashboard-step',
        // info: <Label color="info">+32</Label>,
        // caption: 'caption test',
      },
      // { 
      //   title: (tokens.nav.globalMenu),
      //   path: PATH_DASHBOARD.general.ecommerce,
      //   icon: ICONS.ecommerce,
      //   roles: ['admin', 'manager'],
      // },
      // { 
      //   title: (tokens.nav.analytics),
      //   path: PATH_DASHBOARD.general.analytics,
      //   icon: ICONS.analytics,
      //   roles: ['admin', 'manager'],
      // },
      // { 
      //   title: (tokens.nav.banking),
      //   path: PATH_DASHBOARD.general.banking,
      //   icon: ICONS.banking,
      //   roles: ['admin', 'manager'],
      // },
      // { 
      //   title: (tokens.nav.booking),
      //   path: PATH_DASHBOARD.general.booking,
      //   icon: ICONS.booking,
      //   roles: ['admin', 'manager'],
      // },
      // ORDER
      {
        title: (tokens.nav.orders),
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.order,
        tourKey: 'navbar-orders-step',
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: (tokens.nav.management),
    roles: ['admin', 'manager'],
    items: [
      // USER
      {
        title: (tokens.nav.users),
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        roles: ['admin', 'manager'],
        tourKey: 'navbar-users-step',
        // children: [
        //   { title: 'profile', path: PATH_DASHBOARD.user.profile },
        //   { title: 'cards', path: PATH_DASHBOARD.user.cards },
        //   { title: 'list', path: PATH_DASHBOARD.user.list },
        //   { title: 'create', path: PATH_DASHBOARD.user.new },
        //   { title: 'account', path: PATH_DASHBOARD.user.account },
        // ],
      },

      // VENUES
      { 
        title: (tokens.nav.venues),
        path: PATH_DASHBOARD.venue.root,
        icon: ICONS.store,
        roles: ['admin'],
        tenant: 'all',
        tourKey: 'navbar-venues-step',
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.venue.list },
        //   { title: 'cards', path: PATH_DASHBOARD.venue.cards },
        // ]
      },

      // MENU
      {
        title: (tokens.nav.globalMenu),
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        roles: ['admin', 'manager'],
        children: [
          // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
          { title: (tokens.nav.general), path: PATH_DASHBOARD.eCommerce.list },
          { title: (tokens.nav.categories), path: PATH_DASHBOARD.eCommerce.categories },
          { title: (tokens.nav.products), path: PATH_DASHBOARD.eCommerce.products },
          // { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
          // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
        ],
      },

      // PRODUCT STOCK
      {
        title: (tokens.nav.inventory),
        path: PATH_DASHBOARD.inventory.root,
        icon: ICONS.inventory,
        roles: ['admin', 'manager'],
        info: (
          <Label color="info">
            Novo
          </Label>
        ),
        tourKey: 'navbar-inventory-step',
        children: [
          { title: (tokens.nav.inventoryControl), path: PATH_DASHBOARD.inventory.control },
          { title: (tokens.nav.statement), path: PATH_DASHBOARD.inventory.statement },
          // { title: 'create', path: PATH_DASHBOARD.inventory.add },
        ],
      },

      // INVOICE
      {
        title: (tokens.nav.invoices),
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        roles: ['admin', 'manager'],
        tourKey: 'navbar-invoices-step',
        // children: [
        //   { title: 'list', path: PATH_DASHBOARD.invoice.list },
          // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
          // { title: 'create', path: PATH_DASHBOARD.invoice.new },
          // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
        // ],
      },

      // SECURITY
      // {
      //   title: (tokens.nav.security),
      //   path: PATH_DASHBOARD.security.root,
      //   icon: ICONS.security,
      //   roles: ['admin', 'manager'],
      //   tenant: 'all',
      //   children: [
      //     { title: (tokens.nav.roles), path: PATH_DASHBOARD.security.roleList },
      //     { title: (tokens.nav.users), path: PATH_DASHBOARD.security.userList },
      //     { title: (tokens.nav.economicGroup), path: PATH_DASHBOARD.security.groupList },
      //     { title: (tokens.nav.venues), path: PATH_DASHBOARD.security.venueList },
      //     // { title: 'details', path: PATH_DASHBOARD.security.view },
      //     // { title: 'create', path: PATH_DASHBOARD.security.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.security.edit },
      //     { title: (tokens.nav.reports), path: PATH_DASHBOARD.security.reports },
      //   ],
      // }

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.menuItem,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level_1',
  //       path: '#/dashboard/menu_level_1',
  //       icon: ICONS.menuItem,
  //       children: [
  //         { title: 'menu_level_2a', path: '#/dashboard/menu_level_1/menu_level_2a' },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level_1/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.menuItem,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     { title: 'item_external_link', path: 'https://www.google.com/', icon: ICONS.menuItem },
  //   ],
  // },
];

export default navConfig;
